import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const brandPrimary = definePartsStyle({
  container: {
    borderRadius: "md",
    border: "1px solid",
    borderColor: "stroke.stroke1Light",
    bg: "transparent",
    color: "primary.blue",
    _hover: { bg: "primary.focusOutline" },
    _focus: { boxShadow: "0 0 0 2px rgba(13, 89, 255, 0.5)" }
  }
});

export const tagTheme = defineMultiStyleConfig({
  variants: {
    brand: brandPrimary
  }
});
