import { Timestamp } from "firebase/firestore";
import { ClientData, DataDocs, VISAVALUE } from "./types/tables-data";

export const data: ClientData[] = [
  {
    id: "a03",
    firstName: "Pan",
    lastName: "Twom",
    name: "Pan Twom",
    birthDay: 347144400000,
    visa: VISAVALUE.EB2NIW,
    citizenship: "France",
    createdDate: 1648653700000,
    email: "",
    // status: ,
    priority: "P1",
    actionNeeded: false,
    caseId: 4,
    isActive: false,
    overview: {
      steps: [
        {
          title: "Step 1",
          description: "First call with client",
          isComplete: true
        },
        {
          title: "Step 2",
          description: "Client reviewed tutorials and started data collection",
          isComplete: true
        },
        {
          title: "Step 3",
          description: "Client completed questionnaires",
          isComplete: true
        },
        { title: "Step 4", description: "Follow-up call", isComplete: true },
        { title: "Step 5", description: "Case generation", isComplete: true }
      ]
    }
  }
];

export const overviewTab = {
  steps: [
    {
      title: "Step 1",
      description: "Complete tutorials",
      isComplete: false
    },
    {
      title: "Step 2",
      description: "Upload documents",
      isComplete: false
    },
    {
      title: "Step 3",
      description: "Complete questionnaires",
      isComplete: false
    },
    {
      title: "Step 4",
      description: "Generate drafts",
      isComplete: false
    },
    {
      title: "Step 5",
      description: "Review",
      isComplete: false
    },
    {
      title: "Step 6",
      description: "Submission and tracking",
      isComplete: false
    }
  ]
};

// test-data to build correct DB-structure
const currentTime = Timestamp.now();
export const eb2Docs: DataDocs[] = [
  {
    documentTitle: "Passport",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "Visa",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "I-94",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "Resume | CV",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "I-140 Approval Notice",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "Educational Documents",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "Academic Evaluation",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  }
];

// TODO : merge with the rest of the types to promote reusability
export const o1Docs: DataDocs[] = [
  {
    documentTitle: "Resume | CV",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "Passport",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "Visa",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "I-94",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "Status documents",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "I-140 Approval Notice",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "Educational documents",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "Academic evaluation",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "Offer letter or contract",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "Itinerary",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "Position Job description",
    uploadDate: currentTime,
    uploadBy: "",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  }
];

export const evidenceDocuments: DataDocs[] = [
  {
    documentTitle: "NIW Evidence 1",
    uploadDate: currentTime,
    uploadBy: "Not uploaded",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  },
  {
    documentTitle: "NIW Evidence 1",
    uploadDate: currentTime,
    uploadBy: "Not uploaded",
    isRequireForForms: true,
    isRequireForDraft: true,
    exhibit: 0,
    isDeleted: false
  }
];

export const packet = [
  {
    documentTitle: "Final packet",
    generationDate: "6/1/2023",
    generatedBy: "Laurie Wog"
  }
];

export const testPrefilledForms = [
  {
    documentTitle: "I-140",
    generationDate: "6/1/2023",
    generatedBy: "Finalized"
  },
  {
    documentTitle: "G-28",
    generationDate: "6/1/2023",
    generatedBy: "Lawyer editing"
  }
];
export const testExtractInformation = [
  {
    documentTitle: "A.I extracted information",
    generationDate: "6/1/2023",
    generatedBy: "Finalized"
  }
];
