import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  useToast,
  Alert,
  AlertIcon,
  AlertDescription,
  Flex
} from "@chakra-ui/react";
import {
  PhoneMultiFactorInfo,
  getAuth,
  sendEmailVerification,
  EmailAuthProvider,
  reauthenticateWithCredential,
  getMultiFactorResolver,
  MultiFactorResolver,
  PhoneAuthProvider
} from "firebase/auth";
import { useState, useEffect } from "react";
import { useMultiFactorAuth } from "../hooks/useMultiFactorAuth";
import { CustomPhoneInput } from "./common/PhoneInput";
import { ReauthDialog } from "./ReauthDialog";

export const MFAManagement = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isSendingVerification, setIsSendingVerification] = useState(false);
  const [reauthState, setReauthState] = useState<{
    show: boolean;
    action: "enroll" | "unenroll";
    pendingFactorId?: string;
    pendingPhoneNumber?: string;
    showMFA?: boolean;
    mfaResolver?: MultiFactorResolver;
    verificationId?: string;
  } | null>(null);
  const toast = useToast();
  const auth = getAuth();

  const {
    isEnrolling,
    error,
    enrolledFactors,
    startEnrollment,
    completeEnrollment,
    unenrollFactor,
    getRecaptchaVerifier,
    verifyLoginCode,
    initiateLoginVerification
  } = useMultiFactorAuth();

  // Check email verification status
  const checkEmailVerification = async () => {
    if (auth.currentUser) {
      await auth.currentUser.reload();
      setIsEmailVerified(auth.currentUser.emailVerified);
    }
  };

  // Send verification email
  const sendVerificationEmail = async () => {
    if (auth.currentUser) {
      setIsSendingVerification(true);
      try {
        await sendEmailVerification(auth.currentUser);
        toast({
          title: "Verification Email Sent",
          description: "Please check your email to verify your address",
          status: "success"
        });
      } catch (error: any) {
        toast({
          title: "Error",
          description: error.message,
          status: "error"
        });
      } finally {
        setIsSendingVerification(false);
      }
    }
  };

  // Check verification status when component mounts and after email verification
  useEffect(() => {
    checkEmailVerification();
    const interval = setInterval(checkEmailVerification, 10000); // Check every 10 seconds
    return () => clearInterval(interval);
  }, []);

  const handleReauthenticate = async (password: string) => {
    if (!auth.currentUser?.email) return;

    try {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        password
      );
      await reauthenticateWithCredential(auth.currentUser, credential);

      if (reauthState?.action === "enroll" && reauthState.pendingPhoneNumber) {
        await startEnrollment(reauthState.pendingPhoneNumber);
        setShowVerification(true);
      } else if (
        reauthState?.action === "unenroll" &&
        reauthState.pendingFactorId
      ) {
        await unenrollFactor(reauthState.pendingFactorId);
      }
      setReauthState(null);
    } catch (error: any) {
      if (error.code === "auth/multi-factor-auth-required") {
        const resolver = getMultiFactorResolver(auth, error);
        await initiateLoginVerification(resolver);
        setReauthState({
          ...reauthState!,
          showMFA: true,
          mfaResolver: resolver
        });
      } else {
        toast({
          title: "Error",
          description: error.message,
          status: "error"
        });
      }
    }
  };

  const handleStartEnrollment = async () => {
    try {
      await startEnrollment(phoneNumber);
      setShowVerification(true);
    } catch (error: any) {
      if (error.code === "auth/requires-recent-login") {
        setReauthState({
          show: true,
          action: "enroll",
          pendingPhoneNumber: phoneNumber
        });
      } else {
        toast({
          title: "Error",
          description: error.message,
          status: "error"
        });
      }
    }
  };

  const handleCompleteEnrollment = async () => {
    await completeEnrollment(verificationCode);
    if (!error) {
      setShowVerification(false);
      setPhoneNumber("");
      setVerificationCode("");
      toast({
        title: "MFA Enrolled",
        description:
          "Two-factor authentication has been enabled for your account",
        status: "success"
      });
    }
  };

  const handleUnenroll = async (factorId: string) => {
    try {
      await unenrollFactor(factorId);
      if (!error) {
        toast({
          title: "MFA Removed",
          description: "Two-factor authentication has been disabled",
          status: "info"
        });
      }
    } catch (error: any) {
      if (error.code === "auth/requires-recent-login") {
        setReauthState({
          show: true,
          action: "unenroll",
          pendingFactorId: factorId
        });
      } else {
        toast({
          title: "Error",
          description: error.message,
          status: "error"
        });
      }
    }
  };

  const handlePhoneChange = (phone: string) => {
    // Ensure phone number starts with +
    const formattedPhone = phone.startsWith("+") ? phone : `+${phone}`;
    setPhoneNumber(formattedPhone);
  };

  return (
    <Box>
      {!isEmailVerified ? (
        <Alert status="warning" mb={4}>
          <AlertIcon />
          <AlertDescription
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Text>
              You need to verify your email before enabling two-factor
              authentication.
            </Text>
            <Button
              ml={4}
              size="sm"
              colorScheme="blue"
              onClick={sendVerificationEmail}
              isLoading={isSendingVerification}
              flexShrink={0}
            >
              Send Verification Email
            </Button>
          </AlertDescription>
        </Alert>
      ) : enrolledFactors && enrolledFactors.length > 0 ? (
        <VStack align="stretch" spacing={4}>
          {enrolledFactors.map((factor) => (
            <Box
              key={factor.uid}
              p={4}
              border="1px"
              borderRadius="md"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text>
                Phone Number: {(factor as PhoneMultiFactorInfo).phoneNumber}
              </Text>
              <Button
                colorScheme="red"
                size="sm"
                onClick={() => handleUnenroll(factor.uid)}
                ml={4}
              >
                Remove
              </Button>
            </Box>
          ))}
        </VStack>
      ) : !showVerification ? (
        <FormControl variant="fixed">
          <FormLabel>Phone Number</FormLabel>
          <CustomPhoneInput
            value={phoneNumber}
            onChange={handlePhoneChange}
            placeholder="Enter phone number"
          />
          <Button
            mt={4}
            onClick={handleStartEnrollment}
            isLoading={isEnrolling}
            isDisabled={reauthState?.show}
          >
            Enable Two-Factor
          </Button>
        </FormControl>
      ) : (
        <FormControl variant="fixed">
          <FormLabel>Verification Code</FormLabel>
          <Input
            type="text"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder="Enter code"
          />
          <Button
            mt={4}
            onClick={handleCompleteEnrollment}
            isLoading={isEnrolling}
          >
            Verify
          </Button>
        </FormControl>
      )}

      {reauthState?.show && (
        <ReauthDialog
          onReauthenticate={handleReauthenticate}
          actionText={
            reauthState.action === "enroll" ? "enable MFA" : "remove MFA"
          }
          showMFA={reauthState.showMFA}
          phoneNumber={
            (reauthState.mfaResolver?.hints[0] as PhoneMultiFactorInfo)
              ?.phoneNumber
          }
          onMFACode={async (code) => {
            if (reauthState.mfaResolver) {
              await verifyLoginCode(reauthState.mfaResolver, code);
              // Retry the unenroll operation after successful MFA verification
              if (
                reauthState.action === "unenroll" &&
                reauthState.pendingFactorId
              ) {
                try {
                  await unenrollFactor(reauthState.pendingFactorId);
                  if (!error) {
                    toast({
                      title: "MFA Removed",
                      description:
                        "Two-factor authentication has been disabled",
                      status: "info"
                    });
                  }
                } catch (error: any) {
                  toast({
                    title: "Error",
                    description: error.message,
                    status: "error"
                  });
                }
              }
              setReauthState(null);
            }
          }}
        />
      )}
    </Box>
  );
};
