import { useState, useEffect, useRef } from "react";
import { Tooltip, TooltipProps } from "@chakra-ui/react";

interface CustomTooltipProps extends TooltipProps {
  children: React.ReactNode;
  label: string;
}

const CustomTooltip = ({ children, label, ...props }: CustomTooltipProps) => {
  const tooltipRef = useRef<HTMLSpanElement>(null);

  return (
    <Tooltip
      label={label}
      closeOnScroll
      hasArrow
      bg="gray.900"
      color="white"
      fontSize="sm"
      fontWeight="medium"
      borderRadius="md"
      py={1}
      px={3}
      placement="top"
      zIndex="10000"
      {...props}
    >
      <span ref={tooltipRef} onFocus={(e) => e.preventDefault()}>
        {children}
      </span>
    </Tooltip>
  );
};

export default CustomTooltip;
