import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomTemplate } from "../../types/studio/templates";
import { toSnakeCase } from "../../helpers/string_helpers";

export interface TemplatesState {
  customTemplates: CustomTemplate[];
  selectedTemplate?: CustomTemplate;
  selectedCustomBriefIndex?: number | undefined;
}

const initialState: TemplatesState = {
  customTemplates: [],
  selectedTemplate: undefined,
  selectedCustomBriefIndex: undefined
};

export interface TemplatesReducer {
  templates: TemplatesState;
}

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    setTemplates: (
      state,
      action: PayloadAction<{
        templates: CustomTemplate[];
      }>
    ) => {
      const { templates } = action.payload;

      if (!Array.isArray(templates)) {
        throw new Error("Templates payload must be an array");
      }

      state.customTemplates = templates.map((template: CustomTemplate) => ({
        ...template,
        sections: template.sections?.map((section) => ({
          ...section,
          id: toSnakeCase(section.section_name || "")
        }))
      }));
    },

    setSelectedTemplate: (
      state,
      action: PayloadAction<CustomTemplate | undefined>
    ) => {
      state.selectedTemplate = action.payload;
    },
    setSelectedCustomBriefIndex: (state, action: PayloadAction<number>) => {
      state.selectedCustomBriefIndex = action.payload;
    },
    updateTemplateById: (
      state,
      action: PayloadAction<{ id: string; updates: Partial<CustomTemplate> }>
    ) => {
      const { id, updates } = action.payload;
      const templateIndex = state.customTemplates.findIndex((t) => t.id === id);

      if (templateIndex !== -1) {
        state.customTemplates[templateIndex] = {
          ...state.customTemplates[templateIndex],
          ...updates
        };
      }
    }
  }
});

export const {
  setTemplates,
  setSelectedCustomBriefIndex,
  setSelectedTemplate,
  updateTemplateById
} = templatesSlice.actions;

export default templatesSlice.reducer;
