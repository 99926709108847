import { PromptsReducer } from "./promptsSlice";

const selectAll = (state: PromptsReducer) =>
  Array.isArray(state.prompts.prompts) ? state.prompts.prompts : [];

export const selectSelectedPromptId = (state: PromptsReducer) =>
  state.prompts.selectedPromptId;

export const selectSelectedPrompt = (state: PromptsReducer) =>
  state.prompts.selectedPrompt;

const selectCustomPrompts = (state: PromptsReducer) =>
  selectAll(state).filter((prompt) => !prompt.type);

const selectRewriteOptions = (state: PromptsReducer) =>
  selectAll(state).filter((prompt) => prompt.type === "rewrite_options");

const selectToneOptions = (state: PromptsReducer) =>
  selectAll(state).filter((prompt) => prompt.type === "tone_options");

const selectDefaultPrompts = (state: PromptsReducer) =>
  selectAll(state).filter((prompt) => prompt.type === "default_prompts");

const selectPublicPrompts = (state: PromptsReducer) =>
  selectAll(state).filter((prompt) => prompt.access?.public === true);

export const promptsSelectors = {
  selectAll,
  selectSelectedPromptId,
  selectSelectedPrompt,
  selectRewriteOptions,
  selectToneOptions,
  selectDefaultPrompts,
  selectCustomPrompts,
  selectPublicPrompts
};
