import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { db } from "../api/firebaseApi";
import {
  autoGenerateTopics,
  generateCaseResearchSummary
} from "../api/OpenAIApi";
import {
  removeResearchTopic,
  setResearchTopics
} from "../redux/case-research/caseResearchSlice";
import { CaseResearchTopic } from "../types/case-research";
import { DATABASE } from "../types/tables-data";

const useManageCaseResearch = (
  userId?: string | null,
  withFetch?: boolean | null
) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetch = () => {
    if (!userId) {
      setLoading(false);
      return () => {}; // Cleanup function
    }

    const unsubscribeTopics = onSnapshot(
      query(
        collection(db, DATABASE.CASE_RESEARCH, userId, "research_topics"),
        orderBy("topicTitle") // Order by topicTitle
      ),
      (snapshot) => {
        const topics: CaseResearchTopic[] = snapshot.docs.map((doc) => ({
          ...(doc.data() as CaseResearchTopic),
          id: doc.id
        }));
        dispatch(setResearchTopics(topics));
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching case research topics:", error);
        setLoading(false);
      }
    );

    return () => unsubscribeTopics(); // Cleanup function
  };

  useEffect(() => {
    if (userId && fetch) {
      return fetch();
    }
    return undefined;
  }, [userId, withFetch]);

  const onSaveCaseResearchTopic = async (
    topicId: string,
    updatedDoc: { summary: string; summaryHtml: string }
  ) => {
    if (!userId) {
      console.warn(`[CASE_RESEARCH_WARN] No userId found. Skipping save.`);
      return;
    }

    console.log(
      `[CASE_RESEARCH_START] Saving topic`,
      JSON.stringify({ userId, topicId, updatedDoc })
    );

    try {
      const topicPath = `${DATABASE.CASE_RESEARCH}/${userId}/research_topics`;
      console.log(`[CASE_RESEARCH_PATH] Target path: ${topicPath}`);

      const topicRef = doc(db, topicPath, topicId);
      console.log(`[CASE_RESEARCH_REF] Document reference created`, topicRef);

      await updateDoc(topicRef, updatedDoc);

      console.log(
        `[CASE_RESEARCH_SUCCESS] Topic updated successfully`,
        JSON.stringify({ topicId, updatedDoc })
      );
    } catch (e) {
      console.error(
        `[CASE_RESEARCH_ERROR] Failed to update topic`,
        JSON.stringify({ error: e, topicId, updatedDoc })
      );
      setError(e as Error);
    }
  };

  const deleteResearchTopic = async (topicId: string) => {
    if (!userId) return;
    try {
      const topicRef = doc(
        db,
        `${DATABASE.CASE_RESEARCH}/${userId}/research_topics`,
        topicId
      );
      await deleteDoc(topicRef);
      removeResearchTopic(topicId);
    } catch (e) {
      setError(e as Error);
    }
  };

  const generateSummary = async () => {
    generateCaseResearchSummary(userId!);
  };

  const generateTopics = async (topicId?: string) => {
    await autoGenerateTopics(userId!, topicId);
  };

  return {
    fetch,
    loading,
    error,
    generateSummary,
    generateTopics,
    deleteResearchTopic,
    onSaveCaseResearchTopic
  };
};

export default useManageCaseResearch;
