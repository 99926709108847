import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Switch,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  rectIntersection,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import { doc, updateDoc, writeBatch } from "firebase/firestore";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../api/firebaseApi";
import { SuperGroupedDocument } from "../../helpers/helpers";
import useDocuments from "../../hooks/useDocuments";
import { generateSingleDocumentUrl } from "../../redux/documents/documentsThunk";
import { documentSelectors } from "../../redux/documents/selectors";
import { VisaDocumentType } from "../../redux/documents/types";
import { useTypedDispatch } from "../../redux/store";
import EditMainDocModal from "../individualTabs/individualDocuments/EditMainDocModal";
import { DocumentItemPlaceHolder } from "./DndHelpers";
import { DraggableSuperClass } from "./DocumentsTableComponents";
import { DocumentPreviewModal } from "./DocumentPreviewModal";
import { DATABASE, DataDocs } from "../../types/tables-data";
import UploadByCategoryModal from "../individualTabs/individualDocuments/UploadByCategoryModal";

export const DocumentsTable = () => {
  const dispatch = useTypedDispatch();

  const { visaType: visaTypeInParam, id: uid } = useParams();
  const evidenceDocsCollectionPath = `documents/${uid}/evidence_docs`;
  const evidenceDocumentsStoragePath = `${DATABASE.INDIVIDUALS}/documents/${uid}/Evidences`;

  const [categoryWithSuperClass, setCategoryWithSuperclass] = useState<
    undefined | Record<string, string>
  >(undefined);

  const isLoadingDocuments = useSelector(documentSelectors.isLoadingDocuments);
  const superGroupedDocuments = useSelector(
    documentSelectors.groupedDocuments
  ).filter((sg) => sg.groups.length > 0);
  const flattenedSuperGroupedDocuments = superGroupedDocuments.flatMap((sup) =>
    sup.groups.flatMap((group) => group.subrows)
  );

  const [isAiToggled, setIsAiToggled] = useState(true);
  const [activeDocumentId, setActiveDocumentId] = useState<Record<
    string,
    string
  > | null>(null);

  const { groupedDocuments, updateCurrentOrder } = useDocuments(
    [
      {
        path: `/documents/${uid}/docs`,
        documentType: VisaDocumentType.Standard,
        super_class: "Standard"
      },
      {
        path: `/documents/${uid}/signed_expert_letters`,
        documentType: VisaDocumentType.SignedExpertLetters,
        super_class: "Letters"
      },
      {
        path: `/documents/${uid}/evidence_docs`,
        documentType: VisaDocumentType.Evidence,
        super_class: "Evidence"
      }
    ],
    false
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {})
  );

  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose
  } = useDisclosure();

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose
  } = useDisclosure();

  const [isDeleting, setIsDeleting] = useState(false);

  const [documentToDelete, setDocumentToDelete] = useState<
    DataDocs | undefined
  >();

  const [documentToEdit, setDocumentToEdit] = useState<DataDocs | undefined>();

  const [categoryToDelete, setCategoryToDelete] = useState<{
    superClass: string;
    groupType: string;
  } | null>(null);

  const {
    isOpen: isCategoryDeleteOpen,
    onOpen: onCategoryDeleteOpen,
    onClose: onCategoryDeleteClose
  } = useDisclosure();

  const [isCategoryDeleting, setIsCategoryDeleting] = useState(false);

  const [documentToPreview, setDocumentToPreview] = useState<DataDocs | null>(
    null
  );

  const selectedDocument = flattenedSuperGroupedDocuments.find(
    (doc) => doc.id === documentToPreview?.id
  );
  const {
    isOpen: isPreviewOpen,
    onOpen: onPreviewOpen,
    onClose: onPreviewClose
  } = useDisclosure();

  const handleEditClick = (document: DataDocs) => {
    setDocumentToEdit({ ...document });
  };

  const updateDocumentNewField = (document: DataDocs | undefined) => {
    if (
      document &&
      document.docRef &&
      document.isNew &&
      document.super_class !== "Processing"
    ) {
      const docRef = doc(db, document.docRef);
      updateDoc(docRef, { isNew: false });
    }
  };

  const handleAddByCategory = (category: string, superClass: string) => {
    setCategoryWithSuperclass({
      category,
      superClass
    });
    onAddOpen();
  };
  const handleDeleteClick = (document: DataDocs) => {
    setDocumentToDelete({ ...document });
    updateDocumentNewField(document);
  };

  const handleCategoryDeleteClick = (superClass: string, groupType: string) => {
    setCategoryToDelete({ superClass, groupType });
    onCategoryDeleteOpen();
  };

  const handlePreviewClick = (document: DataDocs) => {
    if (!document.docUrl) {
      dispatch(
        generateSingleDocumentUrl({
          filePath: document.pdf_file_path || document.filePath || "",
          id: document.id!,
          super_class: document.super_class ?? "",
          criterion: document.criterion ?? ""
        })
      );
    }
    setDocumentToPreview(document);
    updateDocumentNewField(document);
    onPreviewOpen();
  };

  const debouncedUpdate = useCallback(
    _.debounce((docs) => {
      console.log("updating order and redux");

      updateCurrentOrder(docs);
    }, 1000),
    []
  );

  useEffect(() => {
    if (groupedDocuments && groupedDocuments.length > 0) {
      debouncedUpdate(groupedDocuments);
    }
    return () => {
      debouncedUpdate.cancel();
    };
  }, [groupedDocuments]);

  const hasSubRows = (sg: SuperGroupedDocument) => {
    const allDocuments = sg.groups.flatMap((gr) =>
      gr.subrows.flatMap((doc) => doc)
    );

    return allDocuments.length > 0;
  };

  useEffect(() => {
    if (documentToEdit) {
      onEditOpen();
    }
  }, [documentToEdit, onEditOpen]);

  useEffect(() => {
    if (documentToDelete) {
      onOpen();
    }
  }, [documentToDelete, onOpen]);

  useEffect(() => {
    updateCurrentOrder(superGroupedDocuments);
  }, [flattenedSuperGroupedDocuments.length]);

  const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = Boolean(e.target.checked);

    setIsAiToggled(checked);
  };

  const toggleGroupExpansion = (superClass: string, groupType: string) => {
    const updatedDocuments = superGroupedDocuments.map((superGroup) => {
      if (superGroup.super_class === superClass) {
        return {
          ...superGroup,
          groups: superGroup.groups.map((group) => {
            if (group.type === groupType) {
              return { ...group, expanded: !group.expanded };
            }
            return group;
          })
        };
      }
      return superGroup;
    });

    updateCurrentOrder(updatedDocuments);
  };

  const toggleSuperGroupExpansion = (superClass: string, fold: boolean) => {
    const updatedDocuments = superGroupedDocuments.map((superGroup) => {
      if (superGroup.super_class === superClass) {
        return {
          ...superGroup,
          groups: superGroup.groups.map((group) => ({
            ...group,
            expanded: fold
          }))
        };
      }
      return superGroup;
    });

    updateCurrentOrder(updatedDocuments);
  };

  return (
    <div className="">
      <DndContext
        collisionDetection={closestCenter}
        sensors={sensors}
        id="group"
        onDragCancel={() => {
          setActiveDocumentId(null);
        }}
        onDragStart={(e) => {
          const { active } = e;

          setActiveDocumentId({ ...active?.data?.current?.metadata });
          const activeElementMetadata = active?.data?.current?.metadata;
          if (activeElementMetadata.container === "row") {
            if (active.id) {
              const document = flattenedSuperGroupedDocuments.find(
                (doc) => doc.id === active.id
              );
              if (!document?.isNew) updateDocumentNewField(document);
            }
          }
        }}
        onDragEnd={async (e) => {
          setActiveDocumentId(null);
          const { active, over } = e;

          if (active && over) {
            const activeElementMetadata = active?.data?.current?.metadata;
            const overElementMetadata = over?.data?.current?.metadata;

            const activeContainer = activeElementMetadata.container;
            const overContainer = overElementMetadata.container;

            // ROW -> ROW
            if (activeContainer === "row" && overContainer === "row") {
              const {
                group: activeGroup,
                superClass: activeSuperClass,
                id: activeId
              } = activeElementMetadata;
              const {
                group: overGroup,
                superClass: overSuperClass,
                id: overId
              } = overElementMetadata;

              // Check if rows are in the same super_class and group
              if (
                activeSuperClass === overSuperClass &&
                activeGroup === overGroup
              ) {
                const superGroupIndex = superGroupedDocuments?.findIndex(
                  (el) => el.super_class === activeSuperClass
                );
                if (superGroupedDocuments && superGroupIndex !== -1) {
                  const newSuperGroupedDocuments = [...superGroupedDocuments];
                  const superGroup = {
                    ...newSuperGroupedDocuments[superGroupIndex]
                  };

                  const categoryIndex = superGroup.groups.findIndex(
                    (cat) => activeGroup === cat.type
                  );

                  if (categoryIndex !== -1) {
                    const group = { ...superGroup.groups[categoryIndex] };

                    const subRows = [...group.subrows];

                    const activeIndex = subRows.findIndex(
                      (subrow) => subrow.id === activeId
                    );
                    const overIndex = subRows.findIndex(
                      (subrow) => subrow.id === overId
                    );

                    if (activeIndex !== -1 && overIndex !== -1) {
                      const newSubRows = arrayMove(
                        subRows,
                        activeIndex,
                        overIndex
                      );

                      group.subrows = newSubRows;

                      const newGroups = [...superGroup.groups];
                      newGroups[categoryIndex] = group;

                      superGroup.groups = newGroups;

                      newSuperGroupedDocuments[superGroupIndex] = superGroup;

                      updateCurrentOrder(newSuperGroupedDocuments);
                    }
                  }
                } else {
                  console.log("dragging different group");
                }
              }
            }

            // ROW -> GROUP
            if (activeContainer === "row" && overContainer === "group") {
              const {
                group: activeGroup,
                superClass: activeSuperClass,
                id: activeId
              } = activeElementMetadata;
              const {
                group: overGroup,
                superClass: overSuperClass,
                id: overId
              } = overElementMetadata;

              if (
                activeGroup === overGroup &&
                activeSuperClass === overSuperClass
              )
                return;

              const superGroupedDocumentsCopy = superGroupedDocuments.map(
                (sg) => ({
                  ...sg,
                  groups: sg.groups.map((gr) => ({
                    ...gr,
                    subrows: [...gr.subrows]
                  }))
                })
              );

              const activeSuperClassIndex = superGroupedDocumentsCopy.findIndex(
                (sg) => sg.super_class === activeSuperClass
              );
              const overSuperClassIndex = superGroupedDocumentsCopy.findIndex(
                (sg) => sg.super_class === overSuperClass
              );

              if (activeSuperClassIndex !== -1 && overSuperClassIndex !== -1) {
                // Remove active document from its current group
                const activeSuperClassData =
                  superGroupedDocumentsCopy[activeSuperClassIndex];

                const activeGroupIndex = activeSuperClassData.groups.findIndex(
                  (gr) => gr.type === activeGroup
                );
                const activeDocument = activeSuperClassData.groups[
                  activeGroupIndex
                ].subrows.find((doc) => doc.id === activeId);

                if (activeGroupIndex !== -1) {
                  activeSuperClassData.groups[activeGroupIndex].subrows =
                    activeSuperClassData.groups[
                      activeGroupIndex
                    ].subrows.filter((sb) => sb.id !== activeId);
                }

                // updated status for super_class
                superGroupedDocumentsCopy[activeSuperClassIndex] = {
                  ...activeSuperClassData
                };

                // Add active document to the target group
                const overSuperClassData =
                  superGroupedDocumentsCopy[overSuperClassIndex];
                const overGroupIndex = overSuperClassData.groups.findIndex(
                  (gr) => gr.type === overGroup
                );
                const group = overSuperClassData.groups[overGroupIndex];
                if (overGroupIndex !== -1 && activeDocument) {
                  overSuperClassData.groups[overGroupIndex].subrows = [
                    ...overSuperClassData.groups[overGroupIndex].subrows,
                    activeDocument
                  ];
                }
                superGroupedDocumentsCopy[overSuperClassIndex] = {
                  ...overSuperClassData
                };

                const docRef = doc(db, activeDocument!.docRef!);

                await updateDoc(docRef, {
                  criterion: group.type,
                  super_class: overSuperClassData.super_class,
                  type: null,
                  // BE will run classification when this attribute is set to true
                  reclassify: true
                });

                updateCurrentOrder(superGroupedDocumentsCopy);
              }
            }

            // ROW -> SUPER_CLASS
            if (activeContainer === "row" && overContainer === "super_class") {
              console.log("dragging row into super_class");
            }

            // SUPER_CLASS -> SUPER_CLASS
            if (
              activeContainer === "super_class" &&
              overContainer === "super_class"
            ) {
              const tempSuperClass = [...superGroupedDocuments];
              const activeSuperClassIndex = tempSuperClass.findIndex(
                (sg) => sg.super_class === active.id.toString()
              );
              const overSuperClassIndex = tempSuperClass.findIndex(
                (sg) => sg.super_class === over.id.toString()
              );
              const newSuperClassOrder = arrayMove(
                tempSuperClass,
                activeSuperClassIndex,
                overSuperClassIndex
              );

              updateCurrentOrder(newSuperClassOrder);
            }

            // GROUP -> GROUP
            if (activeContainer === "group" && overContainer === "group") {
              const { group: activeGroup, superClass: activeSuperClass } =
                activeElementMetadata;
              const { group: overGroup } = overElementMetadata;

              const superGroupIndex = superGroupedDocuments?.findIndex(
                (el) => el.super_class === activeSuperClass
              );
              if (superGroupIndex !== -1) {
                const superGroup = superGroupedDocuments[superGroupIndex];
                const activeCategoryIndex = superGroup?.groups.findIndex(
                  (group) => group.type === activeGroup
                );
                const overCategoryIndex = superGroup?.groups.findIndex(
                  (group) => group.type === overGroup
                );

                const newSuperGroupedDocuments = [...superGroupedDocuments];
                const newSuperGroup = {
                  ...newSuperGroupedDocuments[superGroupIndex]
                };

                newSuperGroup.groups = arrayMove(
                  [...newSuperGroup.groups],
                  activeCategoryIndex,
                  overCategoryIndex
                );
                newSuperGroupedDocuments[superGroupIndex] = newSuperGroup;
                updateCurrentOrder(newSuperGroupedDocuments!);
              }
              console.log("dragging between groups");
            }
          }
        }}
      >
        <div className="flex-col">
          {/* Sticky header */}
          <Box
            className="flex !items-center header sticky top-0 bottom-0 z-[1] shadow-sm w-full py-3 px-6  "
            bg="background.misty"
          >
            <div className="flex flex-1 justify-between items-center gap-2 w-full uppercase text-xs font-[600]">
              <Text>Exhibit</Text>
              <Spacer />
              <div className="flex items-center gap-2">
                <Text>
                  {isAiToggled ? "ai-generated title" : "Document Title"}{" "}
                </Text>
                <Switch isChecked={isAiToggled} onChange={handleToggleChange} />
              </div>
              <Spacer />
              <Text>Actions</Text>
            </div>
          </Box>

          {/* Table body */}
          <div className="flex flex-col  ">
            {/* Render the "Processing" super_class separately to ensure it maintains its order 
                  TODO : delete this logic and fix the root cause from useDocuments hook   */}
            {superGroupedDocuments
              .filter((sg) => sg.super_class === "Processing")
              .map((superGroup) => (
                <DraggableSuperClass
                  key={superGroup.super_class}
                  handleDeleteClick={handleDeleteClick}
                  handleEditClick={handleEditClick}
                  handleCategoryDeleteClick={handleCategoryDeleteClick}
                  handlePreviewClick={handlePreviewClick}
                  isAiToggled={isAiToggled}
                  superGroup={superGroup}
                  toggleGroupExpansion={toggleGroupExpansion}
                  toggleSuperGroupExpansion={toggleSuperGroupExpansion}
                  updateDocumentNewField={updateDocumentNewField}
                  handleAddByCategory={handleAddByCategory}
                  isDraggingSuperClass={
                    activeDocumentId?.container === "super_class"
                  }
                />
              ))}

            <SortableContext
              strategy={verticalListSortingStrategy}
              items={[
                ...superGroupedDocuments.map((sg) => ({
                  id: sg.super_class
                }))
              ]}
            >
              {superGroupedDocuments
                .filter(hasSubRows)
                .filter((sg) => sg.super_class !== "Processing")
                .map((superGroup) => (
                  <DraggableSuperClass
                    key={superGroup.super_class}
                    handleDeleteClick={handleDeleteClick}
                    handleEditClick={handleEditClick}
                    handleCategoryDeleteClick={handleCategoryDeleteClick}
                    handlePreviewClick={handlePreviewClick}
                    isAiToggled={isAiToggled}
                    superGroup={superGroup}
                    toggleGroupExpansion={toggleGroupExpansion}
                    toggleSuperGroupExpansion={toggleSuperGroupExpansion}
                    updateDocumentNewField={updateDocumentNewField}
                    handleAddByCategory={handleAddByCategory}
                    isDraggingSuperClass={
                      activeDocumentId?.container === "super_class"
                    }
                  />
                ))}
            </SortableContext>
          </div>
        </div>
        <DragOverlay dropAnimation={null}>
          {activeDocumentId && activeDocumentId.container === "row" && (
            <DocumentItemPlaceHolder
              document={flattenedSuperGroupedDocuments.find(
                (doc) => doc.id === activeDocumentId.id
              )}
              isAiToggled={isAiToggled}
            />
          )}
        </DragOverlay>
      </DndContext>
      {documentToEdit !== undefined && (
        <EditMainDocModal
          isDeleting={false}
          document={documentToEdit}
          headerText="Edit Main Document"
          isOpen={isEditOpen}
          onClose={onEditClose}
        />
      )}

      <UploadByCategoryModal
        isOpen={isAddOpen}
        onClose={onAddClose}
        storagePath={evidenceDocumentsStoragePath}
        collectionPath={evidenceDocsCollectionPath}
        visaDocumentType={VisaDocumentType.Evidence}
        selectedCategoryWithSuperclass={categoryWithSuperClass}
      />

      {/* delete modal */}
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
            }}
          />
          <ModalBody pb={6}>
            Are you sure you want to delete the document?
          </ModalBody>

          <ModalFooter>
            <Button variant="secondaryOutline" onClick={onClose}>
              Cancel
            </Button>

            <Button
              variant="destructiveFilled"
              ml={3}
              isLoading={isDeleting}
              onClick={async () => {
                // edge case update documents to an empty array
                // before component gets unmounted

                if (groupedDocuments && groupedDocuments.length === 1) {
                  updateCurrentOrder([]);
                }

                if (documentToDelete?.docRef) {
                  setIsDeleting(true);

                  // soft delete
                  const docRef = doc(db, documentToDelete?.docRef ?? "");
                  await updateDoc(docRef, { isDeleted: true });

                  setIsDeleting(false);

                  onClose();
                }
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* category delete modal */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={isCategoryDeleteOpen}
        onClose={onCategoryDeleteClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton onClick={onCategoryDeleteClose} />
          <ModalBody pb={6}>
            Are you sure you want to delete the category?
          </ModalBody>

          <ModalFooter>
            <Button variant="secondaryOutline" onClick={onCategoryDeleteClose}>
              Cancel
            </Button>

            <Button
              variant="destructiveFilled"
              ml={3}
              isLoading={isCategoryDeleting}
              onClick={async () => {
                if (categoryToDelete) {
                  setIsCategoryDeleting(true);
                  const { superClass, groupType } = categoryToDelete;

                  const batch = writeBatch(db);
                  const updatedDocuments = superGroupedDocuments.map(
                    (superGroup) => {
                      if (superGroup.super_class === superClass) {
                        const groupToDelete = superGroup.groups.find(
                          (group) => group.type === groupType
                        );
                        if (groupToDelete) {
                          groupToDelete.subrows.forEach((document) => {
                            if (document.docRef) {
                              const docRef = doc(db, document.docRef);
                              batch.update(docRef, { isDeleted: true });
                            }
                          });
                        }
                        return {
                          ...superGroup,
                          groups: superGroup.groups.filter(
                            (group) => group.type !== groupType
                          )
                        };
                      }
                      return superGroup;
                    }
                  );

                  await batch.commit();
                  updateCurrentOrder(updatedDocuments);
                  setIsCategoryDeleting(false);
                  onCategoryDeleteClose();
                }
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* document preview modal */}
      <DocumentPreviewModal
        isOpen={isPreviewOpen}
        onClose={onPreviewClose}
        document={selectedDocument}
      />
    </div>
  );
};
