import { EditIcon } from "@chakra-ui/icons";
import { Flex, Heading, IconButton } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ClientsReducer } from "../../redux/clients/clientsSlice";
import { clientsSelectors } from "../../redux/clients/selectors";

export const IndividualEditBox = () => {
  const { id, caseID } = useParams();
  const navigate = useNavigate();

  const activeCase = useSelector((state: ClientsReducer) =>
    clientsSelectors.selectClientById(state, id || caseID || "")
  );
  const fullName = `${activeCase?.firstName} ${activeCase?.lastName}`;

  if (!activeCase) {
    return null;
  }

  return (
    <Flex
      flexDirection="column"
      alignContent="flex-start"
      justifyContent="start"
      textAlign="left"
    >
      <Flex alignContent="flex-start" justifyContent="start" mb={4}>
        <div className="">
          <Heading fontSize="24px">
            {_.startCase(_.upperFirst(fullName))}
          </Heading>
          <Heading fontSize="18px">{activeCase?.visa}</Heading>
        </div>
        <IconButton
          variant="outlineIconButton"
          icon={<EditIcon />}
          aria-label="Edit Information"
          onClick={() => {
            navigate(`/cases/${id}/edit-case`);
          }}
        />
      </Flex>
    </Flex>
  );
};
