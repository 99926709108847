// this code is following chakra styling conventions
// make sure to read the documents to understand
// the anatomy approach
// https://chakra-ui.com/docs/styled-system/component-style

import { defineStyleConfig } from "@chakra-ui/react";

export const textAreaTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 500,
    backgroundColor: "white !important",
    borderRadius: 10
  }
});
