import Intercom from "@intercom/messenger-js-sdk";
import dayjs from "dayjs";
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  writeBatch
} from "firebase/firestore";
import { getBlob, ref } from "firebase/storage";
import mammoth from "mammoth";
import * as pdfjsLib from "pdfjs-dist";
import { useSelector } from "react-redux";
import { db, storage } from "../api/firebaseApi";
import { criteriaDocuments } from "../consts/strings";
import { VisaDocumentType } from "../redux/documents/types";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { ExtractedPassport } from "../types/extracted-passport";
import {
  DataDocs,
  DocumentType,
  OverviewStep,
  VISAVALUE
} from "../types/tables-data";
import { SidebarLink } from "../components/Sidebar/types";
import { Feature, visaExclusionMap } from "../types/features";
import { isFeatureAccessible } from "./access_control_helpers";

export interface SuperGroupedDocument {
  super_class: string;
  groups: GroupedDocument[];
}
export interface GroupedDocument {
  type: string;
  index: number;
  subrows: DataDocs[];
  expanded: boolean;
}

export const getStatusValue = (steps: OverviewStep[]) => {
  let status = 1;
  steps.forEach((step) => {
    if (step.isComplete) {
      status += 1;
    }
  });
  return status;
};

const countries = [
  "usa",
  "united states",
  "united states of america",
  "canada",
  "philippines"
];
const countriesWithDashDates = ["tunisia"];
const datesToFormat = ["doB", "dateOfIssue", "expirationDate"];

export const formatPassport = (data: ExtractedPassport) => {
  const formattedPassportData: Record<string, string | number> = {};
  const isNotUsualFormatPassort =
    data.passportCountry &&
    (!countries.includes(data.passportCountry.toLowerCase()) ||
      !countriesWithDashDates.includes(data.passportCountry.toLowerCase()));

  if (isNotUsualFormatPassort) {
    Object.entries(data).forEach(([key, value]) => {
      if (datesToFormat.includes(key)) {
        const formatted = value.split(" ");
        const dateDoc = formatted[0];
        const monthDoc = formatted[1];
        const yearDoc = formatted[2];

        const dateFormatted = dayjs(
          `${yearDoc}-${monthDoc}-${dateDoc}`,
          "YYYY-MMM-DD"
        ).format("YYYY-MM-DD");
        formattedPassportData[key] = Date.parse(dateFormatted);
      } else {
        formattedPassportData[key] = value;
      }
    });
  }
  if (
    data.passportCountry &&
    countriesWithDashDates.includes(data.passportCountry.toLowerCase())
  ) {
    Object.entries(data).forEach(([key, value]) => {
      if (datesToFormat.includes(key)) {
        const formatted = value.split("-");
        const dateDoc = formatted[0];
        const monthDoc = formatted[1];
        const yearDoc = formatted[2];

        const dateFormatted = dayjs(
          `${yearDoc}-${monthDoc}-${dateDoc}`,
          "YYYY-MMM-DD"
        ).format("YYYY-MM-DD");
        formattedPassportData[key] = Date.parse(dateFormatted);
      } else {
        formattedPassportData[key] = value;
      }
    });
  }

  return formattedPassportData;
};

export const getAuthToken = () => {
  try {
    const token = localStorage.getItem("token");
    return token ? `Bearer ${token}` : null;
  } catch (error) {
    console.error("Error retrieving token:", error);
    return null;
  }
};

export const documentsTypes = {
  i94: "i94",
  resume: "resume",
  passport: "passport",
  visa: "visa"
};

// Exhibits
export const getNextExhibitValue = async (
  db: any, // Assuming db is already initialized and passed here
  collectionPath: CollectionReference<DocumentData>,
  type: "alphabetical" | "numerical"
): Promise<string | number> => {
  console.log(
    `[exhibit testing] Starting to get the next exhibit value. Type: ${type} collection : ${collectionPath}`
  );

  const q = query(collectionPath, orderBy("exhibit", "desc"), limit(1));
  const querySnapshot = await getDocs(q);

  let lastExhibitValue: string | number = type === "alphabetical" ? "A" : 1;

  if (!querySnapshot.empty) {
    const lastDoc = querySnapshot.docs[0];
    const lastExhibitData = lastDoc.data().exhibit;
    console.log(
      `[exhibit testing] Last exhibit data retrieved: ${lastExhibitData}`
    );

    if (type === "alphabetical") {
      if (typeof lastExhibitData === "string") {
        const lastCharCode = lastExhibitData.charCodeAt(0);
        console.log(
          `[exhibit testing] Last exhibit character code: ${lastCharCode}`
        );

        if (lastCharCode < 65 || lastCharCode > 90) {
          const error =
            "Exhibit value is not a valid uppercase ASCII character.";
          console.error(`[exhibit testing] ${error}`);
          throw new Error(error);
        }

        if (lastCharCode === 90) {
          const error = 'Reached the last exhibit label "Z".';
          console.error(`[exhibit testing] ${error}`);
          throw new Error(error);
        }

        lastExhibitValue = String.fromCharCode(lastCharCode + 1);
        console.log(
          `[exhibit testing] Next exhibit value: ${lastExhibitValue}`
        );
        return lastExhibitValue;
      }
      const error = "Exhibit value is not a string.";
      console.error(`[exhibit testing] ${error}`);
      throw new Error(error);
    } else if (type === "numerical") {
      if (typeof lastExhibitData === "number") {
        lastExhibitValue = lastExhibitData + 1;
        console.log(
          `[exhibit testing] Next exhibit value: ${lastExhibitValue}`
        );
        return lastExhibitValue;
      }
      const error = "Exhibit value is not a number.";
      console.error(`[exhibit testing] ${error}`);
      throw new Error(error);
    }
  }

  console.log(`[exhibit testing] Default exhibit value: ${lastExhibitValue}`);
  return lastExhibitValue;
};

export const incExhibit = (exhibit: string): string => {
  const lastChar = exhibit.slice(-1);
  const prefix = exhibit.slice(0, -1);

  if (lastChar === "Z") {
    return prefix.length === 0 ? "AA" : `${incExhibit(prefix)}A`;
  }

  return prefix + String.fromCharCode(lastChar.charCodeAt(0) + 1);
};

// Documents files helpers

export const openFilePreview = (docUrls?: string) => {
  if (docUrls) {
    window.open(docUrls, "_blank");
  }
};

export type GenerateDocumentsProps = {
  documents: {
    id: string;
    filePath: string;
    super_class: string;
    criterion: string;
  }[];
  mainDocId?: string;
};

export const fetchFiles = async (filePaths: string[]): Promise<string[]> => {
  const fileUrls: string[] = [];
  try {
    const promises = filePaths.map(async (filePath) => {
      console.log("filePath : ", filePath);
      const fileRef = ref(storage, filePath);
      const fileBlob = await getBlob(fileRef);
      const fileUrl = URL.createObjectURL(fileBlob);
      fileUrls.push(fileUrl);
    });
    await Promise.all(promises);
    console.log("fileUrls, ", fileUrls);
    return fileUrls;
  } catch (error) {
    console.error("Error fetching files: ", error);
    throw error;
  }
};

// Utility function to wrap text and render it on a canvas
const wrapText = (
  context: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  maxWidth: number,
  lineHeight: number
) => {
  const words = text.split(" ");
  let line = "";

  for (let i = 0; i < words.length; i += 1) {
    const testLine = `${line + words[i]} `;
    const testWidth = context.measureText(testLine).width;
    if (testWidth > maxWidth && i > 0) {
      context.fillText(line, x, y);
      line = `${words[i]} `;
      y += lineHeight;
    } else {
      line = testLine;
    }
  }
  context.fillText(line, x, y);
};

export const generateDocxThumbnail = async (
  docxUrl: string
): Promise<string> => {
  // Fetch the DOCX file as an ArrayBuffer
  const response = await fetch(docxUrl);
  const arrayBuffer = await response.arrayBuffer();

  // Use mammoth.js to extract text from the DOCX file
  const result = await mammoth.extractRawText({ arrayBuffer });
  const text = result.value; // Extracted text
  const firstPageText = text.split("\n").slice(0, 80).join("\n");

  // Define thumbnail dimensions (similar to A4)
  const canvasWidth = 595;
  const canvasHeight = 841;

  // Create canvas element
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Unable to create canvas context.");
  }
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  // Style for rendering text on the canvas
  context.fillStyle = "#ffffff"; // Background color (white)
  context.fillRect(0, 0, canvasWidth, canvasHeight); // Fill background
  context.fillStyle = "#000000"; // Text color (black)
  context.font = "16px Arial"; // Font settings
  context.textAlign = "left";
  context.textBaseline = "top";

  // Render the first page's text content to the canvas
  const padding = 20;
  const lineHeight = 20;
  const maxTextWidth = canvasWidth - padding * 2;
  wrapText(context, firstPageText, padding, padding, maxTextWidth, lineHeight);

  // Convert canvas to PNG Blob and return the thumbnail URL
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const thumbnail = URL.createObjectURL(blob);
        resolve(thumbnail);
      } else {
        reject(new Error("Could not create blob from canvas"));
      }
    }, "image/png");
  });
};

export const generatePdfThumbnail = async (pdfUrl: string): Promise<string> => {
  const loadingTask = pdfjsLib.getDocument(pdfUrl);
  const pdf = await loadingTask.promise;

  const page = await pdf.getPage(1);

  const a4WidthPoints = 595;
  const a4HeightPoints = 841;

  const viewport = page.getViewport({ scale: 1.0 });
  const scale = Math.min(
    a4WidthPoints / viewport.width,
    a4HeightPoints / viewport.height
  );

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Unable to create canvas context.");
  }
  canvas.width = a4WidthPoints;
  canvas.height = a4HeightPoints;

  const renderContext = {
    canvasContext: context,
    viewport: page.getViewport({ scale })
  };
  await page.render(renderContext).promise;

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const thumbnail = URL.createObjectURL(blob);
        resolve(thumbnail);
      } else {
        reject(new Error("Could not create blob from canvas"));
      }
    }, "image/png");
  });
};

export const openInNewTab = (fileUrl: string, fileName: string) => {
  window.open(fileUrl, fileName);
};

// status, labels

export const documentStatusLabel = (uploadBy: string) => {
  if (uploadBy == null || uploadBy === "") return "Not uploaded";
  return `Uploaded by ${uploadBy}`;
};

// get user type
export const getUserType = (): string => {
  const loginType = localStorage.getItem("loginType") ?? "";
  return loginType;
};

// mapping
export const getKeyByValue = (object: any, value: any) =>
  Object.keys(object).find((key) => object[key] === value);

export const getTypesForCriterion = (
  criterionCategory?: string | null,
  visaType?: string | null
) => {
  if (!criterionCategory || !visaType) {
    return null;
  }

  const criterion = criteriaDocuments[visaType!]?.find(
    (c) => c?.category === criterionCategory
  );
  const criterionCategories = criterion?.documentTypes?.map(
    (doc) => doc?.title
  );
  return criterion ? criterionCategories : null;
};

export const addPropertiesSafely = <T extends object>(
  target: any,
  source: any
): T => {
  const newObject = { ...target }; // Create a shallow copy of target
  Object.keys(source).forEach((key) => {
    const value = source[key as keyof T];
    if (value !== undefined && value !== null) {
      newObject[key as keyof T] = value;
    }
  });
  return newObject;
};

export const isSuperAdmin = (): boolean => {
  const role = useSelector(lawyerSelectors.selectRole);

  return role === "superadmin";
};

export const isFullUser = (): boolean => {
  const role = useSelector(lawyerSelectors.selectRole);

  return role === "full";
};

export function isDocumentType(type: any): type is DocumentType {
  return typeof type === "object" && type !== null && "title" in type;
}

export const hasId = (item: unknown): item is { id: string | number } =>
  typeof item === "object" && item !== null && "id" in item;

export const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};

// Firebase

/**
 * Generates a new document ID from the specified Firestore collection path.
 *
 * @param db - The Firestore database instance.
 * @param collectionPath - The path to the Firestore collection.
 * @return The generated document ID.
 */
export const generateNewDocId = (collectionPath: string): string => {
  const newDocRef = doc(collection(db, collectionPath));
  return newDocRef.id;
};

/**
 * Rearranges the exhibits in a Firestore collection to be sequentially alphabetical without gaps.
 * @param {CollectionReference<DocumentData>} collection - A reference to the Firestore collection to rearrange.
 */
export const rearrangeExhibits = async (
  collection: CollectionReference<DocumentData>
) => {
  const queryRef = query(collection, orderBy("exhibit", "asc"));
  const querySnapshot = await getDocs(queryRef);

  const batch = writeBatch(db);
  let index = 0; // Manual counter for indexing

  querySnapshot.forEach((docSnapshot) => {
    // Calculate the new exhibit label
    let newExhibitLabel = "";
    let n = index; // Use the manually maintained index
    while (n >= 0) {
      newExhibitLabel = String.fromCharCode(65 + (n % 26)) + newExhibitLabel;
      n = Math.floor(n / 26) - 1;
    }

    const docRef = doc(collection, docSnapshot.id);
    batch.update(docRef, { exhibit: newExhibitLabel });
    index += 1;
  });

  await batch.commit();
  console.log("Exhibits have been rearranged alphabetically.");
};

/**
 * Retrieves the exhibit for a specific evidence document.
 * @param {Array} _superGroupedDocuments - The array with the structure described.
 * @param {string} documentId - The ID of the evidence document to find.
 * @returns {string|null} The exhibit in the format "groupIndex.evidenceIndex" or null if not found.
 */
export const getExhibit = (_superGroupedDocuments: any[], documentId: any) => {
  if (!Array.isArray(_superGroupedDocuments)) {
    console.error("Invalid _superGroupedDocuments format.");
    return null;
  }

  for (
    let groupIndex = 0;
    groupIndex < _superGroupedDocuments.length;
    groupIndex += 1
  ) {
    const superGrouped = _superGroupedDocuments[groupIndex];

    // Ensure super_class is "Evidence" and groups is an array
    if (
      superGrouped.super_class === "Evidence" &&
      Array.isArray(superGrouped.groups)
    ) {
      for (
        let subgroupIndex = 0;
        subgroupIndex < superGrouped.groups.length;
        subgroupIndex += 1
      ) {
        const group = superGrouped.groups[subgroupIndex];

        // Check document IDs in the group's subrows
        for (
          let docIndex = 0;
          docIndex < group.documentIds.length;
          docIndex += 1
        ) {
          const doc = group.documentIds[docIndex];
          if (doc.id === documentId) {
            return `${subgroupIndex + 1}.${docIndex + 1}`;
          }
        }
      }
    }
  }

  // Return null if not found
  return null;
};

export const deleteFirestoreDoc = async (
  firestorePath: DocumentReference<DocumentData>
) => {
  const docSnapshot = await getDoc(firestorePath);
  if (docSnapshot.exists()) {
    await deleteDoc(firestorePath);
    return true; // Document was deleted successfully
  }
  return false; // Document didn't exist
};

export const sleep = (duration: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, duration);
  });

// export const toDate

export const moveDocumentToFirst = (docs: DataDocs[], title: string) => {
  const resumeDocIndex = docs.findIndex((doc) => doc?.documentTitle === title);
  if (resumeDocIndex !== -1) {
    const resumeDocument = docs[resumeDocIndex];
    docs.splice(resumeDocIndex, 1);
    docs.unshift(resumeDocument);
  }
};

// move a document to another collection
export const copyDocumentToNewCollection = async (
  sourcePath: string,
  targetCollectionPath: string
): Promise<{ originalDocRef: DocumentReference<DocumentData> | null }> => {
  const sourceDocRef = doc(db, sourcePath);
  const sourceDocSnapshot = await getDoc(sourceDocRef);

  if (!sourceDocSnapshot.exists()) {
    console.log("Source document does not exist.");
    return { originalDocRef: null };
  }

  const sourceData = sourceDocSnapshot.data();

  // Generate a new document ID in the target collection
  const newDocRef = doc(db, targetCollectionPath, sourceDocRef.id);

  await setDoc(newDocRef, sourceData);

  return { originalDocRef: sourceDocRef };
};

export const showCTAButton = (indivId: string | null): boolean => {
  return indivId === "" || indivId === null;
};

export const isValidPhoneNumber = (value: string): boolean => {
  return value !== "+1" && value !== "";
};

export const groupDocumentsWithSubrows = (
  documents: DataDocs[]
): GroupedDocument[] => {
  // Object to hold the grouped documents
  const grouped: { [key: string]: GroupedDocument } = {};

  // Iterate through each document
  documents
    .filter((doc) => doc.isDeleted === false)
    .forEach((doc: DataDocs) => {
      // Check if the type already exists in the grouped object
      if (!grouped[doc?.criterion || doc?.type || ""]) {
        grouped[doc?.criterion || doc.type || ""] = {
          type: doc.criterion || doc.type || "",
          index: 0,
          subrows: [],
          expanded: false
        };
      }

      // Add the document to the subrows array for this type
      grouped[doc.criterion || doc.type || ""].subrows.push({
        ...doc
      });
    });

  // Convert the grouped object to an array of objects
  return Object.values(grouped);
};

export const setupIntercom = (email: string, uid: string): void => {
  if (process.env.REACT_APP_INTERCOM_APP_ID !== undefined) {
    console.log("support enabled - starting intercom");
    setTimeout(() => {
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID!,
        user_id: uid,
        email
      });
      if (window !== undefined && window.Intercom !== undefined) {
        console.log("updating intercom...");
        window.Intercom("update");
      } else {
        console.log("not updating intercom");
      }
    }, 3000);
  } else {
    console.log("support disabled - skipping intercom");
  }
};

export const getDocumentExhibitById = (
  exhibitMap: Record<string, string> | null,
  documentId: string
) => {
  if (!exhibitMap) return "";
  const exhibitMapAsArray = Object.entries(exhibitMap);

  for (let i = 0; i < exhibitMapAsArray.length; i += 1) {
    const [key, value] = exhibitMapAsArray[i];

    if (value === documentId) {
      return key;
    }
  }
  return "";
};

export const getSubExhibitNumberArray = (prefix: string, length: number) => {
  const alphabetAsciiStart = 65;
  const arrayFromLength = Array.from(
    { length: Number(length) },
    (_, indx) => `${prefix}.${String.fromCharCode(alphabetAsciiStart + indx)}`
  );
  return arrayFromLength;
};

export const mapVisaTypeToPath = (visaType: string) => {
  if (visaType === "O-1-A") return "O-1A";
  if (visaType === "EB-2-NIW") return "EB-2 NIW";
  if (visaType === "O-1-B") return "O-1B";

  return visaType;
};
// // TODO : refactor with original map and handle migration where necessary
export const visaFromPath: { [key: string]: string } = {
  "EB-1A": VISAVALUE.EB1A,
  "EB-1B": VISAVALUE.EB1B,
  "EB-2-NIW": VISAVALUE.EB2NIW,
  "O-1-A": "O-1A",
  "O-1-B": "O-1B"
};

export const getDocumentTypesByTypeVisaCategory = (
  map: any,
  docType: VisaDocumentType,
  visaType: string | null | undefined = null,
  category: string | null | undefined = null
) => {
  if (map) {
    // signed-expert-letters needs to be signed_expert_letters
    const docTypeStr = docType.replace(/-/g, "_");

    if (docTypeStr in map) {
      let retVal = map[docTypeStr];

      // != treats null and undefined as the same - what is WRONG with you Javascript?
      if (visaType != null) {
        retVal = retVal[visaType];
      }

      // != treats null and undefined as the same - what is WRONG with you Javascript?
      if (category != null) {
        if (category in retVal) {
          retVal = retVal[category];
        } else {
          retVal = [""];
        }
      }
      return retVal;
    }
    return [""];
  }
  return [""];
};

export const getStoragePathByVisaType = (
  visaType: VisaDocumentType,
  uid: string
) => {
  switch (visaType) {
    case VisaDocumentType.Standard:
      return `individuals/documents/${uid}/`;
    case VisaDocumentType.Evidence:
      return `individuals/documents/${uid}/Evidences`;
    case VisaDocumentType.SignedExpertLetters:
      return `individuals/documents/${uid}/signed_expert_letters`;
    default:
      return `individuals/documents/${uid}/`;
  }
};

export const getSubdomain = () => {
  const { hostname } = window.location;
  const subdomain = hostname.split(".")[0];
  return subdomain;
};

export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(1);
    }, ms);
  });

export const getLinksForUserType = (
  userType: string,
  role?: string,
  visaType?: string,
  uid?: string,
  accessibleFeatures?: string[]
): SidebarLink[] => {
  switch (userType) {
    case "Lawyer": {
      // Superadmin check
      if (role === "superadmin") {
        return [
          { label: "Cases", url: "/cases", icon: "CasesIcon" },
          {
            label: "CaseBlink Studio",
            url: "/studio/templates",
            icon: "StudioIcon",
            subLinks: [
              {
                label: "Templates",
                url: "/studio/templates",
                icon: "TemplatesIcon"
              },
              {
                label: "Prompts",
                url: "/studio/prompts",
                icon: "PromptIcon"
              }
            ]
          },
          {
            label: "Superadmin Tools",
            url: "/admin/superadmin-tools",
            icon: "ToolsIcon",
            subLinks: [
              { label: "Users", url: "/admin/users", icon: "UsersIcon" },
              { label: "Updates", url: "/admin/updates", icon: "UpdatesIcon" },
              { label: "Law Firm Settings", url: "/individual/lawfirm" },
              { label: "Visa Settings", url: "/admin/visa-settings" },
              { label: "Prompts", url: "/admin/prompts" }
            ]
          }
        ];
      }

      // Lawyers
      const templatesFeatureId = "legal_brief_templates";
      const canAccessTemplates = isFeatureAccessible(
        templatesFeatureId,
        accessibleFeatures ?? []
      );

      const caseBlinkSubLinks: SidebarLink[] = [
        { label: "Prompts", url: "/studio/prompts", icon: "PromptIcon" }
      ];

      if (canAccessTemplates) {
        caseBlinkSubLinks.unshift({
          label: "Templates",
          url: "/studio/templates",
          icon: "TemplatesIcon"
        });
      }

      return [
        { label: "Cases", url: "/cases", icon: "CasesIcon" },
        {
          label: "CaseBlink Studio",
          url: "/studio/templates",
          icon: "StudioIcon",
          subLinks: caseBlinkSubLinks
        }
      ];
    }

    case "Individual":
      return [
        {
          label: `${visaType} Case`,
          url: `/individual/${uid}/${visaType}/documents/standard-documents`,
          icon: "CasesIcon",
          defaultActive: true
        }
      ];

    default:
      return [];
  }
};

export const inDev = () => {
  if (process.env.NODE_ENV === "development") {
    return true;
  }
  return false;
};

// Determine if we show/hide a feature based on visaType
export const showFeatureForVisa = (
  visa: VISAVALUE,
  feature: Feature
): boolean => {
  const excludedFeatures = visaExclusionMap[visa] || [];
  return !excludedFeatures.includes(feature);
};

export const filterActiveDocuments = (
  groupedDocuments: SuperGroupedDocument[]
) =>
  groupedDocuments.flatMap((sg) =>
    sg.groups.flatMap((group) => group.subrows.filter((row) => !row.isDeleted))
  );
