import { memo, forwardRef, useState, useMemo, useEffect } from "react";
import { Box, Input, useColorModeValue } from "@chakra-ui/react";
import debounce from "lodash/debounce";
/* eslint-disable react/no-unused-prop-types */

interface DebouncedSearchInputProps {
  initialValue: string;
  onDebouncedChange: (value: string) => void;
  onMouseDown?: React.MouseEventHandler<HTMLInputElement>;
}

const DebouncedSearchInput = memo(
  forwardRef<HTMLInputElement, DebouncedSearchInputProps>(
    ({ initialValue, onDebouncedChange, onMouseDown }, ref) => {
      const [localValue, setLocalValue] = useState(initialValue);

      const debouncedChange = useMemo(
        () => debounce(onDebouncedChange, 300),
        [onDebouncedChange]
      );

      useEffect(() => {
        debouncedChange(localValue);
        return () => {
          debouncedChange.cancel();
        };
      }, [localValue, debouncedChange]);

      return (
        <Box px={2} mb={2}>
          <Input
            id="search-input"
            ref={ref}
            value={localValue}
            onChange={(e) => setLocalValue(e.target.value)}
            onMouseDown={onMouseDown}
            placeholder="Search..."
            bg={useColorModeValue("background.white", "background.darkWhite")}
            _focus={{
              borderColor: useColorModeValue(
                "primary.focusOutline",
                "primary.red"
              )
            }}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </Box>
      );
    }
  ),
  (prevProps, nextProps) => {
    // Only re-render if these props change. Ignore changes in initialValue.
    return (
      prevProps.onDebouncedChange === nextProps.onDebouncedChange &&
      prevProps.onMouseDown === nextProps.onMouseDown
    );
  }
);

DebouncedSearchInput.displayName = "DebouncedSearchInput";
export default DebouncedSearchInput;
