import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from "@chakra-ui/react";
import { DragEvent, useEffect, useRef, useState } from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { HiOutlineSparkles } from "react-icons/hi2";
import { MdAddToDrive } from "react-icons/md";
import { RiComputerLine } from "react-icons/ri";
import { ImOnedrive } from "react-icons/im";
import { useDispatch } from "react-redux";

import images from "../assets/image-export-assets";
import { supportedExtensionsString2 } from "../constans";
import { VisaDocumentType } from "../redux/documents/types";
// eslint-disable-next-line import/no-cycle
import UploadByCategoryModal from "./individualTabs/individualDocuments/UploadByCategoryModal";
import { useGoogleDrive } from "../hooks/useGoogleDrive";
import { useSharePoint } from "../hooks/useSharePoint";
import { setIsLoading } from "../redux/file-upload/fileUploadSlice";

interface FileUploaderProps {
  uploadTitle?: string;
  title?: string;
  subTitle: string;
  tableRef?: "evidence" | "standard" | "signed_expert_letters";
  onUpload: (files: FileList | null) => void;
  withImg?: boolean;
  styleIndex?: number;
  isDisabled?: boolean;
  uploaded?: boolean;
  fileName?: string;
  // DB & Storage
  storagePath?: string | null;
  collectionPath?: string | null;
  // Upload by Category
  allowUploadByCategory?: boolean;
  superClass?: string | null;
  visaDocumentType?: VisaDocumentType | null;
  showFileName?: boolean | null;
}

export const UploadFilesPlaceholder = ({
  uploadTitle,
  title,
  subTitle,
  tableRef,
  onUpload,
  withImg = false,
  styleIndex = 0,
  isDisabled = false,
  uploaded,
  fileName,
  allowUploadByCategory = false,
  superClass,
  collectionPath,
  storagePath,
  visaDocumentType = VisaDocumentType.Evidence,
  showFileName = false
}: FileUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const [showUploadByCatModal, setShowUploadByCatModal] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [isDragActive, setIsDragActive] = useState(false);
  const toast = useToast();

  const { pickFiles: pickGoogleDriveFiles } = useGoogleDrive();
  const { pickFiles: pickSharePointFiles } = useSharePoint();

  const handleClick = () => {
    fileInputRef?.current?.click();
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    const { files } = e.dataTransfer;
    if (files && files.length > 0) {
      setUploadedFileName(files[0].name);
      onUpload(files);
      toast({
        title: "Files uploaded successfully.",
        status: "success",
        duration: 3000,
        isClosable: true
      });
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      setUploadedFileName(files[0].name);
      onUpload(files);
    }
  };

  useEffect(() => {
    // Cleanup function to clear files on unmount
    return () => {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };
  }, []);

  const onUploadByCatClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setShowUploadByCatModal(true);
  };

  const onUploadByCatModalClose = () => {
    setShowUploadByCatModal(false);
  };

  const handleGoogleDriveSelect = async () => {
    try {
      dispatch(setIsLoading(true));

      // Get files from Google Drive as regular File objects
      const files = await pickGoogleDriveFiles();

      if (files.length > 0) {
        // Create a FileList-like object
        const fileList = {
          length: files.length,
          item: (index: number) => files[index],
          *[Symbol.iterator]() {
            yield* files;
          }
        } as FileList;

        // Use the standard onUpload handler
        onUpload(fileList);

        toast.closeAll();
        toast({
          title: "Files successfully retrieved from Google Drive",
          status: "success",
          duration: 3000,
          isClosable: true
        });
      }
    } catch (error) {
      toast.closeAll();
      toast({
        title: "Error retrieving files from Google Drive",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleSharePointSelect = async () => {
    try {
      dispatch(setIsLoading(true));

      // Get files from SharePoint as regular File objects
      const files = await pickSharePointFiles();

      if (files.length > 0) {
        // Create a FileList-like object
        const fileList = {
          length: files.length,
          item: (index: number) => files[index],
          *[Symbol.iterator]() {
            yield* files;
          }
        } as FileList;

        // Use the standard onUpload handler
        onUpload(fileList);

        toast.closeAll();
        toast({
          title: "Files successfully retrieved from SharePoint",
          status: "success",
          duration: 3000,
          isClosable: true
        });
      }
    } catch (error) {
      toast.closeAll();
      toast({
        title: "Error retrieving files from SharePoint",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const styles = [
    {
      container: {
        bg: "primary.backgroundLight",
        border: "2px dashed",
        borderColor: "#ebedf0",
        borderRadius: "8px",
        padding: "20px",
        textAlign: "center" as const
      },
      buttons: {
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        marginTop: "20px"
      }
    },
    {
      container: {
        bg: isDragActive ? "primary.backgroundLight" : "",
        border: "2px dashed",
        borderColor: isDragActive ? "primary.blue" : "#ebedf0",
        borderRadius: "8px",
        padding: "20px",
        textAlign: "center" as const
      },
      text: {
        fontWeight: "bold",
        color: "primary.darkBlue",
        marginBottom: "10px"
      }
    },

    {
      container: {
        bg: "bgColor.200",
        border: "2px dashed",
        borderColor: "#ebedf0",
        borderRadius: "8px",
        padding: "20px",
        display: "flex" as const,
        flexDirection: "column" as const,
        alignItems: "center" as const,
        textAlign: "center" as const
      },
      buttons: {
        display: "flex",
        gap: "10px"
      }
    }
  ];

  const style = styles[styleIndex] || styles[0];

  // Utility function to determine display text
  const displayText = () => {
    // If a file has been selected, show its name.
    if (uploadedFileName && showFileName) return uploadedFileName;
    // Otherwise, fallback to title if provided, else default text.
    return title || "Upload files";
  };

  const renderMenuItems = () => (
    <MenuList zIndex={1000}>
      <MenuItem onClick={handleClick}>
        <Flex alignItems="center" gap={2}>
          <RiComputerLine />
          Upload from computer
        </Flex>
      </MenuItem>
      <MenuItem onClick={handleGoogleDriveSelect}>
        <Flex alignItems="center" gap={2}>
          <MdAddToDrive />
          Choose from Google Drive
        </Flex>
      </MenuItem>
      <MenuItem onClick={handleSharePointSelect}>
        <Flex alignItems="center" gap={2}>
          <ImOnedrive />
          Choose from SharePoint
        </Flex>
      </MenuItem>
    </MenuList>
  );

  return (
    <Flex flexDirection="column" textAlign="start">
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileInputChange}
        style={{ display: "none" }}
        className="hidden"
        disabled={isDisabled}
        multiple
        accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
      />
      <Box
        bg="background.cloud"
        style={style.container}
        cursor="pointer"
        onClick={(e) => {
          // Only trigger handleClick if clicking the box directly, not on buttons or menu
          if (
            !(e.target as HTMLElement).closest("button") &&
            !(e.target as HTMLElement).closest(".chakra-menu")
          ) {
            handleClick();
          }
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        _hover={{ bg: "primary.backgroundLight" }}
        borderColor={isDragActive ? "primary.blue" : "#ebedf0"}
        backgroundColor={isDragActive ? "primary.backgroundLight" : ""}
      >
        {styleIndex === 0 && (
          <Flex flexDirection="column" alignItems="center">
            <Flex justifyContent="start">
              {withImg && (
                <Image src={images.fileupload} height="120px" mb={4} />
              )}
            </Flex>
            <Text mt={2} fontWeight={600} color="primary.blue">
              {title}
            </Text>
            <Text mt={2} fontWeight={600}>
              {subTitle}
            </Text>
            <Box style={style.buttons}>
              <Menu>
                <MenuButton
                  as={Button}
                  leftIcon={<HiOutlineSparkles />}
                  variant="primaryFilled"
                >
                  Upload with AI
                </MenuButton>
                {renderMenuItems()}
              </Menu>
              {allowUploadByCategory && (
                <>
                  <Text mt={2} fontWeight={600}>
                    {subTitle}
                  </Text>
                  <Button
                    variant="secondaryOutline"
                    onClick={onUploadByCatClick}
                  >
                    Upload by Category
                  </Button>
                </>
              )}
            </Box>
            <Text
              mt={8}
              fontSize="sm"
              fontWeight={500}
              color="text.graySecondary.smog"
            >
              Supported formats: {supportedExtensionsString2}
            </Text>
          </Flex>
        )}
        {styleIndex === 1 &&
          (uploaded ? (
            fileName
          ) : (
            <Flex flexDirection="column" alignItems="center">
              <Flex columnGap={2} alignItems="center" textAlign="center">
                <Text style={style.text} mx={2} my={2}>
                  {uploadTitle || "Drag & Drop for AI Sorting"}
                </Text>
                <Text mx={4}>|</Text>
                <Box style={style.buttons}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      leftIcon={<HiOutlineSparkles />}
                      variant="secondaryOutline"
                    >
                      Upload files
                    </MenuButton>
                    {renderMenuItems()}
                  </Menu>
                </Box>
              </Flex>
              <Text fontSize="sm" color="text.graySecondary.smog" mt={2}>
                Supported formats: {supportedExtensionsString2}
              </Text>
            </Flex>
          ))}
        {styleIndex === 2 && (
          <>
            {showFileName ? (
              // Check if showFileName is true
              uploadedFileName ? (
                // Uploaded file UI
                <Flex
                  rowGap={4}
                  flexDirection="column"
                  alignItems="center"
                  textAlign="center"
                >
                  <Text fontWeight="600" color="primary.blue">
                    {uploadTitle || "Drag & Drop for AI Sorting"}
                  </Text>
                  <Box style={style.buttons}>
                    <Menu>
                      <MenuButton
                        as={Button}
                        size="sm"
                        variant="secondaryOutline"
                        leftIcon={<CheckCircleIcon />}
                      >
                        {displayText()}
                      </MenuButton>
                      {renderMenuItems()}
                    </Menu>
                    {allowUploadByCategory && (
                      <>
                        <Text mt={2} fontWeight={600}>
                          {subTitle}
                        </Text>
                        <Button
                          size="sm"
                          variant="secondaryOutline"
                          onClick={onUploadByCatClick}
                        >
                          Upload by Category
                        </Button>
                      </>
                    )}
                  </Box>
                </Flex>
              ) : (
                // Default UI when no file is uploaded, and showFileName is true
                <Flex columnGap={4} alignItems="center" textAlign="center">
                  <Text fontWeight="600" color="primary.blue">
                    {uploadTitle || "Drag & Drop for AI Sorting"}
                  </Text>
                  <Text mx={4}>OR</Text>
                  <Box style={style.buttons}>
                    <Menu>
                      <MenuButton
                        as={Button}
                        size="sm"
                        variant="secondaryOutline"
                      >
                        {displayText()}
                      </MenuButton>
                      {renderMenuItems()}
                    </Menu>
                    {allowUploadByCategory && (
                      <>
                        <Text mt={2} fontWeight={600}>
                          {subTitle}
                        </Text>
                        <Button
                          size="sm"
                          variant="secondaryOutline"
                          onClick={onUploadByCatClick}
                        >
                          Upload by Category
                        </Button>
                      </>
                    )}
                  </Box>
                </Flex>
              )
            ) : (
              // Default UI when showFileName is false
              <Flex columnGap={4} alignItems="center" textAlign="center">
                <Box style={style.buttons}>
                  <Image src={images.documentUploadCloud} padding="0px" />
                  <Text fontWeight="600" color="primary.blue">
                    {uploadTitle || "Drag & Drop for AI Sorting"}
                  </Text>
                </Box>
                <Text mx={4}>|</Text>
                <Box style={style.buttons}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      leftIcon={<HiOutlineSparkles />}
                      variant="secondaryOutline"
                      size="sm"
                    >
                      {displayText()}
                    </MenuButton>
                    {renderMenuItems()}
                  </Menu>
                  {allowUploadByCategory && (
                    <>
                      <Text mt={2} fontWeight={600}>
                        {subTitle}
                      </Text>
                      <Button
                        size="sm"
                        variant="secondaryOutline"
                        onClick={onUploadByCatClick}
                      >
                        Upload by Category
                      </Button>
                    </>
                  )}
                </Box>
              </Flex>
            )}
            <Text mt={2} fontSize="sm" color="secondary.darkGray">
              Supported formats: {supportedExtensionsString2}
            </Text>
          </>
        )}
      </Box>
      <UploadByCategoryModal
        isOpen={showUploadByCatModal}
        onClose={onUploadByCatModalClose}
        storagePath={storagePath!}
        collectionPath={collectionPath!}
        visaDocumentType={visaDocumentType!}
      />
    </Flex>
  );
};

export default UploadFilesPlaceholder;
