import { CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  IconButton,
  Image,
  Input,
  MenuList,
  Portal,
  Spinner,
  Text,
  Textarea,
  VStack
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import images from "../../../assets/image-export-assets";
import useFirestoreCollection from "../../../hooks/useFirestoreCollection";
import { lawyerSelectors } from "../../../redux/lawyer/selectors";
import { promptsSelectors } from "../../../redux/prompts/selectors";
import { Prompt } from "../../../types/studio/prompts";
import { DATABASE, VISAVALUE } from "../../../types/tables-data";
import { DropdownGeneric } from "../../common/DropdownGeneric";
import { useEditor } from "../../../context/EditorContext";
import {
  setSelectedPromptId,
  setSelectedPrompt as rdSetSelectedPrompt
} from "../../../redux/prompts/promptsSlice";

interface AIPromptMenuProps {
  promptText: string;
  setPromptText: (text: string) => void;
  promptError: string | null;
  setPromptError: (error: string | null) => void;

  setPromptisSelected: (selected: boolean) => void;
  selectedText: string;
  fullText: string;
  setShowToolbar: (value: boolean) => void;
  replaceText: (generatedText: string) => void;
}

const AIPromptMenu: React.FC<AIPromptMenuProps> = ({
  promptText,
  setPromptText,
  promptError,
  setPromptError,
  setPromptisSelected,
  selectedText,
  fullText,
  setShowToolbar,
  replaceText
}) => {
  const { handleAIButtonClick } = useEditor();
  const dispatch = useDispatch();
  const { saveDocument } = useFirestoreCollection(DATABASE.PROMPTS, {
    listen: true,
    useLawyerAccessMap: true
  });

  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedText, setGeneratedText] = useState("");
  const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null);

  // State for saving prompt
  const [isSavingPrompt, setIsSavingPrompt] = useState(false);
  const [isEditingPromptName, setIsEditingPromptName] = useState(false);
  const [promptName, setPromptName] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [hasSavedPrompt, setHasSavedPrompt] = useState(false);

  const dfPrompts = useSelector(promptsSelectors.selectDefaultPrompts);
  const customPrompts = useSelector(promptsSelectors.selectCustomPrompts);
  const hiddenPrompts = useSelector(lawyerSelectors.selectHiddenPrompts);

  // Combine default and custom prompts
  const defaultPrompts = dfPrompts.filter(
    (prompt) => !hiddenPrompts?.includes(prompt.id)
  );

  const reset = () => {
    // Reset all states back to initial UI
    setPromptText("");
    setSelectedPrompt(null);
    setPromptName("");
    setGeneratedText("");
    setIsEditingPromptName(false);
    setIsSaved(false);
    setIsGenerating(false);
    setPromptisSelected(false);
  };

  /** Handle prompt selection change */
  const handlePromptChange = (item: Prompt) => {
    if (!item) return;
    setSelectedPrompt(item);
  };

  /** Generate AI output based on user input */
  const handleGenerate = async () => {
    handleAIButtonClick(undefined, undefined, selectedPrompt?.promptText);
    if (selectedPrompt) dispatch(rdSetSelectedPrompt(selectedPrompt));

    reset();
  };

  /** Reset state on component unmount */
  useEffect(() => {
    return () => {
      setPromptText("");
      setGeneratedText("");
      setIsGenerating(false);
      setSelectedPrompt(null);
      setPromptError(null);
      setPromptisSelected(false);
    };
  }, []);

  /** Auto-generate output when a saved prompt is selected */
  useEffect(() => {
    if (selectedPrompt && selectedPrompt.id !== "") {
      handleGenerate();
    }
  }, [selectedPrompt]);

  /** Save custom prompt */
  const handleSavePrompt = async () => {
    if (!promptName.trim() || !promptText.trim()) return;

    setIsSavingPrompt(true);
    setIsSaved(false); // Reset saved state

    const newPrompt: Partial<Prompt> = {
      promptName, // User-defined prompt name
      promptText // Generated or custom text
    };

    await saveDocument(newPrompt);
    setPromptName(newPrompt.promptName || "");

    setIsSavingPrompt(false);
    setIsSaved(true); // Mark as successfully saved
    setHasSavedPrompt(true);

    setTimeout(() => setIsSaved(false), 2000); // Auto-hide success indicator after 2 sec
    setIsEditingPromptName(false);
  };

  useEffect(() => {
    if (isSaved) {
      setIsEditingPromptName(false);
    }
  }, [isSaved]);

  const handleOnMouseDown = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setPromptisSelected(true);
  };

  return (
    <MenuList
      id="ai-prompt-menu"
      onMouseDown={handleOnMouseDown}
      maxHeight="unset"
      w="28vw"
      zIndex="9999"
      p={0}
      m={0}
      borderWidth={0}
      bg="background.white"
      display="flex"
      flexDirection="column"
      borderRadius="lg"
      boxShadow="lg"
    >
      <Box p={4} display="flex" flexDirection="column" gap={4} flex="1">
        {/* Saved Prompts Dropdown */}
        {customPrompts.length === 0 && (
          <Box display="flex" flexDirection="column" gap={2}>
            <Text fontWeight={600}>No prompts saved</Text>
            <Text>
              Start by adding and saving a custom prompt. Your prompts will
              appear here for future use.
            </Text>
          </Box>
        )}
        {!generatedText && (
          <Box display="flex" flexDirection="column" gap={2} width="100%">
            {customPrompts.length > 0 && (
              <VStack alignItems="start" width="100%">
                <Text fontWeight={600}>My prompts</Text>
                <DropdownGeneric
                  useFullWidth
                  withArrow
                  singleSelect
                  label="Select"
                  mergingField="id"
                  getSelectedItem={(item: Prompt) => {
                    handlePromptChange(item);
                  }}
                  searchField="promptName"
                  list={customPrompts}
                  onMouseDown={handleOnMouseDown}
                />
              </VStack>
            )}

            {defaultPrompts.length > 0 && (
              <VStack alignItems="start" width="100%">
                <Text fontWeight={600}>CaseBlink prompts</Text>
                <DropdownGeneric
                  useFullWidth
                  withArrow
                  singleSelect
                  label="Select"
                  mergingField="id"
                  getSelectedItem={(item: Prompt) => {
                    handlePromptChange(item);
                  }}
                  searchField="promptName"
                  list={defaultPrompts}
                  onMouseDown={handleOnMouseDown}
                />
              </VStack> // Corrected: Closing tag added here
            )}
          </Box>
        )}

        {/* Prompt Header (Name & Actions) */}
        {(selectedPrompt || promptText) && generatedText && (
          <>
            <Box display="flex" flexDirection="column" w="100%">
              {isEditingPromptName && !isSavingPrompt ? (
                <Box display="flex" flexDirection="row" gap={2} w="100%">
                  <VStack w="100%">
                    <Input
                      onMouseDown={handleOnMouseDown}
                      onFocus={() => setPromptisSelected(true)}
                      onBlur={() => setPromptisSelected(false)}
                      borderRadius="md"
                      value={promptName}
                      onChange={(e) => setPromptName(e.target.value)}
                      placeholder="Enter prompt name"
                    />
                    <Flex justifyContent="end" width="100%">
                      <Button
                        variant="tertiaryText"
                        size="sm"
                        onClick={() => setIsEditingPromptName(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        variant="secondaryOutline"
                        onClick={handleSavePrompt}
                        isLoading={isSavingPrompt}
                        isDisabled={promptName.trim().length === 0}
                      >
                        Save
                      </Button>
                    </Flex>
                  </VStack>
                </Box>
              ) : (
                <VStack width="100%">
                  <VStack width="100%">
                    {!selectedPrompt && (
                      <HStack gap={1} width="100%" justifyContent="flex-end">
                        <IconButton
                          size="md"
                          aria-label="Save prompt"
                          icon={
                            isSavingPrompt ? (
                              <Spinner size="xs" color="blue.500" />
                            ) : isSaved ? (
                              <CheckIcon color="green.500" />
                            ) : hasSavedPrompt ? (
                              <FaStar title="Saved" />
                            ) : (
                              <FaRegStar title="Save" />
                            )
                          }
                          variant="outlineIconButton"
                          color="text.graySecondary.smog"
                          onClick={() => setIsEditingPromptName(true)}
                        />
                        <IconButton
                          title="Discard prompt"
                          size="sm"
                          aria-label="Discard prompt"
                          icon={<DeleteIcon />}
                          color="text.graySecondary.smog"
                          variant="outlineIconButton"
                          onClick={reset}
                        />
                      </HStack>
                    )}
                  </VStack>
                </VStack>
              )}
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              alignItems="center"
              bg="gray.100"
              borderRadius="md"
              p={2}
              justifyContent="space-between"
            >
              <HStack>
                <Image src={images.magicStickIcon} h="40px" />
                <Text fontWeight={500} ml={2}>
                  {selectedPrompt?.promptName || promptName || promptText}
                </Text>
              </HStack>
            </Box>
          </>
        )}

        {/* Textarea Input */}
        <FormControl isInvalid={!!promptError}>
          <Textarea
            id="prompt-textarea"
            value={selectedPrompt?.promptText}
            onFocus={() => setPromptisSelected(true)}
            onBlur={() => setPromptisSelected(false)}
            onChange={(e) => {
              setSelectedPrompt({
                created_at: 0,
                id: "",
                promptName: "",
                promptText: e.target.value
              });
              setPromptError(
                e.target.value.trim() ? null : "Prompt can't be empty"
              );
            }}
            placeholder="Ask AI to edit or generate..."
            borderColor="stroke.stroke1Light"
            _hover={{ borderColor: "primary.blue" }}
            minHeight="10vh"
            maxHeight="20vh"
            overflowY="auto"
            resize="vertical"
          />
        </FormControl>

        {/* Action Buttons */}
        <Box mt="auto" display="flex" justifyContent="end" gap={4}>
          {!generatedText ? (
            <Button
              isDisabled={isGenerating || !selectedPrompt?.promptText}
              variant="primaryOutline"
              px={4}
              onClick={handleGenerate}
              leftIcon={
                isGenerating ? (
                  <Box display="flex" justifyContent="center" flex="1">
                    <Spinner size="sm" color="primary.blue" />
                  </Box>
                ) : (
                  <Image src={images.aiGenerate} />
                )
              }
            >
              Generate
            </Button>
          ) : (
            <>
              <Button
                size="sm"
                variant="secondaryOutline"
                onClick={() => {
                  setPromptText("");
                  setGeneratedText("");
                  setSelectedPrompt(null);
                  setPromptisSelected(false);
                  setShowToolbar(false);
                }}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                variant="primaryFilled"
                onClick={() => replaceText(generatedText)}
              >
                Apply changes
              </Button>
            </>
          )}
        </Box>
      </Box>
    </MenuList>
  );
};

export default AIPromptMenu;
