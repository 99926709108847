import { Box, BoxProps, FlexProps } from "@chakra-ui/react";
import React from "react";

interface OutlinedBoxProps extends FlexProps {
  children: React.ReactNode;
}

const OutlinedBox: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Box
      border="1px solid"
      borderColor="primary.blue"
      borderRadius="md"
      px={2}
      py={2}
      {...props}
    >
      {children}
    </Box>
  );
};

export default OutlinedBox;
