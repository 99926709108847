import { DeleteIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { doc, updateDoc } from "firebase/firestore";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { Editor } from "../components/common/Editor";
import DocumentInformation from "../components/individualTabs/individualDocuments/DocumentInformation";
import EditMainDocModal from "../components/individualTabs/individualDocuments/EditMainDocModal";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import {
  deleteDocument,
  updateDocument
} from "../redux/documents/documentSlice";
import { DataDocs } from "../types/tables-data";
import DocumentPreviewCard from "./DocumentPreviewCard";

type Props = {
  exhibit: string;
  document: DataDocs;
};

export const DocumentDetailsSection: React.FC<Props> = ({
  exhibit,
  document
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  const [documentToEdit, setDocumentToEdit] = useState<DataDocs | undefined>();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose
  } = useDisclosure();

  const [isDeleting, setIsDeleting] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState<DataDocs | null>(
    null
  );
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose
  } = useDisclosure();

  const handleDeleteClick = (doc: DataDocs) => {
    setDocumentToDelete(doc);
    onDeleteOpen();
  };

  const panelRefs = useRef<
    Record<string, HTMLButtonElement | HTMLDivElement | null>
  >({});

  useEffect(() => {
    if (documentToEdit) {
      onEditOpen();
    }
  }, [documentToEdit, onEditOpen]);

  const handleAccordionToggle = (panelKey: string, isOpen: boolean) => {
    const editorElement = window.document.querySelector(".ql-editor");
    if (editorElement) {
      /* eslint no-underscore-dangle: 0 */
      const quillInstance = (editorElement.parentNode as any)?.__quill;
      if (quillInstance) {
        // hacky way to blur the quill editor
        // will be implemented in a better way along with the refactor in CB-2010
        quillInstance.focus();
        quillInstance.blur();
      }
    }

    if (!isOpen) {
      if (panelRefs.current[panelKey]) {
        _.debounce(() => {
          panelRefs.current[panelKey]?.scrollIntoView({
            behavior: "smooth",
            block: "start"
          });
        }, 200)();
      }
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const newQueryParameters: URLSearchParams = new URLSearchParams();

  useEffect(() => {
    // implement the params logic here
    if (
      document &&
      !searchParams.has("category") &&
      !searchParams.has("docId")
    ) {
      newQueryParameters.set("category", document.criterion!);
      newQueryParameters.set("docId", document.id!);
      setSearchParams(newQueryParameters);
    }
  }, []);

  return (
    <Box className="h-full !overflow-visible ">
      <Flex alignItems="center" mb={4} gap={4}>
        <SpecialHeading
          title="AI-Generated Summary"
          fontSize="20px"
          fontWeight={600}
        />
        <Spacer />

        <Button
          leftIcon={<DeleteIcon />}
          variant="outlineIconButton"
          size="sm"
          onClick={() => handleDeleteClick(document)}
        >
          Delete Document
        </Button>
        <Button
          variant="secondaryOutline"
          size="sm"
          onClick={() => setDocumentToEdit(document)}
        >
          Edit Document Details
        </Button>
      </Flex>

      {document ? (
        <Box
          key={document.uid}
          className=" rounded-md my-2 h-full !overflow-visible"
        >
          <Text fontWeight="600" textAlign="left" fontSize="14" mb={2}>
            EXHIBIT {exhibit}
          </Text>

          <Box display="inline-flex" alignItems="center" gap={4} mb={6}>
            <Text
              fontWeight="700"
              background="#ECF4FF"
              textColor="#1259A5"
              px={1}
            >
              {document.criterion}
            </Text>
            <Divider
              orientation="vertical"
              h="1em"
              textColor="#111928"
              color="#111928"
              border="1px solid #111928 "
              borderColor="#111928 !important"
              as="span"
            />
            <Text
              fontWeight="700"
              background="#ECF4FF"
              textColor="#1259A5"
              px={1}
            >
              {document.type}
            </Text>
          </Box>
          <Accordion
            defaultIndex={[0, 1, 2]}
            allowMultiple
            gap={2}
            overflow="visible"
          >
            {/* Document basic information */}

            <AccordionItem overflow="visible">
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    ref={(el) => {
                      panelRefs.current.documentInfo = el;
                    }}
                    onClick={() => {
                      handleAccordionToggle("documentInfo", isExpanded);
                    }}
                  >
                    <AccordionIcon />
                    Title
                  </AccordionButton>
                  <AccordionPanel>
                    <DocumentInformation document={document} />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <Divider my={2} visibility="hidden" />

            {/* Additional information */}
            <AccordionItem overflow="visible">
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    onClick={() => {
                      handleAccordionToggle("additionalInfo", isExpanded);
                    }}
                  >
                    <AccordionIcon />
                    Data extractions
                  </AccordionButton>
                  <AccordionPanel
                    ref={(el) => {
                      panelRefs.current.additionalInfo = el;
                    }}
                  >
                    <DocumentPreviewCard document={document} />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <Divider my={2} visibility="hidden" />

            {/* AI generated Summary */}
            <AccordionItem overflow="visible" style={{ overflow: "visible" }}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    overflow="visible"
                    onClick={() => {
                      handleAccordionToggle("summary", isExpanded);
                    }}
                  >
                    <AccordionIcon />
                    Summary
                  </AccordionButton>
                  <AccordionPanel
                    style={{ overflow: "visible" }}
                    ref={(el) => {
                      panelRefs.current.summary = el;
                    }}
                  >
                    <Box className="h-full">
                      {document?.summaryHtml && (
                        <Editor
                          customHeight="auto"
                          saveText={async (html, text) => {
                            if (!document.docRef) return;

                            setIsSaving(true);
                            const docRef = doc(db, document?.docRef || "");
                            await updateDoc(docRef, {
                              summary: text,
                              summaryHtml: html
                            });
                            dispatch(
                              updateDocument({
                                ...document,
                                summary: text,
                                summaryHtml: html
                              })
                            );

                            setIsSaving(false);
                          }}
                          text={document?.summaryHtml}
                        />
                      )}
                    </Box>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>

          <Modal
            closeOnOverlayClick={false}
            isOpen={isDeleteOpen}
            onClose={onDeleteClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirmation</ModalHeader>
              <ModalCloseButton onClick={onDeleteClose} />
              <ModalBody pb={6}>
                Are you sure you want to delete the document?
              </ModalBody>
              <ModalFooter>
                <Button variant="secondaryOutline" onClick={onDeleteClose}>
                  Cancel
                </Button>
                <Button
                  variant="destructiveFilled"
                  ml={3}
                  isLoading={isDeleting}
                  onClick={async () => {
                    if (documentToDelete?.docRef) {
                      setIsDeleting(true);
                      const docRef = doc(db, documentToDelete.docRef);
                      await updateDoc(docRef, { isDeleted: true });
                      dispatch(deleteDocument(documentToDelete));
                      setIsDeleting(false);
                      onDeleteClose();
                    }
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {documentToEdit !== undefined && (
            <EditMainDocModal
              isDeleting={false}
              document={documentToEdit}
              headerText="Edit Document Details"
              isOpen={isEditOpen}
              onClose={onEditClose}
            />
          )}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};
