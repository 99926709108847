import { IconProps } from "@chakra-ui/react";
import {
  CISAIcon,
  GavelIcon,
  GovtIcon,
  JournalIcon,
  PolicyIcon,
  SalaryIcon,
  STEMIcon,
  WebResultsIcon
} from "../components/common/Icons";
import { ExtractionStatus } from "../redux/extraction-jobs/extractionJobsSlice";

export type Source = { source: string; autoTitle: string };

export interface CaseResearchTopic {
  id: string;
  topicTitle: string;
  research_area: string;
  sources: Source[];
  created_by: string;
  created_at: string;
  status?: ExtractionStatus;
  summary: string;
  summaryHtml: string;
  super_class: string;
}

export type ResearchAreaKey =
  | "area_of_expertise"
  | "journal_impact_factors"
  | "high_salary"
  | "policy_manual"
  | "aao_decisions"
  | "stem";

export const researchAreaNames: Record<string, string> = {
  area_of_expertise: "Area of Expertise",
  journal_impact_factors: "Journal Impact Factors",
  high_salary: "High Salary",
  policy_manual: "USCIS Policy Manual",
  aao_decisions: "AAO Decisions",
  stem: "STEM"
};

export type TopicKey =
  | "Government Resources"
  | "Web Results"
  | "STEM"
  | "Critical Infrastructures"
  | "Journal"
  | "High Salary"
  | "USCIS Policy Manual"
  | "Aao Decisions";

interface TopicDefinition {
  key: TopicKey;
  label: string;
  super_class: string;
  Icon: React.FC<IconProps>;
}

export const topicsMetdata: TopicDefinition[] = [
  {
    key: "Critical Infrastructures",
    label: "Critical Infrastructures",
    super_class: "",
    Icon: CISAIcon
  },
  {
    key: "Aao Decisions",
    label: "AAO Decisions",
    super_class: "",
    Icon: GavelIcon
  },
  {
    key: "Government Resources",
    label: "Government Resources",
    super_class: "",
    Icon: GovtIcon
  },
  {
    key: "Journal",
    label: "Journal",
    super_class: "Journal Impact Factors",
    Icon: JournalIcon
  },
  {
    key: "USCIS Policy Manual",
    label: "USCIS Policy Manual",
    super_class: "",
    Icon: PolicyIcon
  },
  {
    key: "High Salary",
    label: "High Salary",
    super_class: "High Salary",
    Icon: SalaryIcon
  },
  {
    key: "STEM",
    label: "STEM",
    super_class: "",
    Icon: STEMIcon
  },
  {
    key: "Web Results",
    label: "Web Results",
    super_class: "",
    Icon: WebResultsIcon
  }
];
