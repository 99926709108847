import { createSelector } from "@reduxjs/toolkit";
import { DataDocs, DataDocsWithIndex } from "../../types/tables-data";
import { DocumentReducer } from "./documentSlice";

const selectAll = (state: DocumentReducer) => state.document;

const documents = (state: DocumentReducer) => selectAll(state).documents;
const groupedDocuments = (state: DocumentReducer) =>
  selectAll(state).groupedDocuments;

const allDocuments = createSelector([groupedDocuments], (groups) => {
  return groups
    .flatMap((group) => group.groups) // Access the nested groups
    .flatMap((group) => group.subrows); // Extract subrows from each group
});

const evidenceDocs = createSelector([groupedDocuments], (groups) => {
  return groups
    .filter((group) => group.super_class === "Evidence") // Filter by super_class
    .flatMap((group) => group.groups) // Access the nested groups
    .flatMap((group) => group.subrows); // Extract subrows from each group
});
const evidenceDocsForNiw = createSelector([groupedDocuments], (groups) => {
  return groups
    .filter((group) =>
      [
        "Prong 1",
        "Prong 2",
        "Prong 3",
        "Advanced Degree & Exceptional Ability"
      ].includes(group.super_class)
    ) // Filter by super_class
    .flatMap((group) => group.groups) // Access the nested groups
    .flatMap((group) => group.subrows); // Extract subrows from each group
});

export const selectDocumentById = createSelector(
  [(state: DocumentReducer) => state.document.groupedDocuments, (_, id) => id],
  (groupedDocuments, id) => {
    for (const superGroup of groupedDocuments) {
      for (const [groupIndex, group] of superGroup.groups.entries()) {
        const index = group.subrows.findIndex((doc) => doc.id === id);

        if (index !== -1) {
          const document: DataDocsWithIndex = {
            ...group.subrows[index],
            index: `${groupIndex + 1}.${index + 1}`
          };
          return document;
        }
      }
    }
    return null;
  }
);

const selectDocumentByType = (
  state: DocumentReducer,
  type: string
): DataDocs | undefined => {
  const document = documents(state)
    .filter((doc) => doc.isDeleted === false)
    .find((element) => {
      if (typeof element.type === "object") {
        return (element?.type as any).title?.toLowerCase().includes(type);
      }

      return element?.type?.toLowerCase().includes(type);
    });
  return document;
};

const documentTypes = (state: DocumentReducer) =>
  selectAll(state).documentTypes;

const extractedData = (state: DocumentReducer) =>
  selectAll(state).extractedData;
const selectedEvidenceDoc = (state: DocumentReducer) =>
  selectAll(state).selectedEvidenceDoc;
const evidenceExhibitMap = (state: DocumentReducer) =>
  selectAll(state).evidenceExhibitMap;
const standardExhibitMap = (state: DocumentReducer) =>
  selectAll(state).standardExhibitMap;
const isLoadingGetEvidence = (state: DocumentReducer) =>
  selectAll(state).isLoadingGetEvidence;
const isLoadingDocuments = (state: DocumentReducer) =>
  selectAll(state).isLoadingDocuments;

export const documentSelectors = {
  selectAll,
  evidenceDocs,
  evidenceDocsForNiw,
  selectedEvidenceDoc,
  extractedData,
  selectDocumentByType,
  selectDocumentById,
  evidenceExhibitMap,
  standardExhibitMap,
  isLoadingGetEvidence,
  isLoadingDocuments,
  documents,
  documentTypes,
  groupedDocuments,
  allDocuments
};
