import { createSelector } from "@reduxjs/toolkit";
import { TemplatesReducer } from "./templatesSlice";
import { visaFromPath, VISAVALUE } from "../../types/tables-data";
import {
  CustomTemplate,
  CustomTemplateType
} from "../../types/studio/templates";

const selectAll = (state: TemplatesReducer) =>
  Array.isArray(state.templates.customTemplates)
    ? state.templates.customTemplates
    : [];
const selectedCustomBriefTemplate = (state: TemplatesReducer) => {
  const legalBriefs = Array.isArray(state.templates.customTemplates)
    ? state.templates.customTemplates
    : [];
  return legalBriefs[state.templates.selectedCustomBriefIndex ?? 0];
};
const selectedCustomBriefTemplateIndex = (state: TemplatesReducer) =>
  state.templates.selectedCustomBriefIndex;

const selectSelectedTemplate = (state: TemplatesReducer) =>
  state.templates.selectedTemplate;

const selectTemplatesByOptions = createSelector(
  [
    selectAll,
    (_, visaType) => visaType,
    (_, __, templateTypes) => templateTypes
  ],
  (templates, visaType, templateTypes) => {
    return templates.filter(
      (template: CustomTemplate) =>
        (template.visa === visaType ||
          template.visa === visaFromPath[visaType]) &&
        templateTypes.includes(template.type)
    );
  }
);

const selectStrictTemplates = createSelector([selectAll], (customTemplates) =>
  customTemplates.map((template) => ({
    ...template,
    id: template.id ?? "missing-id"
  }))
);

const getTemplateById = createSelector(
  [selectAll, (_, templateId) => templateId],
  (templates, templateId) =>
    templates.find((template) => template.id === templateId) ?? null
);

export const templatesSelectors = {
  getTemplateById,
  selectSelectedTemplate,
  selectStrictTemplates,
  selectTemplatesByOptions,
  selectAll,
  selectedCustomBriefTemplateIndex,
  selectedCustomBriefTemplate
};
