import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { individualSelectors } from "../redux/individual/selectors";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { useGetUserDetails } from "../hooks/useGetUserDetails";

type Props = {
  withInfo?: boolean;
  size?: "sm" | "md" | "lg";
};

export const UserAvatar = ({ withInfo, size }: Props) => {
  const {
    userDetails: { avatarSrc, userFullName, userType }
  } = useGetUserDetails();

  return (
    <Flex gap={4} alignItems="center">
      <Avatar
        name={userFullName}
        src={avatarSrc}
        className="shadow-md max-h-10 max-w-10"
        bgGradient="linear(to-r, #00008B, #FF0000)"
        color="white"
        size={size || "sm"}
      />
      {withInfo && (
        <Box>
          <div className="block text-left">
            <Text fontWeight="600">{userFullName}</Text>
            <Text fontWeight={400}>{userType}</Text>
          </div>
        </Box>
      )}
    </Flex>
  );
};
