import { Box, CircularProgress, IconButton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMLVersionString } from "../../api/OpenAIApi";
import { URLS } from "../../constans";
import { getLinksForUserType } from "../../helpers/helpers";
import { useGetUserDetails } from "../../hooks/useGetUserDetails";
import { sidebarSelectors } from "../../redux/sidebar/selectors";
import styles from "../../styles/sidebar.module.css";
import { LeftIcon, StudioIcon } from "../common/Icons";
import { DomainFilter } from "../DomainFilter";
import SidebarLinks from "./SiderbarLinks";
import { toggleSidebar as toggleSideBar } from "../../redux/sidebar/sibdarSlice";
import { visaTypeToPath } from "../../types/tables-data";
import { lawyerSelectors } from "../../redux/lawyer/selectors";

interface SidebarProps {
  showSidebar: boolean;
  toggleSidebar?: () => void;
  closeSidebar?: () => void;
  isLoading?: boolean;
}

export const Sidebar = ({
  showSidebar,
  toggleSidebar,
  closeSidebar,
  isLoading
}: SidebarProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [scrolledY, setScrolledY] = useState(0);
  const [mlVersion, setMlVersion] = useState("");
  const { opened } = useSelector(sidebarSelectors.selectAll);
  const dispatch = useDispatch();

  const {
    userDetails: { userType, role, visaType, id }
  } = useGetUserDetails();
  let visaTypeByPath;
  if (visaType) {
    visaTypeByPath = visaTypeToPath[visaType];
  }

  const accessibleFeatures = useSelector(lawyerSelectors.selectBetaFeatures);

  const userLinksByType = getLinksForUserType(
    userType,
    role,
    visaTypeByPath,
    id,
    accessibleFeatures
  );

  useEffect(() => {
    if (role === "superadmin") {
      const fetchMLVersion = async () => {
        try {
          const data = await getMLVersionString();
          setMlVersion(data);
        } catch (error) {
          console.error("Failed to fetch GH_PAT", error);
        }
      };

      fetchMLVersion();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    const handleScroll = () => {
      // 85 is the height of the navbar
      const { scrollY } = window;
      if (scrollY > 85) return;

      const safeMargin = 85 - scrollY;
      setScrolledY(Number(safeMargin.toFixed(0)));
    };
    window.addEventListener("resize", handleResize);

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`${styles.sidebar} ${
        showSidebar ? styles.show : styles.hide
      }  relative border-r-2 max-h-[100vh] `}
      style={{ width: opened ? "250px" : "100px", overflow: "hidden" }}
    >
      <div className="flex flex-1 mt-2">
        <div className="flex flex-col">
          {isLoading ? (
            <CircularProgress isIndeterminate color="blue.300" />
          ) : (
            <Box display="flex" flexDirection="column">
              <IconButton
                variant="ghost"
                aria-label="toggle"
                mb={4}
                display="flex"
                placeSelf={opened ? "end" : "center"}
                icon={
                  <LeftIcon
                    height="24px"
                    width="24px"
                    style={{ rotate: opened ? "unset" : "180deg" }}
                  />
                }
                onClick={() => {
                  dispatch(toggleSideBar());
                }}
              />
              <Box px={2}>
                <SidebarLinks links={userLinksByType} onClick={toggleSidebar} />
              </Box>
            </Box>
          )}

          {opened && (
            <div className={`mt-auto  text-sm text-center  p-2 z-20  `}>
              <p className=" text-xs text-center">
                <a
                  target="_blank"
                  className="underline "
                  href={URLS.tos}
                  rel="noreferrer"
                >
                  Terms of Service
                </a>
                <span className="mx-2">|</span>
                <a
                  target="_blank"
                  className="underline "
                  href={URLS.privacy}
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>

              <p className="text-xs text-center ">
                Copyright © 2025 <DomainFilter>CaseBlink, Inc.</DomainFilter>{" "}
              </p>
              <p className="text-xs text-center ">{mlVersion}</p>
            </div>
          )}
        </div>
        {isMobile && (
          <div
            className="absolute left-0 w-[100vw] h-[100vh] z-[-5] bg-transparent"
            onClick={toggleSidebar}
          >
            <div className="flex-1 flex-grow w-max" />
          </div>
        )}
      </div>
    </div>
  );
};
