import { IconButton } from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import React from "react";

interface ViewToggleButtonProps {
  isHidden: boolean;
  onToggle: (event: React.MouseEvent) => void;
  visibleIcon?: React.ReactElement;
  hiddenIcon?: React.ReactElement;
}

const ViewToggleButton: React.FC<ViewToggleButtonProps> = ({
  isHidden,
  onToggle,
  visibleIcon = <ViewIcon />,
  hiddenIcon = <ViewOffIcon />
}) => {
  return (
    <IconButton
      title={isHidden ? "Show in prompter" : "Hide in prompter"}
      variant="filledIconButton"
      icon={isHidden ? hiddenIcon : visibleIcon}
      onClick={onToggle}
      aria-label="Toggle Visibility"
    />
  );
};

export default ViewToggleButton;
