import React, { PropsWithChildren } from "react";
import {
  useDisclosure,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Text,
  UnorderedList,
  ListItem,
  Button,
  Divider
} from "@chakra-ui/react";
import { MdInfoOutline } from "react-icons/md";

const data = [
  {
    title: "Extractions from standard documents",
    content:
      "Extractions include structured information and summaries from the uploaded documents. You may edit content as needed."
  },
  {
    title: "Resume Summary",
    content:
      "The information from the summary will be used throughout the case preparation process. Please review the content for accuracy and edit as needed."
  },
  {
    title: "Extractions from evidence documents",
    content:
      "Extractions include structured information and summaries from the uploaded documents. You may review and edit the descriptions and change document categories."
  },
  {
    title: "Edit Main Document",
    content:
      "AI generated titles will be used in the draft Exhibit List and Packet tabs. You can change the document category, document type, make edits to the title, or add a description to provide more context."
  },
  {
    title: "Supporting Documents",
    content: (
      <Box>
        <Text mb={4}>
          You can bundle related documents together by adding them as supporting
          documents. Bundling documents will result in one combined summary.
        </Text>
      </Box>
    )
  },
  {
    title: "Case Strategy",
    content: (
      <Text>
        Provide any additional information that could help shape the case
        strategy. CaseBlink will use some of your uploaded documents and attempt
        to pre-fill this questionnaire. Click “Re-Generate with AI” to update
        the answers based on your preferred documents or use the built-in
        prompter directly in the box.
        <br />
        <br />
        <strong>This information will be used to build the case.</strong>
      </Text>
    )
  },

  {
    title: "Case Research",
    content: (
      <Text>
        CaseBlink runs automatic research based on your answers in the Case
        Strategy section and the documents uploaded via Document AI. It uses
        trusted government sources and relevant online data to support the case.
        Review the links and summaries.
        <br />
        <br />
        <strong>Any research added here will be included in the case.</strong>
      </Text>
    )
  },
  {
    title: "Extractions from case research",
    content:
      "Summaries from case research will be used to supplement the case. You may review and edit the content here."
  },
  {
    title: "Expert Letters",
    content: (
      <Text>
        Click "Add Expert" and add experts one by one to draft recommendation or
        testimonial letters. Start by uploading a resume or pre-filled intake,
        then add any helpful context to strengthen the letter. You can save the
        intake to generate the letter later, or generate it immediately.
      </Text>
    )
  },
  {
    title: "Exhibit list",
    content: (
      <Text>
        The exhibit list follows the order in the Document AI tab. Review the
        arrangement and AI-generated titles there before you generate the
        exhibit. If you upload more documents later, remember to also regenerate
        the exhibit list and packet.
        <br />
        <br />
        <strong>
          Once created, this list sets the order for the brief and final packet.
        </strong>
      </Text>
    )
  },
  {
    title: "Legal Brief / Support Letter",
    content: (
      <Text>
        The legal brief and support letters combine all information from
        Document AI, Case Strategy, and Case Research. Make sure to review the
        extracted data, the Case Strategy answers, and the AI-generated research
        before proceeding.
        <br />
        <br />
        <strong>
          Content from these sections will be used to generate your draft.
        </strong>
      </Text>
    )
  },
  {
    title: "Signed expert letters",
    content:
      "Once finalized, the signed expert letters should be uploaded here."
  },
  {
    title: "Final packet",
    content: (
      <Text>
        The final packet includes the legal brief, exhibit list, and applicant
        documents organized with exhibit tabs based on the order and titles from
        Document AI.
        <br />
        <br />
        <strong>
          Need to include documents? Regenerate the exhibit list before
          regenerating the packet.
        </strong>
      </Text>
    )
  },
  {
    title: "Standard documents",
    content: (
      <Box>
        <Text fontWeight={600} mb={4}>
          Standard documents include:
        </Text>
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
          <UnorderedList spacing={2}>
            <ListItem>Resume</ListItem>
            <ListItem>Passport</ListItem>
            <ListItem>Visa</ListItem>
            <ListItem>I-94</ListItem>
            <ListItem>Status documents</ListItem>
            <ListItem>Educational documents</ListItem>
          </UnorderedList>
          <UnorderedList spacing={2}>
            <ListItem>Academic evaluation</ListItem>
          </UnorderedList>
        </Box>
      </Box>
    )
  },
  {
    title: "Evidence documents",
    content: (
      <Box>
        <Text fontWeight={600} mb={4}>
          Evidence documents include:
        </Text>
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
          <UnorderedList spacing={2}>
            <ListItem>Publications</ListItem>
            <ListItem>Awards or prizes</ListItem>
            <ListItem>Articles</ListItem>
            <ListItem>Art exhibits</ListItem>
            <ListItem>Proof of high salary</ListItem>
            <ListItem>Evidence of leading role</ListItem>
            <ListItem>Position job description</ListItem>
          </UnorderedList>
          <UnorderedList spacing={2}>
            <ListItem>Citation records</ListItem>
            <ListItem>Entrepreneur documents</ListItem>
            <ListItem>
              Employment contract, offer letter, itinerary, or agent agreement
            </ListItem>
            <ListItem>Patents, trademarks, certificates</ListItem>
            <ListItem>Information about the area of expertise</ListItem>
          </UnorderedList>
        </Box>
      </Box>
    )
  },
  {
    title: "Case Documents",
    content: (
      <div>
        <Text>
          Upload all case documents (standard documents, evidence, signed expert
          letters, questionnaires, etc.).
          <br />
          Let CaseBlink auto-tag and organize documents, or manually upload by
          category if your files are sorted. Then, drag to reorder them. Review
          the data extractions in "Summaries", which will be use to draft the
          case.
          <br />
          <br />
          <strong>Your arrangement sets the final exhibit list</strong>.
        </Text>
      </div>
    )
  },
  {
    title: "Documents",
    content: (
      <div className="flex flex-col">
        <Box>
          <Text fontWeight={600} mb={4}>
            Evidence documents include:
          </Text>
          <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
            <UnorderedList spacing={2}>
              <ListItem>Publications</ListItem>
              <ListItem>Awards or prizes</ListItem>
              <ListItem>Articles</ListItem>
              <ListItem>Art exhibits</ListItem>
              <ListItem>Proof of high salary</ListItem>
              <ListItem>Evidence of leading role</ListItem>
              <ListItem>Position job description</ListItem>
            </UnorderedList>
            <UnorderedList spacing={2}>
              <ListItem>Citation records</ListItem>
              <ListItem>Entrepreneur documents</ListItem>
              <ListItem>
                Employment contract, offer letter, itinerary, or agent agreement
              </ListItem>
              <ListItem>Patents, trademarks, certificates</ListItem>
              <ListItem>Information about the area of expertise</ListItem>
            </UnorderedList>
          </Box>
        </Box>
        <Divider my={4} />
        <Box>
          <Text fontWeight={600} mb={4}>
            Standard documents include:
          </Text>
          <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
            <UnorderedList spacing={2}>
              <ListItem>Resume</ListItem>
              <ListItem>Passport</ListItem>
              <ListItem>Visa</ListItem>
              <ListItem>I-94</ListItem>
              <ListItem>Status documents</ListItem>
              <ListItem>Educational documents</ListItem>
            </UnorderedList>
            <UnorderedList spacing={2}>
              <ListItem>Academic evaluation</ListItem>
            </UnorderedList>
          </Box>
        </Box>
      </div>
    )
  }
] as const;

type TitleType = (typeof data)[number]["title"];
interface DocumentsInfoModalProps extends PropsWithChildren {
  title: TitleType;
}

export const DocumentsInfoModal = ({
  title,
  children
}: DocumentsInfoModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Find the content based on the provided title
  const item = data.find((d) => d.title === title);

  return (
    <>
      <IconButton
        variant="filledIconButton"
        onClick={onOpen}
        aria-label="View information"
        icon={<MdInfoOutline size={20} />}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textColor="#00004D">{item?.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{item?.content || children}</ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};
