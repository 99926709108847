/* eslint-disable no-case-declarations */
import { doc } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { auth, db, storage } from "../api/firebaseApi";
import { VisaDocumentType } from "../redux/documents/types";
import { DATABASE, VISAVALUE } from "../types/tables-data";

type FirebaseFunction<T> = (...args: any[]) => Promise<T>;

export function shouldAccess(
  individualData: any | null,
  loginType: string | null,
  feature: string,
  visaType?: VISAVALUE
): boolean {
  if (loginType === null || loginType === "lawyer") {
    return true;
  }

  const individualPortalAccessMap = individualData?.individualPortalAccessMap;
  if (
    individualPortalAccessMap &&
    individualPortalAccessMap instanceof Map &&
    individualPortalAccessMap.has(feature)
  ) {
    const shouldAccess = individualPortalAccessMap.get(feature);
    if (shouldAccess === undefined || shouldAccess === null) {
      return true;
    }
    return shouldAccess;
  }

  return true;
}

export const guardedFirebaseRequest = async <T>(
  firebaseFunction: FirebaseFunction<T>,
  ...args: any[]
): Promise<T> => {
  const { currentUser } = auth;
  if (!currentUser) {
    throw new Error("User is not authenticated");
  }

  try {
    return await firebaseFunction(...args);
  } catch (error) {
    console.error("Firebase request failed:", error);
    throw error;
  }
};

export const getDocRefByVisaType = (
  visaType: VisaDocumentType,
  uid: string,
  docId: string
) => {
  let docTypePath = "";
  if (visaType === VisaDocumentType.Evidence) {
    docTypePath = "evidence_docs";
  }
  if (visaType === VisaDocumentType.Standard) {
    docTypePath = "docs";
  }
  if (visaType === VisaDocumentType.SignedExpertLetters) {
    docTypePath = "signed_expert_letters";
  }

  const docRef = doc(db, `/documents/${uid}/${docTypePath}/${docId}`);
  return docRef;
};

type EntityAttributesMap = {
  individuals: { individualId: string };
  lawyers: { lawyerId: string };
  documents: { documentId: string };
  standardDocument: { documentId: string; individualId: string };
  evidenceDocument: { documentId: string; individualId: string };
  templates: { templateId: string; lawyerUid: string };
};

export const getEntityReference = <T extends keyof EntityAttributesMap>(
  entity: T,
  id: EntityAttributesMap[T]
) => {
  let path = "";
  switch (entity) {
    case "individuals":
      const { individualId } = id as EntityAttributesMap["individuals"];
      path = `/individuals/${individualId}`;
      break;
    case "documents":
      const { documentId } = id as EntityAttributesMap["documents"];
      path = `/documents/${documentId}`;
      break;
    case "standardDocument":
      const { documentId: standardDocId, individualId: uid } =
        id as EntityAttributesMap["standardDocument"];
      path = `/documents/${uid}/docs/${standardDocId}`; // Updated to include a valid path
      break;
    case "evidenceDocument":
      const { documentId: evidenceDocId, individualId: uuid } =
        id as EntityAttributesMap["standardDocument"];
      path = `/documents/${uuid}/evidence_docs/${evidenceDocId}`; // Updated to include a valid path
      break;
    case "lawyers":
      const { lawyerId } = id as EntityAttributesMap["lawyers"];
      path = `/lawyers/${lawyerId}`;
      break;
    case "templates":
      const { templateId, lawyerUid } = id as EntityAttributesMap["templates"];
      path = `/${DATABASE.CUSTOM_TEMPLATES}`;
      break;
    default:
      throw new Error("Invalid entity type provided.");
  }

  if (!path) {
    throw new Error("Path cannot be empty.");
  }

  const docRef = doc(db, path);
  return docRef;
};

export const deleteFile = async (filePath: string) => {
  try {
    const fileRef = ref(storage, filePath);
    await deleteObject(fileRef);
    console.log("File deleted successfully:", filePath);
  } catch (error) {
    console.error("Error deleting file:", error);
  }
};
