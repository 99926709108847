import { useCallback, useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { doc, onSnapshot } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { db } from "../api/firebaseApi";

import { FirestoreWrapper } from "../api/fireStoreWrapper";
import { updateClientCaseData } from "../redux/clients/clientsSlice";
import { ToastManager } from "../services/toast/toastManager";
import { DATABASE } from "../types/tables-data";

function useFirestoreDocument<T>(
  collectionPath: DATABASE,
  docId: string,
  listen = false,
  fullCollectionPath = "",
  disableToast = false // New parameter with default value
) {
  const [document, setDocument] = useState<Partial<T> | null>(null);
  const [liveDocument, setLiveDocument] = useState<Partial<T> | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isFetchCalled, setIsFetchCalled] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();

  const resolvedCollectionPath = fullCollectionPath || collectionPath;

  const firestoreWrapper = new FirestoreWrapper<T>(db, resolvedCollectionPath);

  const listenAndFetch = useCallback(async () => {
    try {
      const unsub = onSnapshot(
        doc(db, resolvedCollectionPath, docId),
        (doc) => {
          const data = doc.data();
          setDocument(data as T);
          setIsFetchCalled(true);
          return () => unsub();
        }
      );
    } catch (err: any) {
      setError(err);
      setLoading(false);
    }
  }, [resolvedCollectionPath, docId]);

  const fetchDocument = useCallback(async () => {
    try {
      setLoading(true);
      const docData = await firestoreWrapper.read(docId);
      setDocument(docData);
      setLoading(false);
    } catch (err: any) {
      setError(err);
      setLoading(false);
    }
  }, [resolvedCollectionPath, docId]);

  useEffect(() => {
    if (listen) {
      listenAndFetch();
    } else {
      fetchDocument();
    }
  }, [fetchDocument, listenAndFetch]);

  const createDocument = async (newData: Partial<T>) => {
    try {
      setLoading(true);
      const result = await firestoreWrapper.create(docId, newData);
      setDocument(newData);
      setLoading(false);

      if (!disableToast) {
        ToastManager.getInstance().showToast(
          collectionPath,
          "success",
          "added"
        );
      }
    } catch (err: any) {
      ToastManager.getInstance().showToast(
        collectionPath,
        "warning",
        "updated"
      );
      setError(err);
    }
  };

  const updateDocument = async (newData: Partial<T>) => {
    const logKeyword = "document_update";
    try {
      console.log(`[${logKeyword}] Start updating document`, {
        docId,
        newData
      });

      await firestoreWrapper.update(docId, newData);

      console.log(`[${logKeyword}] Firestore update successful`, {
        docId,
        newData
      });

      setDocument((prev) => ({ ...prev, ...newData }));

      console.log(`[${logKeyword}] Local state updated`, {
        docId,
        updatedDocument: newData
      });

      dispatch(updateClientCaseData(newData));

      console.log(`[${logKeyword}] Redux state updated`, {
        docId,
        updatedData: newData
      });

      if (!disableToast) {
        ToastManager.getInstance().showToast(
          collectionPath,
          "success",
          "updated"
        );
        console.log(`[${logKeyword}] Toast shown`, {
          collectionPath,
          status: "success"
        });
      }
    } catch (err: any) {
      console.error(`[${logKeyword}] Error updating document`, {
        docId,
        error: err
      });

      ToastManager.getInstance().showToast(
        collectionPath,
        "warning",
        "updated"
      );
      console.log(`[${logKeyword}] Toast shown`, {
        collectionPath,
        status: "warning"
      });

      setError(err);
    }
  };

  const deleteDocument = async (doc = docId) => {
    try {
      await firestoreWrapper.delete(doc);
      setDocument(null);
    } catch (err: any) {
      setError(err);
    }
  };

  const deleteDocumentFields = async (fields: string[]) => {
    try {
      const result = await firestoreWrapper.deleteFields(docId, fields);
      setDocument(null);
      return result;
    } catch (err: any) {
      setError(err);
      return err;
    }
  };

  // TODO
  const deleteMultipleDocuments = async (paths: []) => {
    // const promises = paths.map((string) => {
    //   return
    // }))
  };

  return {
    document,
    liveDocument,
    isFetchCalled,
    loading,
    error,
    fetchDocument,
    createDocument,
    updateDocument,
    deleteDocument,
    deleteDocumentFields,
    listenAndFetch
  };
}

export default useFirestoreDocument;
