import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Badge, Box, Button, Tooltip } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { getFileName } from "../helpers/file_helpers";
import { openInNewTab } from "../helpers/helpers";
import { getShortString } from "../helpers/string_helpers";
import { DataDocs } from "../types/tables-data";
import Thumbnail from "../components/individualTabs/individualDocuments/Thumbnail";
import { useTypedDispatch } from "../redux/store";
import { generateSingleDocumentUrl } from "../redux/documents/documentsThunk";

type Props = {
  indx: string;
  document: DataDocs;
  visible: boolean;
  onClick: () => void;
};

export const DocumentThumbnail: React.FC<Props> = ({
  indx,
  document,
  visible,
  onClick
}) => {
  const dispatch = useTypedDispatch();
  const [docUrl, setDocUrl] = React.useState<string | undefined>(
    document.docUrl
  );

  useEffect(() => {
    if (!document.docUrl) {
      dispatch(
        generateSingleDocumentUrl({
          criterion: document.criterion!,
          filePath: document.pdf_file_path || document.filePath!,
          id: document.id!,
          super_class: document.super_class!
        })
      )
        .unwrap()
        .then((res) => {
          setDocUrl(res.fileUrl);
        });
    }
  }, []);

  return (
    <div className="flex flex-col p-0 m-0 ">
      <Box
        borderColor={`${visible ? "primary.blue" : "#dadde0"}`}
        borderWidth="medium"
        className="w-48 h-64 flex flex-col rounded-lg relative"
        onClick={onClick}
        transition="border-color 0.2s ease"
      >
        <Badge
          border="1px solid #E2E8F0"
          bg="white"
          textColor="#757575"
          fontWeight={700}
          className="absolute right-1 top-1 z-10 !px-2 !py-0 !rounded-full"
        >
          {indx}
        </Badge>
        <div className="max-h-48 w-48 overflow-hidden ">
          <Thumbnail
            documentSummary={document.extracted_argument?.argument_text ?? ""}
            filePath={document.filePath ?? ""}
            disableThumbnailClick
          />
        </div>
        <Box
          className="relative flex flex-col  py-1 justify-end mt-auto"
          bg={`${visible ? "primary.blue" : "gray"} `}
          color="white"
          p={1}
        >
          <Tooltip label={document.autoTitle}>
            {getShortString(document.autoTitle)}
          </Tooltip>
          <small className="flex justify-center font-bold">
            Exhibit: {indx}
          </small>
        </Box>
      </Box>
      <Button
        variant="primaryText"
        visibility={!visible ? "hidden" : "visible"}
        leftIcon={<ExternalLinkIcon />}
        className="self-end"
        key={indx}
        onClick={() => {
          openInNewTab(docUrl ?? "", getFileName(document.filePath));
        }}
      >
        Open file
      </Button>
    </div>
  );
};
