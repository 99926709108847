import { LegalBriefSection } from "../redux/drafts/draftsSlice";
import { DataDocs, VISAVALUE } from "./tables-data";

export type DraftDocument = {
  sop: { text: string };
  exhibit_list: { text: string; html: string; isProcessing?: boolean };
  coverLetter: { text: string };
  created_at: Date;
};

export interface GenerateDraftParams {
  individualId: string;
  draftType: string;
  expertId?: string | null;
  tone?: string | null;
  length?: string | null;
  creativity?: string | null;
  greeting?: string | null;
  useAsync?: boolean;
  referenceResume?: boolean;
  isSupportLetter?: boolean;
  templatePath?: string | null;
  fromPreviousCase?: boolean | null;
}

export interface GeneratePacketParams {
  individualId: string;
  useAsync?: boolean;
}

export enum LegalBriefType {
  LEGAL_BRIEF = "Legal brief",
  SUPPORT_LETTER = "Support letter"
}

export const VisaLegalBriefConfig: Record<VISAVALUE, LegalBriefType[]> = {
  [VISAVALUE.EMPTY]: [],
  [VISAVALUE.EB1A]: [LegalBriefType.LEGAL_BRIEF],
  [VISAVALUE.EB1B]: [LegalBriefType.LEGAL_BRIEF],
  [VISAVALUE.EB1C]: [LegalBriefType.LEGAL_BRIEF],
  [VISAVALUE.EB2NIW]: [LegalBriefType.LEGAL_BRIEF],
  [VISAVALUE.O1A]: [LegalBriefType.LEGAL_BRIEF, LegalBriefType.SUPPORT_LETTER],
  [VISAVALUE.O1B]: [LegalBriefType.LEGAL_BRIEF],
  [VISAVALUE.L1A]: [LegalBriefType.LEGAL_BRIEF],
  [VISAVALUE.L1B]: [LegalBriefType.LEGAL_BRIEF],
  [VISAVALUE.H1B]: [LegalBriefType.LEGAL_BRIEF, LegalBriefType.SUPPORT_LETTER]
};

export enum LegalBriefLength {
  CONSCISE = "Conscise",
  LENGTHY = "Lengthy"
}
