import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";

import { debounce } from "lodash";
import { Delta, Sources } from "quill";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { convertFromHtml } from "../api/draftsApi";
import { addCustomPrompt } from "../api/OpenAIApi";
import { triggerDownload } from "../helpers/file_helpers";
import { setSelectedPrompt } from "../redux/prompts/promptsSlice";
import { promptsSelectors } from "../redux/prompts/selectors";
import { Prompt } from "../types/studio/prompts";
import { VISAVALUE } from "../types/tables-data";

const PROMPT_CHARS_LIMIT = 10000;

export type SaveStatus = "idle" | "saving" | "success" | "error";

export const useEditorHandlers = (
  quillRef: React.RefObject<ReactQuill>,
  text: string,
  updateText: (html: string, text: string) => void,
  saveText?: (html: string, plainText: string) => Promise<void>,
  isFormEditor?: boolean
) => {
  const dispatch = useDispatch();
  const { id: uid } = useParams();
  const allPrompts = useSelector(promptsSelectors.selectAll);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [parsedText, setParsedText] = useState<string>(text);
  const [widgetYPosition, setWidgetYPosition] = useState<any | null>(0);
  const [previewText, setPreviewText] = useState(null);

  const [changingText, setChangingText] = useState(false);
  const [selectedText, setSelectedText] = useState<string>(" ");
  const [bounds, setBounds] = useState<any | null>(null);
  const [range, setRange] = useState<null | ReactQuill.Range>(null);
  const [showToolbar, setShowToolbar] = useState(false);
  const [showPromptIcon, setShowPromptIcon] = useState(false);
  const previousRangeRef = useRef<any>(null);
  const toast = useToast();
  const [saveStatus, setSaveStatus] = useState<SaveStatus>("idle");

  const handleImageUpload = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      if (input.files && input.files[0]) {
        const file = input.files[0];
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
          const quill = quillRef?.current?.getEditor();
          const base64 = reader.result;
          const range = quill?.getSelection();
          quill?.insertEmbed(range!.index, "image", base64, "user");
        };
      }
    };
  }, [quillRef]);

  useEffect(() => {
    if (!showToolbar) {
      // reset to default
      setPreviewText(null);
    }
  }, [showToolbar]);

  // keyboard events
  useEffect(() => {
    const handleKeyboardEvents = (event: KeyboardEvent) => {
      const editor = quillRef.current?.getEditor();
      const isEditorFocused = document.activeElement === editor?.root;

      // Handle Escape key - close toolbar
      if (event.key === "Escape") {
        setShowToolbar(false);
      }

      // Handle Ctrl/Cmd + Shift + A - show AI toolbar
      if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        event.key.toLowerCase() === "a" &&
        isEditorFocused
      ) {
        event.preventDefault();
        setShowToolbar(true);
      }

      // Handle Ctrl/Cmd + Z - remove highlighting
      if (
        (event.ctrlKey || event.metaKey) &&
        event.key.toLowerCase() === "z" &&
        isEditorFocused
      ) {
        if (editor) {
          editor.formatText(
            0,
            editor.getText().length,
            "customHighlight",
            false,
            "user"
          );
        }
      }
    };

    window.addEventListener("keydown", handleKeyboardEvents);
    return () => {
      window.removeEventListener("keydown", handleKeyboardEvents);
    };
  }, [quillRef]);

  useEffect(() => {
    setParsedText(text);
  }, [text]);

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const toolbar = editor.getModule("toolbar");
      toolbar.addHandler("image", handleImageUpload);
    }
  }, []);

  const selectEditor = () => {
    const quill = quillRef.current?.getEditor();
    if (range) quill?.setSelection(range.index, range.length);
  };

  const handleBlur = useCallback(
    (
      previousSelection: ReactQuill.Range | null,
      source: Sources,
      editor: ReactQuill.UnprivilegedEditor
    ) => {
      const selectedElement = document.activeElement;

      const selectable_ids = [
        "prompt-textarea",
        "search-input",
        "ai-prompt-menu",
        "dropdown-generic-container",
        "text-preview-box"
      ];

      const isInsidePopover =
        selectedElement?.id?.startsWith("popover-content-");

      // Ignore blur when clicking on whitespace (document body or null)
      if (!selectedElement || selectedElement === document.body) {
        return;
      }

      // Prevent toolbar from closing if inside an interactive component
      if (
        selectable_ids.includes(selectedElement.id) ||
        isInsidePopover ||
        selectedElement.closest("#ai-prompt-menu") ||
        selectedElement.closest("[role='menu']") // Chakra UI dropdowns
      ) {
        if (previousSelection) {
          const quill = quillRef.current?.getEditor();
          if (quill) {
            quill.formatText(
              previousSelection.index,
              previousSelection.length,
              "customHighlight",
              true,
              "api"
            );
          }
        }
        return;
      }

      setShowPromptIcon(false);
      setShowToolbar(false);
    },
    [quillRef]
  );

  const handleFocus = useCallback(
    (
      previousSelection: ReactQuill.Range,
      _: Sources,
      __: ReactQuill.UnprivilegedEditor
    ) => {
      setShowPromptIcon(true);
      const editor = quillRef.current?.getEditor();
      if (editor) {
        editor.formatText(
          0,
          parsedText.length,
          "customHighlight",
          false,
          "user"
        );
      }
      if (previousSelection && previousSelection.length === 0) {
        setRange(previousSelection);
      }
    },
    [quillRef]
  );

  const handleSelectionChange = useCallback(
    (
      range: ReactQuill.Range,
      source: Sources,
      editor: ReactQuill.UnprivilegedEditor
    ) => {
      const priviligedEdiror = quillRef.current?.getEditor();
      if (range && range.length === 0 && previousRangeRef.current) {
        setShowToolbar(false);
      }

      if (editor && range && priviligedEdiror) {
        console.log("range", { range });

        const selectionBounds = editor.getBounds(range.index, range.length);
        const selectedText = editor.getText(range.index, range.length) || " ";
        setSelectedText(selectedText);
        // get toolbar from editor
        const toolbarHeight =
          priviligedEdiror.getModule("toolbar").container.clientHeight;

        setBounds(selectionBounds);
        setRange(range);
        const topPosition =
          selectionBounds.height > priviligedEdiror.root.clientHeight
            ? priviligedEdiror.root.clientHeight / 2
            : toolbarHeight + selectionBounds.top + selectionBounds.height / 2;
        setWidgetYPosition(topPosition);
        previousRangeRef.current = range;
      }
    },
    [quillRef]
  );

  const handleDownload = async (format: string) => {
    const quill = quillRef.current?.getEditor();
    const html = quill?.root.innerHTML;
    try {
      setIsDownloading(true);
      const { data, filename } = await convertFromHtml({
        format,
        individualId: uid!,
        html
      });
      triggerDownload(data, filename, format);
    } catch (error) {
      console.error(`Error generating ${format} file:`, error);
      setIsDownloading(false);
    } finally {
      setIsDownloading(false);
    }
  };

  const replaceText = (generatedText: string) => {
    const quillEditor = quillRef?.current?.getEditor();
    if (!quillEditor || !range) return;

    quillEditor.deleteText(range.index, range.length, "user");
    quillEditor.insertText(range.index, generatedText, "user");
    quillEditor.setSelection(range.index, generatedText.length);
    setChangingText(false);
    setShowToolbar(false);
    previousRangeRef.current = {
      index: range.index,
      length: generatedText.length
    };
  };

  const handleAIButtonClick = async (
    rewriteOption?: string,
    toneOption?: string,
    customPrompt?: string,
    evidencesToHighlight?: string[],
    visaType?: VISAVALUE,
    enableExhibitNumbers?: boolean
  ) => {
    const quillEditor = quillRef?.current?.getEditor();
    const fullText = quillEditor?.getText() ?? "";

    if (!quillEditor || !selectedText || !range) {
      return;
    }

    if (selectedText.length > PROMPT_CHARS_LIMIT) {
      toast({
        status: "warning",
        colorScheme: "red",
        title: "The text selected is too long.",
        description: "Please shorten the selected text to a maximum of 4 pages."
      });
      return;
    }

    const selectedPromptText =
      rewriteOption ||
      toneOption ||
      customPrompt ||
      (evidencesToHighlight ? "Reference" : "");

    let selectedPrompt = allPrompts.find(
      (prompt) => prompt.promptText === selectedPromptText
    );

    if (!selectedPrompt) {
      selectedPrompt = {
        created_at: 0,
        id: "",
        promptName: "",
        promptText: selectedPromptText
      } as Prompt;
    }

    dispatch(setSelectedPrompt(selectedPrompt));
    setChangingText(true);

    const result = await addCustomPrompt(
      uid!,
      selectedText,
      fullText,
      rewriteOption,
      toneOption,
      customPrompt,
      evidencesToHighlight,
      visaType,
      enableExhibitNumbers
    );

    if (result && result.updatedText) setPreviewText(result.updatedText);

    setChangingText(false);
  };

  const debouncedSaveRef = useRef(
    debounce(async (html: string, text: string) => {
      if (saveText) {
        try {
          console.log("save text executed");
          await saveText(html, text);
          setSaveStatus("success");
        } catch (error) {
          setSaveStatus("error");
          toast({
            title: "Failed to save changes",
            description:
              error instanceof Error ? error.message : "Unknown error occurred",
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "bottom-right"
          });
        }
      }
    }, 1000)
  );

  useEffect(() => {
    return () => {
      debouncedSaveRef.current.cancel();
    };
  }, []);

  useEffect(() => {
    if (saveStatus === "success") {
      const timeout = setTimeout(() => {
        setSaveStatus("idle");
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};
  }, [saveStatus]);

  const handleChange = async (
    value: string,
    delta: Delta,
    source: Sources,
    editor: ReactQuill.UnprivilegedEditor
  ) => {
    const highlightingText = delta.ops?.some(
      (op) => op.attributes && "customHighlight" in op.attributes
    );

    if (!isFormEditor && !highlightingText && source === "user")
      setSaveStatus("saving");
    const plainText = editor.getText();
    updateText(value, plainText);
    setParsedText(value);
    if (!isFormEditor && !highlightingText && source === "user") {
      console.log("debounced save called");
      debouncedSaveRef.current(value, plainText);
    }
  };

  return {
    selectedText,
    bounds,
    range,
    showToolbar,
    isDownloading,
    changingText,
    parsedText,
    showPromptIcon,
    widgetYPosition,
    saveStatus,
    isFormEditor,
    previewText,
    setSelectedText,
    setSaveStatus,
    selectEditor,
    setParsedText,
    setShowToolbar,
    setPreviewText,
    handleSelectionChange,
    handleDownload,
    handleAIButtonClick,
    handleBlur,
    handleImageUpload,
    handleFocus,
    handleChange,
    replaceText
  };
};
