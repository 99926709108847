import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk, { ThunkDispatch } from "redux-thunk";
import rootReducer from "./rootReducers";

// TODO: Remove redux-persist entirely
const persistConfig = {
  key: "root",
  storage,
  // Only persist essential data that's expensive to refetch
  // and needed for initial app state
  whitelist: [
    "lawyer", // Needed for auth/permissions
    "activeClient" // Needed for navigation/context
  ],
  // Everything else can be refetched (and in most cases, is already constantly
  // being refetched by Firestore onSnapshot listeners) or is ephemeral UI state that
  // should be handled with local React state or URL params if needed across refreshes
  blacklist: [
    "drafts", // Large content
    "document", // Large content, can be refetched
    "extraction", // Can be refetched
    "fileUpload", // Temporary state
    "caseResearch", // Can be refetched
    "forms", // Temporary state
    "sidebar", // UI state
    "templates", // Can be refetched
    "prompts", // Can be refetched
    "clients", // Can be refetched
    "individual" // Can be refetched
  ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk]
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;

export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
