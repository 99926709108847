import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExpertLetterDraft } from "../../types/expert-letter-draft";

export interface LegalBriefSection {
  id?: string;
  html?: string;
  text?: string;
  section_name?: string;
  display_name?: string;
  sections_after_review?: string;
  sections_after_review_html?: string;
}
export interface DraftType {
  id?: string;
  value: any;
  date?: any;
  accessEnabled?: boolean;
  sections?: LegalBriefSection[] | null;
  generated_text?: string | null;
  generated_html?: string | null;
}

export interface DraftsState {
  legalBrief?: DraftType | null;
  expertLetters?: Record<string, ExpertLetterDraft> | null;
}

const initialState: DraftsState = {
  legalBrief: null,
  expertLetters: null
};

export interface DraftsReducer {
  drafts: DraftsState;
}

export const draftsSlice = createSlice({
  name: "drafts",
  initialState,
  reducers: {
    setLegalBrief: (state, action: PayloadAction<DraftType | null>) => {
      state.legalBrief = action.payload;
    },
    setExpertLettersDrafts: (
      state,
      action: PayloadAction<Record<string, ExpertLetterDraft>>
    ) => {
      state.expertLetters = action.payload;
    },
    clearLegalBrief: (state) => {
      state.legalBrief = null;
    }
  }
});

export const { clearLegalBrief, setLegalBrief, setExpertLettersDrafts } =
  draftsSlice.actions;

export default draftsSlice.reducer;
