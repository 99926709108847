import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  IconButton,
  useDisclosure,
  Text,
  Flex,
  Box
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

// dnd-kit imports
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
  useSortable
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { MdDragIndicator } from "react-icons/md";

// Define the Section interface
export interface SortableSection {
  id: string | number;
  title: string;
}

// Props for ManageSectionsModal
interface ManageSectionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialSections: SortableSection[];
  onSave: (sections: SortableSection[]) => void;
}

// Props for each sortable item
interface SortableItemProps {
  section: SortableSection;
  onDelete: (section: SortableSection) => void;
  onChangeTitle: (id: string | number, title: string) => void;
}

/**
 * SortableItem - represents a single draggable section.
 */
const SortableItem: React.FC<SortableItemProps> = ({
  section,
  onDelete,
  onChangeTitle
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: section.id
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <Flex ref={setNodeRef} style={style} my={4} align="center">
      {/* Drag handle icon: only this icon is draggable */}
      <Input
        mr={2}
        flex="1"
        placeholder="Section Title"
        value={section.title}
        onChange={(e) => onChangeTitle(section.id, e.target.value)}
      />
      <Flex {...attributes} {...listeners} cursor="grab">
        <MdDragIndicator size={24} color="text.graySecondary.smog" />
      </Flex>
      <IconButton
        aria-label="Delete section"
        icon={<DeleteIcon />}
        ml={2}
        variant="ghost"
        color="text.graySecondary.smog"
        onClick={() => onDelete(section)}
      />
    </Flex>
  );
};

/**
 * ManageSectionsModal - main component for managing a list of sections.
 * Props:
 * - isOpen (bool): Controls the visibility of the modal.
 * - onClose (func): Callback to close the modal.
 * - initialSections (array): Array of section objects [{ id, title }, ...].
 * - onSave (func): Receives the updated list of sections when user clicks "Save".
 */
const ManageSectionsModal: React.FC<ManageSectionsModalProps> = ({
  isOpen,
  onClose,
  initialSections,
  onSave
}) => {
  const [sections, setSections] = useState<SortableSection[]>(
    initialSections || []
  );

  // Delete confirmation sub-modal state
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose
  } = useDisclosure();
  const [sectionToDelete, setSectionToDelete] =
    useState<SortableSection | null>(null);

  // Add a new blank section
  const handleAddSection = () => {
    setSections((prev) => [...prev, { id: Date.now(), title: "" }]);
  };

  // Update the title of a section
  const handleTitleChange = (id: string | number, newTitle: string) => {
    setSections((prev) =>
      prev.map((section) =>
        section.id === id ? { ...section, title: newTitle } : section
      )
    );
  };

  // Request deletion confirmation for a section
  const handleDeleteRequest = (section: SortableSection) => {
    setSectionToDelete(section);
    onDeleteOpen();
  };

  // Confirm deletion of a section
  const confirmDeleteSection = () => {
    if (sectionToDelete) {
      setSections((prev) => prev.filter((s) => s.id !== sectionToDelete.id));
      setSectionToDelete(null);
      onDeleteClose();
    }
  };

  // Configure dnd-kit sensors
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 }
    })
  );

  // Handle drag end event
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    setSections((prev) => {
      const oldIndex = prev.findIndex((item) => item.id === active.id);
      const newIndex = prev.findIndex((item) => item.id === over.id);
      return arrayMove(prev, oldIndex, newIndex);
    });
  };

  // Final save action
  const handleSave = () => {
    onSave(sections);
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      setSections(initialSections);
    }
  }, [isOpen, initialSections]);

  return (
    <>
      {/* Main Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Sections</ModalHeader>
          <ModalBody maxH="80vh" overflowY="auto">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext
                items={sections.map((s) => s.id)}
                strategy={verticalListSortingStrategy}
              >
                {sections.map((section) => (
                  <SortableItem
                    key={section.id}
                    section={section}
                    onDelete={handleDeleteRequest}
                    onChangeTitle={handleTitleChange}
                  />
                ))}
              </SortableContext>
            </DndContext>
            <Flex justifyContent="center">
              <Button
                onClick={handleAddSection}
                size="sm"
                color="primary.blue"
                variant="ghost"
                mt={2}
              >
                + Add Section
              </Button>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondaryOutline" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button variant="primaryFilled" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose} size="sm" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete section</ModalHeader>
          <ModalBody>
            <Text>
              Are you sure you want to delete this section? This action cannot
              be undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondaryOutline" onClick={onDeleteClose} mr={3}>
              Cancel
            </Button>
            <Button variant="destructiveFilled" onClick={confirmDeleteSection}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ManageSectionsModal;
