import { DeleteIcon, EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CSSProperties } from "react";
import { MdDragIndicator } from "react-icons/md";
import { useSelector } from "react-redux";
import { documentSelectors } from "../../redux/documents/selectors";
import { DataDocs } from "../../types/tables-data";
import { DocumentRowNewBadge, DocumentRowStatus } from "./DocumentRowStatus";

export const RowDragHandleCell = ({
  rowId,
  color
}: {
  rowId: string;
  color?: undefined | any;
}) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
    data: {
      id: rowId
    }
  });
  return (
    <IconButton
      color={color}
      variant="ghost"
      aria-label="shuffle"
      icon={<MdDragIndicator />}
      {...listeners}
      {...attributes}
    />
  );
};
interface DraggableRowProps {
  onClick?: () => void;
  children: any;
  id?: string;
  metadata?: any;
  disabled?: boolean;
}
export const DraggableDiv = ({
  onClick,
  children,
  id,
  metadata,
  disabled
}: DraggableRowProps) => {
  const {
    transform,
    transition,
    setNodeRef,
    isDragging,
    listeners,
    attributes,
    isOver
  } = useSortable({
    id: id!,
    disabled,
    data: { metadata }
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition: transition ?? "",
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
    boxShadow: isDragging ? "0 8px 16px rgba(0, 0, 0, 0.3)" : ""
  };

  return (
    <Box style={style} ref={disabled ? null : setNodeRef}>
      {children}
    </Box>
  );
};

export const DocumentItemPlaceHolder = ({
  document,
  isAiToggled
}: {
  document: DataDocs | undefined;
  isAiToggled: boolean;
}) => {
  if (!document) return null;
  return (
    <Box
      bg="white"
      textColor="black"
      className="flex justify-between items-center h-[85px] py-2 px-4"
    >
      <div className="flex gap-2 items-center">
        <RowDragHandleCell rowId={document.id ?? ""} />
        {/* {index !== undefined && `${index + 1}`} */}
        <div className="flex max-w-[400px] w-[400px]">
          <div className="flex flex-col">
            <DocumentRowNewBadge document={document} />
            <p>{document?.type ?? ""}</p>
            {/* We are not displaying the general class anymore */}
            {/* <small className="text-black text-[10px]">
              {document?.general_class?.toString()}
            </small> */}
          </div>
        </div>
      </div>
      <Box className="flex-1">
        <div className="flex justify-start">
          <Tooltip
            label={
              isAiToggled && document.criterion !== "Processing"
                ? document?.autoTitle
                : document?.docNames
            }
          >
            <Text
              textAlign="left"
              noOfLines={2}
              textColor="text.graySecondary.smog"
            >
              {isAiToggled && document.criterion !== "Processing"
                ? document?.autoTitle
                : document?.docNames}
            </Text>
          </Tooltip>
        </div>
      </Box>
      <Box>
        <Flex gap={2} justify="end" alignItems="center">
          <Box border="none">
            <DocumentRowStatus document={document} />
          </Box>
          <IconButton
            variant="filledIconButton"
            icon={<ExternalLinkIcon />}
            onClick={() => {
              // openFilePreview(document.docUrl);
              // updateDocumentNewField(document);
            }}
            aria-label=""
          />
          <>
            <IconButton
              variant="filledIconButton"
              icon={<DeleteIcon />}
              onClick={() => {
                // handleDeleteClick(document);
              }}
              aria-label=""
            />
            <IconButton
              variant="filledIconButton"
              icon={<EditIcon />}
              // onClick={() => handleEditClick(document)}
              aria-label=""
            />
          </>
        </Flex>
      </Box>
    </Box>
  );
};

export const SubGroupDiv = ({ metadata, id, children }: any) => {
  const {
    setNodeRef,
    isDragging,
    transform,
    transition,
    attributes,
    over,
    active
  } = useSortable({
    id,
    data: { metadata }
  });
  const allDocuments = useSelector(documentSelectors.allDocuments);
  const activeDocument = allDocuments.find((doc) => doc.id === active?.id);
  const isOver = over?.id !== active?.id && over?.id.toString() === id;
  const activeTopPx = active?.rect?.current?.translated?.top;
  const overTopPx = over?.rect?.top;
  const isTop = isOver && activeTopPx && overTopPx && activeTopPx < overTopPx;
  const isBottom =
    isOver && activeTopPx && overTopPx && activeTopPx > overTopPx;

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition: transition ?? "",
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
    boxShadow: isDragging ? "0 8px 16px rgba(0, 0, 0, 0.3)" : ""
  };

  return (
    <Box key={id} style={style} ref={setNodeRef} {...attributes}>
      {children}
    </Box>
  );
};

export const GroupedDiv = ({ id, children, metadata }: any) => {
  const { setNodeRef, isDragging, transform, transition, isOver } = useSortable(
    {
      id,
      data: { metadata }
    }
  );
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition ?? "",
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? 1 : 0,
    border: isOver ? "2px solid red" : "unset",
    boxShadow: isDragging ? "0 8px 16px rgba(0, 0, 0, 0.3)" : ""
  };
  return (
    <Box as="tr" key={id} style={style} ref={setNodeRef}>
      {children}
    </Box>
  );
};
