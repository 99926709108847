import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  GroupedDocument,
  GroupedTable
} from "../../../components/common/GroupedTable";
import { SectionWrapper } from "../../../components/common/SectionWrapper";
import { SpecialHeading } from "../../../components/individualTabs/PanelLayout";
import UploadNewTemplateModal from "../../../components/lawyer/custom templates/UploadNewTemplateModal";
import useFirestoreCollection from "../../../hooks/useFirestoreCollection";
import { lawyerSelectors } from "../../../redux/lawyer/selectors";
import { templatesSelectors } from "../../../redux/templates/selectors";
import { CustomTemplate } from "../../../types/studio/templates";
import { DATABASE } from "../../../types/tables-data";

// Define StrictTemplate to ensure `id` is always a string
export type StrictTemplate = Omit<CustomTemplate, "id"> & { id: string };

export const Templates = () => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const lawyerId = useSelector(lawyerSelectors.selectUid);
  const strictTemplates = useSelector(templatesSelectors.selectStrictTemplates);
  const templates = useSelector(templatesSelectors.selectAll);

  const [groupedItems, setGroupedItems] = useState<
    GroupedDocument<StrictTemplate>[]
  >([]);

  const [fromPreviousCase, setFromPreviousCase] = useState(false);

  const handleUpdateOrder = (
    updatedGroups: GroupedDocument<StrictTemplate>[]
  ) => {};

  // 1. Always call the hook. Pass "undefined" if no lawyerId:
  const collectionPath = `${DATABASE.CUSTOM_TEMPLATES}`;

  // 2. The hook is always called, so the call order never changes
  const { loading } = useFirestoreCollection(collectionPath, {
    listen: true,
    fetchFiles: true,
    withThumbnails: true,
    useLawyerAccessMap: true
    // orderByField: "visa"
  });

  // 3. Then conditionally render if neededs
  if (!lawyerId) {
    return <Text>Loading templates...</Text>;
  }

  return (
    <SectionWrapper withBackNav={false} bg="rgba(255, 255, 255, 0.5)">
      <Flex flexDirection="column" flex="1" className="p-4 overflow-auto">
        <Flex justifyContent="space-between" my={4}>
          <Box width="100%">
            <SpecialHeading title="Templates" />
          </Box>
          <Menu>
            <MenuButton
              minW="14vw"
              as={Button}
              leftIcon={<AddIcon />}
              variant="primaryFilled"
            >
              <Text fontSize={14}>Add new template</Text>
            </MenuButton>
            <MenuList zIndex="dropdown">
              <MenuItem onClick={() => setIsUploadModalOpen(true)}>
                Upload new template
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFromPreviousCase(true);
                  setIsUploadModalOpen(true);
                }}
              >
                Upload a previous case sample
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        {strictTemplates ? (
          <Box className="overflow-x-auto overflow-y-hidden w-full">
            <GroupedTable<StrictTemplate>
              items={strictTemplates}
              setGroupedItems={setGroupedItems}
              updateOrder={handleUpdateOrder}
              isLoading={loading}
              groupBy="type"
              feature="templates"
              searchPlaceholder="Search Templates..."
              disablePagination
              sortBy="visa"
              sortBySecondary="templateName"
            />
          </Box>
        ) : (
          <Heading size="sm" my="15px">
            No templates found. <br /> Click &quot;New template&quot; to add the
            first template
          </Heading>
        )}

        <UploadNewTemplateModal
          isOpen={isUploadModalOpen}
          onClose={() => {
            setFromPreviousCase(false);
            setIsUploadModalOpen(false);
          }}
          fromPreviousCase={fromPreviousCase}
        />
      </Flex>
    </SectionWrapper>
  );
};
