import { DATABASE } from "../../types/tables-data";

export type ActionType = "added" | "updated" | "deleted";

export const getDatabaseEntityActionMessage = (
  entity: DATABASE,
  action: ActionType
): string => {
  const entityActionMessages: Record<DATABASE, Record<ActionType, string>> = {
    [DATABASE.LAWYERS]: {
      added: "Lawyer added successfully.",
      updated: "Lawyer updated successfully.",
      deleted: "Lawyer deleted successfully."
    },
    [DATABASE.FIRMS]: {
      added: "Firm added successfully.",
      updated: "Firm updated successfully.",
      deleted: "Firm deleted successfully."
    },
    [DATABASE.CLIENTS]: {
      added: "Client added successfully.",
      updated: "Client updated successfully.",
      deleted: "Client deleted successfully."
    },
    [DATABASE.DOCUMENTS]: {
      added: "Document added successfully.",
      updated: "Document updated successfully.",
      deleted: "Document deleted successfully."
    },
    // Add the rest of the entity messages following the same pattern...
    [DATABASE.FORMS]: {
      added: "Form added successfully.",
      updated: "Form updated successfully.",
      deleted: "Form deleted successfully."
    },
    [DATABASE.PROSPECTS]: {
      added: "Prospect added successfully.",
      updated: "Prospect updated successfully.",
      deleted: "Prospect deleted successfully."
    },
    [DATABASE.EXTRACTED]: {
      added: "Data extracted successfully.",
      updated: "Data extraction updated successfully.",
      deleted: "Data extraction deleted successfully."
    },
    [DATABASE.INDIVIDUALS]: {
      added: "Individual added successfully.",
      updated: "Individual updated successfully.",
      deleted: "Individual deleted successfully."
    },
    [DATABASE.DRAFTS]: {
      added: "Draft added successfully.",
      updated: "Draft updated successfully.",
      deleted: "Draft deleted successfully."
    },
    [DATABASE.QUESTIONNAIRES]: {
      added: "Questionnaire added successfully.",
      updated: "Questionnaire updated successfully.",
      deleted: "Questionnaire deleted successfully."
    },
    [DATABASE.BRIEF_REQUESTS]: {
      added: "Request added successfully.",
      updated: "Request updated successfully.",
      deleted: "Request deleted successfully."
    },
    [DATABASE.ACTIVE_JOBS]: {
      added: "Active job added successfully.",
      updated: "Active job updated successfully.",
      deleted: "Active job deleted successfully."
    },
    [DATABASE.SIGNED_LETTERS]: {
      added: "Signed letter uploaded successfully.",
      updated: "Signed letter updated successfully.",
      deleted: "Signed letter deleted successfully."
    },
    [DATABASE.EVIDENCE_ARGUMENT]: {
      added: "Evidence arguments added successfully.",
      updated: "Evidence arguments updated successfully.",
      deleted: "Evidence arguments deleted successfully."
    },
    [DATABASE.ADMIN_UPDATES]: {
      added: "Update added successfully.",
      updated: "Update updated successfully.",
      deleted: "Update deleted successfully."
    },
    [DATABASE.CASE_RESEARCH]: {
      added: "Case research topic added successfully.",
      updated: "Case research topic updated successfully.",
      deleted: "Case research topic deleted successfully."
    },
    [DATABASE.CUSTOM_TEMPLATES]: {
      added: "Custom template added successfully.",
      updated: "Custom template updated successfully.",
      deleted: "Custom template deleted successfully."
    },
    [DATABASE.PROMPTS]: {
      added: "Custom prompt added successfully.",
      updated: "Custom prompt updated successfully.",
      deleted: "Custom prompt deleted successfully."
    },
    [DATABASE.DEFAULT_PROMPTS]: {
      added: "Default prompt added successfully.",
      updated: "Default prompt updated successfully.",
      deleted: "Default prompt prompt deleted successfully."
    }
  };

  return entityActionMessages[entity][action];
};

// Example usage:
const messageForAddedLawyer = getDatabaseEntityActionMessage(
  DATABASE.LAWYERS,
  "added"
);
console.log(messageForAddedLawyer); // Lawyer added successfully.
