import { memo, useEffect, useRef } from 'react';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';

const RecaptchaContainerComponent = () => {
  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);

  useEffect(() => {
    const auth = getAuth();
    
    // Only initialize if not already initialized
    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
      }, auth);
    }

    // Cleanup function
    return () => {
      if (recaptchaVerifierRef.current) {
        recaptchaVerifierRef.current.clear();
        recaptchaVerifierRef.current = null;
      }
    };
  }, []);

  // Use a fixed position div that stays in the DOM
  return <div id="recaptcha-container" style={{ position: 'fixed', bottom: 0, visibility: 'hidden' }} />;
};

export const RecaptchaContainer = memo(RecaptchaContainerComponent);
