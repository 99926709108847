import { DownloadIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Grid,
  HStack,
  Input,
  Text,
  useToast
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  MdOutlineSettings,
  MdOutlineUnfoldMore,
  MdUnfoldLess
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { convertFromHtml } from "../../../api/draftsApi";
import ExportMenu from "../../../components/common/ExportMenu";
import { Editor } from "../../../components/common/Editor";
import { SectionWrapper } from "../../../components/common/SectionWrapper";
import { SpecialHeading } from "../../../components/individualTabs/PanelLayout";
import { triggerDownload } from "../../../helpers/file_helpers";
import { openFilePreview } from "../../../helpers/helpers";
import useFirestoreDocument from "../../../hooks/useFirestoreDocument";
import { lawyerSelectors } from "../../../redux/lawyer/selectors";
import { templatesSelectors } from "../../../redux/templates/selectors";
import {
  setSelectedTemplate,
  TemplatesReducer,
  updateTemplateById
} from "../../../redux/templates/templatesSlice";
import { CustomTemplate } from "../../../types/studio/templates";
import { DATABASE } from "../../../types/tables-data";
import SectionContainer from "../../../components/common/sectionContainer";
import ManageSectionsModal, {
  SortableSection
} from "../../../components/common/ManageSectionsModal";

const EditTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { templateId } = useParams();
  const [templateName, setTemplateName] = useState<string | undefined>(
    undefined
  );
  const [tempTemplateName, setTempTemplateName] = useState<string | undefined>(
    undefined
  );
  const nameHasChanges = templateName !== tempTemplateName;

  const [isDownloadingFullDraft, setIsDownloadingFullDraft] =
    useState<boolean>(false);

  const lawyerId = useSelector(lawyerSelectors.selectUid);
  const firstName = useSelector(lawyerSelectors.selectFirstName);
  const lastName = useSelector(lawyerSelectors.selectLastName);
  const toast = useToast();
  const { listenAndFetch, updateDocument, loading } =
    useFirestoreDocument<CustomTemplate>(
      DATABASE.CUSTOM_TEMPLATES,
      templateId || "",
      false,
      `${DATABASE.CUSTOM_TEMPLATES}`,
      true
    );

  const template = useSelector((state: TemplatesReducer) =>
    templatesSelectors.getTemplateById(state, templateId)
  );

  const [expandedSections, setExpandedSections] = useState<number[]>([]);
  const [areAllExpanded, setAreAllExpanded] = useState<boolean>(true);
  const [isManageSectionsOpen, setIsManageSectionsOpen] =
    useState<boolean>(false);

  const [highlightedSection, setHighlightedSection] = useState<number | null>(
    null
  );
  const [sections, setSections] = useState(template?.sections || []);
  const [tempSections, setTempSections] = useState(template?.sections || []);
  const [didInitialize, setDidInitialize] = useState<boolean>(false);

  const reducedSections = useMemo(
    () =>
      sections.map((section, index) => ({
        id: section.id || `section_${index + 1}`,
        title: section.section_name || `Section ${index + 1}`
      })),
    [sections]
  );

  useEffect(() => {
    if (template?.sections) {
      setSections(template.sections);
    }
  }, [template?.sections]);

  useEffect(() => {
    if (template?.isNew === true) {
      updateDocument({ ...template, isNew: false });
    }
  }, [template?.isNew]);

  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (template?.sections && !didInitialize) {
      // Set sections for the main view
      setSections(template.sections);
      dispatch(setSelectedTemplate(template as CustomTemplate));
      setDidInitialize(true);

      // Only update tempSections if it hasn't been set already
      setTempSections((prevTempSections: any) => {
        if (prevTempSections.length === 0) {
          return template.sections;
        }
        return prevTempSections; // Keep the current tempSections if already set
      });

      // Expand all sections by default and highlight the first one
      const allSections = template?.sections.map((_, index) => index);
      setExpandedSections(allSections);
      setHighlightedSection(allSections.length > 0 ? 0 : null);
    }
  }, [template]);

  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    listenAndFetch();
    return () => {
      // Cleanup on unmount
      dispatch(setSelectedTemplate(undefined));
    };
  }, []);

  // 2. Only set local state the first time doc is non-null
  useEffect(() => {
    if (!didInitialize && template) {
      setTemplateName(template?.templateName);
      setTempTemplateName(template?.templateName);
      setDidInitialize(true);
    }
  }, [template, didInitialize]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = sectionRefs.current.indexOf(
            entry.target as HTMLDivElement
          );

          if (entry.isIntersecting) {
            if (expandedSections.includes(index)) {
              setHighlightedSection(index); // Highlight only if expanded
            }
          } else if (highlightedSection === index) {
            setHighlightedSection(null); // Remove highlight if not visible
          }
        });
      },
      { threshold: 0.1 }
    );

    sectionRefs.current.forEach((section) => {
      if (section) observer.current?.observe(section);
    });

    return () => {
      observer.current?.disconnect();
    };
  }, [expandedSections]);

  const toggleSectionFromHeader = (index: number) => {
    setExpandedSections((prevExpanded: number[]) => {
      const isExpanded = prevExpanded.includes(index);

      if (!isExpanded) {
        setHighlightedSection(index); // Highlight if expanding
      } else {
        setHighlightedSection(null); // Remove highlight if collapsing
      }

      return isExpanded
        ? prevExpanded.filter((i) => i !== index) // Collapse if already expanded
        : [...prevExpanded, index]; // Expand if not expanded
    });
    const editorElements = window.document.querySelectorAll(".ql-editor");
    const editorElement = editorElements[index];
    if (editorElement) {
      /* eslint no-underscore-dangle: 0 */
      const quillInstance = (editorElement.parentNode as any)?.__quill;
      if (quillInstance) {
        // hacky way to blur the quill editor
        // will be implemented in a better way along with the refactor in CB-2010
        quillInstance.focus({ preventScroll: true });
        quillInstance.blur();
      }
    }
  };

  const expandSectionFromTable = (index: number) => {
    setExpandedSections((prevExpanded) =>
      prevExpanded.includes(index) ? prevExpanded : [...prevExpanded, index]
    );

    setHighlightedSection(index);

    setTimeout(() => {
      sectionRefs.current[index]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }, 50);
  };

  const downloadFullDraft = async (format: string) => {
    setIsDownloadingFullDraft(true);
    try {
      const { data, filename } = await convertFromHtml({
        format,
        individualId: "",
        firebasePath: `${DATABASE.CUSTOM_TEMPLATES}/${templateId}`,
        documentField: "",
        htmlField: "sections_after_review_html"
      });
      triggerDownload(data, filename, format);
    } catch (error) {
      console.error(`Error generating ${format} file:`, error);
    } finally {
      setIsDownloadingFullDraft(false);
    }
  };

  const setSectionRef = useCallback(
    (index: number) => (el: HTMLDivElement | null) => {
      sectionRefs.current[index] = el;

      // Observe only if the element is available and observer is initialized
      if (el && observer.current) {
        observer.current.observe(el);
      }
    },
    []
  );

  const handleSave = async (
    html: string,
    plainText: string,
    sectionIndex: number
  ) => {
    if (!sections || !template) return;

    const updatedSections = sections.map((section, idx) =>
      idx === sectionIndex
        ? {
            ...section,
            sections_after_review_html: html,
            sections_after_review: plainText
          }
        : section
    );

    const updatedTempSections = tempSections.map((section, idx) =>
      idx === sectionIndex
        ? {
            ...section,
            sections_after_review_html: html,
            sections_after_review: plainText
          }
        : section
    );

    // Update local state so subsequent saves are based on the latest state
    setSections(updatedSections);
    setTempSections(updatedTempSections);

    // Dispatch Redux update
    if (templateId) {
      dispatch(
        updateTemplateById({
          id: templateId,
          updates: { sections: updatedSections }
        })
      );
    }

    // Persist changes to Firestore
    try {
      await updateDocument({ ...template, sections: updatedSections });
    } catch (error: any) {
      toast({
        title: "Error saving section",
        description: error.message || "An error occurred while saving.",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  };

  const handleSaveAll = async () => {
    if (!template) return;

    // Dispatch Redux update
    if (templateId) {
      dispatch(
        updateTemplateById({
          id: templateId,
          updates: { sections: tempSections }
        })
      );
    }

    // Persist to Firestore
    try {
      await updateDocument({
        ...template,
        templateName,
        sections: tempSections
      });
      toast({
        title: "All changes saved successfully",
        status: "success",
        duration: 3000,
        isClosable: true
      });
      navigate("/studio/templates");
    } catch (error: any) {
      toast({
        title: "Error saving all changes",
        description: error.message || "An error occurred while saving.",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  };

  const handleSaveTitle = async (index: number, value: string) => {
    if (!sections || !template) {
      console.warn("No sections or document available. Aborting.");
      return;
    }

    const updatedSections = sections.map((section, idx) =>
      idx === index ? { ...section, section_name: value } : section
    );

    // Dispatch Redux update
    if (templateId) {
      dispatch(
        updateTemplateById({
          id: templateId,
          updates: { sections: updatedSections }
        })
      );
    }

    try {
      await updateDocument({ ...template, sections: updatedSections });
      toast({
        title: "Section title updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true
      });
    } catch (error: any) {
      console.error("[handleSaveTitle] Error saving to Firestore:", error);
      toast({
        title: "Error updating section title",
        description: error.message || "An error occurred while updating.",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  };

  const handleSaveSortedSections = (updatedSections: SortableSection[]) => {
    const newSections = updatedSections.map((updatedSection) => {
      const original = sections.find((sec) => sec.id === updatedSection.id);
      return original
        ? { ...original, section_name: updatedSection.title }
        : {
            id: String(updatedSection.id),
            section_name: updatedSection.title,
            html: "",
            text: "",
            display_name: "",
            sections_after_review: "",
            sections_after_review_html: ""
          };
    });

    setSections(newSections);
    setTempSections(newSections);

    // Dispatch Redux update
    if (templateId) {
      dispatch(
        updateTemplateById({
          id: templateId,
          updates: { sections: newSections }
        })
      );
    }

    updateDocument({ ...template, sections: newSections })
      .then(() => {
        toast({
          title: "Sections updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true
        });
      })
      .catch((error) => {
        toast({
          title: "Error updating sections",
          description:
            error.message || "An error occurred while updating sections.",
          status: "error",
          duration: 3000,
          isClosable: true
        });
      });
  };

  const toggleAllSections = () => {
    if (areAllExpanded) {
      setExpandedSections([]); // Collapse all
    } else {
      setExpandedSections(sections.map((_, index) => index)); // Expand all
    }
    setAreAllExpanded(!areAllExpanded);
  };

  const handleEditNameCancel = () => {
    setTempTemplateName(templateName);
  };

  const handleEditNameApply = async () => {
    setTemplateName(tempTemplateName);
    // Dispatch Redux update
    if (templateId) {
      dispatch(
        updateTemplateById({
          id: templateId,
          updates: { templateName: tempTemplateName }
        })
      );
    }

    // Persist changes to Firestore
    try {
      await updateDocument({ ...template, templateName: tempTemplateName });
    } catch (error: any) {
      toast({
        title: "Error saving section",
        description: error.message || "An error occurred while saving.",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Flex justifyContent="center">
          <CircularProgress isIndeterminate color="primary.blue" />
        </Flex>
      );
    }

    if (!template?.sections?.length) {
      return (
        <Box fontSize="18px" textAlign="center">
          No sections available. Add sections to your template.
        </Box>
      );
    }

    return (
      <Grid templateColumns="14vw 1fr" gap={6} width="100%">
        {/* Sidebar Navigation */}
        <Box
          p={4}
          rounded="md"
          position="sticky"
          top="20px"
          height="fit-content"
        >
          <Text fontWeight="bold" mb={4} color="text.display.light">
            Contents
          </Text>
          <Box>
            {sections.map((section, index) => {
              const isSelected =
                highlightedSection === index &&
                expandedSections.includes(index);

              return (
                <Box
                  key={index}
                  cursor="pointer"
                  color="primary.blue"
                  fontWeight={500}
                  fontSize="md"
                  mb={2}
                  px={3}
                  py={1}
                  borderRadius={
                    (section?.section_name?.length ?? 0) > 20 ? "md" : "full"
                  }
                  bg={isSelected ? "primary.focusOutline" : "transparent"}
                  onClick={() => expandSectionFromTable(index)}
                  listStyleType="inherit"
                >
                  {section.section_name || `Section ${index + 1}`}
                </Box>
              );
            })}
          </Box>
        </Box>

        {/* Main Content */}
        <Box>
          <Flex flexDirection="column">
            <Box mb={2} justifyContent="start">
              <SectionContainer title="Template Name">
                <HStack
                  spacing={2}
                  flex="1"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Input
                    borderRadius="md"
                    value={tempTemplateName}
                    onChange={(e) => setTempTemplateName(e.target.value)}
                    size="sm"
                    flex="1"
                    onClick={(e) => e.stopPropagation()}
                  />
                  {nameHasChanges && (
                    <>
                      <Button
                        size="sm"
                        variant="tertiaryText"
                        onClick={handleEditNameCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        variant="secondaryOutline"
                        onClick={handleEditNameApply}
                      >
                        Apply
                      </Button>
                    </>
                  )}
                </HStack>
              </SectionContainer>
            </Box>
            <Flex justifyContent="space-between" className="my-4">
              <Button
                size="sm"
                px={0}
                variant="tertiaryText"
                color="primary.blue"
                onClick={toggleAllSections}
                leftIcon={
                  areAllExpanded ? (
                    <MdUnfoldLess size={24} />
                  ) : (
                    <MdOutlineUnfoldMore size={24} />
                  )
                }
              >
                {areAllExpanded ? "Collapse All" : "Expand All"}
              </Button>
              <Button
                size="sm"
                px={0}
                variant="tertiaryText"
                color="primary.blue"
                onClick={() => {
                  setIsManageSectionsOpen(true);
                }}
                leftIcon={<MdOutlineSettings size={22} />}
              >
                Manage Sections
              </Button>
            </Flex>
            <Flex flexDirection="column" rowGap={4}>
              {sections.map((section, index) => (
                <SectionContainer
                  onSaveTitle={(value: string) => handleSaveTitle(index, value)}
                  isTitleEditable
                  ref={setSectionRef(index)}
                  key={index}
                  title={section.section_name || `Section ${index + 1}`}
                  isExpanded={expandedSections.includes(index)} // Nullable
                  onToggle={() => toggleSectionFromHeader(index)} // Pass only when controlled
                >
                  <Editor
                    key={index}
                    text={
                      sections[index].sections_after_review_html ||
                      sections[index].sections_after_review ||
                      ""
                    }
                    saveText={(html, plainText) =>
                      handleSave(html, plainText, index)
                    }
                    editorId={`editor-${index}`}
                    customHeight="auto"
                    shouldShowSaveBtn={false}
                  />
                </SectionContainer>
              ))}
            </Flex>
          </Flex>
        </Box>
      </Grid>
    );
  };

  return (
    <SectionWrapper backTargetPage="Templates" bg="background.white">
      <Flex flexDirection="column" rowGap="20px" alignItems="center">
        <Flex justifyContent="space-between" width="100%">
          <SpecialHeading title="Edit Template" topTitle="CaseBlink Studio" />
          <Flex justifyContent="end" alignItems="center">
            <Button
              variant="tertiaryText"
              color="primary.blue"
              size="sm"
              onClick={() => {
                if (template?.docUrl) openFilePreview(template.docUrl);
              }}
            >
              <ExternalLinkIcon
                boxSize={5}
                color="primary.blue"
                className="cursor-pointer"
                mr={2}
              />
              View original case sample
            </Button>
            {isDownloadingFullDraft ? (
              <CircularProgress isIndeterminate color="primary.blue" />
            ) : (
              <ExportMenu
                isDownloading={false}
                handleDownload={downloadFullDraft}
                leftIcon={<DownloadIcon />}
                text="Download full draft"
              />
            )}
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          flexDir="column"
          w="full"
          h="full"
          rowGap={4}
          mt={4}
        >
          {renderContent()}
        </Flex>

        <Flex justifyContent="end" w="100%" mt={4} gap={4}>
          <Button
            onClick={handleSaveAll}
            variant="primaryFilled"
            isLoading={loading}
          >
            Save and Go to Templates
          </Button>
        </Flex>
      </Flex>
      <ManageSectionsModal
        isOpen={isManageSectionsOpen}
        onClose={() => {
          setIsManageSectionsOpen(false);
        }}
        initialSections={reducedSections}
        onSave={handleSaveSortedSections}
      />
    </SectionWrapper>
  );
};

export default EditTemplate;
