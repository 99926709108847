import { useEffect, useRef } from "react";
import { store, useTypedDispatch } from "../redux/store";
import { generateSingleDocumentUrl } from "../redux/documents/documentsThunk";

interface RetryItem {
  id: string;
  attempts: number;
  timeoutId?: NodeJS.Timeout;
}

const MAX_RETRIES = 5;
const INITIAL_DELAY = 2000;
const MAX_DELAY = 30000;

export const useDocumentUrlRetry = () => {
  const retryMap = useRef<Map<string, RetryItem>>(new Map());
  const dispatch = useTypedDispatch();

  const clearRetry = (id: string) => {
    const item = retryMap.current.get(id);
    if (item?.timeoutId) {
      clearTimeout(item.timeoutId);
    }
    retryMap.current.delete(id);
  };

  const scheduleRetry = (documentId: string) => {
    const currentItem = retryMap.current.get(documentId) || {
      id: documentId,
      attempts: 0
    };

    if (currentItem.attempts >= MAX_RETRIES) {
      clearRetry(documentId);
      return;
    }

    const delay = Math.min(INITIAL_DELAY ** currentItem.attempts, MAX_DELAY);

    const timeoutId = setTimeout(() => {
      const state = store.getState();
      const document = state.document.groupedDocuments
        ?.flatMap(
          (group) => group.groups?.flatMap((subGroup) => subGroup.subrows) ?? []
        )
        ?.find((doc) => doc?.id === documentId);

      if (document?.pdf_file_path) {
        dispatch(
          generateSingleDocumentUrl({
            filePath: document.pdf_file_path,
            id: documentId,
            super_class: document.super_class!,
            criterion: document.criterion!
          })
        );
        clearRetry(documentId);
      } else {
        currentItem.attempts += 1;
        scheduleRetry(documentId);
      }
    }, delay);

    retryMap.current.set(documentId, { ...currentItem, timeoutId });
  };

  useEffect(() => {
    return () => {
      retryMap.current.forEach((item) => {
        if (item.timeoutId) {
          clearTimeout(item.timeoutId);
        }
      });
    };
  }, []);

  return { scheduleRetry, clearRetry };
};
