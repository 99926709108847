import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Tooltip,
  VStack
} from "@chakra-ui/react";
import {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { getShortString } from "../../helpers/string_helpers";
import DebouncedSearchInput from "./SearchInput";

/**
 * ---------------------------------- Types ----------------------------------
 */
type BaseProps<T> = {
  children?: (props: {
    handleSelect: (item: CheckedItem<T>) => void;
    derivedList: CheckedItem<T>[];
  }) => ReactNode;

  label?: string;
  withArrow?: boolean;
  list: T[];
  isDisabled?: boolean;
  withToolTip?: boolean;
  withFilter?: boolean;
  /**
   * The field you want to search/filter on.
   * If T is just a string array, pass `searchField={undefined}`
   */
  searchField?: keyof T;
  /**
   * The field used for uniqueness. Typically some `id` or `slug` in T.
   * If T is a string array, pass `mergingField={undefined}`
   */
  mergingField?: keyof T;
  /**
   * Field to group items. E.g., `status` or `category` in T.
   */
  groupBy?: keyof T;
  /**
   * The items that should initially be "checked".
   * You can pass their IDs or strings.
   */
  checkedItems?: string[] | null | undefined;
  disabledItems?: string[] | null | undefined;
  withPlusIcon?: boolean;
  width?: number;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  useFullWidth?: boolean;
};

type MultiSelectProps<T> = BaseProps<T> & {
  singleSelect: false;
  getSelectedItems: (items: T[]) => void;
  withCheck?: boolean;
  withView?: boolean;
};

type SingleSelectProps<T> = BaseProps<T> & {
  singleSelect: true;
  getSelectedItem: (item: T) => void;
  withCheck?: never;
  withView?: never;
};

type Props<T> = MultiSelectProps<T> | SingleSelectProps<T>;

type CheckedItem<T> = {
  /**
   * This is the displayed text
   */
  value: string;
  /**
   * This is used as the unique DnD/sortable ID
   */
  id: string;
  disabled?: boolean;
  /**
   * Whether or not the item is currently checked
   */
  checked: boolean;
} & T;

/**
 * --------------------------- Dropdown Component ----------------------------
 */
export const DropdownGeneric = <T,>({
  label,
  singleSelect,
  list,
  isDisabled,
  withToolTip = false,
  withFilter = true,
  withArrow = false,
  searchField,
  mergingField,
  groupBy,
  checkedItems,
  disabledItems,
  withPlusIcon = false,
  width,
  onMouseDown,
  useFullWidth = false,
  children,
  ...rest
}: Props<T>) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedItem, setSelectedItem] = useState<CheckedItem<T>>();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const derivedList = useMemo(() => {
    return list
      .map<CheckedItem<T>>((element, i) => ({
        ...element,
        checked: checkedItems?.includes(getUniqueId(element)) || false,
        disabled: disabledItems?.includes(getUniqueId(element)) || false,
        value: getDisplayValue(element),
        id: getUniqueId(element),
        stableIndex: i + 1
      }))
      .filter((item) => !!item.value);
  }, [list, checkedItems, disabledItems]);

  useEffect(() => {
    console.log({ derivedList });
  }, [derivedList]);

  // For single-select usage:
  //   1) notify parent once selectedItem changes
  useEffect(() => {
    if (singleSelect && "getSelectedItem" in rest && selectedItem) {
      rest.getSelectedItem(selectedItem);
    }
  }, [selectedItem]);

  function getUniqueId(item: T): string {
    if (typeof item === "string") return item; // fallback if array of strings
    if (mergingField) return String(item[mergingField]);
    return "";
  }

  function getDisplayValue(item: T): string {
    if (typeof item === "string") return item;
    if (searchField && item[searchField] != null) {
      const rawValue = item[searchField];
      return typeof rawValue === "string" ? rawValue : String(rawValue);
    }
    return "";
  }

  // Single or Multi select
  const handleSelect = (item: CheckedItem<T>) => {
    if (item.disabled) return;

    if (singleSelect) {
      setSelectedItem(item);
      setMenuIsOpen(false);
    } else if ("getSelectedItems" in rest) {
      const newChecked = item.checked
        ? checkedItems?.filter((id) => id !== item.id)
        : [...(checkedItems || []), item.id];

      const updatedDocs = list.filter((doc) =>
        newChecked?.includes(getUniqueId(doc))
      );

      rest.getSelectedItems(updatedDocs);
    }
  };

  // Filter the list (only for displayed search results)
  const filteredList = derivedList.filter((item) =>
    item.value.toLowerCase().includes(filter.toLowerCase())
  );

  // Group them if needed
  const groupItems = (items: CheckedItem<T>[]) => {
    if (!groupBy) return { "": items };
    return items.reduce<Record<string, CheckedItem<T>[]>>((groups, item) => {
      const key = String(item[groupBy]) || "Uncategorized";
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
      return groups;
    }, {});
  };
  const groupedItems = groupItems(filteredList);

  // For search input
  const handleDebouncedChange = useCallback((value: string) => {
    setFilter(value);
  }, []);

  return (
    <Flex
      className="dropdown-generic-container"
      tabIndex={0}
      flexDirection="column"
      gap={4}
      {...(useFullWidth ? { width: "100%" } : { flexGrow: 1, flexShrink: 1 })}
      onMouseDown={onMouseDown}
    >
      <Box mb={2} display="flex">
        <Popover
          placement="bottom-start"
          isOpen={menuIsOpen}
          onClose={() => setMenuIsOpen(false)}
          closeOnBlur
          closeOnEsc={false}
        >
          <Flex flexDirection="column" flexGrow="1" flexShrink="1">
            <PopoverTrigger>
              <Button
                textAlign="left"
                justifyContent="space-between"
                w="100%"
                px={4}
                bg="white"
                isDisabled={isDisabled}
                leftIcon={withPlusIcon ? <AddIcon /> : undefined}
                variant="add"
                borderRadius="md"
                width={width}
                onClick={() => setMenuIsOpen((prev) => !prev)}
                rightIcon={withArrow ? <ChevronDownIcon /> : undefined}
              >
                {singleSelect && selectedItem
                  ? selectedItem.value
                  : label || "Select"}
              </Button>
            </PopoverTrigger>
            <PopoverContent
              width="30vw"
              maxHeight="50vh"
              overflow="auto"
              m={0}
              onMouseDown={(e) => e.stopPropagation()}
              ref={popoverRef}
            >
              <PopoverBody display="flex" flexDirection="column">
                {withFilter && (
                  <DebouncedSearchInput
                    ref={searchInputRef}
                    initialValue={filter}
                    onDebouncedChange={handleDebouncedChange}
                    onMouseDown={onMouseDown}
                  />
                )}
                {/* Render grouped items */}
                {Object.keys(groupedItems).map((groupKey) => (
                  <Box key={groupKey}>
                    {groupKey && groupKey !== "" && (
                      <Text fontWeight="bold" px={4} py={2} color="black">
                        {groupKey}
                      </Text>
                    )}
                    {groupedItems[groupKey].map((item, index) => (
                      <Box
                        key={`${item.id}-${index}`}
                        cursor="pointer"
                        p={2}
                        _hover={{ bg: "primary.focusOutline" }}
                        onClick={() => handleSelect(item)}
                      >
                        {"withCheck" in rest && rest.withCheck ? (
                          <Checkbox
                            pointerEvents="none"
                            isChecked={item.checked}
                            isDisabled={item.disabled}
                          >
                            {withToolTip ? (
                              <Tooltip label={item.value}>
                                {getShortString(item.value)}
                              </Tooltip>
                            ) : (
                              <Text color="black">{item.value}</Text>
                            )}
                          </Checkbox>
                        ) : (
                          <Text color="black">{item.value}</Text>
                        )}
                      </Box>
                    ))}
                  </Box>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Flex>
        </Popover>
      </Box>

      {/* If multi-select and without shuffle */}
      {!singleSelect && rest.withView && (
        <VStack align="stretch" spacing={3} maxH="md" overflowY="auto">
          {derivedList
            .filter((item) => item.checked)
            .map((item) => (
              <Flex key={item.id} align="flex-start" width="100%">
                <Tooltip label={item.value}>
                  <Tag size="lg" variant="brand" maxW="600px" overflow="hidden">
                    <TagLabel>{item.value}</TagLabel>
                    <TagCloseButton
                      onClick={(e) => {
                        e.stopPropagation(); // Don’t trigger drag
                        handleSelect(item);
                      }}
                    />
                  </Tag>
                </Tooltip>
              </Flex>
            ))}
        </VStack>
      )}

      {children && children({ handleSelect, derivedList })}
    </Flex>
  );
};
