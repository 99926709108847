import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CaseResearchTopic } from "../../types/case-research";

export interface CaseResearchState {
  topics: CaseResearchTopic[];
  extractedData?: ExtractedResearchData | null;
}

// Define the initial state
const initialState: CaseResearchState = {
  topics: [],
  extractedData: null
};

// Define the interface for the reducer
export interface CaseResearchReducer {
  caseResearch: CaseResearchState;
}

export interface ExtractedResearchData {
  summary: string;
  summaryHtml: string;
}

// Create the case research slice
export const caseResearchSlice = createSlice({
  name: "caseResearch",
  initialState,
  reducers: {
    addResearchTopic: (state, action: PayloadAction<CaseResearchTopic>) => {
      state.topics.push(action.payload);
    },
    updateResearchTopic: (state, action: PayloadAction<CaseResearchTopic>) => {
      const { id } = action.payload;
      const index = state.topics.findIndex((topic) => topic.id === id);
      if (index !== -1) {
        state.topics[index] = { ...state.topics[index], ...action.payload };
      }
    },
    removeResearchTopic: (state, action: PayloadAction<string>) => {
      state.topics = state.topics.filter(
        (topic) => topic.id !== action.payload
      );
    },
    setResearchTopics: (state, action: PayloadAction<CaseResearchTopic[]>) => {
      state.topics = action.payload;
    },
    setExtractedData: (
      state,
      action: PayloadAction<ExtractedResearchData | null>
    ) => {
      state.extractedData = action.payload;
    },
    removeAllGeneratedResearch: (state) => {
      state.topics.forEach((topic) => {
        topic.summary = "";
      });
    },
    updateTopicSummary: (state, action) => {
      const { topicId, summary, summaryHtml } = action.payload;
      const topic = state.topics.find((t) => t.id === topicId);
      if (topic) {
        topic.summary = summary;
        topic.summaryHtml = summaryHtml;
      }
    },
    clearCaseResearch: (state) => {
      state.topics = [];
      state.extractedData = null;
    }
  }
});

// Export the actions
export const {
  updateTopicSummary,
  addResearchTopic,
  updateResearchTopic,
  removeResearchTopic,
  setResearchTopics,
  setExtractedData,
  removeAllGeneratedResearch,
  clearCaseResearch
} = caseResearchSlice.actions;

// Export the reducer
export default caseResearchSlice.reducer;
