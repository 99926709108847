import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
  Text
} from "@chakra-ui/react";
import _ from "lodash";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import useFileActions from "../../../hooks/useFileActions";
import { documentSelectors } from "../../../redux/documents/selectors";
import { DATABASE, visaFromPath, VISAVALUE } from "../../../types/tables-data";
import { Dropdown } from "../../common/Dropdown";
// eslint-disable-next-line import/no-cycle
import { UploadFilesPlaceholder } from "../../UploadFilesPlaceholder";
import UploadList from "./UploadList";
import { TableRef } from "../../../types/document";
import { VisaDocumentType } from "../../../redux/documents/types";
import { DropdownGeneric } from "../../common/DropdownGeneric";
import { getDocumentTypesByCategory } from "../../../data/tables-data";
import { mapVisaTypeToPath } from "../../../helpers/helpers";
import { SelectedItem } from "../../../types/dropdown.types";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  storagePath: string;
  collectionPath: string;
  visaDocumentType: VisaDocumentType;
  selectedCategoryWithSuperclass?: Record<string, string> | undefined;
}

const UploadByCategoryModal: React.FC<Props> = ({
  isOpen,
  onClose,
  storagePath,
  collectionPath,
  visaDocumentType,
  selectedCategoryWithSuperclass
}) => {
  const { id, visaType } = useParams();
  const { handleFileActions } = useFileActions();
  const documentTypes = useSelector(documentSelectors.documentTypes);

  const reduceToSelectedItems = (
    data: any,
    visaType: string
  ): SelectedItem[] => {
    if (!data) return [];
    const visaTypeForMap = visaFromPath[visaType];

    const result: SelectedItem[] = [];
    const keys = Object.keys(data);

    keys.forEach((key) => {
      if (data[key] && data[key][visaTypeForMap]) {
        Object.keys(data[key][visaTypeForMap]).forEach((category) => {
          result.push({
            super_class: key,
            category
          });
        });
      }
    });

    return result;
  };

  const [selectedCategory, setSelectedCategory] = useState<
    SelectedItem | undefined
  >(undefined);

  const list: SelectedItem[] = reduceToSelectedItems(documentTypes, visaType!);

  function getDocumentTypesBySelectedItem(item: SelectedItem | undefined) {
    if (!item || !documentTypes || !visaType) return [];
    const selectedSuperClass = item.super_class;
    const mappedVisa = visaFromPath[visaType!];

    return documentTypes[selectedSuperClass][mappedVisa!][item.category];
  }

  const types = useMemo(
    () => getDocumentTypesBySelectedItem(selectedCategory),
    [selectedCategory]
  );

  const [selectedType, setSelectedType] = useState<string | undefined>(
    undefined
  );

  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedCategory(undefined);
      setSelectedType(undefined);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedCategory) {
      setSelectedType(undefined);
    }
  }, [selectedCategory]);

  const handleTypeChange = (type: string) => {
    setSelectedType(type);
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const isFilesListEmpty = files.length === 0;
  const isCategoryEmpty = selectedCategory === undefined;

  const handleUploadFiles = async () => {
    if (!storagePath || !collectionPath) return;
    handleFileActions({
      files,
      path: storagePath,
      collectionPath,
      uid: id!,
      category: selectedCategory?.category,
      type: selectedType,
      super_class: selectedCategory?.super_class
      // visaDocumentType: undefined!
    });
    onClose();
  };

  const onCloseComplete = () => {
    console.log("Modal closed, resetting files.");
    setFiles([]); // Reset files only when the modal is fully closed
  };

  useEffect(() => {
    if (selectedCategoryWithSuperclass !== undefined) {
      setSelectedCategory({
        category: selectedCategoryWithSuperclass.category,
        super_class: selectedCategoryWithSuperclass.superClass
      });
    }
  }, [selectedCategoryWithSuperclass]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} onCloseComplete={onCloseComplete}>
      <ModalOverlay />
      <ModalContent minWidth={files.length > 0 ? "66vw" : "46vw"}>
        <ModalHeader>Upload by category</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            columnGap={files.length > 0 ? 8 : 0}
            justifyContent="start"
            alignItems="start"
          >
            <UploadList files={files} onRemove={handleRemoveFile} />

            <VStack spacing={6} align="stretch">
              <Text>
                Upload documents into a specific category. You can select the
                document type manually or let the AI classifier handle it for
                you.
              </Text>

              <FormControl>
                <FormLabel>Category</FormLabel>
                <DropdownGeneric
                  withArrow
                  singleSelect
                  label={
                    selectedCategory !== undefined
                      ? selectedCategory.category
                      : "Select Category"
                  }
                  getSelectedItem={(item) => {
                    if (item !== undefined) setSelectedCategory(item);
                  }}
                  groupBy="super_class"
                  mergingField="super_class"
                  searchField="category"
                  list={list}
                />
              </FormControl>

              {selectedCategory && types.length > 0 && (
                <FormControl>
                  <FormLabel>Document Type (Optional)</FormLabel>
                  <Dropdown
                    list={types}
                    title="Select Type"
                    selectedValue={selectedType}
                    onItemSelect={handleTypeChange}
                  />
                </FormControl>
              )}

              <UploadFilesPlaceholder
                styleIndex={1}
                title="Drag & Drop here"
                subTitle="Browse files"
                onUpload={(fileList: FileList | null) => {
                  if (fileList) {
                    const newFiles = Array.from(fileList);
                    setFiles((prev) => [...prev, ...newFiles]);
                  }
                }}
              />
            </VStack>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="neutralStateButton" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={isCategoryEmpty || isFilesListEmpty}
            variant="primaryFilled"
            ml={3}
            onClick={handleUploadFiles}
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadByCategoryModal;
