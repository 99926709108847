import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react";
import {
  collection,
  doc,
  DocumentData,
  DocumentReference
} from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Form, useParams } from "react-router-dom";
import { db } from "../../../../api/firebaseApi";
import {
  downloadFileAsBlob,
  getFileName,
  handleUnsupportedExtension
} from "../../../../helpers/file_helpers";
import { getNextDocumentName } from "../../../../helpers/string_helpers";
import { useAddFileToDB } from "../../../../hooks/useAddFileToDB";
import { useDeleteFileFromDB } from "../../../../hooks/useDeleteFileFromDB";
import useFileActions from "../../../../hooks/useFileActions";
import useFirestoreCollection from "../../../../hooks/useFirestoreCollection";
import { useGetFilesFromDB } from "../../../../hooks/useGetFilesFromDB";
import { useUpdateIndivFormsData } from "../../../../hooks/useUpdateIndivFormsData";
import { individualSelectors } from "../../../../redux/individual/selectors";
import { lawyerSelectors } from "../../../../redux/lawyer/selectors";
import { templatesSelectors } from "../../../../redux/templates/selectors";
import { TemplatesReducer } from "../../../../redux/templates/templatesSlice";
import {
  LegalBriefLength,
  LegalBriefType,
  VisaLegalBriefConfig
} from "../../../../types/drafts.type";
import { DATABASE, VISAVALUE } from "../../../../types/tables-data";
import { CustomBox } from "../../../common/CustomBox";
import { Dropdown } from "../../../common/Dropdown";
import { UploadFilesPlaceholder } from "../../../UploadFilesPlaceholder";
import { templatesWarningMsg } from "../../../../consts/strings";
import {
  CustomTemplate,
  CustomTemplateType,
  TemplateOption,
  TemplateOptionKey,
  templateOptions
} from "../../../../types/studio/templates";

type Props = {
  onCancel: () => void;
  onConfirm: (
    templatePath?: string | undefined,
    length?: string | undefined,
    fromPreviousCase?: boolean | undefined,
    legalBriefType?: LegalBriefType | undefined
  ) => void;
  isOpen: boolean;
};

const WarningBox = ({ message }: { message: string }) => (
  <Box bg="colors.errors.red2">
    <Text color="gray.500" fontSize="sm">
      {message}
    </Text>
  </Box>
);

const GenerateLegalBriefModal = ({ onCancel, onConfirm, isOpen }: Props) => {
  const { visaType, id } = useParams();
  const { uid: userId } = useSelector(individualSelectors.selectAll);
  const uid = id || userId;
  const lawyerId = useSelector(lawyerSelectors.selectUid);
  const firstName = useSelector(lawyerSelectors.selectFirstName);
  const lastName = useSelector(lawyerSelectors.selectLastName);
  const colRef = collection(db, DATABASE.CUSTOM_TEMPLATES);
  useFirestoreCollection(colRef?.path as CustomTemplateType, {
    listen: true,
    fetchFiles: false,
    withThumbnails: false,
    useLawyerAccessMap: true
  });
  const { handleFileActions } = useFileActions();
  const { deleteFolderContents } = useDeleteFileFromDB();
  const { onSubmitAddUpdateLetter, isLoadingUpdateFormsData } =
    useUpdateIndivFormsData(uid);

  const storagePath = `${DATABASE.LAWYERS}/documents/${lawyerId}`;

  const templateTypes: CustomTemplateType[] = useMemo(() => {
    const types: CustomTemplateType[] = [
      "legal_brief_templates",
      "support_letters_templates"
    ];
    return types;
  }, [visaType]);

  const customBriefTemplates = useSelector((state: TemplatesReducer) =>
    templatesSelectors.selectTemplatesByOptions(
      state,
      (visaType as VISAVALUE) ?? "",
      templateTypes
    )
  );

  // Needed to generate next template name
  const customTemplates = useSelector(templatesSelectors.selectAll);

  const [generatedTemplateId, setGeneratedTemplateId] = useState<string | null>(
    null
  );
  const [docRef, setDocRef] = useState<DocumentReference<DocumentData> | null>(
    null
  );
  const [selectedTemplateOption, setSelectedTemplateOption] = useState<
    TemplateOption | undefined
  >(templateOptions[0]);

  const [selectedTemplate, setSelectedTemplate] = useState<
    CustomTemplate | undefined
  >(undefined);

  // Option-specific states
  const [briefType, setBriefType] = useState<LegalBriefType>(
    LegalBriefType.LEGAL_BRIEF
  );
  const [briefLength, setBriefLength] = useState<LegalBriefLength | undefined>(
    undefined
  );
  const [existingTemplate, setExistingTemplate] = useState<string | null>(null);
  const [templateName, setTemplateName] = useState<string | undefined>(
    undefined
  );
  const [areaOfExpertise, setAreaOfExpertise] = useState<boolean>(false);
  const [employerDetails, setEmployerDetails] = useState<boolean>(false);
  // const isTemplateOption =
  //   selectedTemplateOption &&
  //   [
  //     templateOptions[1].key,
  //     templateOptions[2].key,
  //   ].includes(selectedTemplateOption.key);

  // const isTemplateOptionWithFile =
  //   selectedTemplateOption &&
  //   [templateOptions[2].key, templateOptions[3].key].includes(
  //     selectedTemplateOption.key
  //   );

  // File states
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const { onSubmitAddFile, fileRef } = useAddFileToDB();
  const { onSubmitGetFiles, storageFiles } = useGetFilesFromDB();

  const [fileName, setFileName] = useState<string | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  // Change handlers
  const handleBriefTypeChange = (value: LegalBriefType) => {
    setBriefType(value);
  };

  const handleBriefLengthChange = (value: LegalBriefLength) => {
    setBriefLength(value);
  };

  useMemo(() => {
    if (!isUploadingFile) setTemplateName(getNextDocumentName(customTemplates));
  }, [customBriefTemplates, isUploadingFile]);

  const generateDocumentId = async () => {
    if (!lawyerId || !colRef) {
      console.error("lawyerId is required to generate a document ID.");
      return null;
    }

    const docRef = doc(colRef);
    const generatedId = docRef.id;
    console.log("Generated document ID:", generatedId);

    setGeneratedTemplateId(generatedId);
    setDocRef(docRef);

    return generatedId;
  };

  const uploadFile = async (templateId: string, file: File) => {
    console.log("upload_testing: Upload started:", {
      templateId,
      fileName: file?.name
    });
    setIsUploadingFile(true);

    if (file && templateId) {
      try {
        const filePath = `${storagePath}/${templateId}`;
        console.log("upload_testing: File path set to:", filePath);

        // Delete any existing files at the specified path
        console.log(
          "upload_testing: Deleting existing files at path:",
          filePath
        );
        await deleteFolderContents(filePath);

        // Upload the new file
        console.log("upload_testing: Uploading file:", {
          fileName: file.name,
          targetPath: `${filePath}/${file.name}`
        });
        await onSubmitAddFile(file, `${filePath}/${file.name}`);

        // Fetch updated file list
        console.log(
          "upload_testing: Fetching updated file list for path:",
          filePath
        );
        await onSubmitGetFiles(filePath);
      } catch (e) {
        console.log("upload_testing: Error occurred during upload:", e);
      } finally {
        console.log("upload_testing: Upload process finalized");
        setIsUploadingFile(false);
        setIsLoadingDoc(false);
      }
    } else {
      console.log(
        "upload_testing: Missing file or templateId. Upload aborted."
      );
    }
  };

  // File handling
  const handleFileChange = async (
    e?: React.ChangeEvent<HTMLInputElement> | null,
    files?: File[] | null
  ) => {
    const templateId = generatedTemplateId || (await generateDocumentId());
    if (e?.target?.files?.length || files?.length) {
      const file = e?.target?.files?.[0] || files?.[0] || null;

      if (handleUnsupportedExtension(file?.name || "N/A", fileRef)) {
        return; // Exit if the file extension is unsupported
      }
      if (file && templateId) {
        setSelectedFile(file);
      }
    }
  };

  const handleConfirm = async () => {
    try {
      console.log(
        "[templates_testing] Handle confirm initiated, ",
        selectedTemplateOption
      );

      // Handle new template upload option
      // if (isTemplateOption) {
      //   const templateId = generatedTemplateId || selectedTemplate?.id;
      //   if (templateId) {
      //     console.log(
      //       "[templates_testing] Uploading file to storage for template ID:",
      //       templateId
      //     );

      //     if (selectedFile) await uploadFile(templateId, selectedFile);
      //     console.log(
      //       "[templates_testing] File uploaded successfully for template ID:",
      //       templateId
      //     );
      //   } else {
      //     console.log("[templates_testing] No generatedTemplateId found");
      //   }

      //   const filePath = `${storagePath}/${templateId}/${selectedFile?.name}`;

      //   // Construct info to retain array
      //   const templateInfoToRetain: string[] = [
      //     areaOfExpertise ? "area_of_expertise" : null,
      //     employerDetails ? "employer_details" : null
      //   ].filter((item): item is string => item !== null);

      //   console.log(
      //     "[templates_testing] Retaining the following template information:",
      //     templateInfoToRetain
      //   );

      //   const template: CustomTemplate = {
      //     templateName: templateName ?? "",
      //     created_at: Date.now(),
      //     uploadDate: Date.now(),
      //     uploadBy: lawyerId,
      //     uploadedByName: `${firstName} ${lastName}`,
      //     created_by: `${firstName} ${lastName}`,
      //     docNames: selectedFile?.name,
      //     filePath,
      //     isDeleted: false,
      //     templateInfoToRetain,
      //     visa: (visaType as VISAVALUE) ?? VISAVALUE.EMPTY,
      //     type: "legal_brief_templates",
      //     addedDuringGeneration: true,
      //     fromPreviousCase: selectedTemplateOption.key === "from_previous_case"
      //   };

      //   console.log("[templates_testing] Template data to submit:", template);

      //   await onSubmitAddUpdateLetter({
      //     values: template,
      //     docRef,
      //     docId: templateId
      //   });
      //   console.log("[templates_testing] Template submitted successfully");

      onConfirm(selectedTemplate?.path || "", undefined, undefined, briefType);
      onCancel();
      return;
      // }

      console.log(
        "[templates_testing] No new template uploaded, confirming default options"
      );
      onConfirm(undefined, briefLength);
    } catch (error) {
      console.error(
        "[templates_testing] Error occurred in handleConfirm:",
        error
      );
    }
  };

  useEffect(() => {
    // When isOpen changes to false, reset the states.
    if (!isOpen) {
      setGeneratedTemplateId(null);
      setSelectedTemplateOption(templateOptions[0]);
      setSelectedTemplate(undefined);
      setBriefType(LegalBriefType.LEGAL_BRIEF);
      setBriefLength(undefined);
      setExistingTemplate(null);
      // setTemplateName("");
      setAreaOfExpertise(false);
      setEmployerDetails(false);
      setSelectedFile(null);
    }
  }, [isOpen]);

  useEffect(() => {
    setIsLoadingDoc(true);
    if (storageFiles.length) {
      const lastFileRef = storageFiles[storageFiles.length - 1];
      downloadFileAsBlob(lastFileRef)
        .then((objectURL: string) => {
          setFileUrl(objectURL);
          setFileName(lastFileRef.name);
        })
        .finally(() => {
          setIsLoadingDoc(false);
        })
        .catch((error) => {
          console.error("Error downloading file as blob:", error);
          setIsLoadingDoc(false);
        });
    }
  }, [storageFiles]);

  const showBriefTypeSelection = visaType === VISAVALUE.O1A;

  // Render content based on selected template option
  const renderContentForTemplateOption = () => {
    switch (selectedTemplateOption?.key as TemplateOptionKey) {
      case "cb_template":
        return (
          <>
            {showBriefTypeSelection && (
              <FormControl>
                <FormLabel>Choose the brief type</FormLabel>
                <RadioGroup
                  onChange={(value: LegalBriefType) => setBriefType(value)}
                  value={briefType}
                >
                  <Stack direction="column">
                    {VisaLegalBriefConfig[
                      (visaType as VISAVALUE) || VISAVALUE.EMPTY
                    ].map((type) => (
                      <Radio key={type} value={type}>
                        {type}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
            {/* <FormControl>
              <FormLabel>Length (Optional)</FormLabel>
              <RadioGroup
                onChange={(value) =>
                  handleBriefLengthChange(value as LegalBriefLength)
                }
                value={briefLength}
              >
                <VStack direction="column" alignItems="start">
                  {Object.values(LegalBriefLength).map((length) => (
                    <Radio key={length} value={length}>
                      {length}
                    </Radio>
                  ))}
                </VStack>
              </RadioGroup>
            </FormControl> */}
          </>
        );
      case "existing_template":
        return (
          <>
            {customBriefTemplates.length > 0 ? (
              <FormControl>
                <FormLabel>Existing Templates</FormLabel>
                <Dropdown
                  type="custom_brief_templates"
                  selectedValue={selectedTemplate}
                  title="Select Template"
                  onItemSelect={(value: CustomTemplate) =>
                    setSelectedTemplate(value)
                  }
                  list={customBriefTemplates}
                />
              </FormControl>
            ) : (
              <>
                <Text fontWeight="bold">No templates found</Text>
                <Text fontSize="sm">
                  Upload new templates using the CaseBlink Studio sidebar. Your
                  uploaded templates will appear here for future use.
                </Text>
              </>
            )}
            <CustomBox type="softRed" fontSize="small">
              {templatesWarningMsg}
            </CustomBox>
          </>
        );
      case "new_template":
      case "from_previous_case":
        return (
          <VStack>
            <HStack justifyContent="center" m={2}>
              {" "}
              <CustomBox type="info">
                Visa Type: The template must be for {visaType} visa.
              </CustomBox>
            </HStack>

            <FormControl isRequired>
              <FormLabel>Template Name</FormLabel>
              <Input
                isRequired
                placeholder="Untitled Template"
                value={templateName || ""}
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>
                Information to maintain from the template (Optional)
              </FormLabel>
              <VStack alignItems="start">
                <Checkbox
                  isChecked={areaOfExpertise}
                  onChange={(e) => setAreaOfExpertise(e.target.checked)}
                >
                  Area of expertise
                </Checkbox>
                <Checkbox
                  isChecked={employerDetails}
                  onChange={(e) => setEmployerDetails(e.target.checked)}
                >
                  Employer details
                </Checkbox>
              </VStack>
            </FormControl>
            <FormControl mt={4}>
              <UploadFilesPlaceholder
                // isDisabled={isLoadingDoc}
                styleIndex={1}
                title="Drag and drop additional files to bundle with this document here"
                subTitle="Click Here to Upload"
                onUpload={(files) => {
                  const filesAsArray = Array.from(files!);
                  handleFileChange(null, filesAsArray);
                }}
                uploaded={selectedFile !== null}
                fileName={
                  selectedFile !== null
                    ? getFileName(selectedFile.name)
                    : undefined
                }
              />
            </FormControl>
            <CustomBox type="softRed" fontSize="small">
              {templatesWarningMsg}
            </CustomBox>
          </VStack>
        );
      default:
        return null;
    }
  };

  const isExistingTemplateOption =
    selectedTemplateOption?.key === templateOptions[1].key;

  const isGenerateButtonDisabled =
    !templateName ||
    templateName === "" ||
    !selectedTemplateOption ||
    // (isTemplateOptionWithFile && !selectedFile) ||
    (isExistingTemplateOption && !selectedTemplate);

  return (
    <Form>
      <Modal
        isOpen={isOpen}
        onClose={onCancel}
        size="lg"
        trapFocus={false}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generate Legal Brief</ModalHeader>
          <ModalBody w="100">
            <VStack alignItems="start" spacing={2} w="100%" gap={4}>
              <Box w="100">
                <FormControl w="100%">
                  <FormLabel>
                    Select how you would like to generate the legal brief
                  </FormLabel>
                  <Box w="100%">
                    <Dropdown
                      withSearch={false}
                      selectedValue={selectedTemplateOption}
                      title="Select"
                      onItemSelect={(value: TemplateOption) =>
                        setSelectedTemplateOption(value)
                      }
                      list={Object.values(templateOptions)}
                    />
                  </Box>
                </FormControl>
              </Box>
              {selectedTemplateOption && showBriefTypeSelection && <Divider />}
              {renderContentForTemplateOption()}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              onClick={() => {
                // setSelectedTemplateOption(undefined);
                onCancel();
              }}
              variant="secondaryOutline"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primaryFilled"
              onClick={handleConfirm}
              isLoading={isUploadingFile}
              isDisabled={isGenerateButtonDisabled}
            >
              Generate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Form>
  );
};

export default GenerateLegalBriefModal;
