import { useState } from "react";
import {
  FirestoreError,
  FirestoreErrorCode,
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  updateDoc
} from "firebase/firestore";
import { useSelector } from "react-redux";
import {
  IndividualState,
  UpdateIndividual
} from "../redux/individual/individualSlice";
import { individualSelectors } from "../redux/individual/selectors";
import { db } from "../api/firebaseApi";
import { DATABASE, VISAVALUE } from "../types/tables-data";

interface UpdateUserAvatar {
  avatarSrc: string;
  avatarName: string;
}

export const useUpdateUser = () => {
  const [isLoadingUpdateUser, setIsLoadingUpdateUser] = useState(false);
  const [errorUpdateUser, setErrorUpdateUser] =
    useState<FirestoreErrorCode | null>(null);
  const email = useSelector(individualSelectors.selectEmail);

  const onSubmitUpdateUser = async (id: string, values: UpdateIndividual) => {
    setIsLoadingUpdateUser(true);
    try {
      const userRef = doc(db, DATABASE.INDIVIDUALS, `${id}`);

      await updateDoc(userRef, {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        email: values.email,
        dob: values.dob,
        pronouns: values.pronouns,
        otherPronouns: values.otherPronouns,
        company: values.company,
        visaType: values.visaType,
        usAddressInCareOf: values.usAddressInCareOf,
        usAddressStreetNumber: values.usAddressStreetNumber,
        usAddressStreetName: values.usAddressStreetName,
        usAddressType: values.usAddressType,
        usAddressAptSteFloor: values.usAddressAptSteFloor,
        usAddressCityOrTown: values.usAddressCityOrTown,
        usAddressState: values.usAddressState,
        usAddressProvince: values.usAddressProvince,
        usAddressZipCode: values.usAddressZipCode,
        foreignAddressInCareOf: values.foreignAddressInCareOf,
        foreignAddressStreetNumber: values.foreignAddressStreetNumber,
        foreignAddressStreetName: values.foreignAddressStreetName,
        foreignAddressType: values.foreignAddressType,
        foreignAddressAptSteFloor: values.foreignAddressAptSteFloor,
        foreignAddressCityOrTown: values.foreignAddressCityOrTown,
        foreignAddressState: values.foreignAddressState,
        foreignAddressProvince: values.foreignAddressProvince,
        foreignAddressPostalCode: values.foreignAddressPostalCode,
        last_updated_by: values.email
      });
    } catch (e: any) {
      const errorCode = e.code;
      setErrorUpdateUser(errorCode);
    } finally {
      setIsLoadingUpdateUser(false);
    }
  };

  const onUpdateUserAvatar = async (id: string, values: UpdateUserAvatar) => {
    setIsLoadingUpdateUser(true);
    try {
      const userRef = doc(db, DATABASE.INDIVIDUALS, `${id}`);

      await updateDoc(userRef, {
        avatarName: values.avatarName,
        avatarSrc: values.avatarSrc,
        last_updated_by: email
      });
    } catch (e) {
      if (e instanceof FirestoreError) {
        const errorCode = e.code;
        setErrorUpdateUser(errorCode);
      }
    } finally {
      setIsLoadingUpdateUser(false);
    }
  };

  /**
   * Updates the beta features array for a user in Firestore.
   * @param {string} userId - The user ID.
   * @param {string} userType
   * @param {string} feature - The beta feature to add or remove.
   * @param {boolean} hasAccess - Whether the user should have access to the feature.
   */
  const updateBetaFeatureAccess = async (
    userId: string,
    userType: DATABASE,
    feature: string,
    hasAccess: boolean
  ) => {
    try {
      const userDocRef = doc(db, userType, userId);

      if (hasAccess) {
        await updateDoc(userDocRef, {
          betaFeatures: arrayUnion(feature)
        });
        console.log(`Feature "${feature}" added for user ${userId}`);
      } else {
        await updateDoc(userDocRef, {
          betaFeatures: arrayRemove(feature)
        });
        console.log(`Feature "${feature}" removed for user ${userId}`);
      }
    } catch (error) {
      console.error(`Error updating beta features for user ${userId}:`, error);
      throw error;
    }
  };

  const getUserBetaFeatures = async (
    userId: string,
    userType: DATABASE
  ): Promise<string[]> => {
    try {
      const userDocRef = doc(db, userType, userId);
      const snapshot = await getDoc(userDocRef);

      if (snapshot.exists()) {
        const data = snapshot.data();
        // If `betaFeatures` is not set, default to an empty array
        return data.betaFeatures ?? [];
      }

      // If `snapshot.exists()` is false, just return []
      return [];
    } catch (error) {
      console.error("Error fetching user beta features:", error);
      throw error;
    }
  };

  return {
    getUserBetaFeatures,
    updateBetaFeatureAccess,
    isLoadingUpdateUser,
    onSubmitUpdateUser,
    errorUpdateUser,
    onUpdateUserAvatar
  };
};
