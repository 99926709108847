import { forwardRef, ReactNode, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Text,
  IconButton,
  AccordionIcon,
  Flex,
  Input,
  Button,
  HStack
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

interface SectionContainerProps {
  title: string;
  children: ReactNode;
  isExpanded?: boolean;
  onToggle?: () => void;
  isTitleEditable?: boolean;
  onSaveTitle?: (newTitle: string) => void;
}

const SectionContainer = forwardRef<HTMLDivElement, SectionContainerProps>(
  (
    {
      title,
      children,
      isExpanded,
      onToggle,
      isTitleEditable = false,
      onSaveTitle
    },
    ref
  ) => {
    const [internalExpanded, setInternalExpanded] = useState(true);
    const expandedState = isExpanded ?? internalExpanded;
    const [isEditing, setIsEditing] = useState(false);
    const [newTitle, setNewTitle] = useState(title);

    const handleToggle = () => {
      const newState = !expandedState;
      setInternalExpanded(newState);
      onToggle?.();
    };

    const handleEdit = (e: any) => {
      e.stopPropagation();
      setIsEditing(true);
    };

    const handleCancel = () => {
      setNewTitle(title);
      setIsEditing(false);
    };

    const handleApply = () => {
      onSaveTitle?.(newTitle);
      setIsEditing(false);
    };

    return (
      <Box ref={ref}>
        <Accordion
          allowToggle
          index={expandedState ? [0] : []}
          onChange={() => {
            // Prevent toggling the accordion if we are editing the title.
            if (!isEditing) {
              handleToggle();
            }
          }}
        >
          <AccordionItem
            border="1px solid"
            borderColor="stroke.stroke3"
            borderRadius="md"
            bg="#F8FAFB"
          >
            <h2>
              <AccordionButton>
                <Flex
                  py={2}
                  flexDirection="row"
                  flex="1"
                  columnGap={2}
                  textAlign="left"
                  alignItems="center"
                >
                  <AccordionIcon />
                  {isEditing ? (
                    <HStack
                      spacing={2}
                      flex="1"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Input
                        borderRadius="md"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        size="sm"
                        flex="1"
                        onClick={(e) => e.stopPropagation()}
                      />
                      <Button
                        size="sm"
                        variant="tertiaryText"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        variant="secondaryOutline"
                        onClick={handleApply}
                      >
                        Apply
                      </Button>
                    </HStack>
                  ) : (
                    <Flex alignItems="center" flex="1" gap={2}>
                      <Text fontWeight="semibold" color="text.display.light">
                        {title}
                      </Text>
                      {isTitleEditable && (
                        <IconButton
                          icon={<EditIcon />}
                          size="xs"
                          variant="ghost"
                          aria-label="Edit title"
                          onClick={handleEdit}
                        />
                      )}
                    </Flex>
                  )}
                </Flex>
              </AccordionButton>
            </h2>
            <AccordionPanel>{children}</AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    );
  }
);

SectionContainer.displayName = "SectionContainer";

export default SectionContainer;
