import React from "react";
import { Box, Button, Flex, Stack, useToast } from "@chakra-ui/react";
import { Formik, FormikHelpers } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { createNewFirebaseUser } from "../../../api/OpenAIApi";
import { InputField } from "../../../forms/InputField";
import { SelectField } from "../../../forms/SelectField";
import { SectionWrapper } from "../../../components/common/SectionWrapper";
import { SpecialHeading } from "../../../components/individualTabs/PanelLayout";

const USER_ROLES = ["lawyer", "individual"];
const SUBSCRIPTION_TYPES = ["freemium", "paid"];
const LAWYER_ACCOUNT_TYPES = ["standard", "full"];
const VISA_TYPES = ["EB-2 NIW", "O-1 A"];

interface NewUserFormState {
  email: string;
  firstName: string;
  lastName: string;
  disabled: boolean;
  emailVerified: boolean;
  newPassword: string;
  role: string;
  practiceName: string;
  visaType: string;
  subscriptionStatus: string;
  lawyerRole: string;
}

const initialValues: NewUserFormState = {
  email: "",
  firstName: "",
  lastName: "",
  disabled: false,
  emailVerified: false,
  newPassword: "",
  role: "",
  practiceName: "",
  visaType: "",
  subscriptionStatus: "",
  lawyerRole: ""
};

YupPassword(Yup);

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  newPassword: Yup.string()
    .required("Required")
    .minLowercase(1, "Password must contain at least 1 lower case letter")
    .minUppercase(1, "Password must contain at least 1 upper case letter")
    .minNumbers(1, "Password must contain at least 1 number")
    .minSymbols(1, "Password must contain at least 1 special character")
    .min(8, "Password must be at least 8 characters"),
  role: Yup.string().required("Required")
});

function AdminNewUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const roleQueryParam = query.get("role");
  const practiceNameQueryParam = query.get("practiceName");

  const handleSubmit = async (
    values: NewUserFormState,
    { setSubmitting }: FormikHelpers<NewUserFormState>
  ) => {
    try {
      const newUser = await createNewFirebaseUser(
        values.email,
        values.newPassword,
        false,
        values.role,
        values.firstName,
        values.lastName,
        values.practiceName,
        values.visaType,
        values.subscriptionStatus,
        values.lawyerRole
      );

      toast({
        title: "User created successfully",
        status: "success",
        duration: 3000
      });

      navigate(`/admin/users/${newUser.uid}/edit`);
    } catch (error: any) {
      toast({
        title: "Error creating user",
        description: error.message || "Please try again",
        status: "error",
        duration: 3000
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <SectionWrapper bg="rgba(255, 255, 255, 0.5)">
      <Box p={4}>
        <Formik
          initialValues={{
            ...initialValues,
            role: roleQueryParam || "",
            practiceName: practiceNameQueryParam || ""
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <form onSubmit={handleSubmit}>
              <Box py="15px" width="100%">
                <SpecialHeading title="Create New User" />
              </Box>

              <Stack
                spacing={4}
                sx={{
                  input: {
                    bg: "white",
                    _hover: { bg: "gray.200" },
                    borderRadius: "md"
                  },
                  button: {
                    borderRadius: "md"
                  }
                }}
              >
                <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                  <InputField name="firstName" label="First Name" isRequired />
                  <InputField name="lastName" label="Last Name" isRequired />
                </Stack>

                <InputField
                  name="email"
                  label="Email"
                  type="email"
                  isRequired
                />

                <InputField
                  name="newPassword"
                  label="Password"
                  type="password"
                  isRequired
                />

                <SelectField
                  variant="whiteBg"
                  name="role"
                  label="Role"
                  isRequired
                  placeHolder="Select"
                  options={USER_ROLES}
                />

                {values.role === "lawyer" && (
                  <>
                    <InputField
                      name="practiceName"
                      label="Practice Name"
                      isRequired
                    />
                    <SelectField
                      variant="whiteBg"
                      name="subscriptionStatus"
                      label="Subscription Type"
                      placeHolder="Select"
                      options={SUBSCRIPTION_TYPES}
                      isRequired
                    />
                    <SelectField
                      variant="whiteBg"
                      name="lawyerRole"
                      label="Account Type"
                      placeHolder="Select"
                      options={LAWYER_ACCOUNT_TYPES}
                      isRequired
                    />
                  </>
                )}

                {values.role === "individual" && (
                  <SelectField
                    variant="whiteBg"
                    name="visaType"
                    label="Visa Type"
                    placeHolder="Select"
                    options={VISA_TYPES}
                    isRequired
                  />
                )}
              </Stack>

              <Flex justifyContent="end" gap={2} mt={4}>
                <Button
                  onClick={() => navigate("/admin/users")}
                  variant="secondaryOutline"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primaryFilled"
                  isLoading={isSubmitting}
                >
                  Create User
                </Button>
              </Flex>
            </form>
          )}
        </Formik>
      </Box>
    </SectionWrapper>
  );
}

export default AdminNewUser;
