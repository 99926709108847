import useDrivePicker from "react-google-drive-picker";
import { useToast } from "@chakra-ui/react";

import axiosInstance from "../api/axiosConfig";
import { useGoogleDriveAuth } from "./useGoogleDriveAuth";

const GOOGLE_DRIVE_CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
const GOOGLE_DRIVE_DEVELOPER_KEY =
  process.env.REACT_APP_GOOGLE_DRIVE_DEVELOPER_KEY;
if (!GOOGLE_DRIVE_CLIENT_ID || !GOOGLE_DRIVE_DEVELOPER_KEY) {
  throw new Error(
    "GOOGLE_DRIVE_CLIENT_ID or GOOGLE_DRIVE_DEVELOPER_KEY is not set"
  );
}

declare const google: {
  picker: {
    PickerBuilder: new () => any;
    ViewId: {
      DOCS: string;
    };
    Action: {
      PICKED: string;
    };
    DocsView: new () => any;
  };
};

export const useGoogleDrive = () => {
  const [openPicker] = useDrivePicker();
  const { hasAccessToken, checkAccessToken, handleAuth } = useGoogleDriveAuth();
  const toast = useToast();

  const pickFiles = async (): Promise<File[]> => {
    const token = hasAccessToken || (await checkAccessToken());
    if (!token) {
      await handleAuth(window.location.href);
      return [];
    }

    return new Promise((resolve) => {
      openPicker({
        clientId: GOOGLE_DRIVE_CLIENT_ID,
        developerKey: GOOGLE_DRIVE_DEVELOPER_KEY,
        viewId: "DOCS",
        supportDrives: true,
        multiselect: true,
        customViews: [
          new google.picker.DocsView()
            .setIncludeFolders(true)
            .setOwnedByMe(false),
          new google.picker.DocsView()
            .setEnableDrives(true)
            .setIncludeFolders(true)
        ],
        callbackFunction: async (data) => {
          if (data.action === "picked") {
            try {
              toast.closeAll();
              toast({
                title: "Retrieving files from Google Drive...",
                status: "loading",
                duration: null,
                isClosable: false
              });
              const filePromises = data.docs.map(async (doc) => {
                const response = await axiosInstance.get(
                  `/google-drive/download`,
                  {
                    params: {
                      fileId: doc.id
                    }
                  }
                );

                // Convert base64 to blob
                const binaryString = atob(response.data.content);
                const bytes = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i += 1) {
                  bytes[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([bytes], {
                  type: response.data.mimeType
                });

                // Create File object
                return new File([blob], response.data.fileName, {
                  type: response.data.mimeType
                });
              });
              const files = await Promise.all(filePromises);
              resolve(files);
            } catch (error) {
              toast.closeAll();
              toast({
                title: "Error retrieving files from Google Drive",
                status: "error",
                duration: 3000,
                isClosable: true
              });
              resolve([]);
            }
          }
        }
      });
    });
  };

  return {
    handleAuth,
    pickFiles
  };
};
