import { IconButton } from "@chakra-ui/react";
import { darken } from "@chakra-ui/theme-tools";
import { motion, Transition } from "framer-motion";
import { useState } from "react";
import { useEditor } from "../../context/EditorContext"; // Adjust the import path as necessary
import { PromptAiIcon } from "./Icons";

export const EditorWidget = () => {
  const [isBouncing, setIsBouncing] = useState(true);
  const { setShowToolbar, widgetYPosition, selectEditor } = useEditor();

  return (
    <motion.div
      animate={isBouncing ? { y: [0, -5, 0] } : { y: 0 }}
      transition={
        {
          duration: 2,
          repeat: isBouncing ? Infinity : 0,
          ease: "easeInOut"
        } as Transition
      }
      onHoverStart={() => setIsBouncing(false)}
      onHoverEnd={() => setIsBouncing(true)}
      style={{
        position: "absolute",
        top: `${widgetYPosition}px`,
        transform: "translateX(-75%)",
        left: "0",
        zIndex: 9999
      }}
    >
      <IconButton
        size="lg"
        bg="white"
        border="1px solid"
        borderColor="accent.emerald !important"
        _hover={{
          bg: darken("white", 5)
        }}
        shadow="md"
        icon={<PromptAiIcon />}
        aria-label="AI Prompt"
        zIndex={9999}
        onClick={() => {
          selectEditor();
          setShowToolbar((prev) => !prev);
        }}
        style={{
          position: "absolute",
          top: `-50%`,
          left: "0",
          transform: "translateX(-90%)"
        }}
      />
    </motion.div>
  );
};
