import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Text,
  useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "../styles/extracted-info.module.css";
import { individualSelectors } from "../redux/individual/selectors";
import { ExpertLetter } from "../redux/clients/clientsSlice";
import { useAddExtractInfo } from "../hooks/useAddExtractInfo";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import { CustomBox } from "../components/common/CustomBox";
import { Editor } from "../components/common/Editor";

interface ExpertSummary {
  expertUid: string;
  summary: string | undefined;
  summaryHtml?: string;
  isDirty: boolean;
  expertName: string;
}

export const ExpertsExtractedHighlights = () => {
  const { id, visaType } = useParams();
  const { onSaveExpertLetterBlurb, isLoadingSaveExpLetter } =
    useAddExtractInfo(id);
  const navigate = useNavigate();
  const toast = useToast();
  const expertLetters = useSelector(
    individualSelectors.selectExpertLetters
  ) as ExpertLetter[];
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  // Initialize summaries with default values
  const [summaries, setSummaries] = useState<ExpertSummary[]>(
    expertLetters?.map((letter) => ({
      expertUid: letter.uid || "", // Use an empty string as the default value
      summary: letter.summary,
      summaryHtml: letter.summaryHtml,
      isDirty: false,
      expertName: letter.expertName!
    })) || []
  );

  const updateSummary = (expertUid: string, html: string, text: string) => {
    setSummaries(
      summaries.map((item) =>
        item.expertUid === expertUid
          ? { ...item, summaryHtml: html, summary: text, isDirty: true }
          : item
      )
    );
  };

  const saveSummary = async (expertUid: string) => {
    const expert = summaries.find((item) => item.expertUid === expertUid);
    if (expert) {
      setIsLoadingSave(true);
      try {
        await onSaveExpertLetterBlurb(
          expertUid,
          expert?.summary ?? "",
          expert?.summaryHtml ?? ""
        ); // Pass expertUid instead of 'id'
        toast({
          title: "Success",
          description: "Summary updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-right"
        });
      } catch (error) {
        console.error("Error saving summary: ", error);
        toast({
          title: "Error",
          description: "Failed to update summary",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "bottom-right"
        });
      } finally {
        setIsLoadingSave(false);
        setSummaries(
          summaries.map((item) =>
            item.expertUid === expertUid ? { ...item, isDirty: false } : item
          )
        );
      }
    }
  };

  const cancelEdit = (expertUid: string) => {
    setSummaries(
      summaries.map((item) =>
        item.expertUid === expertUid
          ? {
              ...item,
              summary: expertLetters.find((letter) => letter.uid === expertUid)
                ?.summary,
              isDirty: false
            }
          : item
      )
    );
  };

  const expertField = (expert: ExpertSummary) => {
    return (
      <Box p={6} key={expert.expertUid}>
        <Text as="h2" mb="4" fontWeight="700" textAlign="left">
          Expert : {expert.expertName}
        </Text>
        <Editor
          text={(expert.summaryHtml || expert.summary) ?? ""}
          updateText={(html, text) =>
            updateSummary(expert.expertUid, html, text ?? "")
          }
          saveText={() => saveSummary(expert.expertUid)}
        />
      </Box>
    );
  };

  return (
    <Flex
      justifyContent="space-between"
      flexDirection="row"
      width="100%"
      className={styles.section}
    >
      <Flex alignItems="center" flexDirection="column" flex="1" width="100%">
        <Box>
          <SpecialHeading title="Extracted information" backNav />
          <div className="">
            {/* custom box here */}
            <CustomBox type="info">
              <Text fontSize="18px">
                The following data was extracted from the documents you
                uploaded. Make edits as needed.
              </Text>
            </CustomBox>
            {summaries && summaries.length > 0 ? (
              <Flex flexDirection="column" mt={10}>
                {summaries.map((expert) => expertField(expert))}
              </Flex>
            ) : (
              <Box fontSize="18px">
                Click the Extract button on the previous page
              </Box>
            )}
          </div>
          <div className="flex justify-center">
            <Button
              my={4}
              className="btn tertiary-btn"
              bg="bgColor.300"
              color="text.100"
              type="button"
              onClick={() =>
                navigate(
                  `/individual/${id}/${visaType}/questionnaires/expert-letter-questionnaires`
                )
              }
              mx="15px"
            >
              Back
            </Button>
          </div>
        </Box>
      </Flex>
    </Flex>
  );
};
