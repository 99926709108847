import { Button, Divider } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { DataDocs } from "../types/tables-data";
import "./document_slider.css";
import { DocumentDetailsSection } from "./DocumentDetailsSection";
import { DocumentThumbnail } from "./DocumentThumbnail";
import { Pagination } from "../components/Pagination";

const SLIDES_PER_PAGE = 4;

const SLIDER_SETTINGS: Settings = {
  speed: 250,
  arrows: true,
  slidesToScroll: SLIDES_PER_PAGE,
  adaptiveHeight: true,
  infinite: false,
  variableWidth: true,
  vertical: false,
  centerMode: false,
  rows: 1
};

type DocumentsSliderProps = {
  categoryIndex: number;
  documents: DataDocs[];
};

export const DocumentsSlider = ({
  categoryIndex,
  documents
}: DocumentsSliderProps) => {
  const sliderRef = React.useRef<Slider>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const docIdFromPath = searchParams.get("docId");

  const docIdFromPathIndex = documents.findIndex(
    (document) => document.id === docIdFromPath
  );
  const defaultDocumentIndex =
    docIdFromPathIndex !== -1 ? docIdFromPathIndex : 0;

  const [activeSlide, setActiveSlide] = useState(defaultDocumentIndex);
  const selectedDocument = documents[activeSlide];

  const totalPages = useMemo(
    () => Math.ceil(documents.length / SLIDES_PER_PAGE),
    [documents.length]
  );

  const currentPage = useMemo(
    () => Math.floor(activeSlide / SLIDES_PER_PAGE) + 1,
    [activeSlide]
  );

  const goToPage = (page: number) => {
    const newSlideIndex = (page - 1) * SLIDES_PER_PAGE;
    setActiveSlide(newSlideIndex);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(newSlideIndex, true);
    }
  };

  useEffect(() => {
    if (selectedDocument) {
      searchParams.set("category", selectedDocument.criterion!);
      searchParams.set("docId", selectedDocument.id!);
      setSearchParams(searchParams);
    }
  }, [activeSlide, documents]);

  useEffect(() => {
    setActiveSlide(defaultDocumentIndex);
  }, [documents]);

  return (
    <div className="!overflow-visible">
      <Slider ref={sliderRef} {...SLIDER_SETTINGS} initialSlide={activeSlide}>
        {documents.map((document: DataDocs, indx: number) => (
          <DocumentThumbnail
            onClick={() => {
              setActiveSlide(indx);
            }}
            key={document.id}
            indx={`${categoryIndex + 1}.${indx + 1}`}
            document={document}
            visible={activeSlide === indx}
          />
        ))}
      </Slider>
      {/* Slider Pagination */}
      {documents.length > 5 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={goToPage}
        />
      )}
      <Divider my={12} />
      {/* EvidenceItem Preview */}
      {selectedDocument !== undefined && (
        <DocumentDetailsSection
          document={selectedDocument}
          key={selectedDocument.id}
          exhibit={`${categoryIndex + 1}.${activeSlide + 1}`}
        />
      )}
    </div>
  );
};
