import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Box
} from "@chakra-ui/react";
import { ReactNode } from "react";

interface Option {
  value: string | number;
  label: ReactNode | string;
}

interface CustomSelectProps {
  options: Option[];
  value: string | number;
  onChange: (value: string | number) => void;
  variant?: string;
  width?: string | number;
}

export const CustomSelect = ({
  options,
  value,
  onChange,
  variant = "outline",
  width = "fit-content"
}: CustomSelectProps) => {
  const selectedOption = options.find((opt) => opt.value === value);

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        borderRadius={4}
        variant="addWithWhiteBg"
        width={width}
        bg="white"
        color="black"
        _hover={{ bg: "gray.50" }}
      >
        {selectedOption?.label}
      </MenuButton>
      <MenuList>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => onChange(option.value)}
            display="flex"
            alignItems="center"
            gap={2}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
