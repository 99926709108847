import {
  DeleteIcon,
  EditIcon,
  ExternalLinkIcon,
  Search2Icon,
  TriangleDownIcon,
  TriangleUpIcon
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Skeleton,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis
} from "@dnd-kit/modifiers";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { doc, updateDoc } from "firebase/firestore";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { db } from "../../api/firebaseApi";
import { formatDate } from "../../helpers/date_helpers";
import { openFilePreview } from "../../helpers/helpers";
import useFirestoreDocument from "../../hooks/useFirestoreDocument";
import {
  LawyerState,
  toggleHiddenPrompt
} from "../../redux/lawyer/lawyerSlice";
import { lawyerSelectors } from "../../redux/lawyer/selectors";
import {
  removePrompt,
  setSelectedPromptId
} from "../../redux/prompts/promptsSlice";
import { Feature } from "../../types/features";
import { DATABASE, VISAVALUE } from "../../types/tables-data";
import ViewToggleButton from "../lawyer/custom_prompts/ViewToggleButton";
import { CustomAlertDialog } from "./CustomAlertDialog";
import { VisaTag } from "./VisaTag";
import { EXTRACTIONSTATES } from "../../redux/extraction-jobs/extractionJobsSlice";
import {
  CustomTemplateType,
  templateTypeDisplayMap,
  templateTypeMap
} from "../../types/studio/templates";
import { NewBadge } from "./DocumentRowStatus";
import OutlinedBox from "./OutlinedBox";
import { SortKey } from "../../helpers/string_helpers";
import { TableSkeleton } from "./Skeletons";

/** ======================
 *  Utility types/defs
 *  ====================== */

interface BaseEntityFields {
  [x: string]: any;
  status?: {
    status?: string;
  };
  id: string;
}

export interface GroupedDocument<T> {
  type: string;
  index: number;
  subrows: T[];
  expanded: boolean;
}

interface GroupedTableProps<T> {
  items: T[];
  setGroupedItems: (grouped: GroupedDocument<T>[]) => void;
  updateOrder: (grouped: GroupedDocument<T>[]) => void;
  isLoading: boolean;
  groupBy?: keyof T;
  feature: string;
  searchPlaceholder?: string;
  isIsolatedView?: boolean;
  variant?: string;
  disablePagination?: boolean;
  sortBy?: keyof T;
  sortBySecondary?: keyof T;
}

/** ===========================
 * GroupedTable
 *  =========================== */

export const GroupedTable = <T extends BaseEntityFields>({
  items,
  setGroupedItems,
  updateOrder,
  isLoading,
  groupBy,
  feature,
  searchPlaceholder = "Search",
  isIsolatedView = false,
  variant = "basicDivided",
  disablePagination = false,
  sortBy = "defaultKey",
  sortBySecondary = "defaultKey"
}: GroupedTableProps<T>) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [groupedItems, setGroupedItemsState] = useState<GroupedDocument<T>[]>(
    []
  );
  const [newItemIds, setNewItemIds] = useState<Set<string>>(new Set());
  const prevItemsRef = useRef<T[]>([]);

  const [filter, setFilter] = useState("");

  const lawyerId = useSelector(lawyerSelectors.selectUid);

  const { document: lawyerData, updateDocument } =
    useFirestoreDocument<LawyerState>(
      DATABASE.LAWYERS,
      lawyerId!,
      true,
      undefined,
      true
    );

  const hiddenPrompts = lawyerData?.hiddenPrompts || [];

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose
  } = useDisclosure();

  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [docIdToDelete, setDocIdToDelete] = useState<string | null>(null);

  // ----------------------------------
  //  Single-column sorting per header
  // ----------------------------------
  const [sortConfig, setSortConfig] = useState<{
    column: SortKey;
    direction: "asc" | "desc";
  }>({ column: sortBy as SortKey, direction: "asc" });

  const handleHeaderClick = useCallback(
    (columnId: SortKey) => {
      setSortConfig((prev) => {
        // If same column, toggle asc/desc
        if (prev?.column === columnId) {
          return {
            column: columnId,
            direction: prev.direction === "asc" ? "desc" : "asc"
          };
        }
        // Otherwise switch to new column, default asc
        return { column: columnId, direction: "asc" };
      });
    },
    [setSortConfig]
  );

  // -----------------------
  // Pagination
  // -----------------------
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageCount = Math.ceil(items.length / pageSize);

  const handlePreviousPage = () => {
    setPageIndex((p) => Math.max(p - 1, 0));
  };
  const handleNextPage = () => {
    if ((pageIndex + 1) * pageSize < items.length) {
      setPageIndex((p) => p + 1);
    }
  };

  // -----------------------
  //  Grouping logic
  // -----------------------
  const groupItems = useCallback(() => {
    if (!groupBy) {
      setGroupedItemsState(
        items.map((item, index) => ({
          type: item.id,
          index,
          subrows: [item],
          expanded: true
        }))
      );
      return;
    }

    const groupedMap = new Map<string, GroupedDocument<T>>();

    items.forEach((item, index) => {
      const groupKey = String(item[groupBy]);
      if (!groupedMap.has(groupKey)) {
        groupedMap.set(groupKey, {
          type: groupKey,
          index,
          subrows: [],
          expanded: true
        });
      }
      groupedMap.get(groupKey)!.subrows.push(item);
    });

    const groupsArray = Array.from(groupedMap.values());
    // Sort groups alphabetically by the group type.
    groupsArray.sort((a, b) => a.type.localeCompare(b.type));
    setGroupedItemsState(groupsArray);
  }, [items, groupBy]);

  useEffect(() => {
    groupItems();
  }, [items, groupBy, groupItems]);

  const toggleExpand = useCallback((type: string) => {
    setGroupedItemsState((prev) =>
      prev?.map((group) =>
        group.type === type ? { ...group, expanded: !group.expanded } : group
      )
    );
  }, []);

  useEffect(() => {
    if (prevItemsRef.current.length === 0) {
      // First render: store items without marking them as new.
      prevItemsRef.current = items;
      return;
    }
    const prevIds = new Set(prevItemsRef.current.map((item) => item.id));
    const newlyAdded = items
      .filter((item) => !prevIds.has(item.id))
      .map((item) => item.id);

    if (newlyAdded.length > 0) {
      setNewItemIds((prev) => new Set([...prev, ...newlyAdded]));
    }

    prevItemsRef.current = items;
  }, [items]);

  // -----------------------
  //  Drag End
  // -----------------------
  const handleDragEnd = useCallback(
    (event: any) => {
      const { active, over } = event;
      if (active && over) {
        const activeIndex = groupedItems?.findIndex(
          (group) => `group-${group.type}` === active.id
        );
        const overIndex = groupedItems?.findIndex(
          (group) => `group-${group.type}` === over.id
        );
        if (
          groupedItems &&
          activeIndex &&
          overIndex &&
          activeIndex !== -1 &&
          overIndex !== -1 &&
          activeIndex !== overIndex
        ) {
          const updatedGroups = arrayMove(groupedItems, activeIndex, overIndex);
          setGroupedItems(updatedGroups);
          updateOrder(updatedGroups);
        }
      }
    },
    [groupedItems, setGroupedItems, updateOrder]
  );

  // -----------------------
  //  Filtering by group type
  // -----------------------
  // Fix: Filter by group type OR any relevant subrow fields
  const filteredGroups = useMemo(() => {
    if (!groupedItems) return undefined;
    if (!filter) return groupedItems;

    return groupedItems
      .map((group) => {
        const filteredSubrows = group.subrows.filter((sub) => {
          const templateName = (sub as any).templateName?.toLowerCase() || "";
          const uploadedBy = (sub as any).uploadedByName?.toLowerCase() || "";
          const visa = (sub as any).visa?.toLowerCase() || "";

          const groupType = group.type.toLowerCase();
          const f = filter.toLowerCase();

          return (
            groupType.includes(f) ||
            templateName.includes(f) ||
            uploadedBy.includes(f) ||
            visa.includes(f)
          );
        });

        return filteredSubrows.length > 0
          ? { ...group, subrows: filteredSubrows }
          : null;
      })
      .filter((grp): grp is GroupedDocument<T> => grp !== null);
  }, [groupedItems, filter]);

  const filteredAndSortedGroups = useMemo(() => {
    if (!filteredGroups) return undefined;
    if (!sortConfig?.column) return filteredGroups;

    const { column, direction } = sortConfig;

    return filteredGroups
      .map((group) => {
        // Create a new array for sorting
        const sortedSubrows = [...group.subrows]
          .sort((a, b) => {
            const primaryA = a[sortBy] ?? "";
            const primaryB = b[sortBy] ?? "";
            if (primaryA < primaryB) return direction === "asc" ? -1 : 1;
            if (primaryA > primaryB) return direction === "asc" ? 1 : -1;

            const secondaryA =
              a[sortBySecondary]?.toString().toLowerCase() ?? "";
            const secondaryB =
              b[sortBySecondary]?.toString().toLowerCase() ?? "";
            return secondaryA.localeCompare(secondaryB);
          })
          // Move new items to the top and sort them by created_at
          .sort((a, b) => {
            if (a.isNew && b.isNew) {
              return +new Date(b.created_at) - +new Date(a.created_at);
            }
            return (b.isNew ? 1 : 0) - (a.isNew ? 1 : 0); // Move new items to the top
          });

        return { ...group, subrows: sortedSubrows };
      })
      .sort((a, b) =>
        direction === "asc"
          ? a.type.localeCompare(b.type)
          : b.type.localeCompare(a.type)
      );
  }, [filteredGroups, sortConfig, sortBy, sortBySecondary]);

  // Apply pagination on the flattened array
  const paginatedGroups = useMemo(() => {
    if (disablePagination) return undefined; // Skip pagination
    return filteredAndSortedGroups?.slice(
      pageIndex * pageSize,
      (pageIndex + 1) * pageSize
    );
  }, [filteredAndSortedGroups, pageIndex, pageSize, disablePagination]);

  // -----------------------
  //  Deletion
  // -----------------------
  const handleDelete = async () => {
    if (!docIdToDelete) return;

    setIsDeleting(true);

    try {
      switch (feature) {
        case "templates": {
          const templateDocRef = doc(
            db,
            DATABASE.CUSTOM_TEMPLATES,
            docIdToDelete
          );
          await updateDoc(templateDocRef, { isDeleted: true });
          break;
        }

        case Feature.CUSTOM_PROMPTS: {
          const promptDocRef = doc(db, DATABASE.PROMPTS, docIdToDelete);
          await updateDoc(promptDocRef, { isDeleted: true });
          dispatch(removePrompt(docIdToDelete));
          break;
        }

        default: {
          console.warn(`Deletion is not supported for feature: ${feature}`);
          break;
        }
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    } finally {
      setIsDeleting(false);
      setDocIdToDelete(null);
      onDeleteModalClose();
    }
  };

  // -----------------------
  //  Edit / Navigation
  // -----------------------
  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    e.stopPropagation();

    switch (feature) {
      case "templates":
        navigate(`/studio/templates/edit/${id}`);
        break;
      case Feature.CUSTOM_PROMPTS:
        // Use edit modal instead of navigation
        dispatch(setSelectedPromptId(id));
        break;
      default:
        console.warn(`Unknown feature: ${feature}`);
    }
  };

  const handleToggleHiddenPrompt = async (e: React.MouseEvent, id: string) => {
    e.stopPropagation();

    // Toggle logic
    const updatedHiddenPrompts = hiddenPrompts?.includes(id)
      ? hiddenPrompts.filter((promptId) => promptId !== id)
      : [...(hiddenPrompts || []), id];

    try {
      // Update Firestore
      await updateDocument({ hiddenPrompts: updatedHiddenPrompts });
      // Update Redux state
      dispatch(toggleHiddenPrompt(id));
    } catch (error) {
      console.error("Error updating hiddenPrompts:", error);
    }
  };

  const handleRowClick = (id: string) => {
    if (!id) return;

    switch (feature) {
      case "templates":
        navigate(`/studio/templates/edit/${id}`);
        break;
      case Feature.CUSTOM_PROMPTS:
        // Use edit modal instead of navigation
        dispatch(setSelectedPromptId(id));
        break;
      default:
        console.warn(`Unknown feature: ${feature}`);
    }
  };

  // Which columns we show
  const getColumns = () => {
    switch (feature) {
      case "templates":
        return ["Template", "Visa", "Uploaded By", "Actions"];
      case "cases":
        return ["Case ID", "Client Name", "Status", "Actions"];
      case Feature.CUSTOM_PROMPTS:
        return ["Prompt", "Actions"];
      default:
        return [];
    }
  };

  const columns = getColumns();

  const renderColumns = (subrow: T, column: string) => {
    switch (feature) {
      case "templates":
        switch (column) {
          case "Template":
            return (
              <Box ml={16}>
                <Flex alignItems="center" minW={0}>
                  <Box
                    as="span"
                    fontWeight="medium"
                    flexShrink={1} // Let this box shrink
                    minW={0} // ...only works if minW=0
                    whiteSpace="normal"
                    wordBreak="break-word"
                    overflowWrap="break-word"
                  >
                    {(subrow as any).templateName}
                  </Box>

                  {(subrow as any).isNew && (
                    <Box as="span" ml={2}>
                      <NewBadge />
                    </Box>
                  )}

                  <Box as="span" ml={2}>
                    <ExternalLinkIcon
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        openFilePreview((subrow as any).docUrl);
                      }}
                    />
                  </Box>
                </Flex>

                <Box fontSize="sm" color="gray.500">
                  {new Date((subrow as any).created_at).toLocaleDateString(
                    "en-US",
                    {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric"
                    }
                  )}
                </Box>
              </Box>
            );
          case "Visa":
            return (
              <VisaTag visaType={(subrow as any).visa ?? VISAVALUE.EMPTY} />
            );
          case "Uploaded By":
            return (subrow as any).uploadedByName;
          case "Actions":
            return (
              <HStack gap={2} justifyContent="end">
                <IconButton
                  variant="filledIconButton"
                  icon={<DeleteIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDocIdToDelete(subrow.id);
                    onDeleteModalOpen();
                  }}
                  aria-label=""
                />
                <IconButton
                  variant="filledIconButton"
                  icon={<EditIcon />}
                  onClick={(e) => handleEdit(e, subrow.id!)}
                  aria-label=""
                />
              </HStack>
            );
          default:
            return null;
        }

      case Feature.CUSTOM_PROMPTS:
        switch (column) {
          case "Prompt":
            return (
              <Box>
                <Text fontWeight="medium">{subrow.promptName}</Text>
                {!subrow.type && (
                  <Box fontSize="sm" color="gray.500">
                    <Text>Date: {formatDate(subrow.created_at)}</Text>
                  </Box>
                )}
              </Box>
            );
          case "Actions":
            return (
              <HStack gap={2} justifyContent="end">
                {subrow.type && (
                  <ViewToggleButton
                    key={subrow.id}
                    isHidden={Boolean(hiddenPrompts?.includes(subrow.id))}
                    onToggle={(e) => handleToggleHiddenPrompt(e, subrow.id)}
                  />
                )}
                {subrow.type === undefined && (
                  <>
                    <IconButton
                      variant="filledIconButton"
                      icon={<EditIcon />}
                      onClick={(e) => handleEdit(e, subrow.id!)}
                      aria-label="Edit Prompt"
                    />
                    <IconButton
                      variant="filledIconButton"
                      icon={<DeleteIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setDocIdToDelete(subrow.id);
                        onDeleteModalOpen();
                      }}
                      aria-label="Delete Prompt"
                    />
                  </>
                )}
              </HStack>
            );

          default:
            return null;
        }

      default:
        return null;
    }
  };

  // For each column header, decide if we show an up/down arrow
  const getSortIcon = (col: string) => {
    if (!sortConfig?.column || sortConfig.column !== col) return null;
    return sortConfig.direction === "asc" ? (
      <TriangleUpIcon ml={1} />
    ) : (
      <TriangleDownIcon ml={1} />
    );
  };

  const groups = disablePagination ? filteredAndSortedGroups : paginatedGroups;

  return (
    <Box p={4}>
      {/* Top bar: Search, plus "Viewing # templates" + "Add new template" */}
      {!isIsolatedView && (
        <Flex mb={4} alignItems="center">
          <InputGroup w="300px">
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.300" />
            </InputLeftElement>
            <Input
              bg="white"
              type="search"
              size="md"
              placeholder={searchPlaceholder}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>

          <Spacer />

          <Flex alignItems="center" gap={4}>
            <Text whiteSpace="nowrap">Viewing {items.length} templates</Text>
          </Flex>
        </Flex>
      )}

      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        sensors={sensors}
        onDragEnd={handleDragEnd}
      >
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <Table variant={variant} sx={{ tableLayout: "fixed" }}>
            {!isIsolatedView && (
              <Thead>
                <Tr textAlign="right">
                  {columns.map((col) => {
                    // If col matches one of our sort keys, handle click to sort
                    let sortKey: SortKey | null = null;
                    if (col === "Template") sortKey = "templateName";
                    if (col === "Visa") sortKey = "visa";
                    if (col === "Uploaded By") sortKey = "uploadedByName";

                    return (
                      <Th
                        key={col}
                        onClick={() => {
                          if (sortKey) handleHeaderClick(sortKey);
                        }}
                        cursor={sortKey ? "pointer" : "default"}
                        w={col === "Actions" ? "15%" : "auto"} // Assign width
                        minWidth={col === "Actions" ? "120px" : "auto"}
                      >
                        <Flex
                          mr={col.toUpperCase() === "ACTIONS" ? "4" : "0"}
                          justifyContent={
                            col.toUpperCase() === "ACTIONS"
                              ? "flex-end"
                              : "flex-start"
                          }
                          alignItems="center"
                        >
                          {col}
                          {sortKey && getSortIcon(sortKey)}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
            )}

            <Tbody>
              {groupBy && groups ? (
                <SortableContext
                  items={groups?.map((group) => `group-${group.type}`)}
                >
                  {groups?.map((group) => (
                    <Fragment key={group.type}>
                      {/* Group Header Row */}
                      <Tr>
                        <Td>
                          <Flex alignItems="center" justifyContent="start">
                            <IconButton
                              onClick={() => toggleExpand(group.type)}
                              icon={
                                group.expanded ? (
                                  <MdOutlineExpandLess />
                                ) : (
                                  <MdOutlineExpandMore />
                                )
                              }
                              aria-label="Expand/Collapse"
                              variant="ghost"
                            />
                            <OutlinedBox>
                              <Text>
                                {feature === "templates"
                                  ? templateTypeDisplayMap[
                                      group.type as CustomTemplateType
                                    ]
                                  : group.type}
                              </Text>
                            </OutlinedBox>
                          </Flex>
                        </Td>
                        {columns.slice(1).map((_, idx) => (
                          <Td key={idx} />
                        ))}
                      </Tr>

                      {/* Subrows */}
                      {group.expanded &&
                        group.subrows.map((subrow: T) =>
                          subrow?.status?.status ===
                          EXTRACTIONSTATES.Processing ? (
                            <Tr key={subrow.id}>
                              {columns.map((col, idx) => (
                                <Td key={idx}>
                                  <Skeleton
                                    height="20px"
                                    borderRadius="md"
                                    width="60%"
                                    ml={idx === 0 ? 16 : 0}
                                  />
                                </Td>
                              ))}
                            </Tr>
                          ) : (
                            <Tr
                              key={subrow.id}
                              cursor="pointer"
                              onClick={() => handleRowClick(subrow.id)}
                            >
                              {columns.map((col) => (
                                <Td key={col}>{renderColumns(subrow, col)}</Td>
                              ))}
                            </Tr>
                          )
                        )}
                    </Fragment>
                  ))}
                </SortableContext>
              ) : (
                // Plain mode: render rows directly without group headers.
                items.map((subrow) =>
                  subrow?.status?.status === EXTRACTIONSTATES.Processing ? (
                    <Tr key={subrow.id}>
                      {columns.map((col, idx) => (
                        <Td key={idx}>
                          <Skeleton
                            height="20px"
                            borderRadius="md"
                            width="60%"
                            ml={idx === 0 ? 16 : 0}
                          />
                        </Td>
                      ))}
                    </Tr>
                  ) : (
                    <Tr
                      key={subrow.id}
                      cursor="pointer"
                      onClick={() => handleRowClick(subrow.id)}
                    >
                      {columns.map((col) => (
                        <Td key={col}>{renderColumns(subrow, col)}</Td>
                      ))}
                    </Tr>
                  )
                )
              )}
            </Tbody>
          </Table>
        )}
      </DndContext>

      {/* Pagination controls at bottom */}
      {!disablePagination && !isIsolatedView && (
        <Flex mt={4} alignItems="center" justifyContent="flex-end" gap={4}>
          <Text>Templates per page:</Text>
          <Select
            width="80px"
            bg="white"
            value={pageSize}
            onChange={(e) => {
              setPageIndex(0);
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>

          <Flex alignItems="center" gap={2}>
            <Button
              variant="outlineIconButton"
              leftIcon={<FaChevronLeft />}
              onClick={handlePreviousPage}
              isDisabled={pageIndex === 0}
            >
              Previous
            </Button>

            <Text>
              Page {pageIndex + 1} of {pageCount}
            </Text>

            <Button
              variant="outlineIconButton"
              rightIcon={<FaChevronRight />}
              onClick={handleNextPage}
              isDisabled={pageIndex >= pageCount - 1 || pageCount === 0}
            >
              Next
            </Button>
          </Flex>
        </Flex>
      )}

      {/* Delete Confirmation Alert */}
      <CustomAlertDialog
        withButton={false}
        alertTitle="WARNING!"
        alertType="delete"
        leastDestructiveRef={cancelRef}
        close={onDeleteModalClose}
        confirm={handleDelete}
        isOpen={isDeleteModalOpen}
        isLoading={isDeleting}
      >
        <Text>
          You are about to permanently delete this record and all data
          associated with it. This cannot be undone.
        </Text>
        <Text mt={2}>Are you sure you want to continue?</Text>
      </CustomAlertDialog>
    </Box>
  );
};
