import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Textarea
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEditor } from "../../context/EditorContext";
import useFirestoreDocument from "../../hooks/useFirestoreDocument";
import { documentSelectors } from "../../redux/documents/selectors";
import { LawyerState } from "../../redux/lawyer/lawyerSlice";
import { lawyerSelectors } from "../../redux/lawyer/selectors";
import { promptsSelectors } from "../../redux/prompts/selectors";
import { templatesSelectors } from "../../redux/templates/selectors";
import { Prompt } from "../../types/studio/prompts";
import { DATABASE, VISAVALUE } from "../../types/tables-data";
import AIPromptMenu from "../lawyer/custom_prompts/AIPromptMenu";
import { ToolbarDropdown } from "./ToolbarDropdown";
import images from "../../assets/image-export-assets";
import { PreviewText } from "./PreviewText";

type AIToolbarProps = {
  setPromptisSelected: (value: boolean | null) => void;
  evidencesToHighlight: string[];
  setEvidencesToHighlight: React.Dispatch<React.SetStateAction<string[]>>;
  quillToolbarRef: React.RefObject<HTMLDivElement>;
  enableExhibitNumbers?: boolean;
};

export const AIToolbar = ({
  setPromptisSelected,
  evidencesToHighlight,
  setEvidencesToHighlight,
  quillToolbarRef,
  enableExhibitNumbers
}: AIToolbarProps) => {
  const {
    bounds,
    quillRef,
    showToolbar,
    changingText,
    selectedText,
    parsedText,
    range,
    setSelectedText,
    handleAIButtonClick,
    setShowToolbar,
    replaceText,
    previewText,
    setPreviewText
  } = useEditor();

  const { visaType } = useParams();
  const evidenceDocuments =
    visaType === "EB-2-NIW"
      ? useSelector(documentSelectors.evidenceDocsForNiw)
      : useSelector(documentSelectors.evidenceDocs);

  const lawyerId = useSelector(lawyerSelectors.selectUid);

  const { document: lawyerData, updateDocument } =
    useFirestoreDocument<LawyerState>(DATABASE.LAWYERS, lawyerId!, false);

  const selectedTemplate = useSelector(
    templatesSelectors.selectSelectedTemplate
  );

  const selectedVisatype: VISAVALUE | undefined =
    selectedTemplate?.visa || undefined;

  const hiddenPrompts = lawyerData?.hiddenPrompts || [];

  const toneOptions = useSelector(promptsSelectors.selectToneOptions)
    .filter((p: Prompt) => !hiddenPrompts?.includes(p.id)) // Filter hidden prompts
    .map((p: Prompt) => p.promptText);

  const rewriteOptions = useSelector(promptsSelectors.selectRewriteOptions)
    .filter((p: Prompt) => !hiddenPrompts?.includes(p.id)) // Filter hidden prompts
    .map((p: Prompt) => p.promptText);

  const [activeMenu, setActiveMenu] = useState<number | null>(null);

  const [promptText, setPromptText] = useState("");
  const [promptError, setPromptError] = useState<string | null>(null);
  const [aiToolbarPosition, setAiToolbarPosition] = useState({
    left: 0,
    top: 0
  });
  const aiToolbarRef = useRef<HTMLDivElement>(null);
  const [menuMaxHeight, setMenuMaxHeight] = useState<number | undefined>(
    undefined
  );

  // Editor container height, used to check for overflow
  const editorHeight = quillRef.current?.getEditor().root.clientHeight;
  const editorWidth = quillRef.current?.getEditor().root.clientWidth;

  useEffect(() => {
    if (range && showToolbar && editorWidth && editorHeight) {
      if (
        aiToolbarRef &&
        aiToolbarRef.current &&
        quillToolbarRef &&
        quillToolbarRef.current
      ) {
        const quillToolbarHeight = quillToolbarRef.current.clientHeight;
        const aitoolbarWidth = aiToolbarRef.current.clientWidth;
        const aitoolbarHeight = aiToolbarRef.current.clientHeight;

        const editorWidth = quillRef.current?.getEditor().root.clientWidth;

        // Determine if toolbar overlaps or overflows
        const isPositionOverlap = bounds.top < quillToolbarHeight;

        const calculatedLeft =
          bounds.left + aitoolbarWidth > editorWidth
            ? bounds.left - (aitoolbarWidth - bounds.width)
            : bounds.left;

        // const baseTopPosition = isPositionOverlap
        //   ? bounds.top + bounds.height + quillToolbarHeight
        //   : bounds.bottom + quillToolbarHeight;
        const baseTopPosition =
          bounds.height > editorHeight
            ? editorHeight / 2
            : quillToolbarHeight + bounds.top + bounds.height + 8;
        setAiToolbarPosition({ left: calculatedLeft, top: baseTopPosition });
      }
    }
  }, [range, showToolbar]);

  const showReferenceMenu = window.location.pathname.includes("individual");
  const selectedTextIsNotEmpty = selectedText && selectedText !== " ";

  useEffect(() => {
    if (!showToolbar) {
      setActiveMenu(null);
      setPromptText("");
      setPromptError(null);
      setPromptisSelected(false);
    }
  }, [showToolbar]);

  return showToolbar ? (
    <Box
      ref={aiToolbarRef}
      className="ai-toolbar min-w-36"
      borderColor={previewText ? "none" : "accent.emerald"}
      backgroundColor="#f0f0f0"
      borderStyle="solid"
      borderWidth="2px"
      borderRadius="4px"
      style={{
        position: "absolute",
        left: `${aiToolbarPosition.left}px`,
        top: `${aiToolbarPosition.top}px`,
        zIndex: 55
      }}
    >
      {previewText && <PreviewText previewText={previewText} />}
      {changingText ? (
        <Text
          as="span"
          display="inline-flex"
          gap={4}
          alignItems="center"
          px={2}
        >
          Generating Text...
          <CircularProgress isIndeterminate size="42px" />
        </Text>
      ) : (
        <div
          className="flex max-w-md"
          style={{ display: previewText ? "none" : "flex" }}
        >
          {selectedTextIsNotEmpty && (
            <Menu
              key="1"
              variant="prompt"
              isOpen={activeMenu === 1}
              onOpen={() => setActiveMenu(1)}
              onClose={() => setActiveMenu(null)}
            >
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                variant="neutralStateButton"
                rounded="0px"
                borderRadius={0}
                px={2}
              >
                Rewrite
              </MenuButton>
              <Portal>
                <MenuList zIndex="9999" overflow="visible">
                  {rewriteOptions.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      onClick={() =>
                        handleAIButtonClick(
                          option,
                          undefined,
                          undefined,
                          undefined,
                          selectedVisatype,
                          enableExhibitNumbers
                        )
                      }
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </Portal>
            </Menu>
          )}
          {selectedTextIsNotEmpty && (
            <div className="flex-1">
              <Divider orientation="vertical" mx={1} />
            </div>
          )}
          {selectedTextIsNotEmpty && (
            <Menu
              key="2"
              variant="prompt"
              isOpen={activeMenu === 2}
              onOpen={() => setActiveMenu(2)}
              onClose={() => setActiveMenu(null)}
            >
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                variant="neutralStateButton"
                rounded="0px"
                borderRadius={0}
                px={2}
              >
                Change tone
              </MenuButton>
              <Portal>
                <MenuList
                  maxHeight={`${menuMaxHeight}px`}
                  zIndex="9999"
                  overflow="visible"
                >
                  {toneOptions.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      onClick={() =>
                        handleAIButtonClick(
                          undefined,
                          option,
                          undefined,
                          undefined,
                          selectedVisatype,
                          enableExhibitNumbers
                        )
                      }
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </Portal>
            </Menu>
          )}
          {selectedTextIsNotEmpty && (
            <div className="flex-1">
              <Divider orientation="vertical" mx={1} />
            </div>
          )}
          <Menu
            key="3"
            isOpen={activeMenu === 3}
            onOpen={() => setActiveMenu(3)}
            onClose={() => setActiveMenu(null)}
          >
            <MenuButton
              as={Button}
              variant="neutralStateButton"
              rounded="0px"
              borderRadius={0}
              px={2}
              onClick={() => {
                const quill = quillRef.current?.getEditor();
                if (range) {
                  const getText = quill?.getText(range.index, range.length);
                  if (getText) setSelectedText(getText);
                }
              }}
            >
              Prompt
            </MenuButton>
            <AIPromptMenu
              setShowToolbar={setShowToolbar}
              promptText={promptText}
              setPromptText={setPromptText}
              promptError={promptError}
              setPromptError={setPromptError}
              setPromptisSelected={setPromptisSelected}
              replaceText={replaceText}
              // images={images}
              selectedText={selectedText || ""}
              fullText={parsedText || ""}
            />
          </Menu>
          <div className="flex-1">
            <Divider orientation="vertical" mx={1} />
          </div>
          {showReferenceMenu && (
            <Menu
              key="4"
              isOpen={activeMenu === 4}
              onOpen={() => setActiveMenu(4)}
              onClose={() => setActiveMenu(null)}
            >
              <MenuButton
                as={Button}
                variant="neutralStateButton"
                rounded="0px"
                borderRadius={0}
                px={2}
                rightIcon={<ChevronDownIcon />}
              >
                Reference
              </MenuButton>

              <ToolbarDropdown
                setPromptisSelected={setPromptisSelected}
                setCheckedItems={setEvidencesToHighlight}
                onClose={() => setActiveMenu(null)}
                onConfirm={async () => {
                  if (evidencesToHighlight.length < 0) {
                    setPromptError("Select documents to reference.");
                    return;
                  }
                  setPromptisSelected(false);
                  setActiveMenu(null);

                  await handleAIButtonClick(
                    undefined,
                    undefined,
                    undefined,
                    evidencesToHighlight,
                    undefined,
                    enableExhibitNumbers
                  );
                  setEvidencesToHighlight([]);
                }}
                groupBy="criterion"
                list={evidenceDocuments}
                checkedItems={evidencesToHighlight}
                mergingField="id"
                searchField="autoTitle"
                withToolTip
              />
            </Menu>
          )}
        </div>
      )}
    </Box>
  ) : null;
};
