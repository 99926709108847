import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import { AddIcon, ChevronDownIcon, DragHandleIcon } from "@chakra-ui/icons";
import { ReactNode, useEffect, useState } from "react";

/**
 * DnD-kit imports
 */
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { getShortString } from "../../helpers/string_helpers";

/**
 * ---------------------------------- Types ----------------------------------
 */
type BaseProps<T> = {
  children?: (props: {
    handleSelect: (item: CheckedItem<T>) => void;
    derivedList: CheckedItem<T>[];
  }) => ReactNode;
  label?: string;
  withArrow?: boolean;
  list: T[];
  isDisabled?: boolean;
  withToolTip?: boolean;
  withFilter?: boolean;
  /**
   * The field you want to search/filter on.
   * If T is just a string array, pass `searchField={undefined}`
   */
  searchField?: keyof T;
  /**
   * The field used for uniqueness. Typically some `id` or `slug` in T.
   * If T is a string array, pass `mergingField={undefined}`
   */
  mergingField?: keyof T;
  /**
   * Field to group items. E.g., `status` or `category` in T.
   */
  groupBy?: keyof T;
  /**
   * The items that should initially be "checked".
   * You can pass their IDs or strings.
   */
  checkedItems?: string[] | null | undefined;
};

type MultiSelectProps<T> = BaseProps<T> & {
  singleSelect: false;
  getSelectedItems: (items: T[]) => void;
  withCheck?: boolean;
  withView?: boolean;
};

type SingleSelectProps<T> = BaseProps<T> & {
  singleSelect: true;
  getSelectedItem: (item: T) => void;
  withCheck?: never;
  withView?: never;
};

type Props<T> = MultiSelectProps<T> | SingleSelectProps<T>;

type CheckedItem<T> = {
  /**
   * This is the displayed text
   */
  value: string;
  /**
   * This is used as the unique DnD/sortable ID
   */
  id: string;
  /**
   * Whether or not the item is currently checked
   */
  checked: boolean;
} & T;

/**
 * --------------------------------- Styles ---------------------------------
 */
const tagStyles = {
  borderRadius: "md",
  border: "1px solid",
  borderColor: "stroke.stroke1Light",
  bg: "transparent",
  color: "primary.blue",
  _hover: { bg: "primary.focusOutline" },
  _focus: { boxShadow: "0 0 0 2px rgba(13, 89, 255, 0.5)" }
};

/**
 * ------------------------------ Sortable Tag -------------------------------
 */
function SortableTag<T>({
  item,
  index,
  onClose
}: {
  item: CheckedItem<T>;
  index: number;
  onClose: (item: CheckedItem<T>) => void;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  return (
    <Box ref={setNodeRef} style={style} display="flex" alignItems="center">
      {/* 1. Drag icon */}
      <Box
        cursor="grab"
        display="flex"
        alignItems="center"
        pr="8px"
        {...attributes}
        {...listeners}
      >
        <DragHandleIcon />
      </Box>

      <Flex
        w="22px"
        textAlign="right"
        mr="8px"
        justifyContent="start"
        alignItems="start"
        fontSize={16}
        fontWeight={500}
      >
        {index + 1}.
      </Flex>

      {/* 3. The Tag with the close button */}
      <Tag size="lg" sx={tagStyles} maxW="600px" overflow="hidden">
        <TagLabel>{item.value}</TagLabel>
        <TagCloseButton
          onClick={(e) => {
            e.stopPropagation(); // Don’t trigger drag
            onClose(item);
          }}
        />
      </Tag>
    </Box>
  );
}

/**
 * --------------------------- Dropdown Component ----------------------------
 */
export const HighlightEvidenceDropdown = <T,>({
  label,
  singleSelect,
  list,
  isDisabled,
  withToolTip = false,
  withFilter = true,
  withArrow = false,
  searchField,
  mergingField,
  groupBy,
  checkedItems,
  children,
  ...rest
}: Props<T>) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedItem, setSelectedItem] = useState<CheckedItem<T>>();

  /**
   * Maintain a derived list of items with "checked" status.
   * We store both:
   *    - The display value (string) in `value`
   *    - A unique id in `id`
   */
  const [derivedList, setDerivedList] = useState<CheckedItem<T>[]>(() =>
    list.map((item, i) => ({
      ...item,
      value: getDisplayValue(item),
      id: getUniqueId(item),
      checked: false,
      stableIndex: i + 1
    }))
  );

  function getUniqueId(item: T): string {
    if (typeof item === "string") return item; // fallback if array of strings
    if (mergingField) return String(item[mergingField]);
    return "";
  }

  function getDisplayValue(item: T): string {
    if (typeof item === "string") return item; // fallback if array of strings
    if (searchField) return String(item[searchField]);
    return "";
  }

  useEffect(() => {
    if (!checkedItems) return;
    setDerivedList((prevList) => {
      // Mark items as checked/un-checked
      const updatedList = prevList.map((item) => ({
        ...item,
        checked: checkedItems.includes(item.id)
      }));

      // Separate the checked items from un-checked items
      const checkedPart = updatedList.filter((item) => item.checked);
      const uncheckedPart = updatedList.filter((item) => !item.checked);

      // Sort the checked items to match the order from `checkedItems`
      checkedPart.sort(
        (a, b) => checkedItems.indexOf(a.id) - checkedItems.indexOf(b.id)
      );

      // Return the new array:
      //   - checked items in the order of `checkedItems`
      //   - then everything else
      return [...checkedPart, ...uncheckedPart];
    });
  }, [checkedItems]);

  // DnD Sensors
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );

  // Called after drag is dropped
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = derivedList.findIndex((el) => el.id === active.id);
    const newIndex = derivedList.findIndex((el) => el.id === over.id);
    if (oldIndex < 0 || newIndex < 0) return;

    // Reorder array
    const newList = arrayMove(derivedList, oldIndex, newIndex);
    setDerivedList(newList);
  };

  // Single or Multi select
  const handleSelect = (item: CheckedItem<T>) => {
    if (singleSelect) {
      setSelectedItem(item);
      setMenuIsOpen(false);
    } else {
      const updatedList = derivedList.map((_item) =>
        _item.id === item.id ? { ..._item, checked: !_item.checked } : _item
      );
      setDerivedList(updatedList);
    }
  };

  // For multi-select usage:
  //   1) notify parent of any changes in checked items
  useEffect(() => {
    if (!singleSelect && "getSelectedItems" in rest) {
      const onlyChecked = derivedList.filter((item) => item.checked);
      // Send back the original T objects
      rest.getSelectedItems(onlyChecked as T[]);
    }
  }, [derivedList]);

  // For single-select usage:
  //   1) notify parent once selectedItem changes
  useEffect(() => {
    if (singleSelect && "getSelectedItem" in rest && selectedItem) {
      rest.getSelectedItem(selectedItem);
    }
  }, [selectedItem]);

  // Filter the list (only for displayed search results)
  const filteredList = derivedList.filter((item) =>
    item.value.toLowerCase().includes(filter.toLowerCase())
  );

  // Group them if needed
  const groupItems = (items: CheckedItem<T>[]) => {
    if (!groupBy) return { "": items };
    return items.reduce<Record<string, CheckedItem<T>[]>>((groups, item) => {
      const key = String(item[groupBy]) || "Uncategorized";
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
      return groups;
    }, {});
  };
  const groupedItems = groupItems(filteredList);

  // For search input
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  return (
    <Flex flexDirection="column" gap={4}>
      <Box mb={2} display="flex" justifyContent="flex-start">
        <Menu isOpen={menuIsOpen} onClose={() => setMenuIsOpen(false)}>
          <Flex flexDirection="column">
            <MenuButton
              textAlign="left"
              bg="white"
              mr={4}
              as={Button}
              isDisabled={isDisabled}
              leftIcon={!singleSelect ? <AddIcon /> : undefined}
              variant="add"
              borderRadius="md"
              onClick={() => setMenuIsOpen((prev) => !prev)}
              rightIcon={withArrow ? <ChevronDownIcon /> : undefined}
            >
              {singleSelect && selectedItem
                ? selectedItem.value
                : label || "Select"}
            </MenuButton>
            <MenuList
              width="30vw"
              maxHeight="50vh"
              overflow="auto"
              display="flex"
              flexDirection="column"
              sx={{
                "&::-webkit-scrollbar": { width: "10px" },
                "&::-webkit-scrollbar-track": {
                  background: "primary.lightGray"
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "accent.lapis",
                  borderRadius: "8px"
                }
              }}
            >
              {withFilter && (
                <Box px={2} mb={2}>
                  <Input
                    placeholder="Search..."
                    variant="filled"
                    bg={useColorModeValue(
                      "background.white",
                      "background.darkWhite"
                    )}
                    _focus={{
                      borderColor: useColorModeValue(
                        "primary.focusOutline",
                        "primary.red"
                      )
                    }}
                    onChange={handleChange}
                  />
                </Box>
              )}

              {/* Render grouped items */}
              {Object.keys(groupedItems).map((groupKey) => (
                <Box key={groupKey}>
                  {groupKey && groupKey !== "" && (
                    <Text fontWeight="bold" px={4} py={2} color="black">
                      {groupKey}
                    </Text>
                  )}
                  {groupedItems[groupKey].map((item, index) => (
                    <MenuItem
                      key={`${item.id}-${index}`}
                      closeOnSelect={singleSelect}
                      onClick={() => handleSelect(item)}
                      _hover={{ bg: "primary.focusOutline" }}
                    >
                      {"withCheck" in rest && rest.withCheck ? (
                        <Checkbox pointerEvents="none" isChecked={item.checked}>
                          {withToolTip ? (
                            <Tooltip label={item.value}>
                              {getShortString(item.value)}
                            </Tooltip>
                          ) : (
                            <Text color="black">{item.value}</Text>
                          )}
                        </Checkbox>
                      ) : (
                        <Text color="black">{item.value}</Text>
                      )}
                    </MenuItem>
                  ))}
                </Box>
              ))}
            </MenuList>
          </Flex>
        </Menu>
      </Box>

      {/* If multi-select with a "view" of the tags */}
      {!singleSelect && rest.withView && (
        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
          <SortableContext
            items={derivedList.filter((i) => i.checked).map((i) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <VStack align="stretch" spacing={3}>
              {derivedList
                .filter((item) => item.checked)
                .map((item, i) => (
                  <Flex key={item.id} align="flex-start" width="100%">
                    {/* 2) Pass “index” to SortableTag */}
                    <SortableTag item={item} index={i} onClose={handleSelect} />
                  </Flex>
                ))}
            </VStack>
          </SortableContext>
        </DndContext>
      )}

      {children && children({ handleSelect, derivedList })}
    </Flex>
  );
};
