import { useState } from "react";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";

import axiosInstance from "../api/axiosConfig";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { individualSelectors } from "../redux/individual/selectors";

export const useGoogleDriveAuth = () => {
  const [hasAccessToken, setHasAccessToken] = useState<boolean>(false);
  const toast = useToast();
  const lawyerId = useSelector(lawyerSelectors.selectUid);
  const individualId = useSelector(individualSelectors.selectUid);
  const userId = lawyerId || individualId;

  const checkAccessToken = async () => {
    toast({
      title: "Connecting to Google Drive...",
      status: "loading",
      duration: 1000,
      isClosable: false
    });
    if (!userId) return false;

    try {
      const response = await axiosInstance.get("/google-drive/credentials");
      if (response.data?.token) {
        setHasAccessToken(true);
        return true;
      }
      setHasAccessToken(false);
      return false;
    } catch (error) {
      setHasAccessToken(false);
      return false;
    }
  };

  const handleAuth = async (feRedirectUrl: string) => {
    const response = await axiosInstance.get("/google-drive/auth-url", {
      params: {
        feRedirectUrl
      }
    });
    window.location.href = response.data.auth_url;
  };

  return {
    hasAccessToken,
    checkAccessToken,
    handleAuth
  };
};
