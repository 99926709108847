import {
  Box,
  Flex,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PremiumModal } from "../components/common/PremiumModal";
import { SectionWrapper } from "../components/common/SectionWrapper";
import { CaseResearch } from "../components/individualTabs/CaseResearch";
import { DocumentsPage } from "../components/individualTabs/DocumentsPage";
import { IndividualDrafts } from "../components/individualTabs/IndividualDrafts";
import { Packet } from "../components/individualTabs/IndividualPacket";
import { CaseStrategyPage } from "../components/individualTabs/individualQuestionnaires/CaseStrategyPage";
import { shouldAccess } from "../helpers/firebase_helpers";
import { useGetIndividualData } from "../hooks/useGetIndividualData";

const IndividualCase = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, visaType } = useParams();

  const [currentTabIndex, setCurrentTabIndex] = useState<number | undefined>(
    undefined
  );

  let loginType = localStorage.getItem("loginType");

  const {
    onSubmitGetIndividualData,
    isLoadingGetIndividualData,
    individualData
  } = useGetIndividualData();

  const [premiumModalOpen, setPremiumModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigateToPath = (tab: string) => {
    const path = `/individual/${id}/${visaType}/${tab}`;
    navigate(path);
  };

  useEffect(() => {
    if (id) {
      onSubmitGetIndividualData(id);
    }
  }, []);

  type Tab = {
    label: string;
    id: string;
    index: number;
    visible: boolean;
    page: React.ReactNode;
  };

  const tabs: Tab[] = [
    {
      index: 0,
      id: "documents",
      label: "Document AI",
      visible: true,
      page: <DocumentsPage />
    },
    {
      index: 1,
      id: "questionnaires",
      label: "Case Strategy",
      visible: true,
      page: <CaseStrategyPage />
    },
    {
      index: 2,
      id: "case-research",
      label: "Case research",
      visible: true,
      page: <CaseResearch />
    },
    {
      index: 3,
      id: "drafts",
      label: "Drafts",
      visible: true,
      page: <IndividualDrafts />
    },
    { index: 4, id: "packet", label: "Packet", visible: true, page: <Packet /> }
  ];

  const filteredTabs = tabs
    .map((tab) => ({
      ...tab,
      visible: shouldAccess(individualData, loginType, tab.label)
    }))
    .filter((tab) => tab.visible);

  useEffect(() => {
    loginType = localStorage.getItem("loginType");
    const selectedTab = location.pathname.split("/").pop();
    const selectedTabIndex = tabs.find((tab) => tab.id === selectedTab);
    setCurrentTabIndex(selectedTabIndex?.index ?? 0);
    setLoading(false);
  }, [location.pathname]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <SectionWrapper withBackNav={false} padding={0}>
      <Flex flexDirection="column" className="relative" flex="1">
        <Tabs bg="#FFFFFF" index={currentTabIndex}>
          <TabList>
            {filteredTabs.map((tab) => (
              <Tab
                key={tab.id}
                fontWeight={600}
                onClick={() => navigateToPath(tab.id)}
              >
                {tab.label}
              </Tab>
            ))}
          </TabList>
          <TabIndicator mt="-1.5px" />

          <TabPanels>
            {filteredTabs.map((tab) => (
              <TabPanel key={tab.id}>{tab.page}</TabPanel>
            ))}
          </TabPanels>
          <PremiumModal
            isOpen={premiumModalOpen}
            onClose={() => {
              setPremiumModalOpen(false);
            }}
          />
        </Tabs>

        {/* Sticky TabNavigation */}
        <Box
          display="none"
          className="border"
          position="sticky"
          bottom="0"
          zIndex="10"
          bg="white"
          borderBottomRadius={12}
          py={2}
        >
          {/* comment until we decide to keep it */}
          {/* <TabNavigation
            currentTabIndex={
              subtabs.length > 0 ? currentSubTabIndex : currentMainTabIndex
            }
            totalTabs={
              subtabs.length > 0 ? subtabs.length : mainTabsWithSubtabs.length
            }
            onBack={handleBack}
            onNext={handleNext}
            prevTabLabel={prevTabLabel || ""}
            nextTabLabel={nextTabLabel || ""}
          /> */}
        </Box>
      </Flex>
    </SectionWrapper>
  );
};

export default IndividualCase;
