import { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import { EditorContext } from "../../context/EditorContext";
import { useEditorHandlers } from "../../hooks/useEditorHandlers";

type EditorProviderProps = {
  children: React.ReactNode;
  text: string;
  updateText?: (html: string, plainText: string) => void;
  saveText?: (html: string, plainText: string) => Promise<void>;
  isFormEditor?: boolean;
};

export const EditorProvider = ({
  children,
  text,
  updateText = () => {},
  saveText,
  isFormEditor
}: EditorProviderProps) => {
  const quillRef = useRef<ReactQuill>(null);
  const editorHandlers = useEditorHandlers(
    quillRef,
    text,
    updateText,
    saveText,
    isFormEditor
  );
  const contextValue = useMemo(
    () => ({ ...editorHandlers, quillRef }),
    [editorHandlers, quillRef]
  );

  return (
    <EditorContext.Provider value={contextValue}>
      {children}
    </EditorContext.Provider>
  );
};
