import { CheckCircleIcon } from "@chakra-ui/icons";
import { CircularProgress, Text, Tooltip } from "@chakra-ui/react";
import { Timestamp } from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { DataDocs } from "../../types/tables-data";
import CustomTooltip from "./CustomTooltip";

const CURRENT_TIME_IN_SECONDS = Timestamp.now().seconds;

type Props = {
  document: DataDocs | undefined;
};
export const NewBadge = () => {
  return (
    <Text
      bg="#268600"
      borderRadius={4}
      px={1}
      fontSize="sm"
      width="fit-content"
      height="fit-content"
      textColor="white"
    >
      NEW
    </Text>
  );
};

export const DocumentRowNewBadge: React.FC<Props> = ({ document }) => {
  const isDocumentNew =
    document?.isNew && document.super_class !== "Processing";

  if (!isDocumentNew) return null;

  return <NewBadge />;
};

export const DocumentRowStatus: React.FC<Props> = ({ document }) => {
  const documentHasSummary = document?.summary;
  const summaryUpdatedAt = document?.summaryUpdatedAt?.seconds || 0;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!summaryUpdatedAt) return () => {};

    const animationEndAt = summaryUpdatedAt + 3;
    const delay = Math.max(animationEndAt - CURRENT_TIME_IN_SECONDS, 0);
    if (delay > 0) {
      setIsVisible(true);
    }
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, delay * 1000);

    return () => clearTimeout(timer);
  }, [document?.summaryUpdatedAt]);

  const styles: React.CSSProperties = {
    transition: "opacity 1s ease-in",
    opacity: isVisible ? 1 : 0
  };

  return documentHasSummary ? (
    <CheckCircleIcon style={styles} color="#268600" />
  ) : (
    <CustomTooltip label="Generating summary...">
      <CircularProgress isIndeterminate color="primary.lightGray" size={4} />
    </CustomTooltip>
  );
};
