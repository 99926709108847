import {
  Box,
  Button,
  chakra,
  Collapse,
  Flex,
  IconButton,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

// Example icons
import { DeleteIcon } from "@chakra-ui/icons";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import {
  MdOutlineLibraryBooks,
  MdOutlineUnfoldMore,
  MdUnfoldLess
} from "react-icons/md";

import CustomTooltip from "../../common/CustomTooltip";
import { AiGenerateIcon } from "../../common/Icons";

import {
  getResearchAreaName,
  getShortString
} from "../../../helpers/string_helpers";
import { EXTRACTIONSTATES } from "../../../redux/extraction-jobs/extractionJobsSlice";
import {
  CaseResearchTopic,
  ResearchAreaKey,
  researchAreaNames
} from "../../../types/case-research";
import { TopicRow } from "./TopicRow";
import MagicIcon from "../../common/MagicIcon";

interface ResearchTopicsTableProps {
  topics: CaseResearchTopic[];
  onViewSummaries?: (researchArea?: string, topicId?: string) => void;
  onEnhanceTopic?: (topicId: string, topicTitle: string) => void;
  onDeleteTopic?: (topicId: string) => void;
}

export const CaseResearchDatatable: React.FC<ResearchTopicsTableProps> = ({
  topics,
  onViewSummaries,
  onEnhanceTopic,
  onDeleteTopic
}) => {
  // Group all topics by their research_area
  const groupedBySuperClass = React.useMemo(() => {
    const map: Record<string, CaseResearchTopic[]> = {};
    topics.forEach((topic) => {
      if (!map[topic.super_class]) {
        map[topic.super_class] = [];
      }
      map[topic.super_class].push(topic);
    });
    return map;
  }, [topics]);

  // Track which bigger sections (areas) are expanded
  const [expandedAreas, setExpandedAreas] = useState<Record<string, boolean>>(
    () =>
      Object.keys(groupedBySuperClass).reduce(
        (acc, key) => ({ ...acc, [key]: true }),
        {}
      )
  );

  useEffect(() => {
    setExpandedAreas((prevExpanded) => {
      const updatedExpanded = { ...prevExpanded };

      Object.keys(groupedBySuperClass).forEach((area) => {
        const prevCount = prevExpanded[area]
          ? groupedBySuperClass[area]?.length || 0
          : 0;
        const newCount = groupedBySuperClass[area]?.length || 0;

        if (!(area in updatedExpanded) || newCount > prevCount) {
          updatedExpanded[area] = true; // Expand newly added areas or areas that received new topics
        }
      });

      return updatedExpanded;
    });
  }, [JSON.stringify(groupedBySuperClass)]); // Ensures deep comparison

  // Track which topics are expanded
  const [expandedTopics, setExpandedTopics] = useState<Record<string, boolean>>(
    {}
  );

  const toggleArea = (area: string) => {
    setExpandedAreas((prev) => ({ ...prev, [area]: !prev[area] }));
  };

  const toggleTopic = (topicId: string) => {
    setExpandedTopics((prev) => ({ ...prev, [topicId]: !prev[topicId] }));
  };

  const headerBg = useColorModeValue(
    "background.tableHeader !important",
    "background.tableHeader !important"
  );
  const areaRowBg = useColorModeValue("blue.50", "blue.900");

  return (
    <Box w="100%" overflowX="auto">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            {/* Three columns: 20% / 60% / 20%, adjust widths as needed */}
            <Th textAlign="start">RESEARCH TOPIC</Th>
            <Th textAlign="start">
              <Box>AI-GENERATED TITLE</Box>
            </Th>
            <Th textAlign="center">ACTION</Th>
          </Tr>
        </Thead>

        <Tbody>
          {Object.entries(groupedBySuperClass).map(
            ([superClass, areaTopics]) => {
              const areaExpanded = expandedAreas[superClass] ?? false;

              return (
                <React.Fragment key={superClass}>
                  {/* 1) AREA HEADER ROW */}
                  <Tr
                    bg={headerBg}
                    color="white"
                    fontWeight="bold"
                    cursor="pointer"
                    onClick={() => toggleArea(superClass)}
                  >
                    {/* Merge first two columns */}
                    <Td colSpan={2}>
                      <Flex align="center" ml={4}>
                        <Box mr={2}>
                          {areaExpanded ? (
                            <MdUnfoldLess size={20} />
                          ) : (
                            <MdOutlineUnfoldMore size={20} />
                          )}
                        </Box>
                        <Text textTransform="uppercase">{superClass}</Text>
                      </Flex>
                    </Td>

                    {/* ACTION column for area-level button */}
                    <Td textAlign="right">
                      <Button
                        size="sm"
                        bg="transparent"
                        border="1px solid white"
                        _hover={{
                          bg: "rgba(255, 255, 255, 0.25)"
                        }}
                        px={4}
                        leftIcon={<MdOutlineLibraryBooks />}
                        onClick={(e) => {
                          e.stopPropagation();
                          onViewSummaries?.(superClass);
                        }}
                      >
                        View Summaries
                      </Button>
                    </Td>
                  </Tr>

                  {/* 2) AREA TOPICS (conditionally rendered if areaExpanded) */}
                  {areaExpanded &&
                    areaTopics.map((topic) => {
                      const topicExpanded = expandedTopics[topic.id] ?? false;

                      return (
                        <React.Fragment key={topic.id}>
                          {/* TOPIC MAIN ROW */}
                          {topic?.status?.status ===
                          EXTRACTIONSTATES.Processing ? (
                            <Tr>
                              <Td w="40%">
                                <Skeleton height="20px" ml={6} />
                              </Td>
                              <Td w="40%">
                                <Skeleton height="20px" />
                              </Td>
                              <Td w="20%" textAlign="right">
                                <Skeleton height="20px" width="80px" />
                              </Td>
                            </Tr>
                          ) : (
                            <Tr
                              _hover={{
                                bg: useColorModeValue("gray.50", "gray.800")
                              }}
                              cursor="pointer"
                              onClick={() => toggleTopic(topic.id)}
                            >
                              <Td w="40%">
                                <Flex align="center" ml={6}>
                                  <Box mr={2}>
                                    {topicExpanded ? (
                                      <FiChevronDown />
                                    ) : (
                                      <FiChevronRight />
                                    )}
                                  </Box>
                                  <TopicRow
                                    topicKey={topic.topicTitle}
                                    super_class={topic.super_class}
                                  />
                                </Flex>
                              </Td>
                              <Td w="40%" />
                              <Td w="20%" textAlign="right">
                                <CustomTooltip label="Re-generate">
                                  <IconButton
                                    variant="filledIconButton"
                                    size="md"
                                    aria-label="Re-generate Topic"
                                    icon={
                                      <AiGenerateIcon
                                        boxSize={6}
                                        fill="primary.blue"
                                      />
                                    }
                                    mr={2}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onEnhanceTopic?.(
                                        topic.id,
                                        topic.topicTitle
                                      );
                                    }}
                                    sx={{
                                      "&:hover svg": {
                                        fill: "white"
                                      }
                                    }}
                                  />
                                </CustomTooltip>

                                <CustomTooltip label="View">
                                  <IconButton
                                    variant="filledIconButton"
                                    size="md"
                                    aria-label="View Summaries"
                                    icon={<MdOutlineLibraryBooks size={18} />}
                                    mr={2}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onViewSummaries?.(
                                        topic.super_class,
                                        topic.id
                                      );
                                    }}
                                  />
                                </CustomTooltip>

                                <CustomTooltip label="Delete">
                                  <IconButton
                                    variant="filledIconButton"
                                    size="md"
                                    aria-label="Delete Topic"
                                    icon={<DeleteIcon boxSize={4} />}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onDeleteTopic?.(topic.id);
                                    }}
                                  />
                                </CustomTooltip>
                              </Td>
                            </Tr>
                          )}

                          {/* 3) TOPIC SUB-ROWS (sources), only if topicExpanded */}
                          {topicExpanded &&
                            topic.sources.map((src, idx) => (
                              <Tr
                                key={idx}
                                _hover={{
                                  bg: useColorModeValue("white", "gray.900")
                                }}
                              >
                                {/* Left cell for indentation or blank */}
                                <Td>
                                  <Box ml={12}>
                                    <CustomTooltip
                                      label={
                                        typeof src === "string"
                                          ? src
                                          : src.source
                                      }
                                    >
                                      <Box display="block" width="200px">
                                        <Text
                                          as="div"
                                          color="blue.600"
                                          sx={{
                                            display: "-webkit-box",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                          }}
                                        >
                                          <a
                                            href={
                                              typeof src === "string"
                                                ? src
                                                : src.source
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {getShortString(
                                              typeof src === "string"
                                                ? src
                                                : src.source,
                                              140
                                            )}
                                          </a>
                                        </Text>
                                      </Box>
                                    </CustomTooltip>
                                  </Box>
                                </Td>

                                {/* Keep extra columns to maintain uniformity */}
                                <Td colSpan={2}>
                                  {typeof src !== "string" && (
                                    <Box py={2}>
                                      <CustomTooltip label={src.autoTitle}>
                                        <Box
                                          display="inline-block"
                                          width="200px"
                                        >
                                          <Text noOfLines={2}>
                                            {getShortString(
                                              src.autoTitle || "",
                                              140
                                            )}
                                          </Text>
                                        </Box>
                                      </CustomTooltip>
                                    </Box>
                                  )}
                                </Td>
                              </Tr>
                            ))}
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            }
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
