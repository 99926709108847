import {
  Box,
  Button,
  Card,
  CardFooter,
  CircularProgress,
  Flex,
  useToast
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useIndivCoverLetter } from "../../../../hooks/useCoverLetterDrafts";
import { individualSelectors } from "../../../../redux/individual/selectors";
import { Editor } from "../../../common/Editor";
import { SpecialHeading } from "../../PanelLayout";

export const EditCoverLetterDraft = () => {
  const { id, visaType } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const editorRef = useRef(null);
  const [value, setValue] = useState(null);
  const { expertId } = useParams();
  const {
    updateCoverLetter,
    getCoverLetter,
    isLoadingCoverLetter,
    coverLetter
  } = useIndivCoverLetter(uid);

  useEffect(() => {
    if (uid) getCoverLetter();
  }, [uid]);

  useEffect(() => {
    if (coverLetter?.value) {
      setValue(coverLetter.value.text);
    }
  }, [coverLetter]);

  const handleSubmit = async (html: string, plainText: string) => {
    const payload = { text: plainText, html };
    if (uid) await updateCoverLetter(payload);
    toast({
      title: "Success",
      description: "Draft updated successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "bottom-right"
    });
    navigate(`/individual/${id}/${visaType}/drafts`);
  };

  return (
    <Card mb="30px" w="90%">
      <Flex flexDirection="column" rowGap="20px" alignItems="center">
        <SpecialHeading title="Draft cover letter" />
        {isLoadingCoverLetter ? (
          <CircularProgress isIndeterminate />
        ) : (
          <Card p="15px" alignItems="center">
            {coverLetter?.value ? (
              <Editor
                text={coverLetter.value.html || coverLetter.value.text}
                saveText={(html, plainText) => handleSubmit(html, plainText)}
              />
            ) : (
              <Box fontSize="18px">
                Click Generate in the previous page for a first draft
              </Box>
            )}
          </Card>
        )}
        <CardFooter>
          <Flex flexDirection="row" columnGap={4}>
            <Button
              my={4}
              className="btn tertiary-btn"
              onClick={() => navigate(`/individual/${id}/${visaType}/drafts`)}
              width="fit-content"
            >
              Back
            </Button>
          </Flex>
        </CardFooter>
      </Flex>
    </Card>
  );
};
