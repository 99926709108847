import React, { FC, HTMLAttributes } from "react";
import { Box, ChakraProps, HStack, Text } from "@chakra-ui/react";
import { TopicKey, topicsMetdata } from "../../../types/case-research";
import OutlinedBox from "../../common/OutlinedBox";

interface TopicRowProps
  extends ChakraProps,
    Omit<HTMLAttributes<HTMLDivElement>, "color"> {
  topicKey: string;
  super_class: string;
}
export const TopicRow: FC<TopicRowProps> = ({
  topicKey,
  super_class,
  ...props
}) => {
  const topic = topicsMetdata.find(
    (t) => topicKey.startsWith(t.key) || super_class === t.super_class
  );

  const Icon = topic?.Icon;

  return (
    <OutlinedBox {...props}>
      <HStack spacing={2}>
        {Icon && <Icon boxSize={6} />}
        <Text>{topicKey}</Text>
      </HStack>
    </OutlinedBox>
  );
};
