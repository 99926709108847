import {
  Box,
  Collapse,
  IconButton,
  Flex,
  Text,
  useDisclosure,
  ChakraProps
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import React, { HTMLAttributes } from "react";
import OutlinedBox from "./OutlinedBox";

interface ExpandableSectionProps
  extends ChakraProps,
    Omit<HTMLAttributes<HTMLDivElement>, "color"> {
  title: string;
  children?: React.ReactNode;
}

const ExpandableSection: React.FC<ExpandableSectionProps> = ({
  title,
  children,
  ...props
}) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  return (
    <Box
      bg="background.white"
      border="1px solid"
      borderColor="stroke.stroke2"
      borderRadius="md"
      p={2}
      {...props}
    >
      <Flex
        alignItems="center"
        cursor="pointer"
        onClick={onToggle}
        gap={2}
        p={1}
      >
        <IconButton
          icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          aria-label="Toggle section"
          variant="ghost"
        />
        <OutlinedBox>
          <Text fontSize="md" fontWeight="medium" color="text.display.light">
            {title}
          </Text>
        </OutlinedBox>{" "}
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Box mt={1} pl={2}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default ExpandableSection;
