import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  IconButton,
  Skeleton,
  Spacer,
  Text,
  Tooltip
} from "@chakra-ui/react";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CSSProperties } from "react";
import {
  MdAddCircleOutline,
  MdOutlineDocumentScanner,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
  MdOutlineLibraryBooks,
  MdOutlineUnfoldMore,
  MdUnfoldLess
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { closestCenter, DndContext, pointerWithin } from "@dnd-kit/core";
import { GroupedDocument, SuperGroupedDocument } from "../../helpers/helpers";
import { ensureSuperclassFormat } from "../../helpers/string_helpers";
import { DataDocs } from "../../types/tables-data";
import {
  DocumentRowNewBadge,
  DocumentRowStatus,
  NewBadge
} from "./DocumentRowStatus";

import { EXTRACTIONSTATES } from "../../redux/extraction-jobs/extractionJobsSlice";
import { DraggableDiv, RowDragHandleCell, SubGroupDiv } from "./DndHelpers";
import { CriteriaIcon } from "./Icons";
import CustomTooltip from "./CustomTooltip";

type DraggableSuperClassProps = {
  superGroup: SuperGroupedDocument;
  toggleSuperGroupExpansion: (superClass: string, fold: boolean) => void;
  toggleGroupExpansion: (superClass: string, groupType: string) => void;
  isAiToggled: boolean;
  updateDocumentNewField: (document: DataDocs | undefined) => void;
  handleDeleteClick: (document: DataDocs) => void;
  handleEditClick: (document: DataDocs) => void;
  handleCategoryDeleteClick: (superClass: string, groupType: string) => void;
  handlePreviewClick: (document: DataDocs) => void;
  handleAddByCategory: (category: string, superClass: string) => void;
  isDraggingSuperClass: boolean;
};

export const DraggableCategory = ({
  group,
  superGroup,
  toggleGroupExpansion,
  updateDocumentNewField,
  handleDeleteClick,
  handleEditClick,
  handleCategoryDeleteClick,
  handlePreviewClick,
  handleAddByCategory,
  isAiToggled,
  index,
  isDraggingSuperClass
}: {
  group: GroupedDocument;
  superGroup: SuperGroupedDocument;
  toggleGroupExpansion: (superClass: string, groupType: string) => void;
  updateDocumentNewField: (document: DataDocs | undefined) => void;
  isAiToggled: boolean;
  handleDeleteClick: (document: DataDocs) => void;
  handleEditClick: (document: DataDocs) => void;
  handleCategoryDeleteClick: (superClass: string, groupType: string) => void;
  handlePreviewClick: (document: DataDocs) => void;
  handleAddByCategory: (category: string, superClass: string) => void;
  isDraggingSuperClass: boolean;
  index: number;
}) => {
  const navigate = useNavigate();
  const { visaType: visaTypeInParam, id: uid } = useParams();

  const documentsHasCriteria = (documents: DataDocs[]) => {
    return documents.some((doc) => {
      const generalClasses = Object.values(doc.general_class || {});
      return generalClasses.includes("CRITERIA");
    });
  };
  const {
    transform,
    transition,
    setNodeRef,
    isDragging,
    listeners,
    attributes,
    over,
    isOver,
    active
  } = useSortable({
    id: `cat:${group.type}:${superGroup.super_class}`,
    data: {
      metadata: {
        container: "group",
        group: group.type,
        superClass: superGroup.super_class
      }
    },
    strategy: verticalListSortingStrategy
  });

  const items = group.subrows.map((doc) => ({
    id: doc.id!
  }));

  const isOverAndDifferentCategory =
    active?.data.current?.metadata?.container === "row" &&
    active?.data.current?.metadata?.group !== group.type &&
    isOver;

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition: transition ?? "",
    opacity: isDragging ? 0.7 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
    boxShadow: isDragging ? "0 8px 16px rgba(0, 0, 0, 0.3)" : "",
    background: isOverAndDifferentCategory ? "rgb(226, 232, 240)" : "white"
  };

  return (
    <Box style={style} ref={setNodeRef}>
      <Box py={3} px={3}>
        <div className="flex items-center ml-4">
          <div className="flex items-center gap-2 w-fit">
            <RowDragHandleCell
              rowId={`cat:${group.type}:${superGroup.super_class}`}
            />
            <IconButton
              variant="filledIconButton"
              onClick={() => {
                toggleGroupExpansion(superGroup.super_class, group.type);
              }}
              aria-label=""
              icon={
                group.expanded ? (
                  <MdOutlineExpandLess />
                ) : (
                  <MdOutlineExpandMore />
                )
              }
            />
            <div>
              {!group.type || group.type === "" ? (
                <Skeleton w={20} h={8} />
              ) : (
                <div className="flex gap-2 justify-center items-center">
                  <Text
                    border="1px"
                    borderColor="#01004D"
                    px={2}
                    py={1}
                    borderRadius={6}
                    color="#01004D"
                  >
                    {documentsHasCriteria(group.subrows) && (
                      <Box as={CriteriaIcon} boxSize={6} h={6} w={6} />
                    )}
                    {group.type}
                  </Text>

                  {group.subrows.some((doc) => doc.isNew) && <NewBadge />}
                </div>
              )}
            </div>
          </div>
          <Spacer />
          <Flex gap={2}>
            {group.subrows.some(
              (doc) =>
                !doc.status ||
                !doc.status.status ||
                doc?.status?.status !== EXTRACTIONSTATES.Completed
            ) && (
              <CustomTooltip label="Processing documents">
                <CircularProgress isIndeterminate size={8} color="blue.500" />
              </CustomTooltip>
            )}
            {/* view summaries button for categories */}
            {superGroup.super_class !== "Processing" && (
              <>
                <CustomTooltip label="View summary">
                  <IconButton
                    variant="filledIconButton"
                    icon={<MdOutlineLibraryBooks size={18} />}
                    aria-label="test"
                    onClick={() => {
                      const { super_class } = superGroup;
                      const currentGroup = group.type;
                      const firstDocumentId = group.subrows[0].id;

                      const path = `/individual/${uid}/${visaTypeInParam}/documents/${super_class}/extracted-information?category=${currentGroup}&docId=${firstDocumentId}`;
                      navigate(path);
                    }}
                  />
                </CustomTooltip>
                <CustomTooltip label="Add Documents">
                  <IconButton
                    variant="filledIconButton"
                    mx={0}
                    icon={<MdAddCircleOutline size={24} />}
                    onClick={() =>
                      handleAddByCategory(group.type, superGroup.super_class)
                    }
                    aria-label=""
                  />
                </CustomTooltip>
                <CustomTooltip label="Delete category">
                  <IconButton
                    variant="filledIconButton"
                    icon={<DeleteIcon />}
                    onClick={() =>
                      handleCategoryDeleteClick(
                        superGroup.super_class,
                        group.type
                      )
                    }
                    aria-label=""
                  />
                </CustomTooltip>
              </>
            )}
          </Flex>
        </div>
        {/* subrows */}
        <SortableContext
          key={`sub-${group.type}`}
          items={items}
          disabled={isDraggingSuperClass}
        >
          {group.expanded &&
            group.subrows.map((document, i) => (
              <SubGroupDiv
                id={document.id}
                key={document.id}
                metadata={{
                  container: "row",
                  group: group.type,
                  superClass: superGroup.super_class,
                  id: document.id
                }}
              >
                <Divider mt={2} />

                <Box
                  bg="white"
                  textColor="black"
                  className="flex justify-between items-center h-[70px] py-2 pl-2"
                >
                  <div className="flex gap-2 items-center">
                    <RowDragHandleCell rowId={document.id ?? ""} />
                    {`${index + 1}.${i + 1}`}
                    <div className="flex max-w-[400px] w-[400px]">
                      <div className="flex flex-col">
                        <DocumentRowNewBadge document={document} />
                        <p>{document?.type ?? ""}</p>
                        {/* We are not displaying the general class anymore */}
                        {/* <small className="text-black text-[10px]">
                          {document?.general_class?.toString()}
                        </small> */}
                      </div>
                    </div>
                  </div>
                  <Box className="flex-1">
                    {document.criterion !== "Processing" &&
                    !document.autoTitle ? (
                      <Skeleton w={20} h={8} />
                    ) : (
                      <div className="flex justify-start">
                        <Tooltip
                          label={
                            isAiToggled && document.criterion !== "Processing"
                              ? document?.autoTitle
                              : document?.docNames
                          }
                        >
                          <Text
                            textAlign="left"
                            noOfLines={2}
                            textColor="text.graySecondary.smog"
                          >
                            {isAiToggled && document.criterion !== "Processing"
                              ? document?.autoTitle
                              : document?.docNames}
                          </Text>
                        </Tooltip>
                      </div>
                    )}
                  </Box>
                  <Box>
                    <Flex gap={2} justify="end" alignItems="center">
                      <Box border="none" pr={4}>
                        <DocumentRowStatus document={document} />
                      </Box>

                      {superGroup.super_class !== "Processing" && (
                        <>
                          <CustomTooltip label="Preview document">
                            <IconButton
                              variant="filledIconButton"
                              icon={<MdOutlineDocumentScanner />}
                              onClick={() => {
                                handlePreviewClick(document);
                                updateDocumentNewField(document);
                              }}
                              aria-label=""
                            />
                          </CustomTooltip>

                          <CustomTooltip label="Delete document">
                            <IconButton
                              variant="filledIconButton"
                              icon={<DeleteIcon />}
                              onClick={() => {
                                handleDeleteClick(document);
                              }}
                              aria-label=""
                            />
                          </CustomTooltip>
                          <CustomTooltip label="Edit document">
                            <IconButton
                              variant="filledIconButton"
                              icon={<EditIcon />}
                              onClick={() => handleEditClick(document)}
                              aria-label=""
                            />
                          </CustomTooltip>
                        </>
                      )}
                    </Flex>
                  </Box>
                </Box>
              </SubGroupDiv>
            ))}
        </SortableContext>
      </Box>
      <Divider w="full" h={0.5} />
    </Box>
  );
};

export const DraggableSuperClass = (props: DraggableSuperClassProps) => {
  const {
    superGroup,
    toggleSuperGroupExpansion,
    toggleGroupExpansion,
    isAiToggled,
    updateDocumentNewField,
    handleDeleteClick,
    handleEditClick,
    handleCategoryDeleteClick,
    handlePreviewClick,
    isDraggingSuperClass,
    handleAddByCategory
  } = props;
  const { visaType: visaTypeInParam, id: uid } = useParams();
  const navigate = useNavigate();
  const isDisabled = superGroup.super_class === "Processing";

  return (
    <DraggableDiv
      key={superGroup.super_class}
      id={superGroup.super_class}
      metadata={{
        container: "super_class",
        superClass: superGroup.super_class
      }}
      disabled={isDisabled}
    >
      <Box key={superGroup.super_class} className=" text-white">
        {/* super group header */}
        <div className="flex items-center py-2 px-3 uppercase font-medium !bg-[#4A6ABB]">
          {!isDisabled && (
            <RowDragHandleCell rowId={superGroup.super_class} color="white" />
          )}
          <span className="inline-flex items-center gap-2 ">
            {superGroup.groups.some((group) => group.expanded) ? (
              <IconButton
                bg="transparent"
                title="Collapse All"
                icon={<MdUnfoldLess size={24} />}
                aria-label="unfold"
                onClick={() =>
                  toggleSuperGroupExpansion(superGroup.super_class, false)
                }
              />
            ) : (
              <IconButton
                bg="transparent"
                title="Expand All"
                icon={<MdOutlineUnfoldMore size={24} />}
                aria-label="fold "
                onClick={() =>
                  toggleSuperGroupExpansion(superGroup.super_class, true)
                }
              />
            )}
            {ensureSuperclassFormat(superGroup.super_class)}
          </span>
          <Spacer />
          {superGroup.super_class !== "Processing" && (
            <div className="flex flex-shrink gap-1 items-center justify-end ">
              <Button
                onClick={() => {
                  if (superGroup.super_class !== "Processing") {
                    const path = `/individual/${uid}/${visaTypeInParam}/documents/${superGroup.super_class}/extracted-information`;
                    navigate(path);
                  }
                }}
                bg="transparent"
                border="1px solid white"
                _hover={{
                  bg: "rgba(255, 255, 255, 0.25)"
                }}
                px={6}
                leftIcon={<MdOutlineLibraryBooks />}
              >
                View Summaries
              </Button>
            </div>
          )}
        </div>

        {/* Categories of super_class */}
        <SortableContext
          disabled={isDraggingSuperClass}
          strategy={verticalListSortingStrategy}
          key={`sortable-${superGroup.super_class}`}
          items={superGroup.groups.map((group) => ({
            id: `cat:${group.type}:${superGroup.super_class}`
          }))}
        >
          {/* categories */}
          {superGroup.groups.map(
            (group, index) =>
              group.subrows.length > 0 && (
                <DraggableCategory
                  key={`cat:${group.type}:${superGroup.super_class}`}
                  handleDeleteClick={handleDeleteClick}
                  handleEditClick={handleEditClick}
                  handleCategoryDeleteClick={handleCategoryDeleteClick}
                  handlePreviewClick={handlePreviewClick}
                  index={index}
                  isAiToggled={isAiToggled}
                  group={group}
                  superGroup={superGroup}
                  toggleGroupExpansion={toggleGroupExpansion}
                  updateDocumentNewField={updateDocumentNewField}
                  isDraggingSuperClass={isDraggingSuperClass}
                  handleAddByCategory={handleAddByCategory}
                />
              )
          )}
        </SortableContext>
      </Box>
    </DraggableDiv>
  );
};
