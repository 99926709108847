import { useState, useEffect, useRef } from "react";
import {
  getAuth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  MultiFactorInfo,
  MultiFactorResolver,
  RecaptchaVerifier,
  PhoneMultiFactorInfo
} from "firebase/auth";
import { useToast } from "@chakra-ui/react";

export const useMultiFactorAuth = () => {
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [verificationId, setVerificationId] = useState<string>("");
  const [enrolledFactors, setEnrolledFactors] = useState<MultiFactorInfo[]>([]);
  const [error, setError] = useState<any>(null);
  const toast = useToast();

  const getRecaptchaVerifier = () => {
    const auth = getAuth();
    const container = document.getElementById("recaptcha-container");

    if (!container) {
      throw new Error("Could not find recaptcha container");
    }

    // Get existing verifier if it exists
    const existingVerifier = (window as any).recaptchaVerifier;
    if (existingVerifier) {
      return existingVerifier;
    }

    // Create new verifier if none exists
    const verifier = new RecaptchaVerifier(
      container,
      {
        size: "invisible"
      },
      auth
    );

    // Store verifier globally
    (window as any).recaptchaVerifier = verifier;

    return verifier;
  };

  const startEnrollment = async (phoneNumber: string) => {
    setIsEnrolling(true);
    setError(null);
    let verifier;

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error("No authenticated user");
      }

      verifier = getRecaptchaVerifier();
      const multiFactorSession = await multiFactor(user).getSession();

      const phoneInfoOptions = {
        phoneNumber,
        session: multiFactorSession
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        verifier
      );

      setVerificationId(verificationId);
      toast({
        title: "Verification Code Sent",
        description: `A verification code has been sent to ${phoneNumber}`,
        status: "info",
        duration: 5000,
        isClosable: true
      });
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setIsEnrolling(false);
    }
  };

  const completeEnrollment = async (verificationCode: string) => {
    setIsEnrolling(true);
    setError(null);

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error("No authenticated user");
      }

      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      await multiFactor(user).enroll(multiFactorAssertion, "Phone Number");

      // Refresh enrolled factors
      const enrolledMfa = multiFactor(user).enrolledFactors;
      setEnrolledFactors(enrolledMfa);
      setVerificationId("");
    } catch (err: any) {
      setError(err);
    } finally {
      setIsEnrolling(false);
    }
  };

  const initiateLoginVerification = async (resolver: MultiFactorResolver) => {
    let verifier;
    try {
      const auth = getAuth();
      verifier = getRecaptchaVerifier();

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        {
          multiFactorHint: resolver.hints[0],
          session: resolver.session
        },
        verifier
      );

      setVerificationId(verificationId);
      toast({
        title: "Verification Code Sent",
        description: `A verification code has been sent to ${
          (resolver.hints[0] as PhoneMultiFactorInfo).phoneNumber
        }`,
        status: "info",
        duration: 3000,
        isClosable: true
      });
      return verificationId;
    } catch (err: any) {
      console.error("MFA Verification Initiation Error:", err);
      setError(err);
      throw err;
    }
  };

  const verifyLoginCode = async (
    resolver: MultiFactorResolver,
    verificationCode: string
  ) => {
    try {
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await resolver.resolveSignIn(multiFactorAssertion);
      return true;
    } catch (err: any) {
      console.error("MFA Code Verification Error:", err);
      setError(err);
      throw err;
    }
  };

  const unenrollFactor = async (factorId: string) => {
    setError(null);

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error("No authenticated user");
      }

      await multiFactor(user).unenroll(factorId);

      // Refresh enrolled factors
      const enrolledMfa = multiFactor(user).enrolledFactors;
      setEnrolledFactors(enrolledMfa);
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  useEffect(() => {
    const fetchEnrolledFactors = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          const mfa = multiFactor(user);
          setEnrolledFactors(mfa.enrolledFactors);
        }
      } catch (err) {
        console.error("Error fetching enrolled factors:", err);
      }
    };

    fetchEnrolledFactors();
  }, []);

  return {
    isEnrolling,
    error,
    enrolledFactors,
    verificationId,
    startEnrollment,
    completeEnrollment,
    initiateLoginVerification,
    verifyLoginCode,
    unenrollFactor,
    getRecaptchaVerifier
  };
};
