/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  useToast
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import { FaSave } from "react-icons/fa";
import { useEditor } from "../../context/EditorContext";
import { SaveStatus } from "../../hooks/useEditorHandlers";
import { SaveStatusComponent } from "./SaveStatus";

type Props = {
  saveStatus: SaveStatus;
  editorId?: string;
};

export const EditorToolbar = forwardRef<HTMLDivElement, Props>(
  ({ saveStatus, editorId = "" }, ref) => {
    const toast = useToast();

    const { isDownloading, quillRef, handleDownload } = useEditor();

    return (
      <Box
        id={`toolbar-${editorId}`}
        display="flex"
        flexWrap="wrap"
        bg="white"
        position="relative"
        zIndex="20"
        className="toolbar"
        borderTopRadius="md"
        ref={ref}
      >
        <span className="ql-formats">
          <select className="ql-font">
            <option value="times-new-roman" selected>
              Times New Roman
            </option>
            <option value="garamond">Garamond</option>
            <option value="georgia">Georgia</option>
            <option value="cambria">Cambria</option>
            <option value="century-schoolbook">Century Schoolbook</option>
            <option value="arial">Arial</option>
            <option value="verdana">Verdana</option>
            <option value="helvetica">Helvetica</option>
            <option value="book-antiqua">Book Antiqua</option>
          </select>
        </span>
        <span className="ql-formats">
          <select className="ql-header" defaultValue="0">
            <option value="1">Heading 1</option>
            <option value="2">Heading 2</option>
            <option value="3">Heading 3</option>
            <option value="0">Normal</option>
          </select>{" "}
        </span>
        <span className="ql-formats">
          <button className="ql-bold" type="button" aria-label="bold" />
          <button className="ql-italic" type="button" aria-label="italic" />
          <button
            className="ql-underline"
            type="button"
            aria-label="underline"
          />
        </span>
        <span className="ql-formats">
          <select className="ql-color" />
          <select className="ql-background" />
        </span>
        <span className="ql-formats">
          <button className="ql-script" value="sub" />
          <button className="ql-script" value="super" />
        </span>
        {/* Align */}
        <span className="ql-formats">
          <select className="ql-align" defaultValue="">
            <option value="" />
            <option value="center" />
            <option value="right" />
            <option value="justify" />
          </select>
        </span>
        <span className="ql-formats">
          <button className="ql-blockquote" />
        </span>
        <span className="ql-formats">
          <button className="ql-link" />
          <button className="ql-image" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-indent" value="-1" />
          <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
          <button className="ql-clean" />
        </span>
        <span className="ql-formats ml-auto">
          <button className="ql-copy" type="button" aria-label="test">
            <CopyIcon
              onClick={(e) => {
                const text = quillRef?.current?.getEditor().getText();
                if (text) navigator.clipboard.writeText(text);
                if (!toast.isActive("copy"))
                  toast({
                    title: "Summary copied to clipboard! ",
                    id: "copy"
                  });
              }}
            />
          </button>
          {isDownloading && <Spinner size="md" color="primary.darkBlue" />}
          {!isDownloading && saveStatus === "idle" && (
            <Menu variant="toolbar">
              <MenuButton
                as={Button}
                leftIcon={<FaSave />}
                className="!w-auto !text-black"
                bg="transparent"
              >
                Export and Save
              </MenuButton>
              <MenuList>
                <MenuItem
                  icon={
                    <Box w={4} h={4}>
                      <FileIcon extension="docx" {...defaultStyles.docx} />
                    </Box>
                  }
                  onClick={() => {
                    handleDownload("docx");
                  }}
                >
                  DOCX
                </MenuItem>
                <MenuItem
                  icon={
                    <Box w={4} h={4}>
                      <FileIcon extension="pdf" {...defaultStyles.pdf} />
                    </Box>
                  }
                  onClick={() => {
                    handleDownload("pdf");
                  }}
                >
                  PDF
                </MenuItem>
              </MenuList>
            </Menu>
          )}
          <SaveStatusComponent status={saveStatus} />
        </span>
      </Box>
    );
  }
);

EditorToolbar.displayName = "RichTextToolbar";
