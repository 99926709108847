import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  CircularProgress
} from "@chakra-ui/react";
import { useTypedDispatch } from "../../redux/store";
import { generateSingleDocumentUrl } from "../../redux/documents/documentsThunk";
import { DataDocs } from "../../types/tables-data";

interface DocumentPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  document: DataDocs | undefined;
}

export const DocumentPreviewModal = ({
  isOpen,
  onClose,
  document
}: DocumentPreviewModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xxl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Document Preview</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {document?.docUrl ? (
            <iframe
              src={document?.docUrl}
              width="100%"
              height="100%"
              title="Document Preview"
              style={{ minHeight: "80vh" }}
            />
          ) : (
            <div
              style={{
                minHeight: "80vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <CircularProgress isIndeterminate color="primary.blue" />
            </div>
          )}
          <Text pt={2} textDecoration="underline">
            {document?.autoTitle}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
