import {
  Button,
  Checkbox,
  CheckboxGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useUpdateUser } from "../hooks/useUpdateUser";
import { DATABASE } from "../types/tables-data";
import { betaFeatures } from "../constans";

// Mapping for converting between "UI display name" and "database field name"
const featureMapping: Record<string, string> = {
  "Legal Brief: Custom Templates": "legal_brief_templates",
  "Another Feature Name": "another_feature_variable"
  // add more if needed
};

interface BetaAccessModalProps {
  isOpen: boolean;
  userId: string | undefined;
  userName: string;
  closeModal: () => void;
}

const BetaAccessModal: React.FC<BetaAccessModalProps> = ({
  isOpen,
  userId,
  userName,
  closeModal
}) => {
  const { updateBetaFeatureAccess, getUserBetaFeatures } = useUpdateUser();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);

  // Convert database format -> presentation
  const convertToPresentation = (features: string[]) =>
    features.map((feature) =>
      Object.keys(featureMapping).find(
        (key) => featureMapping[key] === feature
      ) || feature // fallback to the raw feature if not found
    );

  // Convert presentation -> database format
  const convertToDatabase = (feature: string) =>
    featureMapping[feature] || feature;

  // Fetch user’s existing features on open
  useEffect(() => {
    const fetchBetaFeatures = async () => {
      if (!userId) return;
      setIsLoading(true);

      try {
        const featuresFromDb = await getUserBetaFeatures(
          userId,
          DATABASE.LAWYERS
        );
        setSelectedFeatures(convertToPresentation(featuresFromDb));
      } catch (error) {
        console.error("Error fetching user beta features:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchBetaFeatures();
    }
  }, [isOpen]);

  // Toggle an individual feature
  const handleFeatureToggle = async (feature: string, isChecked: boolean) => {
    if (!userId) return;
    setIsLoading(true);

    try {
      const featureToStore = convertToDatabase(feature);
      await updateBetaFeatureAccess(
        userId,
        DATABASE.LAWYERS,
        featureToStore,
        isChecked
      );

      if (isChecked) {
        setSelectedFeatures((prev) => [...prev, feature]);
      } else {
        setSelectedFeatures((prev) => prev.filter((f) => f !== feature));
      }
    } catch (error) {
      console.error("Error updating beta feature access:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Beta Features for {userName}</ModalHeader>
        <ModalBody>
          <Text>Manage beta feature access for this user.</Text>

          {/* Show a spinner while loading initial data */}
          {isLoading && selectedFeatures.length === 0 ? (
            <Spinner size="sm" mt={2} />
          ) : (
            <CheckboxGroup value={selectedFeatures}>
              {betaFeatures.map((feature) => (
                <Checkbox
                  key={feature}
                  isChecked={selectedFeatures.includes(feature)}
                  onChange={(e) => handleFeatureToggle(feature, e.target.checked)}
                  isDisabled={isLoading /* Disable toggling while API call */}
                  mt={2}
                >
                  {feature}
                </Checkbox>
              ))}
            </CheckboxGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal} isDisabled={isLoading}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BetaAccessModal;
