import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  Wrap
} from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../../api/firebaseApi";
import useFirestoreDocument from "../../../../hooks/useFirestoreDocument";
import { useGenerateDrafts } from "../../../../hooks/useGenerateDrafts";
import { useGetFormsData } from "../../../../hooks/useGetFormsData";
import { useSubscribeToJob } from "../../../../hooks/useSubscribeToJob";
import {
  ExtractionJob,
  EXTRACTIONSTATES
} from "../../../../redux/extraction-jobs/extractionJobsSlice";
import { individualSelectors } from "../../../../redux/individual/selectors";
import { DATABASE } from "../../../../types/tables-data";
import { Dropdown } from "../../../common/Dropdown";
import { SpecialHeading } from "../../PanelLayout";
import { ReplaceExpertLetterDraft } from "./ReplaceDialog";
import { draftOptionsConfig } from "../../../../config/draftOptionsConfig";

type Props = {
  onCancel: () => void;
  isOpen: boolean;
  expertId: string | undefined;
  setIsGenerateTapped: React.Dispatch<React.SetStateAction<boolean>>;
};

const ExpertLetterModal = ({
  onCancel,
  isOpen,
  expertId,
  setIsGenerateTapped
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, visaType } = useParams();
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const expertLetters = useSelector(individualSelectors.selectExpertLetters);
  const { initiateJob } = useSubscribeToJob();
  const { onSubmitGenerateDraft } = useGenerateDrafts(uid);

  useGetFormsData();
  const [draftOptions, setDraftOptions] = useState({
    tone: "Objective",
    draftLength: "Long",
    creativity: "Creative",
    greet: "Dear Madam or Sir"
  });
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const [showExpertRequired, setShowExpertRequired] = useState<boolean>(false);
  const selectedExpert = useMemo(() => {
    if (expertId) {
      return expertLetters?.find((exp) => exp.uid === expertId) || null;
    }
    return null;
  }, [expertId, expertLetters]);
  const { document: expertResume, fetchDocument } = useFirestoreDocument(
    DATABASE.FORMS,
    selectedExpert && selectedExpert.uid ? selectedExpert.uid : "",
    false,
    `${DATABASE.FORMS}/${uid}/expert_letters`
  );

  const expertResumeIsBlank = () => {
    return (
      !expertResume ||
      (expertResume as any)?.autoOCRText === undefined ||
      (expertResume as any)?.autoOCRText === ""
    );
  };

  const expertSummaryIsBlank = () => {
    return (
      !expertResume ||
      (expertResume as any)?.summary === undefined ||
      (expertResume as any)?.summary === ""
    );
  };

  const onGenerateConfirm = async () => {
    try {
      console.log("onGenerateConfirm initiated");

      // Hide current modal
      setIsGenerateTapped(true);
      console.log("Modal hidden and generation tapped");

      setShowConfirmationDialog(false);
      console.log("Confirmation dialog closed");

      if (uid && expertId) {
        console.log(
          `Submitting draft generation for user: ${uid}, expert: ${expertId}`
        );

        // Initiating real-time tracking
        const job: ExtractionJob = {
          id: expertId,
          type: "Expert letter draft generation",
          docRef: doc(
            db,
            DATABASE.DRAFTS,
            `${uid}`,
            "expert_letters",
            `${expertId}`
          ),
          jobRef: doc(
            db,
            DATABASE.ACTIVE_JOBS,
            `${uid}`,
            "jobs",
            `${expertId}`
          ),
          status: { status: EXTRACTIONSTATES.Pending },
          toastTitle: "Extraction status",
          toastDesc: `The generation is completed for expert letter draft: ${
            selectedExpert?.expertName || ""
          }`,
          docName: selectedExpert?.expertName || ""
        };

        console.log(
          `Job created for expert: ${selectedExpert?.expertName || ""}`
        );

        await initiateJob(job);
        console.log("Job initiated successfully");

        onSubmitGenerateDraft({
          individualId: uid,
          draftType: "expert_letters",
          expertId,
          tone: draftOptions.tone,
          length: draftOptions.draftLength,
          creativity: draftOptions.creativity,
          greeting: draftOptions.greet,
          useAsync: true
        });

        console.log("Draft generation submitted");

        navigate(`/individual/${id}/${visaType}/drafts`);
        console.log(
          `Navigating to drafts page for individual: ${id}, visaType: ${visaType}`
        );
      } else {
        console.warn("UID or selectedExpert UID is missing");
      }
    } catch (error) {
      console.error("Error during draft generation:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCancel} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <SpecialHeading title="Generate Draft" />
        </ModalHeader>
        <ModalBody>
          <VStack alignItems="start" spacing={6}>
            <Box>
              <VStack alignItems="start" spacing={6} flexGrow={0}>
                <Flex flexDirection="column" rowGap={4}>
                  <Heading size="md" fontWeight={500}>
                    Greeting
                  </Heading>
                  <Dropdown
                    list={draftOptionsConfig.greetings}
                    onItemSelect={(value) =>
                      setDraftOptions((prev) => ({ ...prev, greet: value }))
                    }
                    title="Select Greeting"
                    selectedValue={draftOptions.greet}
                  />
                </Flex>
                <Flex flexDirection="column" rowGap={4}>
                  <Heading size="md" fontWeight={500}>
                    Other options
                  </Heading>
                  <Flex gap={4}>
                    <Dropdown
                      selectedValue={draftOptions.tone}
                      title="Tone"
                      onItemSelect={(value) =>
                        setDraftOptions((prev) => ({ ...prev, tone: value }))
                      }
                      list={draftOptionsConfig.tones}
                    />
                    <Dropdown
                      selectedValue={draftOptions.draftLength}
                      title="Length"
                      onItemSelect={(value) =>
                        setDraftOptions((prev) => ({
                          ...prev,
                          draftLength: value
                        }))
                      }
                      list={draftOptionsConfig.lengths}
                    />
                    <Dropdown
                      selectedValue={draftOptions.creativity}
                      title="Creativity"
                      onItemSelect={(value) =>
                        setDraftOptions((prev) => ({
                          ...prev,
                          creativity: value
                        }))
                      }
                      list={draftOptionsConfig.creativities}
                    />
                  </Flex>
                </Flex>
              </VStack>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex columnGap={4} mt={4}>
            <Button variant="secondaryOutline" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="primaryFilled"
              onClick={onGenerateConfirm}
              // isDisabled={expertResumeIsBlank() || expertSummaryIsBlank()}
            >
              Generate
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
      {showConfirmationDialog && (
        <ReplaceExpertLetterDraft
          expertName={selectedExpert?.expertName ?? ""}
          onConfirm={onGenerateConfirm}
          setShowConfirmationDialog={setShowConfirmationDialog}
          isLoading={false}
        />
      )}
    </Modal>
  );
};

export default ExpertLetterModal;
