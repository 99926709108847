import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ExpandableSection from "../../../components/common/ExpandableSection";
import { GroupedTable } from "../../../components/common/GroupedTable";
import { SectionWrapper } from "../../../components/common/SectionWrapper";
import { TableSkeleton } from "../../../components/common/Skeletons";
import { SpecialHeading } from "../../../components/individualTabs/PanelLayout";
import useFirestoreCollection from "../../../hooks/useFirestoreCollection";
import { setSelectedPromptId } from "../../../redux/prompts/promptsSlice";
import { promptsSelectors } from "../../../redux/prompts/selectors";
import { Feature } from "../../../types/features";
import { Prompt } from "../../../types/studio/prompts";
import { DATABASE } from "../../../types/tables-data";
import AddCustomPromptModal from "./AddCustomPrompt";

export const Prompts = () => {
  const dispatch = useDispatch();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  // Firestore collection
  const {
    documents: prompts,
    isDocumentSaving,
    saveDocument
  } = useFirestoreCollection<Prompt>(DATABASE.PROMPTS, {
    listen: true,
    useLawyerAccessMap: true
  });

  // Selected prompt data
  // const prompts = useSelector(promptsSelectors.selectAll);
  const selectedPromptId = useSelector(promptsSelectors.selectSelectedPromptId);

  const categorizedPrompts = useMemo(() => {
    if (!prompts) return null;
    return prompts?.reduce(
      (acc, prompt) => {
        if (!prompt.type) {
          acc.my_prompts.push(prompt); // Custom prompts go here
          return acc;
        }
        const type = prompt.type as keyof typeof acc;
        if (acc[type]) {
          acc[type].push(prompt);
        }
        return acc;
      },
      {
        default_prompts: [] as Prompt[],
        rewrite_options: [] as Prompt[],
        tone_options: [] as Prompt[],
        my_prompts: [] as Prompt[]
      }
    );
  }, [prompts]);

  // Function to render an ExpandableSection
  const renderSection = (title: string, data: Prompt[]) => (
    <ExpandableSection key={title} title={title} my={2}>
      {isDocumentSaving ? (
        <Box mt={12}>
          <TableSkeleton />
        </Box>
      ) : data.length ? (
        <GroupedTable<Prompt>
          items={data}
          setGroupedItems={() => {}}
          updateOrder={() => {}}
          isLoading={false}
          feature={Feature.CUSTOM_PROMPTS}
          searchPlaceholder={`Search ${title}...`}
          isIsolatedView
          variant="basicDivided"
        />
      ) : (
        <Text fontWeight={500} size="md" mx={8} my={4}>
          No prompts found.
        </Text>
      )}
    </ExpandableSection>
  );

  return (
    <SectionWrapper withBackNav={false} bg="rgba(255, 255, 255, 0.5)">
      <Flex flexDirection="column" flex="1" p={4} overflow="auto">
        {/* Show skeleton loading if categorizedPrompts is null */}
        {!categorizedPrompts ? (
          <Box mt={12}>
            <TableSkeleton />
          </Box>
        ) : (
          <>
            {/* Header Section with Add Button */}
            <Flex justifyContent="space-between" my={4}>
              <SpecialHeading title="Prompts" />
              <Button
                minW="14vw"
                leftIcon={<AddIcon />}
                variant="primaryFilled"
                onClick={() => setIsUploadModalOpen(true)}
              >
                <Text fontSize={14}>Add Prompt</Text>
              </Button>
            </Flex>

            {/* Render Sections */}
            {renderSection("My Prompts", categorizedPrompts.my_prompts)}
            {categorizedPrompts.default_prompts.length > 0 &&
              renderSection(
                "Default Prompts",
                categorizedPrompts.default_prompts
              )}
            {renderSection(
              "Rewrite Options",
              categorizedPrompts.rewrite_options
            )}
            {renderSection("Tone Options", categorizedPrompts.tone_options)}

            {/* Modal for Adding Custom Prompts */}
            <AddCustomPromptModal
              isOpen={isUploadModalOpen || !!selectedPromptId}
              onClose={() => {
                setIsUploadModalOpen(false);
                dispatch(setSelectedPromptId(undefined));
              }}
              onSave={async (prompt: Partial<Prompt>) => {
                await saveDocument(prompt);
                setIsUploadModalOpen(false);
              }}
              isSaving={isDocumentSaving}
              existingPrompt={prompts?.find((p) => p.id === selectedPromptId)}
            />
          </>
        )}
      </Flex>
    </SectionWrapper>
  );
};
