import { createSlice } from "@reduxjs/toolkit";

interface DebugState {
  firebase: {
    snapshotConnections: number;
  };
}

const initialState: DebugState = {
  firebase: {
    snapshotConnections: 0
  }
};

const debugSlice = createSlice({
  name: "debug",
  initialState,
  reducers: {
    incrementSnapshotConnections: (state) => {
      state.firebase.snapshotConnections += 1;
    },
    decrementSnapshotConnections: (state) => {
      state.firebase.snapshotConnections -= 1;
    }
  }
});

export const { incrementSnapshotConnections, decrementSnapshotConnections } =
  debugSlice.actions;
export default debugSlice.reducer;
