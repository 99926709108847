import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useDeleteExpertLetter from "../../../hooks/useDeleteIndivExpertLetter";
import { ExpertLetter } from "../../../redux/clients/clientsSlice";
import { draftsSelectors } from "../../../redux/drafts/selectors";
import {
  EXTRACTIONSTATES,
  GenerationStatesDescription
} from "../../../redux/extraction-jobs/extractionJobsSlice";
import { formsSelectors } from "../../../redux/forms/selectors";
import { setActiveExpertLetter } from "../../../redux/individual/individualSlice";
import { ExtractionCard } from "../../common/ExtractionCard";
import { DeleteExperLetterDialog } from "../individualQuestionnaires/expertLetter/DeleteExpertLetterDialog";

const ExtractionCardWrapper = ({
  expertLetterForm,
  setDocumentToDelete,
  setShowDeletionDialog
}: {
  expertLetterForm: ExpertLetter;
  setDocumentToDelete: any;
  setShowDeletionDialog: any;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, visaType } = useParams();

  const expertLettersDrafts = useSelector(draftsSelectors.selectExpertLetters);
  const draft = expertLettersDrafts
    ? expertLettersDrafts![expertLetterForm.uid!]
    : null;

  const statusMsg = draft?.status
    ? GenerationStatesDescription[draft.status.status]
    : GenerationStatesDescription.NotStarted;

  const onEdit = (expertId: string) => {
    if (expertLetterForm) {
      dispatch(setActiveExpertLetter(expertLetterForm));
      navigate(
        `/individual/${id}/${visaType}/drafts/expert-letters/expert-letter-inputs/${expertId}`
      );
    }
  };

  const onView = (expertId: string) => {
    if (expertId) {
      navigate(
        `/individual/${id}/${visaType}/drafts/expert-letters/${expertId}/edit`
      );
    }
  };

  const onDelete = (expertId: string) => {
    if (expertLetterForm) {
      setDocumentToDelete(expertLetterForm);
    }
    setShowDeletionDialog(true);
  };
  return (
    <ExtractionCard
      key={expertLetterForm.uid}
      disableIcons={
        [EXTRACTIONSTATES.Pending, EXTRACTIONSTATES.Processing].includes(
          draft?.status?.status as EXTRACTIONSTATES
        ) || false
      }
      showIcons
      expertLetterId={expertLetterForm.uid!}
      type="drafts/expert-letter"
      id={draft?.uid?.toString()}
      statusMsg={statusMsg}
      extractedData={expertLetterForm}
      onExtractionDelete={() => {
        onDelete(expertLetterForm.uid!);
      }}
      // onExtractionDelete={() => onDelete(expertLetter.uid!)}
      onClick={() => {
        if (draft?.status?.status === EXTRACTIONSTATES.Completed) {
          onView(expertLetterForm.uid!);
        } else {
          onEdit(expertLetterForm.uid!);
        }
      }}
      onEdit={() => onEdit(expertLetterForm.uid!)}
      onView={() => onView(expertLetterForm.uid!)}
    />
  );
};
export const ExpertLettersList = () => {
  const { id, visaType } = useParams();
  const toast = useToast();

  const expertLettersForms = useSelector(
    formsSelectors.selectExpertLettersForms
  );
  const [showDeletionDialog, setShowDeletionDialog] = useState<boolean>(false);
  const [documentToDelete, setDocumentToDelete] = useState<ExpertLetter | null>(
    null
  );
  const { onSubmitDeleteExpertLetter } = useDeleteExpertLetter(id!);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const deleteDocumentConfirm = async () => {
    setShowDeletionDialog(false);
    if (documentToDelete?.uid) {
      await onSubmitDeleteExpertLetter(documentToDelete.uid);
      toast({
        title: "Success",
        description: "Expert letter deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
      scrollToTop();
    }
  };
  return (
    <div className="flex flex-col gap-4">
      {expertLettersForms?.map((expertLetterForm, id) => (
        <ExtractionCardWrapper
          key={id}
          expertLetterForm={expertLetterForm}
          setDocumentToDelete={setDocumentToDelete}
          setShowDeletionDialog={setShowDeletionDialog}
        />
      ))}

      {showDeletionDialog && documentToDelete?.uid && (
        <DeleteExperLetterDialog
          isLoading={false}
          setShowDeleteDialog={setShowDeletionDialog}
          onConfirm={() => deleteDocumentConfirm()}
          expertName={documentToDelete.expertName || ""}
        />
      )}
    </div>
  );
};
