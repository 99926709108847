import { Box, useToast } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../styles/reactquill.css";
import { ChangingText } from "./ChangingTextClass";
import { CustomHighlight } from "./CustomHighlightClass";
import { EditorContent } from "./EditorContent";
import { EditorProvider } from "./EditorProvider";

const fontList = [
  "times-new-roman",
  "garamond",
  "georgia",
  "cambria",
  "century-schoolbook",
  "arial",
  "verdana",
  "helvetica",
  "book-antiqua"
];

// Custom highlighting configuration
Quill.register(CustomHighlight, true);
Quill.register(ChangingText, true);

// Font configuration
const Font = Quill.import("formats/font");
Font.whitelist = fontList;
Quill.register(Font, true);

type EditorProps = {
  text: string;
  isFormEditor?: boolean;
  saveText?: (html: string, plainText: string) => Promise<void>;
  updateText?: (html: string, plainText: string) => void;
  shouldShowSaveBtn?: boolean | null;
  placeholder?: string | null;
  customHeight?: string;
  editorId?: string;
  loading?: boolean | null;
  enableExhibitNumbers?: boolean;
};

export const Editor = ({
  text,
  saveText,
  updateText = () => {},
  shouldShowSaveBtn = true,
  placeholder,
  customHeight = "80vh",
  editorId = "",
  loading = false,
  isFormEditor,
  enableExhibitNumbers
}: EditorProps) => {
  const QUILL_MODULES = {
    history: {
      delay: 1000,
      userOnly: true,
      maxStack: 100
    },
    toolbar: {
      container: `#toolbar-${editorId}`
    }
  };

  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const [isPromptSelected, setIsPromptSelected] = useState<boolean | null>(
    false
  );
  const [evidencesToHighlight, setEvidencesToHighlight] = useState<string[]>(
    []
  );
  const [allowOverflow, setAllowOverflow] = useState(false);

  return (
    <EditorProvider
      text={text}
      updateText={updateText}
      saveText={saveText}
      isFormEditor={isFormEditor}
    >
      <Box
        position="relative"
        height="100%"
        width="100%"
        wordBreak="break-word"
        overflow="visible"
      >
        <EditorContent
          editorId={editorId}
          loading={loading!}
          placeholder={placeholder}
          customHeight={customHeight}
          toolbarRef={toolbarRef}
          evidencesToHighlight={evidencesToHighlight}
          setEvidencesToHighlight={setEvidencesToHighlight}
          isPromptSelected={isPromptSelected}
          setIsPromptSelected={setIsPromptSelected}
          allowOverflow={allowOverflow}
          setAllowOverflow={setAllowOverflow}
          QUILL_MODULES={QUILL_MODULES}
          enableExhibitNumbers={enableExhibitNumbers}
        />
      </Box>
    </EditorProvider>
  );
};
