import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { Spinner, Text, keyframes } from "@chakra-ui/react";
import { useEffect } from "react";
import { useEditor } from "../../context/EditorContext";
import { SaveStatus as SaveStatusType } from "../../hooks/useEditorHandlers";

type Props = {
  status: SaveStatusType;
};

export const SaveStatusComponent = ({ status }: Props) => {
  switch (status) {
    case "saving":
      return (
        <Text as="span" display="inline-flex" gap={2} alignItems="center">
          <Spinner size="sm" thickness="3px" color="gray.400" />
          Saving...
        </Text>
      );
    case "success":
      return (
        <Text as="span" color="green.500">
          <CheckCircleIcon /> Saved
        </Text>
      );
    case "error":
      return (
        <Text as="span" color="red.500">
          <WarningIcon /> Failed to save
        </Text>
      );

    default:
      return null;
  }
};
