import {
  deleteField,
  doc,
  getDoc,
  onSnapshot,
  updateDoc
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";
import { LegalBriefSection, setLegalBrief } from "../redux/drafts/draftsSlice";
import { individualSelectors } from "../redux/individual/selectors";
import { draftsSelectors } from "../redux/drafts/selectors";

export const useIndivSop = (indivId: string) => {
  const dispatch = useDispatch();
  const email = useSelector(individualSelectors.selectEmail);
  const legalBrief = useSelector(draftsSelectors.selectLegalBrief);

  const [isLoadingUpdate, setIsLoadingUpdate] = useState<{
    [key: number]: boolean;
  }>({});
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingSop, setIsLoadingSop] = useState(false);
  const [deleteError, setDeleteError] = useState<any>(null);
  const [getError, setGetError] = useState<any>(null);
  const [updateError, setUpdateError] = useState<any>(null);

  const deleteSop = async () => {
    if (!indivId) return;
    const docRef = doc(db, `${DATABASE.DRAFTS}/${indivId}`);
    setIsLoadingDelete(true);

    try {
      await updateDoc(docRef, {
        sop: deleteField(),
        last_updated_by: email
      });
    } catch (e) {
      console.error("DELETE_SOP: Error deleting SOP:", e);
      setDeleteError(e);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const updateSopSection = async (
    sectionIndex: number,
    newHtml: string,
    newPlainText: string,
    newAccessEnabled: boolean
  ) => {
    if (!indivId) return;

    const docRef = doc(db, `${DATABASE.DRAFTS}/${indivId}`);
    setIsLoadingUpdate((prev) => ({ ...prev, [sectionIndex]: true }));

    try {
      const docSnapshot = await getDoc(docRef);
      if (!docSnapshot.exists()) {
        throw new Error("Document does not exist!");
      }

      const data = docSnapshot.data();
      const sopData = data.sop || {};

      const currentSections: LegalBriefSection[] = Array.isArray(
        sopData.sections
      )
        ? sopData.sections
        : [];

      if (sectionIndex < 0 || sectionIndex >= currentSections.length) {
        throw new Error("Invalid section index");
      }

      const updatedSection = {
        ...currentSections[sectionIndex],
        html: newHtml,
        text: newPlainText
      };
      currentSections[sectionIndex] = updatedSection;

      await updateDoc(docRef, {
        "sop.sections": currentSections,
        "sop.accessEnabled": newAccessEnabled,
        last_updated_by: email
      });
    } catch (e) {
      console.error("UPDATE_SOP_SECTION: Error updating SOP section:", e);
      setUpdateError(e);
    } finally {
      setIsLoadingUpdate((prev) => ({ ...prev, [sectionIndex]: false }));
    }
  };

  useEffect(() => {
    if (!indivId) return undefined;
    const documentReference = doc(db, `${DATABASE.DRAFTS}/${indivId}`);

    const unsubscribe = onSnapshot(
      documentReference,
      (documentSnapshot) => {
        if (!documentSnapshot.exists()) {
          setGetError(new Error("No such document"));
          return;
        }
        setIsLoadingSop(true);

        const sopData = documentSnapshot.data()?.sop ?? {};
        const sections: LegalBriefSection[] = Array.isArray(sopData?.sections)
          ? sopData.sections.map((section: LegalBriefSection) => ({
              html: section?.html || "",
              text: section?.text || "",
              section_name: section?.section_name || "",
              display_name: section?.display_name || ""
            }))
          : [];

        const sopDraft = {
          value: {
            generated_text: sopData?.generated_text ?? "",
            generated_html: sopData?.generated_html ?? "",
            accessEnabled: sopData?.accessEnabled ?? false,
            sections
          }
        };

        dispatch(setLegalBrief(sopDraft));
        setIsLoadingSop(false);
      },
      (error) => {
        console.error("SOP_SNAPSHOT: Error fetching SOP:", error);
        setGetError(error);
        setIsLoadingSop(false);
      }
    );

    return () => unsubscribe();
  }, [indivId]);

  return {
    getError,
    isLoadingSop,
    updateError,
    isLoadingUpdate,
    updateSopSection,
    isLoadingDelete,
    deleteError,
    deleteSop
  };
};
