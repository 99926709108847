import { useState } from "react";
import { User, UserCredential, signInWithCustomToken, signInWithEmailAndPassword, MultiFactorError, getMultiFactorResolver, MultiFactorResolver } from "firebase/auth";
import { getAnalytics, setUserId, setUserProperties } from "firebase/analytics";
import { auth } from "../api/firebaseApi";
import { useMultiFactorAuth } from './useMultiFactorAuth';

export const useLogin = () => {
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [loginAs, setLoginAs] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [errorLogin, setErrorLogin] = useState<string | null>(null);
  const [mfaResolver, setMfaResolver] = useState<MultiFactorResolver | null>(null);

  const onSubmitLogin = async (email: string, password: string, customToken=null, userRole=null) => {
    setIsLoadingLogin(true);
    setErrorLogin(null);
    try {
      if (customToken){
        console.log("logging in with custom token")
        setLoginAs(true);
        const {user} = await signInWithCustomToken(auth, customToken);
        setUserRole(userRole)
        setUser(user);
      }
      else {
        console.log("logging in with username and password");
        const {user} = await signInWithEmailAndPassword(auth, email, password);
        setUser(user);
        const analytics = getAnalytics();
        setUserId(analytics, user.uid);
        let isInternal = false;
        if (user.email?.includes("@caseblink.com")){
          isInternal = true;
        }
        setUserProperties(analytics, {'is_internal': isInternal});
      }
    } catch (error: any) {
      if (error.code === 'auth/multi-factor-auth-required') {
        const resolver = getMultiFactorResolver(auth, error as MultiFactorError);
        setMfaResolver(resolver);
        throw { ...error, resolver };
      } else {
        setErrorLogin(error.code);
        throw error;
      }
    } finally {
      setIsLoadingLogin(false);
    }
  };

  const handleMFASuccess = (user: User) => {
    setUser(user);
    const analytics = getAnalytics();
    setUserId(analytics, user.uid);
    let isInternal = false;
    if (user.email?.includes("@caseblink.com")) {
      isInternal = true;
    }
    setUserProperties(analytics, { 'is_internal': isInternal });
  };

  return {
    isLoadingLogin,
    onSubmitLogin,
    user,
    errorLogin,
    loginAs,
    userRole,
    mfaResolver,
    handleMFASuccess
  };
};
