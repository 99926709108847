import {
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Text
} from "@chakra-ui/react";
import { useState } from "react";
import { MultiFactorResolver } from "firebase/auth";

interface ReauthDialogProps {
  onReauthenticate: (password: string) => Promise<void>;
  actionText: string;
  onMFACode?: (code: string) => Promise<void>;
  phoneNumber?: string;
  showMFA?: boolean;
}

export const ReauthDialog = ({
  onReauthenticate,
  actionText,
  onMFACode,
  phoneNumber,
  showMFA
}: ReauthDialogProps) => {
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);

  const handleSubmit = async () => {
    if (showMFA && onMFACode) {
      setIsVerifying(true);
      try {
        await onMFACode(verificationCode);
      } finally {
        setIsVerifying(false);
      }
    } else {
      await onReauthenticate(password);
    }
    setPassword("");
    setVerificationCode("");
  };

  return (
    <FormControl mt={4}>
      {!showMFA ? (
        <>
          <FormLabel>Please enter your password to {actionText}</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
          />
        </>
      ) : (
        <VStack align="stretch" spacing={4}>
          <Text>Please enter the verification code sent to {phoneNumber}</Text>
          <Input
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder="Enter verification code"
          />
        </VStack>
      )}
      <Button mt={4} onClick={handleSubmit} isLoading={isVerifying}>
        Confirm
      </Button>
    </FormControl>
  );
};
