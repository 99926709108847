import { Button } from "@chakra-ui/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

export const Pagination = ({
  currentPage,
  totalPages,
  onPageChange
}: PaginationProps) => {
  return (
    <div className="flex justify-center items-center gap-2 my-2">
      <Button
        aria-label="previous page"
        variant="outlineIconButton"
        leftIcon={<FaChevronLeft />}
        onClick={() => onPageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
      >
        Previous
      </Button>
      <span className="flex items-end gap-2">
        {totalPages > 1 && (
          <>
            <Button
              variant={currentPage === 1 ? "primaryFilled" : "ghost"}
              onClick={() => onPageChange(1)}
              px={0}
              py={0}
              className="rounded-full"
            >
              1
            </Button>
            {currentPage > 2 && (
              <Button
                pointerEvents="none"
                variant="ghost"
                px={0}
                py={0}
                className="rounded-full"
              >
                ...
              </Button>
            )}
            {currentPage > 1 && currentPage < totalPages && (
              <Button
                variant="primaryFilled"
                px={0}
                py={0}
                className="rounded-full"
              >
                {currentPage}
              </Button>
            )}
            {currentPage < totalPages - 1 && (
              <Button
                pointerEvents="none"
                variant="ghost"
                px={0}
                py={0}
                className="rounded-full"
              >
                ...
              </Button>
            )}
            <Button
              variant={currentPage === totalPages ? "primaryFilled" : "ghost"}
              onClick={() => onPageChange(totalPages)}
              px={0}
              py={0}
              className="rounded-full"
            >
              {totalPages}
            </Button>
          </>
        )}
      </span>
      <Button
        aria-label="next page"
        variant="outlineIconButton"
        rightIcon={<FaChevronRight />}
        onClick={() => onPageChange(currentPage + 1)}
        isDisabled={currentPage === totalPages}
      >
        Next
      </Button>
    </div>
  );
};
