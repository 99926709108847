import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Stack,
  useToast,
  FormLabel,
  Input,
  Icon
} from "@chakra-ui/react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { ViewIcon } from "@chakra-ui/icons";

import {
  getFirebaseUsers,
  getLoginAsToken,
  updateFirebaseUser
} from "../../../api/OpenAIApi";
import { InputField } from "../../../forms/InputField";
import { SelectField } from "../../../forms/SelectField";
import { SectionWrapper } from "../../../components/common/SectionWrapper";
import { SpecialHeading } from "../../../components/individualTabs/PanelLayout";

const USER_ROLES = ["lawyer", "individual"];
const SUBSCRIPTION_TYPES = ["freemium", "paid"];
const LAWYER_ACCOUNT_TYPES = ["standard", "full"];
const VISA_TYPES = ["EB-2 NIW", "O-1 A"];

interface UserFormState {
  email: string;
  firstName: string;
  lastName: string;
  disabled: boolean;
  newPassword: string;
  role: string;
  practiceName: string;
  visaType: string;
  subscriptionStatus: string;
  lawyerRole: string;
}

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required")
});

function AdminEditUser() {
  const { uid } = useParams<{ uid: string }>();
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = React.useState(true);
  const [showPasswordInput, setShowPasswordInput] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState<UserFormState>({
    email: "",
    firstName: "",
    lastName: "",
    disabled: false,
    newPassword: "",
    role: "",
    practiceName: "",
    visaType: "",
    subscriptionStatus: "",
    lawyerRole: ""
  });

  React.useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      try {
        const usersData = await getFirebaseUsers("email", uid);
        if (Array.isArray(usersData.users) && usersData.users.length > 0) {
          const userData = usersData.users[0];
          setInitialValues({
            email: userData.email || "",
            firstName: userData.firstName || "",
            lastName: userData.lastName || "",
            disabled: userData.disabled || false,
            newPassword: "",
            role: userData.role,
            practiceName: userData.practiceName || "",
            visaType: userData.visaType || "",
            subscriptionStatus: userData.subscriptionStatus || "",
            lawyerRole: userData.userRole || ""
          });
        }
      } catch (error) {
        toast({
          title: "Error fetching user",
          description: "Please try again",
          status: "error",
          duration: 3000
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (uid) fetchUser();
  }, [uid, toast]);

  const viewAsUser = async (targetUserId: string, customRole: string) => {
    try {
      const requestData = await getLoginAsToken(targetUserId);
      if (requestData) {
        navigate("/login", {
          state: { customToken: requestData.token, customRole }
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to login as user",
        status: "error",
        duration: 3000
      });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <SectionWrapper bg="rgba(255, 255, 255, 0.5)">
      <Box p={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <SpecialHeading title="Update User" />
          <Flex gap={2}>
            {initialValues.role === "lawyer" && (
              <Link
                to={`/admin/users/new?role=lawyer&practiceName=${initialValues.practiceName}`}
                style={{ textDecoration: "none" }}
              >
                <Button variant="secondaryOutline">
                  Create new user with access to the same cases
                </Button>
              </Link>
            )}
            <Button
              onClick={() => viewAsUser(uid!, initialValues.role)}
              variant="secondaryOutline"
              leftIcon={<Icon as={ViewIcon} />}
            >
              View the portal as this user
            </Button>
          </Flex>
        </Flex>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const changedValues = Object.entries(values).reduce(
                (acc, [key, value]) => {
                  if (value !== initialValues[key as keyof UserFormState]) {
                    acc[key] = value;
                  }
                  return acc;
                },
                {} as Record<string, any>
              );

              if (Object.keys(changedValues).length) {
                await updateFirebaseUser(uid!, changedValues);
                toast({
                  title: "User updated successfully",
                  status: "success",
                  duration: 3000
                });
              } else {
                toast({
                  title: "No changes to update",
                  status: "info",
                  duration: 3000
                });
              }
            } catch (error) {
              toast({
                title: "Error updating user",
                description: "Please try again",
                status: "error",
                duration: 3000
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ handleSubmit, isSubmitting, values, handleChange }) => (
            <form onSubmit={handleSubmit}>
              <Stack
                spacing={4}
                sx={{
                  input: {
                    bg: "white",
                    _hover: { bg: "gray.200" },
                    borderRadius: "md"
                  },
                  button: {
                    borderRadius: "md"
                  }
                }}
              >
                <FormControl>
                  <FormLabel>Role</FormLabel>
                  <Input
                    name="role"
                    value={values.role}
                    readOnly
                    bg="gray.100"
                    cursor="not-allowed"
                  />
                </FormControl>

                <InputField name="firstName" label="First Name" isRequired />

                <InputField name="lastName" label="Last Name" isRequired />

                <InputField
                  name="email"
                  label="Email"
                  type="email"
                  isRequired
                />

                {values.role === "lawyer" && (
                  <>
                    <InputField name="practiceName" label="Practice Name" />
                    <SelectField
                      variant="whiteBg"
                      name="subscriptionStatus"
                      label="Subscription Type"
                      placeHolder="Select"
                      options={SUBSCRIPTION_TYPES}
                    />
                    <SelectField
                      variant="whiteBg"
                      name="lawyerRole"
                      label="Account Type"
                      placeHolder="Select"
                      options={LAWYER_ACCOUNT_TYPES}
                    />
                  </>
                )}

                {values.role === "individual" && (
                  <SelectField
                    variant="whiteBg"
                    name="visaType"
                    label="Visa Type"
                    placeHolder="Select"
                    options={VISA_TYPES}
                  />
                )}

                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    name="showPasswordInput"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setShowPasswordInput(e.target.checked)
                    }
                  >
                    Manually set the user's password
                  </Checkbox>
                </FormControl>

                {showPasswordInput && (
                  <InputField
                    name="newPassword"
                    label="New Password"
                    type="password"
                    autoComplete="off"
                  />
                )}

                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    name="disabled"
                    isChecked={values.disabled}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: "disabled",
                          value: e.target.checked
                        }
                      });
                    }}
                  >
                    Disabled
                  </Checkbox>
                </FormControl>

                <Flex justifyContent="end" gap={2} mt={4}>
                  <Button
                    onClick={() => navigate("/admin/users")}
                    variant="secondaryOutline"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="primaryFilled"
                    isLoading={isSubmitting}
                  >
                    Update User
                  </Button>
                </Flex>
              </Stack>
            </form>
          )}
        </Formik>
      </Box>
    </SectionWrapper>
  );
}

export default AdminEditUser;
