import { SuperGroupedDocument } from "../helpers/helpers";
import { DataDocs } from "../types/tables-data";

export type DocumentOrder = {
  super_class: string;
  groups: {
    category: string;
    documentIds: { id: string; path: string }[];
  }[];
};

// Define the default order for each visa type
export const DEFAULT_ORDERS = {
  "EB-2-NIW": [
    "Processing",
    "Standard",
    "Expert Documents",
    "Letters",
    "Advanced Degree & Exceptional Ability",
    "Prong 1",
    "Prong 2",
    "Prong 3",
    "Law Firm Intake",
    "Unclassified"
  ],
  default: [
    "Processing",
    "Standard",
    "Expert Documents",
    "Letters",
    "Evidence",
    "Law Firm Intake",
    "Unclassified"
  ]
} as const;

export class DocumentOrderingService {
  private static instance: DocumentOrderingService;

  private orderMap: Map<string, number>;

  private visaType: string;

  private constructor(visaType: string) {
    this.visaType = visaType;
    this.orderMap = new Map();
    const defaultOrder =
      DEFAULT_ORDERS[visaType as keyof typeof DEFAULT_ORDERS] ||
      DEFAULT_ORDERS.default;

    // Initialize order map with default order
    defaultOrder.forEach((superClass, index) => {
      this.orderMap.set(superClass, index);
    });
  }

  public static getInstance(visaType: string): DocumentOrderingService {
    if (
      !DocumentOrderingService.instance ||
      DocumentOrderingService.instance.visaType !== visaType
    ) {
      DocumentOrderingService.instance = new DocumentOrderingService(visaType);
    }
    return DocumentOrderingService.instance;
  }

  /**
   * Group documents by super class and apply default order
   */
  public groupAndOrderDocuments(documents: DataDocs[]): SuperGroupedDocument[] {
    // Initialize map with all possible super classes from default order
    const superGroupedMap = new Map<string, SuperGroupedDocument>();
    const defaultOrder =
      DEFAULT_ORDERS[this.visaType as keyof typeof DEFAULT_ORDERS] ||
      DEFAULT_ORDERS.default;

    // Initialize all possible super classes
    defaultOrder.forEach((superClass) => {
      superGroupedMap.set(superClass, {
        super_class: superClass,
        groups: []
      });
    });

    // Group documents into their super classes
    documents.forEach((doc) => {
      const { super_class: superClass, criterion } = doc;
      if (!superClass || !criterion) return;

      // If we encounter a super class not in our default order, add it to the map
      if (!superGroupedMap.has(superClass)) {
        superGroupedMap.set(superClass, {
          super_class: superClass,
          groups: []
        });
      }

      const superGroup = superGroupedMap.get(superClass)!;
      let group = superGroup.groups.find((g) => g.type === criterion);

      if (!group) {
        group = { type: criterion, subrows: [], index: -1, expanded: false };
        superGroup.groups.push(group);
      }

      group.subrows.push(doc);
    });

    // Convert map to array and apply default order
    return this.applyDefaultOrder(Array.from(superGroupedMap.values()));
  }

  /**
   * Apply the default order to documents
   */
  public applyDefaultOrder(
    documents: SuperGroupedDocument[]
  ): SuperGroupedDocument[] {
    return [...documents].sort((a, b) => {
      const orderA =
        this.orderMap.get(a.super_class) ?? Number.MAX_SAFE_INTEGER;
      const orderB =
        this.orderMap.get(b.super_class) ?? Number.MAX_SAFE_INTEGER;
      return orderA - orderB;
    });
  }

  /**
   * Apply a saved order while preserving the default order for unknown categories
   */
  public applySavedOrder(
    documents: SuperGroupedDocument[],
    savedOrder: DocumentOrder[]
  ): SuperGroupedDocument[] {
    // Create maps for saved ordering
    const superClassOrderMap = new Map<string, number>();
    const groupOrderMap = new Map<string, number>();
    const documentOrderMap = new Map<string, Map<string, number>>();

    // Process saved order
    savedOrder.forEach((superGroup, superIndex) => {
      superClassOrderMap.set(superGroup.super_class, superIndex);

      superGroup.groups.forEach((group, groupIndex) => {
        const groupKey = `${superGroup.super_class}:${group.category}`;
        groupOrderMap.set(groupKey, groupIndex);

        const docMap = new Map<string, number>();
        group.documentIds.forEach((doc, docIndex) => {
          docMap.set(doc.id, docIndex);
        });
        documentOrderMap.set(groupKey, docMap);
      });
    });

    // Check if the saved order follows the default order
    const isDefaultOrder = this.isSavedOrderMatchingDefault(savedOrder);

    // Sort documents
    return [...documents]
      .sort((a, b) => {
        const orderA = superClassOrderMap.get(a.super_class);
        const orderB = superClassOrderMap.get(b.super_class);

        // If both superclasses are in the saved order, use saved order
        if (orderA !== undefined && orderB !== undefined) {
          return orderA - orderB;
        }

        // If saved order matches default order, use default order for new superclasses
        if (isDefaultOrder) {
          const defaultOrderA =
            this.orderMap.get(a.super_class) ?? Number.MAX_SAFE_INTEGER;
          const defaultOrderB =
            this.orderMap.get(b.super_class) ?? Number.MAX_SAFE_INTEGER;
          return defaultOrderA - defaultOrderB;
        }

        // If saved order is different from default, put new superclasses at the end
        if (orderA === undefined && orderB === undefined) {
          // If both are new, maintain their relative default order
          const defaultOrderA =
            this.orderMap.get(a.super_class) ?? Number.MAX_SAFE_INTEGER;
          const defaultOrderB =
            this.orderMap.get(b.super_class) ?? Number.MAX_SAFE_INTEGER;
          return defaultOrderA - defaultOrderB;
        }
        // Put the new superclass (undefined order) at the end
        return orderA === undefined ? 1 : -1;
      })
      .map((superGroup) => ({
        ...superGroup,
        groups: [...superGroup.groups]
          .sort((a, b) => {
            const keyA = `${superGroup.super_class}:${a.type}`;
            const keyB = `${superGroup.super_class}:${b.type}`;
            return (
              (groupOrderMap.get(keyA) ?? Number.MAX_SAFE_INTEGER) -
              (groupOrderMap.get(keyB) ?? Number.MAX_SAFE_INTEGER)
            );
          })
          .map((group) => ({
            ...group,
            subrows: [...group.subrows].sort((a, b) => {
              const groupKey = `${superGroup.super_class}:${group.type}`;
              const docMap = documentOrderMap.get(groupKey);
              return (
                (docMap?.get(a.id!) ?? Number.MAX_SAFE_INTEGER) -
                (docMap?.get(b.id!) ?? Number.MAX_SAFE_INTEGER)
              );
            })
          }))
      }));
  }

  /**
   * Check if the saved order follows the default order
   */
  private isSavedOrderMatchingDefault(savedOrder: DocumentOrder[]): boolean {
    const defaultOrder =
      DEFAULT_ORDERS[this.visaType as keyof typeof DEFAULT_ORDERS] ||
      DEFAULT_ORDERS.default;

    // Get ordered list of superclasses that exist in both saved and default orders
    const savedSuperClasses = new Set(savedOrder.map((sg) => sg.super_class));
    const defaultSuperClasses = [...defaultOrder].filter((sc) =>
      savedSuperClasses.has(sc)
    );

    // Compare the relative ordering of superclasses that exist in both orders
    for (let i = 0; i < defaultSuperClasses.length - 1; i += 1) {
      const currentClass = defaultSuperClasses[i];
      const nextClass = defaultSuperClasses[i + 1];

      const currentIndex = savedOrder.findIndex(
        (sg) => sg.super_class === currentClass
      );
      const nextIndex = savedOrder.findIndex(
        (sg) => sg.super_class === nextClass
      );

      if (currentIndex > nextIndex) {
        return false;
      }
    }

    return true;
  }

  /**
   * Check if any documents are still processing
   */
  public static hasProcessingDocuments(
    documents: SuperGroupedDocument[]
  ): boolean {
    return documents.some((sg) =>
      sg.groups.some((g) => g.subrows.some((doc) => doc.isProcessing))
    );
  }

  /**
   * Convert SuperGroupedDocument to DocumentOrder format
   */
  public static convertToFirestoreDocumentTableOrder(
    documents: SuperGroupedDocument[]
  ): DocumentOrder[] {
    return documents.map((superGroup) => ({
      super_class: superGroup.super_class,
      groups: superGroup.groups.map((group) => ({
        category: group.type,
        documentIds: group.subrows.map((doc) => ({
          id: doc.id!,
          path: doc.collectionPath || `/documents/${doc.id}/evidence_docs`
        }))
      }))
    }));
  }
}
