import { Box, ChakraProps, Container, Flex } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import CustomBackButton from "./CustomBackButton";
import { IndividualEditBox } from "./IndividualEditBox";

type Props = {
  withBackNav?: boolean;
  withIndividualEditBox?: boolean;
  backTargetPage?: string;
  bg?: string;
  onClick?: any;
} & PropsWithChildren &
  ChakraProps;

export const SectionWrapper = ({
  withBackNav = true,
  withIndividualEditBox = true,
  children,
  backTargetPage = "Dashboard",
  onClick = undefined,
  bg = "primary.backgroundLight",
  ...rest
}: Props) => {
  const backAriaLabel = `Back to ${backTargetPage}`;
  return (
    <Container py={4} my={4} maxW="100%" {...rest} overflow="visible">
      <Box>
        <Flex
          justifyContent="start"
          flexDirection="column"
          alignItems="start"
          gap={4}
        >
          {withIndividualEditBox && <IndividualEditBox />}
          {withBackNav && (
            <CustomBackButton ariaLabel={backAriaLabel} onClick={onClick} />
          )}
        </Flex>
      </Box>

      <Container maxW="100%" {...rest} bg={bg} py={4} overflow="visible">
        {children}
      </Container>
    </Container>
  );
};
