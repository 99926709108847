import { CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  Spinner,
  Text,
  Textarea,
  VStack
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { useSelector } from "react-redux";
import images from "../../assets/image-export-assets";
import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import { lawyerSelectors } from "../../redux/lawyer/selectors";
import { promptsSelectors } from "../../redux/prompts/selectors";
import { Prompt } from "../../types/studio/prompts";
import { DATABASE } from "../../types/tables-data";
import { useEditor } from "../../context/EditorContext";

type PreviewTextProps = {
  previewText: string;
};

export const PreviewText = ({ previewText }: PreviewTextProps) => {
  const { setShowToolbar, setPreviewText, replaceText } = useEditor();

  const previewTextRef = useRef<HTMLDivElement | null>(null);
  const { saveDocument } = useFirestoreCollection(DATABASE.PROMPTS, {
    listen: true,
    useLawyerAccessMap: true
  });

  // State for saving prompt
  const [isSavingPrompt, setIsSavingPrompt] = useState(false);
  const [isEditingPromptName, setIsEditingPromptName] = useState(false);
  const [promptName, setPromptName] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [hasSavedPrompt, setHasSavedPrompt] = useState(false);

  const [selectedPrompt, setSelectedPrompt] = useState(
    useSelector(promptsSelectors.selectSelectedPrompt)
  );

  /** Save custom prompt */
  const handleSavePrompt = async () => {
    if (!selectedPrompt || !selectedPrompt.promptName.trim()) return;

    setIsSavingPrompt(true);
    setIsSaved(false); // Reset saved state

    const newPrompt: Partial<Prompt> = {
      promptName: selectedPrompt.promptName,
      promptText: selectedPrompt.promptText
    };

    await saveDocument(newPrompt);
    setPromptName(newPrompt.promptName || "");

    setIsSavingPrompt(false);
    setIsSaved(true); // Mark as successfully saved
    setHasSavedPrompt(true);

    setTimeout(() => setIsSaved(false), 2000); // Auto-hide success indicator after 2 sec
    setIsEditingPromptName(false);
  };
  useEffect(() => {
    if (previewTextRef.current && previewText) {
      previewTextRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end"
      });
    }
  }, [previewTextRef, previewText]);

  return (
    <Box
      id="text-preview"
      ref={previewTextRef}
      border="1px solid"
      p={4}
      display="flex"
      flexDirection="column"
      flex="1"
      maxHeight="500px"
      borderColor="stroke.stroke2"
      borderRadius="lg"
      width="100%"
      bg="white"
    >
      {selectedPrompt && previewText && (
        <>
          <Box display="flex" flexDirection="column" w="100%">
            {isEditingPromptName && !isSavingPrompt ? (
              <Box display="flex" flexDirection="row" gap={2} w="100%">
                <VStack w="100%">
                  <Input
                    borderRadius="md"
                    value={selectedPrompt.promptName}
                    onChange={(e) =>
                      setSelectedPrompt((prev) =>
                        prev
                          ? { ...prev, promptName: e.target.value }
                          : undefined
                      )
                    }
                    placeholder="Enter prompt name"
                  />
                  <Flex justifyContent="end" width="100%" mb={2}>
                    <Button
                      variant="tertiaryText"
                      size="sm"
                      onClick={() => setIsEditingPromptName(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      variant="secondaryOutline"
                      onClick={handleSavePrompt}
                      isLoading={isSavingPrompt}
                      isDisabled={selectedPrompt.promptName.trim().length === 0}
                    >
                      Save
                    </Button>
                  </Flex>
                </VStack>
              </Box>
            ) : (
              <VStack width="100%">
                <VStack width="100%">
                  {selectedPrompt.id === "" && (
                    <HStack gap={1} width="100%" justifyContent="flex-end">
                      <IconButton
                        size="md"
                        aria-label="Save prompt"
                        icon={
                          isSavingPrompt ? (
                            <Spinner size="xs" color="blue.500" />
                          ) : isSaved ? (
                            <CheckIcon color="green.500" />
                          ) : hasSavedPrompt ? (
                            <FaStar title="Saved" />
                          ) : (
                            <FaRegStar title="Save" />
                          )
                        }
                        variant="outlineIconButton"
                        color="text.graySecondary.smog"
                        onClick={() => setIsEditingPromptName(true)}
                      />
                      <IconButton
                        title="Discard prompt"
                        size="sm"
                        aria-label="Discard prompt"
                        icon={<DeleteIcon />}
                        color="text.graySecondary.smog"
                        variant="outlineIconButton"
                        onClick={() => {
                          setPreviewText(null);
                        }}
                      />
                    </HStack>
                  )}
                </VStack>
              </VStack>
            )}
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            bg="gray.100"
            borderRadius="md"
            p={2}
            justifyContent="space-between"
          >
            <HStack>
              <Image src={images.magicStickIcon} h="40px" />
              <Text fontWeight={500} ml={2}>
                {selectedPrompt.promptName || selectedPrompt.promptText}
              </Text>
            </HStack>
          </Box>
        </>
      )}

      <Text fontSize="sm" fontWeight="bold" color="gray.700" my={2}>
        GENERATED RESULT PREVIEW
      </Text>
      <Textarea
        isReadOnly
        fontWeight={400}
        resize="none"
        fontSize="md"
        textColor="gray.800"
        id="text-preview-box"
        maxHeight="500px"
        height="fit-content"
        minHeight="200px"
        width="3xl"
        textAlign="left"
        p={2}
        value={previewText}
        overflowY="auto"
        whiteSpace="pre-wrap"
        wordBreak="break-word"
        sx={{
          "&::-webkit-scrollbar": {
            width: "8px"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray.300",
            borderRadius: "4px"
          }
        }}
      >
        {previewText}
      </Textarea>
      <Box display="flex" justifyContent="end" gap={2} p={2}>
        <Button
          size="sm"
          variant="secondaryOutline"
          onClick={() => {
            setPreviewText(null);
            setShowToolbar(false);
          }}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          variant="primaryFilled"
          onClick={() => {
            replaceText(previewText);
          }}
        >
          Apply changes
        </Button>
      </Box>
    </Box>
  );
};
