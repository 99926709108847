import { Box, Divider, VStack } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { shouldAccess } from "../../helpers/firebase_helpers";
import { useExpertLetterDrafts } from "../../hooks/useExpertLetterDrafts";
import { useGetFormsData } from "../../hooks/useGetFormsData";
import { useGetIndividualData } from "../../hooks/useGetIndividualData";
import { visaFromPath, VISAVALUE } from "../../types/tables-data";
import { ExhibitListSection } from "./individualDrafts/ExhibitListSection";
import { ExpertLetterDraftsSection } from "./individualDrafts/ExpertLettersDraftSection";
import { SopDraftSection } from "./individualDrafts/SopDraftSection";
import { Feature } from "../../types/features";

interface Props {
  mainTabIndex?: number | null;
  subTabIndex?: number | null;
}

export const IndividualDrafts = ({ mainTabIndex, subTabIndex }: Props) => {
  const { id, visaType } = useParams();
  const loginType = localStorage.getItem("loginType");
  const {
    onSubmitGetIndividualData,
    isLoadingGetIndividualData,
    individualData
  } = useGetIndividualData();
  const [isLoadingDrafts, setIsLoadingDrafts] = useState(true);

  useEffect(() => {
    if (id) {
      onSubmitGetIndividualData(id);
      setIsLoadingDrafts(false);
    }
  }, [id]);

  useExpertLetterDrafts(id!);
  useGetFormsData();

  const accessControl = useMemo(
    () => ({
      experts: shouldAccess(individualData, loginType, Feature.EXPERT_LETTERS),
      exhibitList: shouldAccess(
        individualData,
        loginType,
        Feature.EXHIBIT_LIST
      ),
      sop: shouldAccess(individualData, loginType, Feature.SOP)
    }),
    [individualData, loginType]
  );

  if (isLoadingDrafts || isLoadingGetIndividualData) {
    return <div>Loading drafts...</div>;
  }

  const showForCurrentVisa = ![
    VISAVALUE.EB1C,
    VISAVALUE.L1A,
    VISAVALUE.H1B,
    VISAVALUE.L1B
  ].includes(visaFromPath[visaType || ""]);

  return (
    <Box className="w-full h-full">
      <VStack
        alignItems="stretch"
        gap={8}
        divider={<Divider my={8} />}
        w="100%"
      >
        {accessControl.experts && showForCurrentVisa && (
          <ExpertLetterDraftsSection />
        )}
        {accessControl.exhibitList && <ExhibitListSection />}
        {accessControl.sop && <SopDraftSection />}
      </VStack>
    </Box>
    // )
  );
};
