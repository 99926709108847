import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  Select
} from "@chakra-ui/react";

interface WageInputProps {
  value: { amount: number | null; period: string };
  onChange: (value: { amount: number | null; period: string }) => void;
}

const WageInput: React.FC<WageInputProps> = ({ value, onChange }) => {
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numValue = parseFloat(e.target.value);
    onChange({ ...value, amount: Number.isNaN(numValue) ? null : numValue });
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPeriod = e.target.value;
    onChange({ ...value, period: selectedPeriod });
  };

  return (
    <Flex>
      {/* Amount Input with Placeholder */}
      <Box flex="1" mr={2}>
        <InputGroup>
          <Input
            placeholder="Enter amount"
            value={value.amount !== null && value.amount !== 0 ? value.amount : ""}
            onChange={handleAmountChange}
            type="number"
          />
          <InputRightAddon>$</InputRightAddon>
        </InputGroup>
      </Box>
      {/* Period Select */}
      <Box flex="1">
        <Select
          value={value.period || "monthly"}
          onChange={handlePeriodChange}
        >
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </Select>
      </Box>
    </Flex>
  );
};

export default WageInput;
