import { Button, Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useUserRoleAndSubscription } from "../../../hooks/useUserRoleAndSubscription";
import { documentSelectors } from "../../../redux/documents/selectors";
import { EXTRACTIONSTATES } from "../../../redux/extraction-jobs/extractionJobsSlice";
import { CustomBox } from "../../common/CustomBox";
import { CustomButton } from "../../common/CustomButton";
import { DocumentsInfoModal } from "../../common/DocumentsInfoModal";
import { SpecialHeading } from "../PanelLayout";
import { ExpertLettersList } from "./ExpertLettersList";

export const ExpertLetterDraftsSection = () => {
  const navigate = useNavigate();
  const { id, visaType } = useParams();
  const { isIndividual } = useUserRoleAndSubscription();

  const groupedDocuments = useSelector(documentSelectors.groupedDocuments);

  const candidateResume = (() => {
    const alldocuments = groupedDocuments
      .flatMap((element) => element.groups)
      .flatMap((group) => group.subrows);
    return alldocuments.find(
      (document) =>
        document.criterion === "Resume" ||
        document.criterion === "Applicant Resume"
    );
  })();

  // Derived state: Determine if "Generate" button should be disabled
  const isGenerateDisabled = (() => {
    if (!candidateResume) {
      return {
        isDisabled: true,
        message:
          "To generate expert letters, please upload the necessary document(s):",
        requirement: "Applicant resume"
      };
    }

    if (candidateResume.status?.status !== EXTRACTIONSTATES.Completed) {
      return {
        isDisabled: true,
        message:
          "Dependency document is processing. Please wait until it's complete:",
        requirement: "Applicant resume"
      };
    }

    return { isDisabled: false, message: null, requirement: null };
  })();

  const onGenerate = () => {
    navigate(`/individual/${id}/${visaType}/drafts/expert-letters/add`);
  };

  return (
    <Flex flexDirection="column">
      <SpecialHeading
        title="Expert Letters"
        withInfo={<DocumentsInfoModal title="Expert Letters" />}
      />
      {/* Missing Requirements Info */}
      {isGenerateDisabled.isDisabled && isGenerateDisabled.requirement && (
        <CustomBox type="info">
          <Flex flexDirection="column">
            <Text>{isGenerateDisabled.message}</Text>
            <Flex p={4} columnGap={2} alignItems="center">
              <li>
                <Text as="span" fontWeight="bold">
                  {isGenerateDisabled.requirement}
                </Text>
                {isGenerateDisabled.requirement.includes("resume") &&
                  !candidateResume && (
                    <Button
                      m={2}
                      variant="secondaryOutline"
                      size="sm"
                      onClick={() => navigate("documents")}
                    >
                      Click here to upload
                    </Button>
                  )}
              </li>
            </Flex>
          </Flex>
        </CustomBox>
      )}
      {/* Expert Letters List */}
      <ExpertLettersList />
      <Flex mt={4} justifyContent="center">
        <CustomButton
          type="ai"
          title="Add Expert"
          isPremium
          isDisabled={isGenerateDisabled.isDisabled}
          onClick={onGenerate}
        />
        {isIndividual && (
          <Button
            className="btn primary-btn"
            isDisabled={isGenerateDisabled.isDisabled}
            onClick={onGenerate}
          >
            Add Expert
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
