import { useToast } from "@chakra-ui/react";
import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where
} from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../api/firebaseApi";
import { fetchFiles, generatePdfThumbnail } from "../helpers/helpers";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { setPrompts } from "../redux/prompts/promptsSlice";
import { templatesSelectors } from "../redux/templates/selectors";
import { setTemplates } from "../redux/templates/templatesSlice";
import { Prompt } from "../types/studio/prompts";
import { CustomTemplate } from "../types/studio/templates";
import { DATABASE } from "../types/tables-data";

interface FirestoreHookOptions {
  listen?: boolean;
  fetchFiles?: boolean;
  withThumbnails?: boolean;
  useLawyerAccessMap?: boolean;
  enableToast?: boolean;
  orderByField?: string;
}

function useFirestoreCollection<T>(
  collectionPath?: string,
  options: FirestoreHookOptions = {
    orderByField: "",
    listen: false,
    fetchFiles: false,
    withThumbnails: false,
    useLawyerAccessMap: false,
    enableToast: true
  }
) {
  const dispatch = useDispatch();
  const toast = useToast();

  const [documents, setDocuments] = useState<T[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [isDocumentSaving, setIsDocumentSaving] = useState(false);
  const lawyerId = useSelector(lawyerSelectors.selectUid);
  const lawyerPractice = useSelector(lawyerSelectors.selectPracticeName);
  const role = useSelector(lawyerSelectors.selectRole);

  const {
    orderByField,
    listen,
    fetchFiles: shouldFetchFiles,
    withThumbnails,
    enableToast
  } = options;

  const handleDispatch = useCallback(
    (data: T[]) => {
      if (!collectionPath) return;
      const segment = Object.values(DATABASE).includes(
        collectionPath as DATABASE
      )
        ? (collectionPath as DATABASE)
        : (collectionPath.split("/")[2] as DATABASE);

      switch (segment) {
        case DATABASE.CUSTOM_TEMPLATES:
          dispatch(setTemplates({ templates: data as CustomTemplate[] }));
          break;
        case DATABASE.PROMPTS:
          dispatch(setPrompts({ prompts: data as Prompt[] }));
          break;
        default:
          console.log("Unknown database type", { segment });
          break;
      }
    },
    [collectionPath, dispatch]
  );

  const fetchDocFilesAndThumbnails = async (docData: any) => {
    const finalData = { ...docData, path: docData.path };

    if (shouldFetchFiles && finalData.filePath) {
      try {
        const docUrl = await fetchFiles([finalData.filePath]).then(
          (urls) => urls[0] || null
        );
        finalData.docUrl = docUrl ?? "";
        if (withThumbnails && finalData.filePath.endsWith(".pdf") && docUrl) {
          finalData.thumbnail = await generatePdfThumbnail(docUrl);
        }
      } catch (err) {
        console.error("Error fetching file or thumbnail:", err);
      }
    }
    return finalData;
  };

  const getQueries = (colRef: CollectionReference) => {
    const queries = [];
    if (options.useLawyerAccessMap && role !== "superadmin") {
      if (lawyerId) {
        queries.push(
          query(
            colRef,
            where("isDeleted", "==", false),
            where("access.lawyerId", "==", lawyerId)
          )
        );
      }
      if (lawyerPractice) {
        queries.push(
          query(
            colRef,
            where("isDeleted", "==", false),
            where("access.practiceName", "==", lawyerPractice)
          )
        );
      }
      queries.push(
        query(
          colRef,
          where("isDeleted", "==", false),
          where("access.public", "==", true)
        )
      );
    } else {
      queries.push(query(colRef, where("isDeleted", "==", false)));
    }
    return queries;
  };

  const handleError = (err: any) => {
    console.error("Error fetching data:", err);
    setError(err);
    setLoading(false);
    toast({
      title: "Error fetching data",
      description: err.message,
      status: "error",
      duration: 5000,
      isClosable: true
    });
  };

  const fetchData = async () => {
    if (!collectionPath) return;
    const colRef = collection(db, collectionPath);
    const queries = getQueries(colRef);
    setLoading(true);
    try {
      const snapshots = await Promise.all(queries.map((q) => getDocs(q)));
      const mergedMap = new Map<string, any>();
      snapshots.forEach((snapshot) => {
        snapshot.docs.forEach((doc) => {
          mergedMap.set(doc.id, doc);
        });
      });
      const dataPromises = Array.from(mergedMap.values()).map(async (doc) => {
        let docData = { id: doc.id, path: doc.ref.path, ...doc.data() };
        docData = await fetchDocFilesAndThumbnails(docData);
        return docData;
      });
      const data = (await Promise.all(dataPromises)) as T[];
      setDocuments(data);
      handleDispatch(data);
      setLoading(false);
      setInitialLoadDone(true);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    if (!collectionPath) {
      console.log("useFirestoreCollection: No collectionPath provided");
      return () => {};
    }

    const colRef = collection(db, collectionPath);
    const queries = getQueries(colRef);
    console.log("Firestore queries:", queries);

    let unsubscribe: (() => void) | undefined;

    if (listen) {
      const unsubscribes: Array<() => void> = [];
      const docsMaps = queries.map(() => new Map<string, any>());
      const initialSnapshots = new Array(queries.length).fill(false);
      const hasInitialDataLoaded = new Array(queries.length).fill(false); // Track if initial data is loaded
      setLoading(true);
      setInitialLoadDone(false);

      queries.forEach((q, index) => {
        unsubscribes.push(
          onSnapshot(
            q,
            async (snapshot) => {
              const docPromises = snapshot.docChanges().map(async (change) => {
                if (change.type === "removed" || change.doc.data().isDeleted) {
                  docsMaps[index].delete(change.doc.id);
                } else {
                  let docData = {
                    id: change.doc.id,
                    path: change.doc.ref.path,
                    ...change.doc.data()
                  };
                  docData = await fetchDocFilesAndThumbnails(docData);
                  docsMaps[index].set(change.doc.id, docData);
                }
              });

              await Promise.all(docPromises);

              // Check if this is the initial snapshot and it's not from cache
              if (
                !snapshot.metadata.fromCache &&
                !hasInitialDataLoaded[index]
              ) {
                hasInitialDataLoaded[index] = true; // Mark initial data as loaded
              }

              // Only update state if ALL queries have returned their first snapshot
              if (hasInitialDataLoaded.every((loaded) => loaded)) {
                const mergedMap = new Map<string, any>();
                docsMaps.forEach((map) => {
                  map.forEach((value, key) => {
                    mergedMap.set(key, value);
                  });
                });
                const updatedData = Array.from(mergedMap.values());
                setDocuments(updatedData);
                handleDispatch(updatedData);
                setLoading(false);
                if (!initialLoadDone) {
                  setInitialLoadDone(true);
                }
              }
            },
            handleError
          )
        );
      });

      unsubscribe = () => unsubscribes.forEach((unsub) => unsub());
    } else {
      // One-time fetch
      fetchData();
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [
    collectionPath,
    listen,
    orderByField,
    options.useLawyerAccessMap,
    lawyerId,
    lawyerPractice,
    role
  ]);

  const saveDocument = useCallback(
    async (newDoc: Partial<any>) => {
      if (!collectionPath) {
        console.error("saveDocument: No collectionPath provided");
        toast({
          title: "Error",
          description: "No collection path provided. Unable to save document.",
          status: "error",
          duration: 5000,
          isClosable: true
        });
        return;
      }
      setIsDocumentSaving(true);
      try {
        const colRef = collection(db, collectionPath);
        if (options.useLawyerAccessMap) {
          newDoc.access = {
            lawyerId: lawyerId || "",
            practiceName: lawyerPractice || "",
            public: false
          };
        }
        if (newDoc.id) {
          const docRef = doc(db, collectionPath, newDoc.id);
          await updateDoc(docRef, {
            ...newDoc,
            updated_at: serverTimestamp()
          });
          if (enableToast)
            toast({
              title: "Document updated",
              description: "Document successfully updated.",
              status: "success",
              duration: 5000,
              isClosable: true
            });
        } else {
          await addDoc(colRef, {
            ...newDoc,
            created_at: serverTimestamp(),
            isDeleted: false
          });
          toast({
            title: "Document added",
            description: "Document successfully added.",
            status: "success",
            duration: 5000,
            isClosable: true
          });
        }
      } catch (err: any) {
        console.error("Error saving document:", err);
        toast({
          title: "Error saving document",
          description: err.message || "An unknown error occurred.",
          status: "error",
          duration: 5000,
          isClosable: true
        });
      } finally {
        setIsDocumentSaving(false);
      }
    },
    [
      collectionPath,
      toast,
      lawyerId,
      lawyerPractice,
      enableToast,
      options.useLawyerAccessMap
    ]
  );

  const deleteDocument = useCallback(
    async (id: string) => {
      if (!collectionPath) {
        console.error("deleteDocument: No collectionPath provided");
        toast({
          title: "Error",
          description:
            "No collection path provided. Unable to delete document.",
          status: "error",
          duration: 5000,
          isClosable: true
        });
        return;
      }
      try {
        const docRef = doc(db, collectionPath, id);
        await updateDoc(docRef, { isDeleted: true });
        toast({
          title: "Document deleted",
          description: "The document has been successfully deleted.",
          status: "success",
          duration: 5000,
          isClosable: true
        });
      } catch (err: any) {
        console.error("Error deleting document:", err);
        toast({
          title: "Error deleting document",
          description: err.message || "An unknown error occurred.",
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }
    },
    [collectionPath, toast]
  );

  return {
    saveDocument,
    deleteDocument,
    isDocumentSaving,
    documents,
    loading,
    error,
    initialLoadDone,
    fetchData
  };
}

export default useFirestoreCollection;
