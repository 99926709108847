import { Box, SkeletonText } from "@chakra-ui/react";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import { useEditor } from "../../context/EditorContext";
import { AIToolbar } from "./AiToolbar";
import { EditorToolbar } from "./EditorToolbar";
import { EditorWidget } from "./EditorWidget";

type EditorContentProps = {
  editorId: string;
  loading?: boolean;
  placeholder?: string | null;
  customHeight: string;
  toolbarRef: React.RefObject<HTMLDivElement>;
  evidencesToHighlight: string[];
  setEvidencesToHighlight: React.Dispatch<React.SetStateAction<string[]>>;
  isPromptSelected: boolean | null;
  setIsPromptSelected: (selected: boolean | null) => void;
  allowOverflow: boolean;
  setAllowOverflow: (allow: boolean) => void;
  QUILL_MODULES: any;
  enableExhibitNumbers?: boolean;
};

export const EditorContent = ({
  editorId,
  loading,
  placeholder,
  customHeight,
  toolbarRef,
  evidencesToHighlight,
  setEvidencesToHighlight,
  isPromptSelected,
  setIsPromptSelected,
  allowOverflow,
  setAllowOverflow,
  QUILL_MODULES,
  enableExhibitNumbers
}: EditorContentProps) => {
  const {
    quillRef,
    parsedText,
    bounds,
    range,
    saveStatus,
    showPromptIcon,
    handleSelectionChange,
    handleBlur,
    handleFocus,
    handleChange
  } = useEditor();

  const fullText = quillRef?.current?.getEditor().getText();

  return (
    <>
      {showPromptIcon && <EditorWidget />}

      {/* Editor toolbar */}
      <EditorToolbar
        saveStatus={saveStatus}
        editorId={editorId}
        ref={toolbarRef}
      />

      <Box
        position="relative"
        border={loading ? "1px solid" : undefined}
        borderColor={loading ? "gray.300" : undefined}
        borderRadius="md"
        backgroundColor="white"
      >
        <ReactQuill
          id={`quill-editor-${editorId}`}
          ref={quillRef}
          placeholder={placeholder ?? ""}
          theme="snow"
          value={parsedText}
          readOnly={loading ?? false}
          style={{ height: customHeight }}
          modules={QUILL_MODULES}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChangeSelection={handleSelectionChange}
          onChange={handleChange}
        />

        {/* Skeleton Animation */}
        {loading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            backgroundColor="rgba(255, 255, 255, 0.8)"
            zIndex={1}
            display="flex"
            flexDirection="column"
            padding="10px"
            gap="10px"
          >
            <SkeletonText
              mt={6}
              width="62vw"
              noOfLines={8}
              spacing="18px"
              skeletonHeight="12px"
            />
          </Box>
        )}
      </Box>

      {/* Prompting toolbar */}
      {bounds && (
        <AIToolbar
          setPromptisSelected={setIsPromptSelected}
          evidencesToHighlight={evidencesToHighlight}
          setEvidencesToHighlight={setEvidencesToHighlight}
          quillToolbarRef={toolbarRef}
          enableExhibitNumbers={enableExhibitNumbers}
        />
      )}
    </>
  );
};
