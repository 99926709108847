import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";
import { Worker } from "@react-pdf-viewer/core";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { scan } from "react-scan";
import { PersistGate } from "redux-persist/integration/react";
import AppInitializer from "./AppInitializer";
import { NavbarProvider } from "./hooks/useNavbarContext";
import { persistor, store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

scan({ enabled: false });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StrictMode>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ChakraProvider theme={theme}>
            <NavbarProvider>
              <AppInitializer />
            </NavbarProvider>
          </ChakraProvider>
        </PersistGate>
      </Provider>
    </Worker>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
