import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tableAnatomy.keys
);

export const tablesTheme = defineMultiStyleConfig({
  // Define the parts you're going to style
  baseStyle: {
    table: {
      borderRadius: "md", // Apply rounded corners to the entire table
      border: "none" // No border for the table
    },
    thead: {
      bg: "background.misty",
      borderTopRadius: "md" // Rounded top for the table head
    },
    tbody: {
      tr: {
        background: "white",
        fontWeight: "500",
        borderBottom: "1px solid", // Subtle divider for rows
        borderColor: "gray.300", // Lighter gray color for divider
        "&:last-of-type": {
          borderBottom: "none" // Remove the bottom border for the last row
        }
      }
    }
  },
  variants: {
    stripedHover: {
      table: {
        borderRadius: "md",
        overflow: "hidden"
      },
      tbody: {
        tr: {
          fontWeight: "500",
          "&:nth-of-type(odd)": {
            background: "white" // Background for odd rows
          },
          "&:nth-of-type(even)": {
            background: "#EEF2F666" // Background for even rows
          },
          _hover: {
            background: "#D1D5DB99" // Darker hover color for even rows
          }
        }
      }
    },
    basicDivided: {
      table: {
        borderRadius: "md",
        overflow: "hidden"
      },
      thead: {
        bg: "background.misty"
      }
    }
  }
});
