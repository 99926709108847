import {
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Textarea
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { CheckIcon } from "@chakra-ui/icons";
import images from "../../../assets/image-export-assets";
import { Prompt } from "../../../types/studio/prompts";

interface CustomPromptModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (prompt: Partial<Prompt>) => Promise<void>;
  isSaving: boolean;
  existingPrompt?: Partial<Prompt>; // Optional prop for editing existing prompt
}

const AddEditPrompt: React.FC<CustomPromptModalProps> = ({
  isOpen,
  onClose,
  onSave,
  isSaving,
  existingPrompt
}) => {
  const [promptName, setPromptName] = useState(
    existingPrompt?.promptName || ""
  );
  const [promptText, setPromptText] = useState(
    existingPrompt?.promptText || ""
  );
  const [saveState, setSaveState] = useState<"idle" | "saving" | "success">(
    "idle"
  );

  const isDisabled =
    promptName === "" || promptText === "" || saveState === "saving";

  const handleSave = async () => {
    if (isDisabled) return;

    setSaveState("saving");
    try {
      await onSave({
        ...existingPrompt,
        promptName,
        promptText
      } as Partial<Prompt>);
      setSaveState("success");
    } catch (error) {
      console.error("Error saving prompt:", error);
      setSaveState("idle");
    }
  };

  useEffect(() => {
    if (saveState === "success") {
      const timer = setTimeout(() => {
        setSaveState("idle");
        onClose();
      }, 100);

      return () => clearTimeout(timer); // Cleanup on unmount
    }

    return () => {}; // No-op cleanup for other cases
  }, [saveState, onClose]);

  useEffect(() => {
    if (existingPrompt) {
      setPromptName(existingPrompt.promptName || "");
      setPromptText(existingPrompt.promptText || "");
    }

    return () => {
      setPromptName("");
      setPromptText(""); // Reset state on unmount
    };
  }, [existingPrompt]);

  useEffect(() => {
    if (!isOpen) {
      setSaveState("idle"); // Reset saveState when the modal is closed
      setPromptName("");
      setPromptText("");
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {existingPrompt ? "Edit Prompt" : "Add a Custom Prompt"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Prompt name</FormLabel>
            <InputGroup>
              <InputLeftElement>
                <Image
                  boxSize="40px"
                  objectFit="contain"
                  src={images.magicStickIcon}
                />
              </InputLeftElement>
              <Input
                placeholder="Prompt name"
                value={promptName}
                onChange={(e) => setPromptName(e.target.value)}
              />
            </InputGroup>
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Prompt</FormLabel>
            <Textarea
              placeholder="Enter your prompt here..."
              value={promptText}
              onChange={(e) => setPromptText(e.target.value)}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="secondaryOutline"
            onClick={onClose}
            isDisabled={saveState === "saving"}
          >
            Cancel
          </Button>
          <Button
            variant="primaryFilled"
            ml={3}
            onClick={handleSave}
            isDisabled={isDisabled}
            leftIcon={
              saveState === "saving" ? (
                <Spinner size="sm" />
              ) : saveState === "success" ? (
                <CheckIcon />
              ) : undefined
            }
          >
            {saveState === "saving"
              ? "Saving..."
              : saveState === "success"
              ? "Saved"
              : existingPrompt
              ? "Update Prompt"
              : "Save Prompt"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddEditPrompt;
