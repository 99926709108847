import { createContext, useContext, RefObject } from "react";
import ReactQuill from "react-quill";
import { useEditorHandlers } from "../hooks/useEditorHandlers";

type EditorContextType = ReturnType<typeof useEditorHandlers> & {
  quillRef: RefObject<ReactQuill>;
};

export const EditorContext = createContext<EditorContextType | null>(null);

export const useEditor = () => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error("useEditor must be used within an EditorProvider");
  }
  return context;
};
