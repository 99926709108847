import { LegalBriefSection } from "../../redux/drafts/draftsSlice";
import { LegalBriefType } from "../drafts.type";
import { DataDocs, VISAVALUE } from "../tables-data";
import { Access } from "./prompts";

export type TemplateParams = {
  briefType: string | null;
  briefLength: string | null;
  existingTemplate: string | null;
  templateName: string | null;
  retainAreaOfExpertise: boolean | null;
  retainEmployerDetails: boolean | null;
  fileUploaded: boolean | null;
};

export type CustomTemplate = DataDocs & {
  id?: string;
  templateName: string;
  templateInfoToRetain: string[];
  created_at: number;
  created_by: string;
  last_updated_by?: string | null;
  visa: VISAVALUE;
  type: CustomTemplateType;
  fromPreviousCase?: boolean;
  sections?: LegalBriefSection[];
  addedDuringGeneration?: boolean;
  access?: Access;
};

export type CustomTemplateType =
  | "legal_brief_templates"
  // | "expert_letters_templates"
  | "support_letters_templates";

export const templateTypeMap: Record<CustomTemplateType, LegalBriefType> = {
  legal_brief_templates: LegalBriefType.LEGAL_BRIEF,
  // expert_letters_templates: "Expert Letters",
  support_letters_templates: LegalBriefType.SUPPORT_LETTER
};

export const templateTypeDisplayMap: Record<CustomTemplateType, string> = {
  legal_brief_templates: "Legal Brief Templates",
  // expert_letters_templates: "Expert Letters",
  support_letters_templates: "Support Letter Templates"
};

export type TemplateOptionKey =
  | "cb_template"
  | "existing_template"
  | "new_template"
  | "from_previous_case";

export interface TemplateOption {
  key?: string;
  title: string;
  badgeText?: string;
}

export const templateOptions: TemplateOption[] = [
  { key: "cb_template", title: "Use CaseBlink's template" },
  {
    key: "existing_template",
    title: "Use my templates",
    badgeText: "BETA"
  }
  // { key: "new_template", title: "Upload new template", badgeText: "BETA" },
  // {
  //   key: "from_previous_case",
  //   title: "Upload a previous case sample",
  //   badgeText: "BETA"
  // }
];
