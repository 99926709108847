import { DragHandleIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack
} from "@chakra-ui/react";
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactNode, useEffect, useState } from "react";
import { DataDocs } from "../../types/tables-data";
import { CheckedItem } from "../../types/dropdown.types";

function SortableTag<T>({
  item,
  index,
  onClose,
  children
}: {
  item: DataDocs;
  index: number;
  onClose: (item: DataDocs) => void;
  children: ReactNode;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: item.id! });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  return (
    <Box ref={setNodeRef} style={style} display="flex" alignItems="center">
      {/* 1. Drag icon */}
      <Box
        cursor="grab"
        display="flex"
        alignItems="center"
        pr="8px"
        {...attributes}
        {...listeners}
      >
        <DragHandleIcon />
      </Box>

      <Flex
        w="22px"
        textAlign="right"
        mr="8px"
        justifyContent="start"
        alignItems="start"
        fontSize={16}
        fontWeight={500}
      >
        {index + 1}.
      </Flex>

      {/* 3. The Tag with the close button */}
      {children}
    </Box>
  );
}

function ShufflableTags({
  derivedList,
  handleSelect,
  setSelectedEvidenceIds
}: {
  derivedList: CheckedItem<DataDocs>[];
  handleSelect: (item: CheckedItem<DataDocs>) => void;
  setSelectedEvidenceIds: (value: React.SetStateAction<string[]>) => void;
}) {
  const [items, setItems] = useState(derivedList);
  // DnD Sensors
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );
  // Called after drag is dropped
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = items.findIndex((el) => el.id === active.id);
    const newIndex = items.findIndex((el) => el.id === over.id);
    if (oldIndex < 0 || newIndex < 0) return;

    // Reorder array
    const newList = arrayMove(items, oldIndex, newIndex);
    setItems(newList);
    setSelectedEvidenceIds(newList.map((item) => item.id));
  };

  useEffect(() => {
    if (items.length !== derivedList.length) setItems(derivedList);
  }, [derivedList]);

  return (
    <div>
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext
          items={items.map((doc) => ({ id: doc.id! }))}
          strategy={verticalListSortingStrategy}
        >
          <VStack align="stretch" spacing={3} maxH="md" overflowY="auto">
            {items.map((item, i) => (
              <Flex key={item.id} align="flex-start" width="100%">
                {/* 2) Pass “index” to SortableTag */}
                <SortableTag item={item} index={i} onClose={() => {}}>
                  <Tag size="lg" variant="brand" maxW="600px" overflow="hidden">
                    <TagLabel>{item.autoTitle}</TagLabel>
                    <TagCloseButton
                      onClick={(e) => {
                        e.stopPropagation(); // Don’t trigger drag
                        handleSelect(item);
                      }}
                    />
                  </Tag>
                </SortableTag>
              </Flex>
            ))}
          </VStack>
        </SortableContext>
      </DndContext>
    </div>
  );
}

export default ShufflableTags;
