import { useToast } from "@chakra-ui/react";
import type { OneDriveOptions, OneDriveFile } from "../types/onedrive";

const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID;

if (!MICROSOFT_CLIENT_ID) {
  throw new Error("MICROSOFT_CLIENT_ID environment variable is not set");
}

// Use the root URL as the redirect URI to match Azure configuration
const REDIRECT_URI = window.location.origin;

const getFileExtension = (file: OneDriveFile): string | undefined => {
  return file.name.split(".").pop()?.toLowerCase();
};

const getMimeType = (file: OneDriveFile): string => {
  const extension = getFileExtension(file);
  switch (extension) {
    case "png":
      return "image/png";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "pdf":
      return "application/pdf";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "doc":
      return "application/msword";
    default:
      return "application/octet-stream";
  }
};

export const useSharePoint = () => {
  const toast = useToast();

  const pickFiles = async (): Promise<File[]> => {
    return new Promise((resolve) => {
      const options: OneDriveOptions = {
        clientId: MICROSOFT_CLIENT_ID,
        action: "download",
        multiSelect: true,
        openInNewWindow: true,
        advanced: {
          redirectUri: REDIRECT_URI
        },
        success: async (response: {
          value: OneDriveFile[];
          accessToken: string;
        }) => {
          try {
            toast.closeAll();
            toast({
              title: "Retrieving files from SharePoint...",
              status: "loading",
              duration: null,
              isClosable: false
            });

            const filePromises = response.value.map(
              async (file: OneDriveFile) => {
                const downloadUrl = file["@microsoft.graph.downloadUrl"];
                const fileResponse = await fetch(downloadUrl);
                const blob = await fileResponse.blob();
                return new File([blob], file.name, {
                  type: getMimeType(file)
                });
              }
            );

            const downloadedFiles = await Promise.all(filePromises);
            toast.closeAll();
            resolve(downloadedFiles);
          } catch (error) {
            toast.closeAll();
            toast({
              title: "Error retrieving files from SharePoint",
              status: "error",
              duration: 3000,
              isClosable: true
            });
            resolve([]);
          }
        },
        cancel: () => resolve([]),
        error: (error: Error) => {
          console.error("OneDrive Picker Error:", error);
          toast({
            title: "Error connecting to Microsoft account",
            description: error.message || "Please try again",
            status: "error",
            duration: 3000,
            isClosable: true
          });
          resolve([]);
        }
      };

      if (window.OneDrive) {
        window.OneDrive.open(options);
      } else {
        toast({
          title: "SharePoint not available",
          description: "Please try again",
          status: "error",
          duration: 3000,
          isClosable: true
        });
        resolve([]);
      }
    });
  };

  return { pickFiles };
};
