import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  root: {
    overflow: "visible !important"
  },
  button: {
    textColor: "text.color",
    fontWeight: "600",
    bg: "#F8FAFB",
    py: "16px",
    gap: "8px"
  },
  panel: {
    overflow: "visible !important"
  },
  container: {
    overflow: "visible",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    borderColor: "#E2E8F0",
    bg: "#F8FAFB"
  }
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle });
