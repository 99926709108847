import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
  List,
  ListItem,
  Spacer
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { sidebarSelectors } from "../../redux/sidebar/selectors";
import { CustomIcon } from "../common/Icons";
import { NavigationLink } from "./NavigationLink";
import { SidebarLink } from "./types";

type Props = {
  links: SidebarLink[];
  onClick?: () => void;
};
const SidebarLinks = ({ links, onClick }: Props) => {
  const { opened } = useSelector(sidebarSelectors.selectAll);

  return (
    <Accordion allowToggle variant="sidebar-menu ">
      <List
        spacing={4}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start"
        }}
      >
        {links.map((link, index) => (
          <ListItem key={index}>
            {!link.subLinks && (
              <NavigationLink key={index} link={link} onClick={onClick} />
            )}
            {link.subLinks && (
              <AccordionItem border={0} as={Link}>
                <AccordionButton
                  py={2}
                  whiteSpace="nowrap"
                  transition="width s ease-in-out, opacity 1s ease-in-out"
                  paddingInline={0}
                  px={2}
                  bg="none"
                >
                  <Box>
                    <CustomIcon
                      label={link.icon ?? ""}
                      width="24px"
                      height="24px"
                    />
                    {opened && (
                      <span style={{ marginLeft: "8px" }}>{link.label}</span>
                    )}
                  </Box>

                  {opened && <Spacer />}
                  {opened && <AccordionIcon />}
                </AccordionButton>
                <AccordionPanel paddingInline={0}>
                  <div className="flex flex-col">
                    {link.subLinks.map((sublink) => (
                      <NavigationLink
                        key={sublink.label}
                        link={sublink}
                        onClick={onClick}
                        isSub
                      />
                    ))}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            )}
          </ListItem>
        ))}
      </List>
    </Accordion>
  );
};

export default SidebarLinks;
