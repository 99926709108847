import {
  Box,
  Button,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  VStack
} from "@chakra-ui/react";
import { useState } from "react";
import useFirestoreCollection from "../../../../hooks/useFirestoreCollection";
import { Prompt } from "../../../../types/studio/prompts";
import { DATABASE } from "../../../../types/tables-data";

const PromptsManager = () => {
  const { documents, saveDocument, deleteDocument } =
    useFirestoreCollection<Prompt>(DATABASE.PROMPTS, {
      listen: true
    });

  const prompts = documents?.filter((prompt) => prompt?.type !== undefined);

  const [selectedType, setSelectedType] = useState("default_prompts");
  const [newPrompt, setNewPrompt] = useState({
    promptName: "",
    promptText: ""
  });
  const [editing, setEditing] = useState<Prompt | null>(null);
  const toast = useToast();

  const handleSave = async () => {
    if (!newPrompt.promptName || !newPrompt.promptText) {
      toast({
        title: "Error",
        description: "Both fields are required",
        status: "error",
        duration: 3000
      });
      return;
    }

    await saveDocument({
      promptName: newPrompt.promptName,
      promptText: newPrompt.promptText,
      type: selectedType,
      access: { public: true },
      created_at: Date.now()
    });

    setNewPrompt({ promptName: "", promptText: "" });
  };

  const handleUpdate = async () => {
    if (!editing) return;

    await saveDocument({
      promptName: editing.promptName,
      promptText: editing.promptText
    });

    setEditing(null);
  };

  const filteredPrompts =
    prompts?.filter((prompt: Prompt) => prompt.type === selectedType) || [];

  return (
    <Box p={5} bg="background.white" borderRadius="lg" boxShadow="md" w="100%">
      <VStack spacing={4} align="stretch">
        {/* Type Selection */}
        <Select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
        >
          <option value="default_prompts">Default Prompts</option>
          <option value="rewrite_options">Rewrite Options</option>
          <option value="tone_options">Tone Options</option>
        </Select>

        {/* Add or Edit Prompt */}
        <Box display="flex" gap={2}>
          <Input
            placeholder="Prompt Name"
            value={editing ? editing.promptName : newPrompt.promptName}
            onChange={(e) =>
              editing
                ? setEditing({ ...editing, promptName: e.target.value })
                : setNewPrompt({ ...newPrompt, promptName: e.target.value })
            }
          />
          <Input
            placeholder="Prompt Text"
            value={editing ? editing.promptText : newPrompt.promptText}
            onChange={(e) =>
              editing
                ? setEditing({ ...editing, promptText: e.target.value })
                : setNewPrompt({ ...newPrompt, promptText: e.target.value })
            }
          />
          <Button
            colorScheme="blue"
            onClick={editing ? handleUpdate : handleSave}
          >
            {editing ? "Save" : "Add"}
          </Button>
        </Box>

        {/* Prompts Table */}
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Prompt Name</Th>
              <Th>Prompt Text</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredPrompts.map((prompt: Prompt) => (
              <Tr key={prompt.id}>
                <Td>{prompt.promptName}</Td>
                <Td>{prompt.promptText}</Td>
                <Td>
                  <Button
                    colorScheme="yellow"
                    onClick={() => setEditing(prompt)}
                  >
                    Edit
                  </Button>
                  <Button
                    colorScheme="red"
                    ml={2}
                    onClick={() => deleteDocument(prompt.id)}
                  >
                    Delete
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>
    </Box>
  );
};

export default PromptsManager;
