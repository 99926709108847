import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../api/firebaseApi";
import useFirestoreDocument from "../../hooks/useFirestoreDocument";
import useManageCaseResearch from "../../hooks/useManageCaseResearch";
import { useSubscribeToJob } from "../../hooks/useSubscribeToJob";
import { removeResearchTopic } from "../../redux/case-research/caseResearchSlice";
import { caseResearchSelectors } from "../../redux/case-research/selectors";
import {
  ExtractionJob,
  ExtractionReducer,
  EXTRACTIONSTATES,
  GenerationStatesDescription
} from "../../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../../redux/extraction-jobs/selectors";
import { DATABASE, visaFromPath, VISAVALUE } from "../../types/tables-data";
import { CustomBox } from "../common/CustomBox";
import { DocumentsInfoModal } from "../common/DocumentsInfoModal";
import { AiGenerateIcon } from "../common/Icons";
import { SpecialHeading } from "./PanelLayout";
import { CaseResearchDatatable } from "./caseResearch/Datatable";
import { ReplaceDialog } from "./individualDrafts/sopDrafts/ReplaceDialog";

export const CaseResearch = () => {
  const { id, visaType } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { topics, extractedData } = useSelector(
    caseResearchSelectors.selectAll
  );

  const { initiateJob } = useSubscribeToJob();

  const {
    generateTopics,
    generateSummary,
    deleteResearchTopic,
    onSaveCaseResearchTopic
  } = useManageCaseResearch(id, true);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedTopicTitle, setSelectedTopicTitle] = useState<string>();
  const [selectedTopicId, setSelectedTopicId] = useState<string>();
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);

  const [showEnhanceTopic, setShowEnhanceTopic] = useState<boolean>(false);
  const [missingRequirement, setMissingRequirement] = useState<
    string | undefined
  >(undefined);

  const caseResearchJob = useSelector((state: ExtractionReducer) =>
    extractionSelectors.selectJobById(state, "Case Research")
  );

  const statusMsg = useMemo(() => {
    const state = caseResearchJob?.status?.status as EXTRACTIONSTATES;
    return (
      GenerationStatesDescription[state] ??
      GenerationStatesDescription.NotStarted
    );
  }, [caseResearchJob]);

  const status = caseResearchJob?.status?.status;

  const isGenerated = useMemo(
    () => status === EXTRACTIONSTATES.Completed,
    [status]
  );

  const isGenerating = useMemo(
    () => status === EXTRACTIONSTATES.Processing,
    [status]
  );

  const handleGenerateSummary = async () => {
    await generateSummary();
  };

  const handleGenerateTopics = async (topicId?: string, topicName?: string) => {
    // Initiating db real-time tracking
    const topicType = topicName
      ? `Case Research Generation : ${topicName}`
      : "Case Research Generation";

    const docRef = topicId
      ? doc(
          db,
          DATABASE.CASE_RESEARCH,
          `${id}`,
          `research_topics`,
          `${topicId}`
        )
      : doc(db, DATABASE.CASE_RESEARCH, `${id}`);

    const job: ExtractionJob = {
      id: topicId ?? "Case Research",
      type: topicType,
      docRef,
      jobRef: doc(db, DATABASE.ACTIVE_JOBS, `${id}`, `jobs`, `Case Research`),
      status: { status: EXTRACTIONSTATES.Pending },
      toastTitle: "Generation status",
      toastDesc: `Case research generation is completed`
    };
    await initiateJob(job);
    if (topicId) {
      await generateTopics(topicId);
    } else {
      await generateTopics();
    }
  };

  const openDialog = () => {
    if (isGenerated) {
      setShowConfirmationDialog(true);
      return;
    }
    handleGenerateTopics();
    setShowConfirmationDialog(false);
  };

  const handleDeleteTopic = async (topicId: string) => {
    setConfirmModalOpen(false);
    removeResearchTopic(topicId);
    deleteResearchTopic(topicId);
  };

  const openDeleteConfirm = (topicTitle: string, topicId: string) => {
    setSelectedTopicTitle(topicTitle);
    setSelectedTopicId(topicId);
    setConfirmModalOpen(true);
  };

  const {
    isOpen: isExtractDeleteOpen,
    onOpen: onExtractDeleteOpen,
    onClose: onExtractDeleteClose
  } = useDisclosure();

  // TODO : generate formdata interfaces for all visa questions in db
  interface Prong1Data {
    areaOfExpertise?: string;
  }
  interface FormData {
    generalField?: string;
    applicantField?: string;
    prong1Data?: Prong1Data;
    academicField?: string;
  }

  const { document: questionnaireData, listenAndFetch } =
    useFirestoreDocument<FormData>(DATABASE.QUESTIONNAIRES, id!);

  useEffect(() => {
    listenAndFetch();
  }, []);

  const onViewSummaries = (researchArea?: string, topicId?: string) => {
    // Construct the base URL
    let url = `/individual/${id}/${visaType}/case-research/summaries`;

    // Create URL search parameters
    const params = new URLSearchParams();
    if (researchArea) params.set("researchArea", researchArea);
    if (topicId) params.set("topicId", topicId);

    // Append the parameters if any were added
    const queryString = params.toString();
    if (queryString) {
      url += `?${queryString}`;
    }

    // Navigate to the constructed URL
    navigate(url);
  };

  const isGenerateDisabled = useMemo(() => {
    if (!visaType) return true;

    const checkIfRequirementsExist = (
      condition: string | undefined,
      requirement: string
    ) => {
      if (!condition || condition.trim() === "") {
        setMissingRequirement(requirement);
        return true;
      }
      return false;
    };

    switch (visaFromPath[visaType]) {
      case VISAVALUE.O1A:
        return checkIfRequirementsExist(
          questionnaireData?.applicantField,
          "General field of work"
        );
      case VISAVALUE.O1B:
        return checkIfRequirementsExist(
          questionnaireData?.applicantField,
          "Field of work"
        );
      case VISAVALUE.EB1A:
        return checkIfRequirementsExist(
          questionnaireData?.generalField,
          "General field of extraordinary ability"
        );
      case VISAVALUE.EB1B:
        return checkIfRequirementsExist(
          questionnaireData?.academicField,
          "Academic Field"
        );
      case VISAVALUE.EB2NIW:
        return checkIfRequirementsExist(
          questionnaireData?.prong1Data?.areaOfExpertise,
          "Area of expertise"
        );
      default:
        return true;
    }
  }, [questionnaireData, visaType]);

  return (
    <>
      <Flex justifyContent="space-between">
        <SpecialHeading
          title="Case research"
          withInfo={<DocumentsInfoModal title="Case Research" />}
        />
        <Button
          mt={4}
          size="sm"
          variant="secondaryOutline"
          px={6}
          leftIcon={<MdOutlineLibraryBooks />}
          onClick={() => {
            navigate(`/individual/${id}/${visaType}/case-research/summaries`);
          }}
        >
          View All Summaries
        </Button>
      </Flex>
      <Flex flexDirection="column" rowGap={10} mt={2}>
        <Box>
          <CaseResearchDatatable
            topics={topics}
            onViewSummaries={onViewSummaries}
            onEnhanceTopic={(topicId: string, topicTitle: string) => {
              setSelectedTopicId(topicId);
              setSelectedTopicTitle(topicTitle);
              setShowEnhanceTopic(true);
            }}
            onDeleteTopic={(topicId: string) => {
              setSelectedTopicId(topicId);
              setConfirmModalOpen(true);
            }}
          />

          {isGenerateDisabled && missingRequirement && (
            <CustomBox type="info" mt={10}>
              <Text>
                The{" "}
                <Text as="span" fontWeight="bold">
                  {missingRequirement}
                </Text>{" "}
                field in the questionnaires is required to generate case
                research. Please provide the necessary information.
              </Text>
            </CustomBox>
          )}
          <Flex className="flex justify-center mt-12" columnGap={4}>
            {/* <Button my={4} bg="primary.darkBlue" color="white" leftIcon={<AddIcon h={4} />}>
            Add manually
          </Button> */}
            <Button
              isLoading={isGenerating}
              isDisabled={isGenerateDisabled}
              leftIcon={<Box as={AiGenerateIcon} boxSize={6} fill="white" />}
              onClick={openDialog}
            >
              {isGenerating ? "Generating ..." : "Generate Case Research"}
            </Button>
          </Flex>
        </Box>
        <Modal
          closeOnOverlayClick={false}
          isOpen={confirmModalOpen}
          onClose={() => {
            setConfirmModalOpen(false);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              Are you sure you want to delete this topic?
            </ModalBody>

            <ModalFooter>
              <Button
                variant="secondaryOutline"
                onClick={() => {
                  setConfirmModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="destructiveFilled"
                ml={3}
                onClick={() => handleDeleteTopic(selectedTopicId!)}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          closeOnOverlayClick={false}
          isOpen={showEnhanceTopic}
          onClose={() => {
            setShowEnhanceTopic(false);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Re-generate research topic</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              You already generated research for this topic : "
              {selectedTopicTitle}". Would you like to replace it?
            </ModalBody>

            <ModalFooter>
              <Button
                variant="secondaryOutline"
                onClick={() => {
                  setShowEnhanceTopic(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primaryFilled"
                ml={3}
                onClick={() => {
                  setShowEnhanceTopic(false);
                  handleGenerateTopics(selectedTopicId, selectedTopicTitle);
                }}
              >
                Replace
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <ReplaceDialog
          isOpen={showConfirmationDialog}
          onConfirm={() => {
            setShowConfirmationDialog(false);
            handleGenerateTopics();
          }}
          setShowConfirmationDialog={setShowConfirmationDialog}
          isLoading={false}
          jobType="generated"
          jobName="Case Research"
        />
      </Flex>
    </>
  );
};
