import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack
} from "@chakra-ui/react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { FaLightbulb } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import {
  autoFillQuestionnaireWithDocuments,
  getPreselectedDocuments
} from "../../../api/OpenAIApi";
import { filterActiveDocuments } from "../../../helpers/helpers";
import { documentSelectors } from "../../../redux/documents/selectors";
import { DATABASE, DataDocs, VISAVALUE } from "../../../types/tables-data";
import CustomTooltip from "../../common/CustomTooltip";
import { DropdownGeneric } from "../../common/DropdownGeneric";
import { AiGenerateIcon } from "../../common/Icons";
import { EXTRACTIONSTATES } from "../../../redux/extraction-jobs/extractionJobsSlice";

// ==========================
// Component Props
// ==========================
type Props = {
  isOpen: boolean;
  onClose: () => void;
};

// ==========================
// Component
// ==========================
const AIQuestionnaireModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { id: uid, visaType } = useParams();

  // ==========================
  // Redux and Firestore References
  // ==========================
  const groupedDocuments = useSelector(documentSelectors.groupedDocuments);
  const questionnaireDocRef = doc(db, DATABASE.QUESTIONNAIRES, uid!);

  // ==========================
  // State Management
  // ==========================
  const [allDocuments, setAllDocuments] = useState<DataDocs[]>(
    filterActiveDocuments(groupedDocuments)
  );
  const [previouslySelectedDocuments, setPreviouslyDocuments] = useState<
    DataDocs[]
  >([]);
  const [documents, setDocuments] = useState<DataDocs[]>([]);
  const [suggestedDocuments, setSuggestedDocuments] = useState<DataDocs[]>([]);
  const [autoFilling, setAutoFilling] = useState(false);
  const [defaultPreselectMap, setDefaultPreselectMap] = useState<any>({});

  // ==========================
  // Handlers and Helpers
  // ==========================

  // Get documents previously used in questionnaire from Firestore
  const getUsedDocuments = async () => {
    if (questionnaireDocRef) {
      const documentSnapshot = await getDoc(questionnaireDocRef);
      const data = documentSnapshot.data();
      let usedDocumentsIds: string[] = [];

      if (data && "usedDocuments" in data && data.usedDocuments.length > 0) {
        usedDocumentsIds = data.usedDocuments as string[];
      } else {
        usedDocumentsIds = [];
      }

      const usedDocs = allDocuments.filter((doc) =>
        usedDocumentsIds.includes(doc.id!)
      );

      setPreviouslyDocuments(usedDocs);
      setDocuments(usedDocs);
    }
  };

  // Update Firestore with selected documents
  const updateUsedDocuments = async () => {
    if (questionnaireDocRef) {
      const selectedDocumentsIds = documents.map((doc) => doc.id);
      await updateDoc(questionnaireDocRef, {
        usedDocuments: selectedDocumentsIds
      });
    }
  };

  // Autofill questionnaire using selected documents
  const handleAutoFill = async () => {
    setAutoFilling(true);
    autoFillQuestionnaireWithDocuments(documents, uid!);
    onClose();
    setAutoFilling(false);
    await updateUsedDocuments();
    await getUsedDocuments();
  };

  // Apply AI-suggested documents that haven't already been selected
  const handleApplySuggestions = () => {
    const notSelectedSuggestions = suggestedDocuments.filter(
      (doc) => !documents.some((selected) => selected.id === doc.id)
    );
    const updatedDocuments = [...documents, ...notSelectedSuggestions];
    setDocuments(updatedDocuments);
  };

  // Reset form to saved state on close
  const handleClose = () => {
    setDocuments(previouslySelectedDocuments);
    onClose();
  };

  const remainingSuggestedCount = suggestedDocuments.filter(
    (doc) => !documents.some((selected) => selected.id === doc.id)
  ).length;

  // ==========================
  // Effects
  // ==========================

  // Fetch default preselection map based on visaType
  useEffect(() => {
    const fetchDefaultPreselectMap = async () => {
      if (visaType) {
        const preselectedDocs = await getPreselectedDocuments(
          visaType as VISAVALUE
        );
        setDefaultPreselectMap(preselectedDocs);
      }
    };
    fetchDefaultPreselectMap();
  }, [visaType]);

  interface PreselectedDoc {
    category: string;
    document_type: string;
  }

  // Update all documents when Redux document store changes
  useEffect(() => {
    setAllDocuments(filterActiveDocuments(groupedDocuments));
  }, [groupedDocuments]);

  // When modal opens, re-fetch used documents
  useEffect(() => {
    if (isOpen) {
      getUsedDocuments();
    }
  }, [isOpen]);

  // Match suggested documents based on visaType and preselect map
  useEffect(() => {
    const preselectArray = defaultPreselectMap[visaType as VISAVALUE];

    if (!allDocuments.length || !preselectArray?.length) return;

    const matches = allDocuments.filter((doc) => {
      if (!doc.autoTitle || doc.status?.status === EXTRACTIONSTATES.Processing)
        return false;

      return preselectArray.some((entry: PreselectedDoc) => {
        const hasDocType =
          entry.document_type !== undefined && entry.document_type !== "";
        const hasCategory =
          entry.category !== undefined && entry.category !== "";

        const matchDocType = hasDocType
          ? entry.document_type === doc.type
          : false;
        const matchCategory = hasCategory
          ? entry.category === doc.criterion
          : false;

        if (hasDocType && hasCategory) {
          return matchDocType && matchCategory;
        }
        return matchDocType || matchCategory;
      });
    });

    setSuggestedDocuments(
      matches.sort((a, b) =>
        (a.autoTitle ?? "").localeCompare(b.autoTitle ?? "")
      )
    );
  }, [allDocuments, defaultPreselectMap, visaType]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent maxW={remainingSuggestedCount ? "4xl" : "lg"}>
        <ModalHeader textColor="text.color">
          Generate Questionnaires Using AI
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap={4}>
            {/* Left Side - Suggested Documents */}
            {remainingSuggestedCount > 0 && (
              <Box flex={0.7}>
                <Flex align="center" columnGap={2} mb={2}>
                  <WarningIcon color="text.display.light" />
                  <Text
                    fontWeight={500}
                    fontSize="sm"
                    color="text.display.light"
                  >
                    Suggested documents ({remainingSuggestedCount})
                  </Text>
                </Flex>
                <Flex wrap="wrap" gap={2} maxH="50vh" overflowY="auto" p={2}>
                  {suggestedDocuments
                    .filter(
                      (doc) =>
                        !documents.some((selected) => selected.id === doc.id)
                    )
                    .map((doc) => (
                      <CustomTooltip label={doc.autoTitle || ""} key={doc.id}>
                        <Tag size="md" variant="brand">
                          <TagLabel>{doc.autoTitle}</TagLabel>
                        </Tag>
                      </CustomTooltip>
                    ))}
                </Flex>
                <Flex justifyContent="center">
                  <Button
                    width="fit-content"
                    size="sm"
                    mt={3}
                    onClick={handleApplySuggestions}
                    variant="secondaryOutline"
                  >
                    Add to selection
                  </Button>
                </Flex>
              </Box>
            )}
            {/* Vertical Divider */}
            {remainingSuggestedCount > 0 && <Box w="1px" bg="stroke.stroke2" />}

            {/* Right Side Content */}
            <Box flex={1}>
              {/* Warning Section */}
              <Box bg="errors.red2" borderRadius="md" p={4} mb={4}>
                <Flex align="center" gap={4}>
                  <WarningIcon fill="white" color="red" boxSize={5} />
                  <Box>
                    <Text fontWeight="bold" color="text.color" mb={2}>
                      Questionnaire Regeneration
                    </Text>
                    <Text color="text.color" fontWeight={400}>
                      Selecting a new document will overwrite current answers
                      and remove any prior edits to the questionnaire.
                    </Text>
                  </Box>
                </Flex>
              </Box>

              {/* Document Selection Dropdown */}
              <Text mb={2}>
                Choose a document to pre-fill the questionnaire
              </Text>
              <Flex w="100%">
                {allDocuments ? (
                  <DropdownGeneric
                    list={allDocuments.filter(
                      (doc) =>
                        doc.autoTitle &&
                        doc.status?.status !== EXTRACTIONSTATES.Processing
                    )}
                    mergingField="id"
                    searchField="autoTitle"
                    groupBy="super_class"
                    checkedItems={documents.map((doc) => doc.id!)}
                    getSelectedItems={(selectedItems) => {
                      setDocuments(selectedItems);
                    }}
                    singleSelect={false}
                    withCheck
                    withArrow
                    withFilter
                    isDisabled={autoFilling}
                  >
                    {({ derivedList, handleSelect }) => (
                      <VStack
                        align="stretch"
                        spacing={3}
                        maxH="30vh"
                        overflowY="auto"
                      >
                        {derivedList
                          .filter((item) => item.checked)
                          .map((item) => (
                            <Flex key={item.id} align="flex-start" width="100%">
                              <CustomTooltip label={item.value}>
                                <Tag
                                  size="lg"
                                  variant="brand"
                                  maxW="600px"
                                  overflow="hidden"
                                >
                                  <TagLabel>{item.value}</TagLabel>

                                  <TagCloseButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSelect(item);
                                    }}
                                  />
                                </Tag>
                              </CustomTooltip>
                            </Flex>
                          ))}
                      </VStack>
                    )}
                  </DropdownGeneric>
                ) : (
                  <Skeleton />
                )}
              </Flex>
            </Box>
          </Flex>
        </ModalBody>

        {/* Footer Actions */}
        <ModalFooter>
          <Button mr={3} onClick={handleClose} variant="secondaryOutline">
            Cancel
          </Button>
          <Button
            variant="primaryFilled"
            isDisabled={documents.length === 0}
            isLoading={autoFilling}
            leftIcon={<Box as={AiGenerateIcon} boxSize={6} fill="white" />}
            onClick={handleAutoFill}
          >
            Re-Generate with AI
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AIQuestionnaireModal;
