export const draftOptionsConfig = {
  tones: [
    "Enthusiastic",
    "Formal",
    "Objective",
    "Very professional",
    "Professional"
  ],

  lengths: ["Short", "Long"],

  creativities: ["Conservative", "Creative", "Very creative"],

  greetings: [
    "Dear Madam or Sir",
    "Dear Officer",
    "Dear Immigration Officer",
    "Dear USCIS"
  ]
};
