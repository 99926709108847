import axiosInstance from "./axiosConfig";

export const convertFromHtml = async ({
  format,
  individualId,
  html,
  firebasePath,
  documentField,
  htmlField,
  superClass
}: {
  format: string;
  individualId: string;
  html?: string;
  firebasePath?: string;
  documentField?: string;
  htmlField?: string;
  superClass?: string;
}): Promise<{ filename: string; data: ArrayBuffer }> => {
  try {
    const requestBody: Record<string, any> = {
      format,
      individual_id: individualId
    };

    if (html) requestBody.html = html;
    if (firebasePath) requestBody.firebase_path = firebasePath;
    if (documentField) requestBody.document_field = documentField;
    if (htmlField) requestBody.html_field = htmlField;
    if (superClass) requestBody.super_class = superClass;

    const response = await axiosInstance.post(
      "/convert_from_html",
      requestBody,
      { responseType: "arraybuffer" }
    );

    // Extract Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    console.log({ contentDisposition });
    let filename = "unknown";

    if (contentDisposition) {
      // Match the filename in the Content-Disposition header
      const filenameMatch = contentDisposition.match(/filename="?(.+?)"?$/);
      if (filenameMatch) {
        [, filename] = filenameMatch;
      }
    }

    const result = {
      filename,
      data: response.data as ArrayBuffer
    };
    return result;
  } catch (error) {
    console.error(`Error generating ${format} file:`, error);
    throw error;
  }
};
