import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Skeleton,
  Spacer,
  Text,
  useToast,
  VStack
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { convertFromHtml } from "../../../api/draftsApi";
import { triggerDownload } from "../../../helpers/file_helpers";
import useManageCaseResearch from "../../../hooks/useManageCaseResearch";
import { updateTopicSummary } from "../../../redux/case-research/caseResearchSlice";
import { caseResearchSelectors } from "../../../redux/case-research/selectors";
import {
  ExtractionReducer,
  EXTRACTIONSTATES
} from "../../../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../../../redux/extraction-jobs/selectors";
import { CaseResearchTopic } from "../../../types/case-research";
import { DATABASE } from "../../../types/tables-data";
import { Dropdown } from "../../common/Dropdown";
import { Editor } from "../../common/Editor";
import { SectionWrapper } from "../../common/SectionWrapper";
import { TopicRow } from "./TopicRow";

export const CaseResearchSummary = () => {
  const { id, visaType } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const topics = useSelector(caseResearchSelectors.selectTopics);
  const [searchParams, setSearchParams] = useSearchParams();
  const { onSaveCaseResearchTopic } = useManageCaseResearch(id);

  const researchArea = searchParams.get("researchArea") || "";
  const topicId = searchParams.get("topicId") || "";

  const [filteredTopics, setFilteredTopics] = useState<CaseResearchTopic[]>([]);
  const [tempTopics, setTempTopics] = useState<
    Record<string, { summary: string; summaryHtml: string }>
  >({});
  const [loading, setLoading] = useState(false);
  const [isSavingLocal, setIsSavingLocal] = useState(false);

  const panelRefs = useRef<Record<string, HTMLButtonElement | null>>({});
  const [initialized, setInitialized] = useState(false);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);

  useEffect(() => {
    if (!initialized && filteredTopics.length > 0) {
      const initialTemp: Record<
        string,
        { summary: string; summaryHtml: string }
      > = {};
      filteredTopics.forEach((topic) => {
        initialTemp[topic.id] = {
          summary: topic.summary || "",
          summaryHtml: topic.summaryHtml || ""
        };
      });
      setTempTopics(initialTemp);
      setInitialized(true);
    }
  }, [filteredTopics, initialized]);

  useEffect(() => {
    if (isSavingLocal) return;
    setLoading(true);
    let filtered = topics;
    if (researchArea) {
      filtered = topics.filter((topic) => topic.super_class === researchArea);
    }
    setFilteredTopics(filtered);
    setTimeout(() => setLoading(false), 500);
  }, [researchArea, topics, isSavingLocal]);

  useEffect(() => {
    if (filteredTopics.length > 0) {
      if (topicId) {
        // Scroll to the specified topic if topicId exists
        const targetEl = panelRefs.current[topicId];
        if (targetEl) {
          setTimeout(() => {
            targetEl.scrollIntoView({ behavior: "smooth", block: "start" });
          }, 200);
        }
      } else if (researchArea) {
        // Expand and scroll to the first topic of the research area if only researchArea is provided
        const firstTopic = filteredTopics[0]?.id;
        if (firstTopic) {
          setTimeout(() => {
            const targetEl = panelRefs.current[firstTopic];
            if (targetEl) {
              targetEl.scrollIntoView({ behavior: "smooth", block: "start" });
            }
          }, 200);

          // Update searchParams to reflect the selected topic
          setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set("topicId", firstTopic);
            return newParams;
          });
        }
      }
    }
  }, [topicId, researchArea, filteredTopics, setSearchParams]);

  const setPanelRef = useCallback(
    (id: string) => (el: HTMLButtonElement | null) => {
      panelRefs.current[id] = el;
      console.log(`DEBUG_SCROLL: Ref set for id ${id}:`, el);
      // Optionally trigger scroll immediately if this is the one:
      if (id === topicId && el) {
        console.log("DEBUG_SCROLL: Immediately scrolling because ref is set");
        el.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }
    },
    [topicId]
  );

  const handleResearchAreaChange = (area: string) => {
    const newParams = new URLSearchParams(searchParams);
    if (area === "All Research Topics") {
      newParams.delete("researchArea");
    } else {
      newParams.set("researchArea", area);
    }
    setSearchParams(newParams);
  };

  const handleDownloadAll = async (format: string) => {
    if (!id) return;
    setIsDownloadingAll(true);

    try {
      const { data, filename } = await convertFromHtml({
        format,
        individualId: id,
        firebasePath: `${DATABASE.CASE_RESEARCH}/${id}/research_topics`,
        documentField: undefined,
        htmlField: "summaryHtml",
        ...(researchArea && { superClass: researchArea })
      });
      triggerDownload(data, filename, format);
    } catch (error) {
      console.error(`Error generating ${format} file:`, error);
    } finally {
      setIsDownloadingAll(false);
    }
  };

  const caseResearchJob = useSelector((state: ExtractionReducer) =>
    extractionSelectors.selectJobById(state, "Case Research")
  );

  const status = caseResearchJob?.status?.status;

  const isGenerated = useMemo(
    () => status === EXTRACTIONSTATES.Completed,
    [status]
  );

  const isGenerating = useMemo(
    () => status === EXTRACTIONSTATES.Processing,
    [status]
  );

  return (
    <SectionWrapper
      backTargetPage="Case Research"
      bg="white"
      withIndividualEditBox
      borderTopRadius={8}
      position="relative"
      onClick={() => {
        navigate(`/individual/${id}/${visaType}/case-research`);
      }}
    >
      {/* Dropdown to select research area */}
      <Box
        py={2}
        pl={8}
        mb={4}
        bg="accent.lapis"
        color="white"
        borderTopRadius={8}
        position="absolute"
        top="0"
        left={0}
        right={0}
      >
        <Dropdown
          withSearch={false}
          variant="clearButton"
          list={[
            "All Research Topics",
            ...new Set(topics.map((t) => t.super_class || "Uncategorized"))
          ]}
          onItemSelect={handleResearchAreaChange}
          selectedValue={researchArea || "All Research Topics"}
          title=""
        />
      </Box>

      {/* Research topics summaries */}
      <Box p={6} mt={12}>
        <Flex flexDirection="column" rowGap={4}>
          <HStack justifyContent="space-between">
            <Text fontWeight={600} fontSize="x-large">
              Summaries
            </Text>
            {/* Per Tina and Khalil we're going to comment out this functionality
            for now since we're not sure of the usefulness yet */}
            {/* {isDownloadingAll ? (
              <CircularProgress isIndeterminate color="primary.blue" />
            ) : (
              <ExportMenu
                disabled={isGenerating}
                isDownloading={false}
                handleDownload={handleDownloadAll}
                leftIcon={<DownloadIcon />}
                text="Download All Summaries"
              />
            )} */}
          </HStack>
          <VStack>
            {filteredTopics.map((topic, index) => (
              <Box key={topic.id} borderRadius="md" width="100%">
                {/* Accordion to display the topic */}
                <Accordion
                  defaultIndex={
                    topicId
                      ? topicId === topic.id
                        ? [0]
                        : []
                      : researchArea && index === 0
                      ? [0]
                      : []
                  }
                  allowToggle
                >
                  <AccordionItem mb={4}>
                    {({ isExpanded }) => (
                      <>
                        {/* Flashing Skeleton for TopicRow when loading */}
                        {topic.status?.status ===
                        EXTRACTIONSTATES.Processing ? (
                          <Skeleton
                            height="40px"
                            borderRadius="md"
                            width="100%"
                          />
                        ) : (
                          <AccordionButton ref={setPanelRef(topic.id)}>
                            <AccordionIcon mr={2} />
                            <TopicRow
                              topicKey={topic.topicTitle}
                              super_class={topic.super_class}
                              bg="white"
                            />
                            <Spacer />
                          </AccordionButton>
                        )}

                        {/* Topic Content */}
                        <AccordionPanel>
                          {topic?.status?.status ===
                          EXTRACTIONSTATES.Processing ? (
                            // Show Skeleton when topic is loading
                            <Skeleton
                              height="120px"
                              borderRadius="md"
                              width="100%"
                            />
                          ) : (
                            // Show actual content when topic is not loading
                            <VStack alignItems="start" pt={2} px={8} rowGap={2}>
                              <Text fontWeight={600}>AI-generated Summary</Text>
                              <Editor
                                customHeight="40vh"
                                text={topic.summaryHtml || topic.summary || ""}
                                saveText={(html, plainText) =>
                                  onSaveCaseResearchTopic(topic.id, {
                                    summary: plainText,
                                    summaryHtml: html
                                  })
                                }
                                editorId={`editor-${topic.id}`}
                              />
                            </VStack>
                          )}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </Accordion>
              </Box>
            ))}
          </VStack>
        </Flex>
      </Box>
    </SectionWrapper>
  );
};
