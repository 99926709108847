import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Prompt } from "../../types/studio/prompts";

export interface PromptsState {
  prompts: Prompt[];
  selectedPromptId?: string | undefined;
  selectedPrompt?: Prompt | undefined;
}

const initialState: PromptsState = {
  prompts: [],
  selectedPromptId: undefined
};

export interface PromptsReducer {
  prompts: PromptsState;
}

export const promptsSlice = createSlice({
  name: "prompts",
  initialState,
  reducers: {
    setPrompts: (
      state,
      action: PayloadAction<{
        prompts: Prompt[];
      }>
    ) => {
      const { prompts } = action.payload;

      if (!Array.isArray(prompts)) {
        throw new Error("Templates payload must be an array");
      }
      state.prompts = prompts;
    },
    setSelectedPromptId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedPromptId = action.payload;
    },
    setSelectedPrompt: (state, action: PayloadAction<Prompt | undefined>) => {
      state.selectedPrompt = action.payload;
    },
    clearPrompts: (state) => {
      state.prompts = [];
      state.selectedPromptId = undefined;
    },
    removePrompt: (state, action: PayloadAction<string>) => {
      state.prompts = state.prompts.filter(
        (prompt) => prompt.id !== action.payload
      );
      if (state.selectedPromptId === action.payload) {
        state.selectedPromptId = undefined;
      }
    }
  }
});

export const {
  setPrompts,
  setSelectedPromptId,
  setSelectedPrompt,
  clearPrompts,
  removePrompt
} = promptsSlice.actions;

export default promptsSlice.reducer;
